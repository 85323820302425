<template>
    <div v-if="mode === 'messages'">
        <ul>
            <li class="selection-item" v-for="(item, index) in items" :key="index">
                {{ `${index}:${item.type} ${item.time}` }}
            </li>
        </ul>
    </div>
    <div v-else>
        <ul>
            <li class="selection-item" v-for="(item, index) in items" :key="index">
                {{ item.replaceAll('"', '').replaceAll("'", '') }}
            </li>
        </ul>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    export default defineComponent({
        props: {
            items: Array,
            mode: String
        }
    })
</script>

<style>
    .selection-item{
        height: 17px;
        font-family: ds-digi;
        font-size: 12pt;
    }
</style>