<template>
    <div class="receive-ack-container">
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">INDIVIDUAL CALL</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <div v-if="!alarmOff">
            <p v-if="isComply" class="mode-text">Received ACK</p>
            <p v-else class="unable-text">Received unable ACK</p>
            <p class="timer-text">Elapsed: {{timer}}</p>
        </div>
        <div v-else-if="alarmOff && isComply">
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p class="channel-text">{{ channelString }}</p>
            <div class="divider"></div>
            <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
            <p class="elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div v-else>
            <p class="watt-text">INDIVIDUAL CALL FAILED</p>
        </div>
    </div>
    <div v-if="!alarmOff" class="alarm-btn">
        <p>ALARM OFF</p>
    </div>
    <div v-else class="exit-btn">
        <p>EXIT</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    data(){
        return {
            timer: '00:00:00',
            countdownTimer: '',
            timerInterval: undefined,
            countdownInterval: undefined,
            seconds: 0,
        }
    },
    computed: {
        isComply(){
            return !this.$store.getters['ds100/acknowledgementDetails'].complyMode.includes('cant');
        },
        acknowledgementDetails(){
            return this.$store.getters['ds100/acknowledgementDetails'];
        },
        channelString(){
            return this.acknowledgementDetails.channel.join('');
        },
        transmittedCall(){
            return this.$store.getters['transmittedCall'];
        }
    },
    mounted(){
        this.startTimer();
    },
    methods: {
        startTimer(){
            this.timerInterval = setInterval(() => {
                this.interval();
            },1000);
        },
        interval(){
            this.seconds++;
            var hour = Math.floor(this.seconds / 3600);
            var minute = Math.floor((this.seconds - hour * 3600) / 60);
            var updSecond = this.seconds - (hour * 3600 + minute * 60);
            this.timer = (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (updSecond < 10 ? '0' + updSecond : updSecond);
        }
    },
    props: {
        alarmOff: Boolean,
        isHigh: Boolean,
        isSending: Boolean,
        isBusy: Boolean,
        backlightColor: String,
    }
})
</script>

<style scoped>
.busy-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        top:8px;
        left: 5px;
        color: v-bind('backlightColor') !important;
        padding-left: 4px;
        padding-right: 2px;
        border-radius: 20%;
        text-align: center;
        background-color: rgb(37, 37, 37);
    }
.exit-btn{
    position:absolute;
    bottom: 3px;
    left: 12px;
    background-color: rgb(37, 37, 37);
    height: 15px;
    width: 45px;
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: v-bind('backlightColor') !important;
    font-size: 12pt;
    font-family: ds-digi;
}
.elapsed-timer-text{
    position: absolute;
    top: 55px;
    left: 5px;
    font-family: m423-text-font;
    font-size: 9pt;
}
.from-text{
    position: absolute;
    top: 38px;
    left: 5px;
    font-family: m423-text-font;
    font-size: 9pt;
}
.divider{
    position: absolute;
    top: 35px;
    width: 100%;
    height: 1px;
    border: 1px solid rgb(37, 37, 37);
}
.watt-text{
    position: absolute;
    font-size: 8pt;
    top: 8px;
}
.channel-text{
    position: absolute;
    font-size: 45pt;
    top:-20px;
    left: 160px;
    font-family: m423-font;
}
.alarm-btn{
    position:absolute;
    bottom: 3px;
    left: 12px;
    background-color: rgb(37, 37, 37);
    height: 15px;
    width: 90%;
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: v-bind('backlightColor') !important;
    font-size: 12pt;
    font-family: ds-digi;
}
.timer-text{
    position: absolute;
    top: 35px;
    left: 48px;
}
.unable-text{
    position: absolute;
    top: 10px;
    left: 35px;
}
.mode-text{
    position: absolute;
    top: 10px;
    left: 60px;
}
.menu-header-title{
    color: v-bind('backlightColor') !important;
    font-family: m423-font;
    font-size: 16pt;
    padding-top: 5px;
}
.receive-ack-container{
    position: absolute;
    height: 95px;
    width: 227px;
    left: 5px;
    top: 5px;
    border: 2px solid rgb(37, 37, 37);;
    border-top: 20px solid rgb(37, 37, 37);;
}  
.menu-header{
    position: absolute;
    width: 225px;
    height: 20px;
    top: -20px;
    left: -3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-left: 2%;
    padding-right: 1%;
    text-align: center;
}
</style>