<template>
  <device-select v-if="!submitted" @submit="(devices) => submit(devices)"></device-select>
  <device-container v-else></device-container>
</template>

<script>
import { defineComponent } from 'vue';
import DeviceSelect from '@/components/DeviceSelect.vue';
import DeviceContainer from '@/components/DeviceContainer.vue';

export default defineComponent({
  name: 'PlaygroundView',

  computed:{
      submitted(){
        return this.$store.getters['submitted'];
      }
  },

  components: {
    DeviceSelect,
    DeviceContainer
  },

  methods: {
    submit(selectedDevices) {
      const selectedCount = selectedDevices.filter((device) => device.selected === true).length;
      if(selectedCount === 0) return;
      this.$store.dispatch("setSelectedDevices", selectedDevices);
      this.$store.dispatch("toggleSubmit");
    }
  }
});
</script>
