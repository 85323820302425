<template>
    <div v-if="isSelectingCategory">
        <p class="title">{{ '<Select a subject>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" :items="subjects"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isSelectingAddressIdCategory">
        <p class="title">{{ '<Select a subject>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" :items="addressCategories"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <DS100AddressId 
        v-if="addressModeActive" 
        :mode="addressMode" 
        :inputStep="inputStep" 
        :entryLatitude="entryLatitude" 
        :entryLongitude="entryLongitude"
        :addressToShowIndex="addressToShowIndex"
    />
    <div v-if="isOffsetTime">
        <p class="title">{{ '<Offset time>' }}</p>
        <DS100Input class="input-container" :items="offsetTime" :selectedIndex="inputStep" :isActive="true"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isBrightnessMenu">
        <p class="title">{{ '<Brightness>' }}</p>
        <p class="level-title">Level:</p>
        <DS100Input class="brightness-input" :items="brightnessLevel" :selectedIndex="inputStep" :isActive="true"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isContrastMenu">
        <p class="title">{{ '<Contrast>' }}</p>
        <p class="level-title">Level:</p>
        <DS100Input class="brightness-input" :items="contrastLevel" :selectedIndex="inputStep" :isActive="true"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isMMSIMenu">
        <p class="title">{{ '<MMSI check>' }}</p>
        <p class="level-title">{{ mmsi }}</p>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import DS100Selection from '../base/DS100Selection.vue'
import DS100AddressId from '../base/DS100AddressId.vue'
import DS100Input from '../base/DS100Input.vue'

export default defineComponent({
    computed: {
        addressModeActive(){
            return (
                this.$props.addressMode === 'add' || 
                this.$props.addressMode === 'delete' || 
                this.$props.addressMode === 'show' || 
                this.$props.addressMode === 'addGroup' || 
                this.$props.addressMode === 'deleteGroup' || 
                this.$props.addressMode === 'showGroup'
            );
        },
        offsetTime(){
            return this.$store.getters['ds100/offsetTime'];
        },
        brightnessLevel(){
            return this.$store.getters['ds100/brightnessLevel'];
        },
        contrastLevel(){
            return this.$store.getters['ds100/contrastLevel'];
        },
        mmsi(){
            return this.$store.getters['ds100/mmsi'];
        }
    },
    data(){
        return {
            subjects: [
                'Address ID',
                'Offset time',
                'Brightness',
                'Contrast',
                'MMSI Check'
            ],
            addressCategories: [
                'Add an address ID',
                'Delete address ID',
                'Add a group ID',
                'Delete group ID'
            ]
        }
    },
    props: {
        isSelectingCategory: Boolean,
        isSelectingAddressIdCategory: Boolean,
        addressMode: String,
        inputStep: Number,
        entryLatitude: Boolean,
        entryLongitude: Boolean,
        addressToShowIndex: Number,
        isOffsetTime: Boolean,
        isBrightnessMenu: Boolean,
        isContrastMenu: Boolean,
        isMMSIMenu: Boolean,
    },
    components: {
        DS100Selection,
        DS100AddressId,
        DS100Input,
    }
})
</script>

<style scoped>
    .brightness-input{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 70px;
        top: 30px;
    }
    .level-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 10px !important;
        line-height: 14px !important;
        top: 7px !important;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .id-header{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .multi-line-exit{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 90px;
        line-height: 20px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        line-height: 20px;
        top: 5px;
    }
    .title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
.selection-cursor{
    position: absolute;
    font-family: ds-digi;
    font-size: 20pt;
    left: 7px;
    top: 14px;
}
.selection-list{
    max-height: 140px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    left: 20px;
    top: 22px;
    font-family: ds-digi;
}
</style>