<template>
    <div class="sub-menu-container">
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">{{currentMenu.toUpperCase()}}</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <div class="distress-note-container">
            Push <div class="distress">DISTRESS</div> for 3 sec
        </div>
        <v-list class="sub-menu-list">
            <v-list-item density class="menu-list-item id-item pt-0 pb-0 pl-1">{{ 'MMSI: ' + distressCallDetails.id }}</v-list-item>
            <v-list-item density id="category-item" class="menu-list-item category pt-0 pb-0 pl-1">{{ distressCallDetails.category }}</v-list-item>
            <v-list-item v-if="!distressPositionAndTime.position.lat && !distressPositionAndTime.position.long" density id="position-item" class="menu-list-item position pt-0 pb-0 pl-1">No Position data</v-list-item>
            <v-list-item v-if="distressPositionAndTime.position.lat" density id="position-item" class="menu-list-item position pt-0 pb-0 pl-1">{{ distressPositionAndTime.position.lat }}</v-list-item>
            <v-list-item v-if="distressPositionAndTime.position.long" density id="position-item" class="menu-list-item position pt-0 pb-0 pl-1">{{ distressPositionAndTime.position.long }}</v-list-item>
            <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ distressPositionAndTime.utc ? distressPositionAndTime.utc + ' UTC' : 'No Time Data' }}</v-list-item>
            <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ 'Telephony' }}</v-list-item>
        </v-list>
        <div class="menu-actions">
            <div class="action exit">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back" v-if="!isWaitingForAck">
                <p class="action-text">BACK</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    computed: {
        distressCallDetails(){
            return this.$store.getters['m423/distressCallDetails'];
        },
        distressPositionAndTime(){
            return this.$store.getters['m423/distressPositionInputDetails'];
        },
    },
    watch: {
        menuSelectionIndex(value){
            if(value <= 4){
                var element = document.querySelector('.id-item');
                if(value == 1){
                    element = document.querySelector('.category');
                }
                else if (value == 2){
                    element = document.querySelector('.position');
                }
                this.scrollIntoViewIfNeeded(element);
            }
        },
    },
    props: {
        currentMenu: String,
        menuSelectionIndex: Number,
        backlightColor: String
    },
    methods: {
        scrollIntoViewIfNeeded(focusedItem) {
            if (focusedItem) {
                focusedItem.scrollIntoView();
            }
        }
    }
})
</script>

<style scoped>
    .menu-list-item{
        font-size: 8pt;
        white-space: nowrap;
        font-family: m423-text-font;
        color: rgb(37, 37, 37);
    }
    .sub-menu-list{
        border: 2px solid rgb(37, 37, 37);
        position: absolute;
        height: 50px;
        overflow-y: auto;
        overflow-x: hidden;
        right: 2px;
        top: 20px;
        width: 98%;
        padding-top: 1px;
        background-color: v-bind('backlightColor') !important;
    }
    .distress{
        font-family: m423-text-font;
        font-size: 5pt;
        background-color: rgb(37, 37, 37);
        border-radius: 5px;
        height: 17px;
        color: v-bind('backlightColor') !important;
        padding: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .distress-note-container{
        display: flex;
        flex-direction: row;
        font-family: m423-text-font;
        font-size: 8pt;
        position: absolute;
        top: 2px;
        left: 5px;
        align-items: center;
    }
    .distress-note{
        position: absolute;
    }
   .enter{
        position:absolute;
        right: 5px;
    }
    .back{
        position:absolute;
        left: 62px;
    }
    .exit{
        position:absolute;
        left: 5px;
    }
.menu-actions{
        position: absolute;
        display: flex;

        align-items: center;
        bottom: -24px;
        left: -2px;
        width: 227px;
        height: 20px;
    }
.action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
.sub-menu-container{
    position: absolute;
    height: 90px;
    width: 227px;
    left: 5px;
    top: 5px;
    border: 2px solid rgb(37, 37, 37);;
    border-top: 20px solid rgb(37, 37, 37);;
}
.menu-header{
    position: absolute;
    width: 225px;
    height: 20px;
    top: -20px;
    left: -3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-left: 2%;
    padding-right: 1%;
    text-align: center;
}
.menu-header-title{
    color: v-bind('backlightColor') !important;
    font-family: m423-font;
    font-size: 16pt;
    padding-top: 5px;
}
</style>