<template>
    <div v-if="isSelectingType">
        <p class="title">{{ '<Select a nature>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" :items="distressTypes"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="submitDistress">
        <p class="multi-line-title">{{ '<Push and hold' }} <br/> {{ '[DISTRESS] for 5 sec>' }}</p>
        <p class="nature-title">Nature of distress</p>
        <p class="nature">Undesignated</p>
        <p v-if="latitudeIsNull" class="ds100-home-mnl-time">MNL: No time data</p>
        <p v-if="latitudeIsNull" class="ds100-home-mnl-position">No position data</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-time">MNL:</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-utc">UTC</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-time-format">{{formattedTime}}</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-lat">Lat</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-lat-format">{{'' + latitude[0] + latitude[1] + ' ° ' + latitude[2] + latitude[3] + ` ' ` + latitude[4]}}</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-lon">Lon</p>
        <p v-if="!latitudeIsNull" class="ds100-home-mnl-lon-format">{{'' + longitude[0] + longitude[1] + longitude[2] + ' ° ' + longitude[3] + longitude[4] + ` ' ` + longitude[5]}}</p>
    </div>
    <div v-if="distressSubmitted">
        <p class="multi-line-title">{{ '<Distress alert' }} <br/> {{ 'Completed>' }}</p>
        <p class="nature-title">Now waiting for acknowledgement</p>
        <p class="exit-text">{{ '<CLR->Exit>' }}</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import DS100Selection from '../base/DS100Selection.vue'
export default defineComponent({
    components: {
        DS100Selection
    },
    props: {
        isSelectingType: Boolean,
        submitDistress: Boolean,
        distressSubmitted: Boolean,
    },
    computed: {
        latitude(){
            return this.$store.getters['ds100/position'].latitude;
        },
        longitude(){
            return this.$store.getters['ds100/position'].longitude;
        },
        time(){
            return this.$store.getters['ds100/time'];
        },
        formattedTime(){
            let formatted = '';
            this.time.forEach(time => {
                formatted += JSON.stringify(time);
            });
            return formatted.replaceAll('"','');
        },
        distressSettings(){
            return this.$store.getters['ds100/distressSettings'];
        },
        distressTypes(){
            return this.$store.getters['ds100/distressTypes'];
        },
        latitudeIsNull(){
            return JSON.stringify(this.latitude) === JSON.stringify(['_','_','_','_','N']); 
        },
        longitudeIsNull(){
            return JSON.stringify(this.longitude) === JSON.stringify(['_','_','_','_','_','E']); 
        },
    }
})
</script>

<style scoped>
.ds100-home-mnl-lon-format{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 132px;
    top: 105px;
}
.ds100-home-mnl-lon{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 60px;
    top: 105px;
}
.ds100-home-mnl-lat-format{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 132px;
    top: 87px;
}
.ds100-home-mnl-lat{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 60px;
    top: 87px;
}
.ds100-home-mnl-time-format{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 132px;
    top: 70px;
}
.ds100-home-mnl-utc{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 60px;
    top: 70px;
}
.ds100-home-mnl-position{
    position: absolute;
    font-family: ds-digi;
    font-size: 16pt;
    left: 49px;
    top: 85px;
}
.ds100-home-mnl-time{
    position: absolute;
    font-family: ds-digi;
    font-size: 16pt;
    left: 8px;
    top: 65px;
}
.nature{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 55px;
    top: 50px;
}
.multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 10px !important;
        line-height: 14px !important;
        top: 7px !important;
    }
.nature-title{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 10px;
    top: 35px;
}
.channel-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 45px;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .id-header{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .multi-line-exit{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 90px;
        line-height: 20px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        line-height: 20px;
        top: 5px;
    }
    .title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
.selection-cursor{
    position: absolute;
    font-family: ds-digi;
    font-size: 20pt;
    left: 7px;
    top: 14px;
}
.selection-list{
    max-height: 140px;
    height: 90px;
    overflow: hidden;
    position: absolute;
    left: 20px;
    top: 22px;
    font-family: ds-digi;
}
</style>