import { createRouter, createWebHistory } from 'vue-router'
import PlaygroundView from '../views/PlaygroundView.vue'

const routes = [
  {
    path: '/',
    name: 'playground',
    component: PlaygroundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
