<template>
    <div class="sub-menu-container">
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">{{currentMenu.toUpperCase()}}</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <p class="title">{{title}}</p>
        <div class="channel-input-container">
            <p class="chan-text">CHAN:</p>
            <div class="chan-input">
                <p class="channel-value">{{ channel }}</p>
                <div class="bar">
                    <v-icon class="icon-up" size="30">mdi-menu-up</v-icon>
                    <v-icon class="icon-down" size="30">mdi-menu-down</v-icon>
                </div>
            </div>
        </div>
        <div class="menu-actions">
            <div class="action exit">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back">
                <p class="action-text">BACK</p>
            </div>
            <div class="action enter">
                <p class="action-text">ENT</p>
            </div>
        </div>
    </div>
</template> 

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    computed: {
        channel(){
            return this.$store.getters['m423/channels'][this.$props.channelIndex].number;
        },
        title(){
            if(this.$props.currentMenu === 'Individual Call'){
                return 'Intership CH';
            }
            else if(this.$props.currentMenu === 'Group Call'){
                return 'Intership CH';
            }
            else if(this.$props.currentMenu === 'All Ships Call'){
                return 'ITU CH';
            }
            return 'Intership CH';
        }
    },
    props: {
        currentMenu: String,
        channelIndex: Number,
        backlightColor: String,
    }
})
</script>

<style scoped>
.chan-text{
    color: rgb(37, 37, 37);
}
.icon-down{
    position: absolute;
    bottom: -12px;
    right: -7px;
    color: rgb(37, 37, 37);
}
.icon-up{
    position: absolute;
    top: 1px;
    right: -7px;
    color: rgb(37, 37, 37);
}
.channel-value{
    position: absolute;
    top: -8px;
    left: 20px;
    color: rgb(37, 37, 37);
}
.bar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    height: 30px;
    width: 10px;
    right: 0px;
    top: -8px;
    align-items: center;
}
.chan-input{
    z-index: 10;
    width: 90px;
    border: 2px solid rgb(37, 37, 37);
    padding-left: 7px;
    height: 30px;
    margin-left: 10px;
    font-size: 25pt;
    display: flex;
    align-items: center;
    position: absolute;
    left: 60px;
    top: 2px;
}
.channel-input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 17px;
    left: 5px;
    font-size: 20pt;
    text-align: center;
    align-content: center;
    font-family: m423-font;
}
.title{
    position: absolute;
    left: 5px;
    font-size: 8pt;
    font-family: m423-text-font;
    color: rgb(37, 37, 37);
}
.enter{
        position:absolute;
        right: 5px;
    }
    .back{
        position:absolute;
        left: 62px;
    }
    .exit{
        position:absolute;
        left: 5px;
    }
.menu-actions{
        position: absolute;
        display: flex;

        align-items: center;
        bottom: -24px;
        left: -2px;
        width: 227px;
        height: 20px;
    }
.action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
.sub-menu-container{
    position: absolute;
    height: 90px;
    width: 227px;
    left: 5px;
    top: 5px;
    border: 2px solid rgb(37, 37, 37);;
    border-top: 20px solid rgb(37, 37, 37);;
    background-color: v-bind('backlightColor') !important;
}
.menu-header{
    position: absolute;
    width: 225px;
    height: 20px;
    top: -20px;
    left: -3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-left: 2%;
    padding-right: 1%;
    text-align: center;
}
.menu-header-title{
    color: v-bind('backlightColor') !important;
    font-family: m423-font;
    font-size: 16pt;
    padding-top: 5px;
}
</style>