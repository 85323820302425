<template>
    <div class="menu-container" v-if="menuStep === 0">
        <div :style="'color:' + backlightColor" class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p :style="'color:' + backlightColor" class="menu-header-title">MENU</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <v-icon class="selected-icon" size="60">{{icon}}</v-icon>
        <v-list :style="{'background-color': backlightColor}" id="myList" class="menu-list">
            <v-list-item ref="menu-item" :key="index" :class="{ 'focused-item': index === menuSelectionIndex }" density append-icon="mdi-menu-right" class="menu-list-item pt-0 pb-0 pl-1" v-for="(item, index) in menuItems">{{ item.title }}</v-list-item>
        </v-list>
    </div>
    <div class="sub-menu-container" v-else>
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">{{currentMenu.toUpperCase()}}</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <v-list id="myList" class="sub-menu-list" v-if="currentMenu == 'DSC Calls' || currentMenu == 'DSC Settings' || currentMenu == 'Radio Settings' || currentMenu == 'Configuration' || currentMenu == 'Alarm' || currentMenu == 'Noise Cancel' || currentMenu == 'Inactivity Timer'">
            <v-list-item ref="menu-item" :key="index" :class="{ 'focused-item': index === menuSelectionIndex }" density class="menu-list-item pt-0 pb-0 pl-1" v-for="(item, index) in menuItems">
                <template v-slot:append>
                    <p class="menu-item-value" :class="{ 'focused-item': index === menuSelectionIndex }">{{ item.value }}</p>
                    <v-icon class="menu-item-append-icon">mdi-menu-right</v-icon>
                </template>
                {{ item.title }}
            </v-list-item>
        </v-list>
        <v-list class="sub-menu-list" v-else-if="isDistressInfoDialog">
            <v-list-item density class="menu-list-item id-item pt-0 pb-0 pl-1">{{ 'MMSI: ' + mmsi }}</v-list-item>
            <v-list-item density id="atis-item" class="menu-list-item atis pt-0 pb-0 pl-1">Undesignated</v-list-item>
            <v-list-item density id="lat-item" class="menu-list-item lat pt-0 pb-0 pl-1">{{ 'LAT: ' + (positionInputDetails.position.lat ? positionInputDetails.position.lat : '--°--.-----') }}</v-list-item>
            <v-list-item density id="lon-item" class="menu-list-item lon pt-0 pb-0 pl-1">{{ 'LON: ' + (positionInputDetails.position.long ? positionInputDetails.position.long : '---°--.-----') }}</v-list-item>
            <v-list-item density id="utc-item" class="menu-list-item utc pt-0 pb-0 pl-1">{{ 'UTC: ' + (positionInputDetails.utc ? positionInputDetails.utc : '--:--') }}</v-list-item>
            <v-list-item density id="sog-item" class="menu-list-item sog pt-0 pb-0 pl-1">Telephony</v-list-item>
        </v-list>
        <v-list id="myList" class="sub-menu-list" v-else-if="currentMenu === 'Individual ACK' || currentMenu === 'Position ACK' || currentMenu === 'Test ACK' || currentMenu === 'CH 16 Switch' || currentMenu === 'DSC Data Output' || currentMenu === 'Safety' || currentMenu === 'Routine' || currentMenu === 'Warning' || currentMenu === 'Self Terminate' || currentMenu === 'Discrete' || currentMenu === 'Scan Type' || currentMenu === 'Scan Timer' || currentMenu === 'Dual/Tri-Watch' || currentMenu === 'CHAN Group' || currentMenu === 'Key Beep' || currentMenu === 'RX' || currentMenu === 'TX' || currentMenu === 'Not DSC Related' || currentMenu === 'DSC Related'">
            <v-list-item ref="menu-item" :key="index" :class="{ 'focused-item': index === menuSelectionIndex }" density class="menu-list-item pt-0 pb-0 pl-5" v-for="(item, index) in menuItems">
                <template v-slot:prepend>
                    <v-icon class="menu-item-prepend-icon" v-if="item.selected">mdi-check</v-icon>
                </template>
                {{ item.title}}
            </v-list-item>
        </v-list>
        <v-list id="myList" class="sub-menu-list" v-else-if="currentMenu === 'Individual Id' || currentMenu === 'Group Id'">
            <p v-if="!menuItems.length > 0" class="no-message">No ID</p>
            <v-list-item ref="menu-item" :key="index" :class="{ 'focused-item': index === menuSelectionIndex }" density :prepend-icon="item.icon" append-icon="mdi-menu-right" class="menu-list-item pt-0 pb-0 pl-1" v-for="(item, index) in menuItems">{{ item.title ? item.title : item.time + ' ' + item.type}}</v-list-item>
        </v-list>
        <div v-else-if="isInputSql">
            <p class="value-text">{{sliderValue}}</p>
            <m423SliderInput
                class="slider-input"
                :value="sliderValue"
                :min="0"
                :max="10"
            />
            <div class="dots-sql"><p class="dot-sql" v-for="item in 10" :key="item">.</p></div>
        </div>
        <div v-else-if="isInputBacklight">
            <p class="backlight-value-text">{{sliderValue > 0 ? sliderValue : 'OFF'}}</p>
            <m423SliderInput
                class="slider-input"
                :value="sliderValue"
                :min="0"
                :max="7"
            />
            <v-button v-if="sliderValue > 0" icon class="icon"><v-icon>mdi-weather-sunny</v-icon></v-button>
            <v-button v-else icon class="icon"><v-icon>mdi-weather-sunny-off</v-icon></v-button>
            <div class="dots-backlight"><p class="dot-backlight" v-for="item in 7" :key="item">.</p></div>
        </div>
        <div v-else-if="isInputContrast">
            <p class="backlight-value-text">{{sliderValue > 0 ? sliderValue : 'OFF'}}</p>
            <m423SliderInput
                class="slider-input"
                :value="sliderValue"
                :min="1"
                :max="8"
            />
            <v-button v-if="sliderValue > 1" icon class="icon"><v-icon>mdi-circle-half-full</v-icon></v-button>
            <v-button v-else icon class="icon"><v-icon>mdi-circle-half-full</v-icon></v-button>
            <div class="dots-backlight"><p class="dot-backlight" v-for="item in 7" :key="item">.</p></div>
        </div>
        <div v-else-if="currentMenu === 'DSC Loop Test'">
            <p class="ok-text" v-if="loopIsOk">OK</p>
            <div class="loop-text-container">
                <p class="loop-text">Push</p>
                <div class="ent-btn">
                    <p class="enter-btn-text">ENT</p>
                </div>
                <p class="loop-text">to Start</p>
            </div>
        </div>
        <m423FreeInput
            v-else-if="currentMenu == 'UTC Offset'"
                :items="utcOffset"
                :selectedIndex="selectedIndex"
                :isActive="true"
            />
        <div v-else-if="currentMenu == 'Remote ID'">
            <p class="remote-id">{{ remoteId }}</p>
        </div>
        <v-list class="sub-menu-list" v-else-if="currentMenu == 'MMSI/GPS Info'">
            <v-list-item density class="menu-list-item id-item pt-0 pb-0 pl-1">{{ 'MMSI: ' + mmsi }}</v-list-item>
            <v-list-item density id="atis-item" class="menu-list-item atis pt-0 pb-0 pl-1">{{ 'ATIS: ' + atis }}</v-list-item>
            <v-list-item density id="lat-item" class="menu-list-item lat pt-0 pb-0 pl-1">{{ 'LAT: ' + (positionInputDetails.position.lat ? positionInputDetails.position.lat : '--°--.-----') }}</v-list-item>
            <v-list-item density id="lon-item" class="menu-list-item lon pt-0 pb-0 pl-1">{{ 'LON: ' + (positionInputDetails.position.long ? positionInputDetails.position.long : '---°--.-----') }}</v-list-item>
            <v-list-item density id="utc-item" class="menu-list-item utc pt-0 pb-0 pl-1">{{ 'UTC: ' + (positionInputDetails.utc ? positionInputDetails.utc : '--:--') }}</v-list-item>
            <v-list-item density id="sog-item" class="menu-list-item sog pt-0 pb-0 pl-1">{{ 'SOG: ' + (sog ? sog : '---.-kt') }}</v-list-item>
            <v-list-item density id="cog-item" class="menu-list-item cog pt-0 pb-0 pl-1">{{ 'COG: ' + (cog ? cog : '---.-°') }}</v-list-item>
            <v-list-item density id="sw-item" class="menu-list-item sw pt-0 pb-0 pl-1">{{ 'SW Ver: ' + (swver ? swver : '1.001') }}</v-list-item>
        </v-list>
        <v-list class="sub-menu-list" v-else-if="currentMenu == 'RCVD Call Log' && menuStep == 3 && !isDeleteLogView">
            <v-list-item density class="menu-list-item type pt-0 pb-0 pl-1">{{ selectedCallLog.type }}</v-list-item>
            <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ 'From: ' + selectedCallLog.from }}</v-list-item>
            <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.category }}</v-list-item>
            <v-list-item v-if="selectedCallLog.lat" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.lat }}</v-list-item>
            <v-list-item v-if="selectedCallLog.lon" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.lon }}</v-list-item>
            <v-list-item v-if="selectedCallLog.utc" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.utc + ' UTC' }}</v-list-item>
            <v-list-item v-if="selectedCallLog.channel" density class="menu-list-item pt-0 pb-0 pl-1">{{ 'CH ' + selectedCallLog.channel }}</v-list-item>
            <v-list-item density class="menu-list-item channel-type pt-0 pb-0 pl-1">{{ selectedCallLog.channelType }}</v-list-item>
            <v-list-item v-if="selectedCallLog.type == 'Distress'" density class="menu-list-item pt-0 pb-0 pl-1">RNG: ---.-nm</v-list-item>
            <v-list-item v-if="selectedCallLog.type == 'Distress'" density class="menu-list-item brg pt-0 pb-0 pl-1">BRG: ---.-°</v-list-item>
        </v-list>
        <v-list class="sub-menu-list" v-else-if="currentMenu == 'TX Call Log' && menuStep == 3 && !isDeleteLogView">
            <v-list-item density class="menu-list-item type pt-0 pb-0 pl-1">{{ selectedCallLog.type }}</v-list-item>
            <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ 'From: ' + selectedCallLog.from }}</v-list-item>
            <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.category }}</v-list-item>
            <v-list-item v-if="selectedCallLog.lat" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.lat }}</v-list-item>
            <v-list-item v-if="selectedCallLog.lon" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.lon }}</v-list-item>
            <v-list-item v-if="selectedCallLog.utc" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.utc + ' UTC' }}</v-list-item>
            <v-list-item v-if="selectedCallLog.channel" density class="menu-list-item pt-0 pb-0 pl-1">{{ 'CH ' + selectedCallLog.channel }}</v-list-item>
            <v-list-item density class="menu-list-item channel-type pt-0 pb-0 pl-1">{{ selectedCallLog.channelType }}</v-list-item>
            <v-list-item v-if="selectedCallLog.type == 'Distress'" density class="menu-list-item pt-0 pb-0 pl-1">RNG: ---.-nm</v-list-item>
            <v-list-item v-if="selectedCallLog.type == 'Distress'" density class="menu-list-item brg pt-0 pb-0 pl-1">BRG: ---.-°</v-list-item>
        </v-list>
        <v-list id="myList" class="sub-menu-list" v-else-if="!isDeleteLogView">
            <p v-if="!menuItems.length > 0" class="no-message">No Message</p>
            <v-list-item ref="menu-item" :key="index" :class="{ 'focused-item': index === menuSelectionIndex }" density :prepend-icon="item.icon" append-icon="mdi-menu-right" class="menu-list-item pt-0 pb-0 pl-1" v-for="(item, index) in menuItems">{{ item.title ? item.title : item.time ? item.time + ' ' + item.type : '--:--' + ' ' + item.type}}</v-list-item>
        </v-list>
        <div v-if="isDeleteLogView" class="delete-log-view">
            <p class="delete-text">ARE YOU SURE?</p>
        </div>
        <div v-if="currentMenu == 'Individual Id' || currentMenu == 'Group Id'" class="menu-actions">
            <div class="action add">
                <p class="action-text">ADD</p>
            </div>
            <div class="action edit">
                <p class="action-text">EDIT</p>
            </div>
            <div class="action delete">
                <p class="action-text">DEL</p>
            </div>
        </div>
        <div v-else-if="isDeleteLogView" class="menu-actions">
            <div class="action-big ok">
                <p class="action-text">OK</p>
            </div>
            <div class="action-big cancel">
                <p class="action-text">CANCEL</p>
            </div>
        </div>
        <div v-else class="menu-actions">
            <div class="action exit">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back">
                <p class="action-text">BACK</p>
            </div>
            <div v-show="(currentMenu == 'RCVD Call Log' || currentMenu == 'TX Call Log' || currentMenu == 'UTC Offset') && menuStep != 3" class="action enter">
                <p class="action-text">ENT</p>
            </div>
            <div v-show="(currentMenu == 'RCVD Call Log' || currentMenu == 'TX Call Log') && menuStep == 3" class="action delete">
                <p class="action-text">DEL</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import m423SliderInput from '../../base/M423_SliderInput.vue'
import m423FreeInput from '../../base/M423_FreeInput.vue'

export default defineComponent({
    components: {
        m423SliderInput,
        m423FreeInput,
    },
    computed: {
        icon(){
            return this.menuItems[this.$props.menuSelectionIndex].icon;
        },
        utcOffset(){
            return this.$store.getters['m423/utcOffset'];
        },
        remoteId(){
            return this.$store.getters['m423/remoteId'];
        },
        mmsi(){
            return this.$store.getters['m423/mmsi']
        },
        atis(){
            return this.$store.getters['m423/atis'];
        },
        positionInputDetails(){
            return this.$store.getters['m423/positionInputDetails'];
        },
        sog(){
            return this.$store.getters['m423/sog'];
        },
        cog(){
            return this.$store.getters['m423/cog'];
        },
        swver(){
            return this.$store.getters['m423/swver'];
        },
    },
    beforeUpdate(){
        if(this.$props.menuSelectionIndex < this.menuItems.length){
            var element = document.querySelectorAll('.menu-list-item')[this.$props.menuSelectionIndex];
            this.scrollIntoViewIfNeeded(element);
        }
    },
    watch: {
        menuSelectionIndex(value){
            if(this.$props.currentMenu === 'MMSI/GPS Info' || this.$props.isDistressInfoDialog){
                let element = undefined;
                if(value <= 4){
                    element = document.querySelector('.id-item');
                    if(value == 1){
                        element = document.querySelector('.atis');
                    }
                    else if (value == 2){
                        element = document.querySelector('.lat');
                    }
                    else if (value == 3){
                        element = document.querySelector('.lon');
                    }
                    else if (value == 4){
                        element = document.querySelector('.utc');
                    }
                    this.scrollIntoViewIfNeededForInfo(element);
                }
            } 
            else if(this.$props.currentMenu == 'RCVD Call Log' || this.$props.currentMenu == 'TX Call Log' && this.$props.menuStep == 3){
                let element = undefined;
                if(value == 1){
                    if(this.$props.selectedCallLog.type == 'Distress'){
                        element = document.querySelector('.brg');
                    }
                    else{
                        element = document.querySelector('.channel-type');
                    }
                }
                else if (value == 0){
                    element = document.querySelector('.type');
                }
                this.scrollIntoViewIfNeeded(element);
            }
            else {
                if(value < this.menuItems.length){
                    var element = document.querySelectorAll('.menu-list-item')[value];
                    this.scrollIntoViewIfNeeded(element);
                }
            }
        }
    },
    props: {
        menuSelectionIndex: Number,
        menuItems: Array,
        menuStep: Number,
        currentMenu: String,
        isInputSql: Boolean,
        isInputBacklight: Boolean,
        sliderValue: Number,
        loopIsOk: Boolean,
        isInputContrast: Boolean,
        selectedIndex: Number,
        isDistressInfoDialog: Boolean,
        backlightColor: String,
        selectedCallLog: Object,
        isDeleteLogView: Boolean
    },
    methods: {
        scrollIntoViewIfNeededForInfo(focusedItem) {
            if (focusedItem) {
                focusedItem.scrollIntoView();
            }
        },
        scrollIntoViewIfNeeded(focusedItem) {
            var listContainer = null;
            if(document.querySelector('.menu-list')){
                listContainer = document.querySelector('.menu-list');
            }
            else if(document.querySelector('.sub-menu-list')){
                listContainer = document.querySelector('.sub-menu-list');
            }

            if (focusedItem) {
                const containerRect = listContainer.getBoundingClientRect();
                const itemRect = focusedItem.getBoundingClientRect();

                if (itemRect.top < containerRect.top || itemRect.bottom > containerRect.bottom) {
                    focusedItem.scrollIntoView({ behavior: "smooth", block: 'end' });
                }
            }
        }
    }
})
</script>

<style>
    .action-big{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 105px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
    .cancel{
        position:absolute;
        right: 5px;
    }
    .ok{
        position:absolute;
        left: 5px;
    }
    .delete-log-view{
        border: 3px solid rgb(37, 37, 37);
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .delete-text{
        font-family: m423-text-font;
        font-size: 10pt;
    }
    .menu-list-item{
        font-size: 12pt;
        white-space: nowrap;
        font-family: m423-text-font;
        color: rgb(37, 37, 37);
    }
    .remote-id{
        font-family: m423-text-font;
        font-size: 10pt;
        position: absolute;
        left: 5px;
        top: 2px;
        color: v-bind('backlightColor') !important;
        padding-left: 3px;
        background-color: #000000;
        opacity: 0.9;
    }
    .icon{
        position: absolute;
        top: 27px;
        left: 15px;
        z-index: 15 !important;
        color: rgb(37, 37, 37);
    }
    .dots-backlight{
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20px;
        top: 43px;
        left: 43px;
    }
    .dot-backlight{
        margin-left: 18px;
    }
    .backlight-value-text{
        position: absolute;
        right: 20px;
        font-family: m423-text-font;
    }
    .ok-text{
        position: absolute;
        left: 100px;
        top: 20px;
        font-family: m423-text-font;
        font-size: 8pt;
    }
    .enter-btn-text{
        font-family: m423-text-font;
        font-size: 7pt;
        padding-top: 1px;
        padding-left: 6.5px;
        color: v-bind('backlightColor') !important;
    }
    .ent-btn{
        width: 35px;
        height: 15px;
        background-color: rgb(37, 37, 37);
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 25%;
    }
    .loop-text{
        font-family: m423-text-font;
        font-size: 8pt;
    }
    .loop-text-container{
        position: absolute;
        display: flex;
        flex-direction: row;
        bottom: 15px;
        left: 43px;
        justify-content: center;
        align-items: center;
    }
    .dots-sql{
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20px;
        top: 43px;
        left: 43px;
    }
    .dot-sql{
        margin-left: 12px;
    }
    .value-text{
        position: absolute;
        right: 20px;
        font-family: m423-text-font;
    }
    .slider-input{
        position: absolute;
        left: 75px;
        top: 30px;
    }
    .menu-item-prepend-icon{
        position: absolute;
        left: 2px;
    }
    .menu-item-append-icon{
        position: absolute;
        right: -5px;
    }
    .no-message{
        font-family: m423-text-font;
        font-size: 8pt;
        margin-left: 5px;
    }
    .add{
        position:absolute;
        left: 5px;
    }
    .edit{
        position:absolute;
        left: 62px;
    }
    .delete{
        position:absolute;
        left: 119px;
    }
    .enter{
        position:absolute;
        right: 5px;
    }
    .back{
        position:absolute;
        left: 62px;
    }
    .exit{
        position:absolute;
        left: 5px;
    }
    .action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
    .menu-actions{
        position: absolute;
        display: flex;

        align-items: center;
        bottom: -24px;
        left: -2px;
        width: 227px;
        height: 20px;
    }
    .sub-menu-container{
        position: absolute;
        height: 90px;
        width: 227px;
        left: 5px;
        top: 5px;
        border: 2px solid rgb(37, 37, 37);;
        border-top: 20px solid rgb(37, 37, 37);;
    }
    .menu-header{
        position: absolute;
        width: 225px;
        height: 20px;
        top: -20px;
        left: -3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        padding-left: 2%;
        padding-right: 1%;
        text-align: center;
    }
    .focused-item {
        background-color: rgb(37, 37, 37); /* Customize the focused item's style here */
        color: v-bind('backlightColor') !important;
    }
    .v-list-item__content{
        min-width: fit-content;
    }
    .v-list-item__append{
        transform: scale(1.5) !important;
        width:40px;
        position: absolute;
        right: 0px;
        
    }
    a:focus{
        background-color: black !important;
    }
    .selected-icon{
        position: absolute;
        top: 15px;
        left: 10px;
        color: rgb(37, 37, 37);
    }
    .menu-list-item:focus{
        background-color: gray;
    }
    .menu-list-item{
        font-size: 8pt;
        white-space: nowrap;
        font-family: m423-text-font;
        color: rgb(37, 37, 37);
    }
    .menu-item-value{
        position: absolute;
        right: 5px;
        font-size: 5pt;
        white-space: nowrap;
        font-family: m423-text-font;
        color: rgb(37, 37, 37);
    }
    .arrow{
        color: rgb(37, 37, 37);
    }
    .sub-menu-list::-webkit-scrollbar{
        background-color: v-bind('backlightColor') !important;
        width: 5px;
    }

    .sub-menu-list::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(37, 37, 37);;
        border: 1px solid #000;
    }
    
    .sub-menu-list{
        position: absolute;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        right: 0;
        width: 100%;
        padding-top: 4px;
        background-color: v-bind('backlightColor') !important;
    }

    .menu-list::-webkit-scrollbar{
        background-color: v-bind('backlightColor') !important;
        width: 5px;
    }

    .menu-list::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(37, 37, 37);;
        border: 1px solid #000;
    }
    
    .menu-list{
        position: absolute;
        height: 50px;
        min-height: 85px;
        overflow-y: hidden;
        overflow-x: hidden;
        right: 0;
        width: 140px;
        padding-top: 4px;
        padding-right: 4px;
        background-color: v-bind('backlightColor') !important;
    }
    .menu-container{
        position: absolute;
        height: 112px;
        width: 227px;
        left: 5px;
        top: 5px;
        border: 2px solid rgb(37, 37, 37);;
        border-top: 20px solid rgb(37, 37, 37);;
    }
    .menu-header-title{
        color: v-bind('backlightColor') !important;
        font-family: m423-font;
        font-size: 16pt;
        padding-top: 5px;

    }
    .icon-left{

    }
    .icon-right{

    }
    .image{
        height: 50px;
        width: 50px;
    }
</style>