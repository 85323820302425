<template>
    <div class="list">
        <p class="arrow-left">{{ '<' }}</p>
        <div class="list-item-container" v-for="item in menuItemsToShow" :key="item">
            <p :style="'color:' + backlightColor" class="list-item">{{item}}</p>
        </div>
        <p class="arrow-right">{{ '>' }}</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    data(){
        return{
            menuItemsToShow: [],
            items: [
                'SCAN',
                'DW',
                'HI/LO',
                'CHAN',
                'PA',
                'RX',
                'HORN',
                'INCM',
                'AQUA',
                '*',
                'NAME',
                'BKLT',
                'LOG',
                'SCAN',
                'DW',
                'HI/LO',
                'CHAN',
                'PA',
                'RX',
                'HORN',
                'INCM',
                'AQUA',
                '*',
                'NAME',
                'BKLT',
                'LOG',
                'SCAN',
                'DW',
                'HI/LO',
                'CHAN',
                'PA',
                'RX',
                'HORN',
                'INCM',
                'AQUA',
                '*',
                'NAME',
                'BKLT',
                'LOG',
            ]
        }
    },
    props: {
        currentMenuIndex: Number,
        backlightColor: String
    },
    mounted(){
        var itemCopy = [...this.items];
        this.menuItemsToShow = itemCopy.splice(this.currentMenuIndex, 4);
        this.$store.dispatch('m423/setCurrentCarouselMenuItems', this.menuItemsToShow);
    },
    watch: {
        currentMenuIndex(val){
            var itemCopy = [...this.items];
            this.menuItemsToShow = itemCopy.splice(val, 4);
            this.$store.dispatch('m423/setCurrentCarouselMenuItems', this.menuItemsToShow);
        }
    }
})
</script>

<style>
    .arrow-right{
        position:absolute;
        top: -8px;
        right: -13px;
        font-size: 17pt;
    }
    .arrow-left{
        position:absolute;
        top: -8px;
        left: -10px;
        font-size: 17pt;
    }
    .list{
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 100px;
        left: 14px;

    }
    .list-item-container{
        background-color: black;
        border-radius: 10%;
        margin-left: 2px;
        width: 50px;
        height: 18px;
        text-align: center;
    }
    .list-item{
        font-family: m423-font;
        color: #b56e1c;
        font-size: 11pt;
    }
</style>