<template>
    <div :style="'background-color:' + backlightColor" class="quick-menu-container">
        <div v-if="isInputVolume">
            <p class="title">Volume:</p>
            <p class="number">{{volumeValue > 0 ? volumeValue : 'OFF'}}</p>
            <v-button v-if="volumeValue > 0" icon class="icon"><v-icon>mdi-volume-high</v-icon></v-button>
            <v-button v-else icon class="icon"><v-icon>mdi-volume-off</v-icon></v-button>
            <m423_sliderInput :min="0" :max="20" :value="volumeValue" class="slider-container"/>
            <div class="dots"><p class="dot" v-for="item in 20" :key="item">.</p></div>
        </div>
        <div v-if="isInputSql">
            <p class="title">SQL:</p>
            <p class="number">{{sqlValue > 0 ? sqlValue : 'OPEN'}}</p>
            <m423_sliderInput :min="0" :max="10" :value="sqlValue" class="slider-container"/>
            <div class="dots-sql"><p class="dot-sql" v-for="item in 10" :key="item">.</p></div>
        </div>
        <div v-if="isInputChannel">
            <p class="title">Chan:</p>
            <p class="channel-number">{{currentChannel}}</p>
        </div>
        <div v-if="isInputBacklight">
            <p class="title">Backlight:</p>
            <p class="number">{{backlightValue > 0 ? backlightValue : 'OFF'}}</p>
            <v-button v-if="backlightValue > 0" icon class="icon"><v-icon>mdi-weather-sunny</v-icon></v-button>
            <v-button v-else icon class="icon"><v-icon>mdi-weather-sunny-off</v-icon></v-button>
            <m423_sliderInput :min="0" :max="7" :value="backlightValue" class="slider-container"/>
            <div class="dots-backlight"><p class="dot-backlight" v-for="item in 7" :key="item">.</p></div>
        </div>
        <div v-if="isInputRX">
            <p class="title">RX SP Volume:</p>
            <p class="number">{{rxValue > 0 ? rxValue : 'OFF'}}</p>
            <v-button v-if="rxValue > 0" icon class="icon"><v-icon>mdi-volume-high</v-icon></v-button>
            <v-button v-else icon class="icon"><v-icon>mdi-volume-off</v-icon></v-button>
            <m423_sliderInput :min="0" :max="20" :value="rxValue" class="slider-container"/>
            <div class="dots"><p class="dot" v-for="item in 20" :key="item">.</p></div>
        </div>
        <div v-if="isAqua">
            <p class="aqua-title">AquaQuake</p>
            <v-icon class="radio" size="50">mdi-radio-handheld</v-icon>
            <v-icon class="aqua-1" size="40">mdi-zodiac-aquarius</v-icon>
            <v-icon class="aqua-2" size="40">mdi-zodiac-aquarius</v-icon>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import m423_sliderInput from '../base/M423_SliderInput.vue'
export default defineComponent({
    computed: {
        currentChannel(){
            return this.$store.getters['m423/channels'][this.$props.channelIndex].number;
        }
    },
    components:{
        m423_sliderInput,
    },
    props: {
        isInputVolume: Boolean,
        isInputSql: Boolean,
        isInputChannel: Boolean,
        isInputBacklight: Boolean,
        volumeValue: Number,
        sqlValue: Number,
        channelIndex: Number,
        backlightValue: Number,
        rxValue: Number,
        isInputRX: Boolean,
        isAqua: Boolean,
        backlightColor: String,
    }
})
</script>

<style scoped>
    .aqua-title{
        text-align: center;
        font-family: m423-text-font;
    }
    .aqua-2{
        position: absolute;
        top: 30px;
        left: 107px;
        transform: rotate(0.75turn);
    }
    .aqua-1{
        position: absolute;
        top: 30px;
        left: 55px;
        transform: rotate(0.25turn);
    }
    .radio{
        position: absolute;
        top: 20px;
        left: 75px;
    }
    .channel-number{
        position: absolute;
        top: -20px;
        right: 30px;
        font-size: 70pt;
        font-family: m423-font;
    }
    .dot-backlight{
        margin-left: 20px;
    }
    .dots-backlight{
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20px;
        top: 43px;
        left: 21px;
    }
    .dot-sql{
        margin-left: 12px;
    }
    .dots-sql{
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20px;
        top: 43px;
        left: 29px;
    }
    .dot{
        margin-left: 3.3px;
    }
    .dots{
        position: absolute;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20px;
        top: 43px;
        left: 39px;
    }
    .icon{
        position: absolute;
        top: 32px;
        left: 10px;
        z-index: 15 !important;
        color: rgb(37, 37, 37);
    }
    .quick-menu-container{
        background-color: #b56e1c;
        border: 3px solid rgb(37, 37, 37);
        box-shadow: 2px 2px 0px 0px rgb(42, 42, 42);
        height: 80px;
        width: 210px;
        position: absolute;
        top: 20px;
        left: 14px;
    }
    .title{
        position: absolute;
        top: 3px;
        left: 3px;
        font-family: m423-text-font;
        font-size: 12pt;
    }
    .number{
        position: absolute;
        top: 3px;
        right: 3px;
        font-family: m423-text-font;
        font-size: 12pt;
    }
    .slider-container{
        position:absolute;
        width: 140px;
        height: 24px;
        top: 35px;
        left: 60px;
    }
</style>