<template>
    <div class="slider-container">
        <input type="range" :min="min" :max="max" :value="value" class="slider" id="myRange"/>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        value: Number,
        min: Number,
        max: Number,
    }
})
</script>

<style scoped>
input[type="range"] { 
    margin: auto;
    -webkit-appearance: none;
    position: absolute;
    left: -20px;
    overflow: hidden;
    height: 20px;
    width: 150px;
    cursor: pointer;
    border-radius: 25px; /* iOS */
}

::-webkit-slider-runnable-track {
    background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 * 2. Shadow is negative the full width of the input and has a spread 
 *    of the width of the input.
 */
::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 2px; /* 1 */
    height: 40px;
    background: #fff;
    box-shadow: -200px 0 0 200px rgb(67, 67, 67); /* 2 */
    border: 2px solid #b56e1c; /* 1 */
}

::-moz-range-track {
    height: 40px;
    background: #ddd;
}

::-moz-range-thumb {
    background: #fff;
    height: 40px;
    width: 20px;
    border: 3px solid #999;
    border-radius: 0 !important;
    box-shadow: -200px 0 0 200px rgb(67, 67, 67); /* 2 */
    box-sizing: border-box;
}

::-ms-fill-lower { 
    background: rgb(74, 74, 74);
}

::-ms-thumb { 
    background: #fff;
    border: 2px solid #999;
    height: 40px;
    width: 20px;
    box-sizing: border-box;
}

::-ms-ticks-after { 
    display: none; 
}

::-ms-ticks-before { 
    display: none; 
}

::-ms-track { 
    background: #ddd;
    color: transparent;
    height: 40px;
    border: none;
}

::-ms-tooltip { 
    display: none;
}
</style>