<template>
    <div v-if="mode === 'add'">
        <p class="title">{{ '<Input an address>' }}</p>
        <p class="id-header">{{ 'ID: (9digit)' }}</p>
        <DS100Input class="input-container" :selectedIndex="inputStep" :items="addressToAdd.id" :isActive="entryLatitude"/>
        <p class="name-header">{{ 'Name: (15characters)' }}</p>
        <DS100Input class="input-container-2" :selectedIndex="inputStep" :items="addressToAdd.name" :isActive="entryLongitude" mode="name"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="mode === 'addGroup'">
        <p class="title">{{ '<Input a group ID>' }}</p>
        <p class="id-header">{{ 'ID: (8digit)' }}</p>
        <DS100Input class="input-container" :selectedIndex="inputStep" :items="groupAddressToAdd.id" :isActive="entryLatitude" mode="group"/>
        <p class="name-header">{{ 'Name: (15characters)' }}</p>
        <DS100Input class="input-container-2" :selectedIndex="inputStep" :items="groupAddressToAdd.name" :isActive="entryLongitude" mode="name"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="mode === 'delete'">
        <p class="title">{{ '<Delete address ID>' }}</p>
        <p class="selection-cursor">></p>
        <DS100AddressIdSelection class="selection-list" :items="addressIds"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="mode === 'deleteGroup'">
        <p class="title">{{ '<Delete this ID>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" :items="groupAddressIdsNames"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="mode === 'show'">
        <p class="title">{{ '<Delete this ID>' }}</p>
        <div v-if="addressIds.length > 0">
            <p class="id-header">{{ 'ID: (9digit)' }}</p>
            <p class="id">{{ addressIds[addressToShowIndex].id }}</p>
            <p class="name-header">{{ 'Name: (15characters)' }}</p>
            <p class="name">{{ addressIds[addressToShowIndex].name }}</p>
            <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
        </div>
        <div v-else>
            <p class="id-header">{{ 'No address ID' }}</p>
            <p class="exit-text">{{ '<CLR->Exit>' }}</p>
        </div>
    </div>
    <div v-if="mode === 'showGroup'">
        <p class="title">{{ '<Delete this ID>' }}</p>
        <div v-if="groupAddressIds.length > 0">
            <p class="id-header">{{ 'ID: (9digit)' }}</p>
            <p class="id">{{ groupAddressIds[addressToShowIndex].id }}</p>
            <p class="name-header">{{ 'Name: (15characters)' }}</p>
            <p class="name">{{ groupAddressIds[addressToShowIndex].name }}</p>
            <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
        </div>
        <div v-else>
            <p class="id-header">{{ 'No address ID' }}</p>
            <p class="exit-text">{{ '<CLR->Exit>' }}</p>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import DS100Input from '../base/DS100Input.vue'
import DS100Selection from '../base/DS100Selection.vue'
import DS100AddressIdSelection from '../IndividualCallMenu/DS100AddressIdSelection.vue'

export default defineComponent({
    computed: {
        addressToAdd(){
            return this.$store.getters['ds100/addressToAdd'];
        },
        addressIds(){
            return this.$store.getters['ds100/addressIds'];
        },
        groupAddressToAdd(){
            return this.$store.getters['ds100/groupAddressToAdd'];
        },
        groupAddressIdsNames(){
            return this.groupAddressIds.map(id => id.name);
        },
        groupAddressIds(){
            return this.$store.getters['ds100/groupAddressIds'];
        }
    },
    props: {
        mode: String,
        entryLatitude: Boolean,
        entryLongitude: Boolean,
        inputStep: Number,
        addressToShowIndex: Number,
    },
    components: {
        DS100Input,
        DS100AddressIdSelection,
        DS100Selection
    }
})
</script>

<style>
.id{
    position: absolute;
    left: 50px;
    top: 45px;
    font-family: ds-digi;
    font-size: 14pt;
}
.name {
    position: absolute;
    left: 50px;
    top: 85px;
    font-family: ds-digi;
    font-size: 14pt;
}
.name-header{
    position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 70px;
}
.channel-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 45px;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .input-container-2{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 85px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .id-header{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .multi-line-exit{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 90px;
        line-height: 20px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        line-height: 20px;
        top: 5px;
    }
    .title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
    .selection-list{
        max-height: 90px;
        height: 90px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 22px;
        font-family: ds-digi;
    }
    .selection-cursor{
        position: absolute;
        font-family: ds-digi;
        font-size: 20pt;
        left: 7px;
        top: 14px;
        height: 30px;
        width: 30px;
        z-index: 10;
    }
</style>