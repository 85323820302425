<template>
    <div draggable="false" class="device-holder">
        <div class="m423-container">
            <v-img draggable="false" class="speaker" :src="require('../../assets/M423/Muschel1.png')" alt=""></v-img>
            <v-img draggable="0" class="m423" :src="require('../../assets/M423/NewM423.png')" alt=""></v-img>
            <v-img draggable="false" @click="pressTurnButton" @mousedown="turnBtnPressed = true" @mouseup="turnBtnPressed = false" @mouseleave="turnBtnPressed = false" class="turn-btn" v-click.hold.1s="togglePower" :src="currentTurnBtnState"></v-img>
            <v-img draggable="0" @mousedown="sendBtnPressed = true" @mouseup="sendBtnPressed = false" @mouseleave="sendBtnPressed = false" class="send-btn" :class="{'send-pressed': sendBtnPressed}" :src="currentSendBtnState"></v-img>
            <div @click="isHigh = !isHigh" class="speaker-hilo" :class="{'pressed': hiloPressed}" @mousedown="hiloPressed = true" @mouseup="hiloPressed = false"></div>
            <div @click="pressNavigation('up')" class="speaker-up" :class="{'pressed': speakerUpPressed}" @mousedown="speakerUpPressed = true" @mouseup="speakerUpPressed = false"></div>
            <div @click="pressNavigation('down')" class="speaker-down" :class="{'pressed': speakerDownPressed}" @mousedown="speakerDownPressed = true" @mouseup="speakerDownPressed = false"></div>
            <div @click="pressNavigation('left')" class="nav-left" :class="{'pressed': navLeftPressed}" @mousedown="navLeftPressed = true" @mouseup="navLeftPressed = false"></div>
            <div @click="pressNavigation('right')" class="nav-right" :class="{'pressed': navRightPressed}" @mousedown="navRightPressed = true" @mouseup="navRightPressed = false"></div>
            <div @click="pressNavigation('up')" class="nav-up" :class="{'pressed': navUpPressed}" @mousedown="navUpPressed = true" @mouseup="navUpPressed = false"></div>
            <div @click="pressNavigation('down')" class="nav-down" :class="{'pressed': navDownPressed}" @mousedown="navDownPressed = true" @mouseup="navDownPressed = false"></div>
            <div @click="pressMenu" class="menu-btn" :class="{'pressed': menuBtnPressed}" @mousedown="menuBtnPressed = true" @mouseup="menuBtnPressed = false"></div>
            <div @click="pressClear" class="clear-btn" :class="{'pressed': clearBtnPressed}" @mousedown="clearBtnPressed = true" @mouseup="clearBtnPressed = false"></div>
            <div @click="pressEnter" class="enter-btn" :class="{'pressed': enterBtnPressed}" @mousedown="enterBtnPressed = true" @mouseup="enterBtnPressed = false"></div>
            <div @click="pressQuickBtn(0)" class="quick-1-btn" :class="{'pressed': quick1Pressed}" @mousedown="quick1Pressed = true" @mouseup="quick1Pressed = false"></div>
            <div @click="pressQuickBtn(1)" class="quick-2-btn" :class="{'pressed': quick2Pressed}" @mousedown="quick2Pressed = true" @mouseup="quick2Pressed = false"></div>
            <div @click="pressQuickBtn(2)" class="quick-3-btn" :class="{'pressed': quick3Pressed}" @mousedown="quick3Pressed = true" @mouseup="quick3Pressed = false"></div>
            <div @click="pressQuickBtn(3)" class="quick-4-btn" :class="{'pressed': quick4Pressed}" @mousedown="quick4Pressed = true" @mouseup="quick4Pressed = false"></div>
            <div @click="pressChannel16Btn" class="ch-16-btn" :class="{'pressed': channel16Pressed}" @mousedown="channel16Pressed = true" @mouseup="channel16Pressed = false"></div>
            <div class="distress-btn-background"></div>
            <div class="distress-btn" v-click.hold.3s="execDistressCall" @mousedown="distressPressed = true" @mouseup="distressPressed = false" :class="{'distress-btn-pressed': distressPressed}"></div>
            <div v-if="powerIsOn" :style="'opacity:' + constrastLevel + '!important'" class="display-contrast-container-m423"></div>
            <div :style="'background-color:' + backlightColor" :class="[powerIsOn ? activeDisplayClass : notActiveDisplayClass]">
            
            <m423Spashscreen 
                v-if="isSplashScreen"
                class="splash-screen"
                :step="splashScreenStep"
            />
            <m423HomeScreen
                v-if="isHome"
                :currentMenuIndex="currentCarouselMenuIndex"
                :currentChannelIndex="currentChannelIndex"
                :isInputVolume="isInputVolume"
                :isInputSql="isInputSql"
                :isInputChannel="isInputChannel"
                :isInputBacklight="isInputBacklight"
                :isQuickMenu="isQuickMenu"
                :volumeValue="volumeValue"
                :sqlValue="sqlValue"
                :channelIndex="currentChannelIndex"
                :backlightValue="backlightValue"
                :isDualMode="isDualMode"
                :scanIsRunning="scanIsRunning"
                :isHigh="isHigh"
                :isInputRX="isInputRX"
                :rxValue="rxValue"
                :isRX="isRX"
                :isAqua="isAqua"
                :isSending="isSending"
                :isBusy="isBusy"
                :isAtis="isAtisMode"
                :isDsc="isDscMode"
                :isInt="isIntMode"
                :backlightColor="backlightColor"
                class="home-screen"
            />
            <m423Menu
                v-if="isMenu"
                :menuSelectionIndex="menuSelectionIndex"
                :menuItems="selectedMenuItems"
                :currentMenu="currentMenu"
                :menuStep="menuStep"
                :isInputSql="isInputSql"
                :isInputBacklight="isInputBacklight"
                :sliderValue="sliderValue"
                :loopIsOk="loopIsOk"
                :isInputContrast="isInputContrast"
                :selectedIndex="inputMenuProps.currentInputIndex"
                :isDistressInfoDialog="isDistressInfoDialog"
                :backlightColor="backlightColor"
                :selectedCallLog="selectedCallLog"
                :isDeleteLogView="isDeleteLogView"
            />
            <m423InputMenu
                v-if="isInputMenu"
                :selectedDigitIndex="inputMenuProps.selectedDigitIndex"
                :isInputName="inputMenuProps.isInputName"
                :isInputId="inputMenuProps.isInputId"
                :isInputGroupId="inputMenuProps.isInputGroupId"
                :activeInputTypes="inputMenuProps.activeInputTypes"
                :currentMenu="currentMenu"
                :currentInputIndex="inputMenuProps.currentInputIndex"
                :selectableDigits="inputMenuProps.selectableDigits"
                :input="inputMenuProps.input"
                :inputTitle="inputMenuProps.inputTitle"
                :isNoDataAvailable="isNoDataAvailable"
                :backlightColor="backlightColor"
            />
            <m423ChannelCallSelection
                v-if="isSelectingCallChannel"
                :currentMenu="currentMenu"
                :channelIndex="currentCallChannelIndex"
                :backlightColor="backlightColor"
            />
            <m423CallOverview
                v-if="isCallOverviewMenu"
                :currentMenu="currentMenu"
                :isTransmitting="isTransmitting"
                :isWaitingForAck="isWaitingForAck"
                :isAllShipsCalling="isAllShipsCalling"
                :isDistressCountdownActive="isDistressCountdownActive"
                :distressCountdownSeconds="distressCountdownSeconds"
                :currentCarouselMenuIndex="currentCarouselMenuIndex"
                :distressCallCarouselItems="distressCallCarouselItems"
                :isCancelingDistress="isCancelingDistress"
                :isTimerPaused="isWaitingForAckTimerPaused"
                :isReportSituationDistressCancelDialog="isReportSituationDistressCancelDialog"
                :isTransmittingDistressCancel="isTransmittingDistressCancel"
                :isTransmittedDistressCancel="isTransmittedDistressCancel"
                :isResendDistressDialog="isResendDistressDialog"
                :resendCountdownSec="resendDistressCountdown"
                :isRetransmittingDistressDialog="isRetransmittingDistressDialog"
                :isDistressPausedDialog="isDistressPausedDialog"
                :isGroupCallSent="isGroupCallSent"
                :isHigh="isHigh"
                :isBusy="isBusy"
                :isSending="isSending"
                :isIndividualAckSent="isIndividualAckSent"
                :backlightColor="backlightColor"
            />
            <m423PosTimeOverview
                v-if="isPosTimeOverviewMenu"
                :currentMenu="currentMenu"
                :backlightColor="backlightColor"
            />
            <m423DistressCallOverview
                v-if="isDistressCallOverviewMenu"
                :currentMenu="currentMenu"
                :menuSelectionIndex="menuSelectionIndex"
                :backlightColor="backlightColor"
            />
            <m423Horn
                v-if="isHornMenu"
                :isSending="isSending"
                :hornTitle="hornTitle"
                :backlightColor="backlightColor"
            />
            <m423ReceiveAck
                v-if="receivedACK"
                :alarmOff="receivedACKAlarmOff"
                :isHigh="isHigh"
                :isSending="isSending"
                :isBusy="isBusy"
                :backlightColor="backlightColor"
            />
            <m423ReceiveCall
                v-if="isReceiveCallMenu"
                :receivedCallMode="receivedCallMode"
                :alarmOff="callAlarmOff"
                :menuSelectionIndex="menuSelectionIndex"
                :isHigh="isHigh"
                :isSending="isSending"
                :isBusy="isBusy"
                :channelString="tempChannelString"
                :backlightColor="backlightColor"
            />
            </div>
        </div>
        <audio ref="beepFalsch" preload="auto" :src="beepFalschSound"></audio>
        <audio ref="beepOk" preload="auto" :src="beepOkSound"></audio>
        <audio ref="rauschen" preload="auto" :src="rauschenSound"></audio>
        <audio ref="aquaQuake" preload="auto" :src="aquaQuakeSound"></audio>
        <audio ref="startRauschen" preload="auto" :src="startRauschenSound"></audio>
        <audio ref="alarmSound" preload="auto" :src="alarmSound"></audio>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import m423Spashscreen from '../M423/Splashscreen/M423_Splashscreen.vue'
import m423HomeScreen from '../M423/Display/M423_HomeScreen.vue'
import m423Menu from '../M423/Display/Menu/M423_MenuContainer.vue'
import m423InputMenu from '../M423/base/M423_Input.vue'
import m423ChannelCallSelection from './base/M423_ChannelCallSelection.vue';
import m423CallOverview from './base/M423_CallOverview.vue'
import m423PosTimeOverview from './base/M423_PosTimeOverview.vue'
import m423DistressCallOverview from './base/M423_DistressCallOverview.vue';
import m423Horn from './base/M423_Horn.vue'
import m423ReceiveAck from './base/M423_ReceiveAck.vue'
import m423ReceiveCall from './base/M423_ReceiveCall.vue'

export default defineComponent({
    watch: {
        isTransmittingCall(newVal){
            if(newVal && this.transmittedCall.senderMmsi != this.mmsi){
                switch(this.transmittedCall.mode){
                    case 'IND ACK': {
                        if(this.transmittedCall.receiverMmsi == this.mmsi){
                            this.goToHome();
                            this.isHome = false;
                            this.receivedACK = true;
                            this.$store.dispatch('m423/addReceivedCallToLog', {
                                mode: 'other',
                                type: 'Individual ACK',
                                from: this.transmittedCall.senderMmsi,
                                category: this.transmittedCall.category,
                                channel: this.transmittedCall.channel,
                                channelType: this.channels.find((el) => el.number == this.transmittedCall.channel).description,
                            });
                        }
                        break;
                    }
                    case 'Individual Call': {
                        if(this.transmittedCall.receiverMmsi == this.mmsi){
                            this.goToHome();
                            this.isHome = false;
                            this.isReceiveCallMenu = true;
                            this.receivedCallMode = 'IND Overview';
                            this.$store.dispatch('m423/addReceivedCallToLog', {
                                mode: 'other',
                                type: 'Individual Call',
                                from: this.transmittedCall.senderMmsi,
                                category: this.transmittedCall.category,
                                channel: this.transmittedCall.channel,
                                channelType: this.channels.find((el) => el.number == this.transmittedCall.channel).description,
                            });
                        }
                        break;
                    }
                    case 'DS100 Group Call': {
                        this.goToHome();
                        this.isHome = false;
                        this.isReceiveCallMenu = true;
                        this.receivedCallMode = 'GRP Overview';
                        this.$store.dispatch('m423/addReceivedCallToLog', {
                            mode: 'other',
                            type: 'Group Call',
                            from: this.transmittedCall.senderMmsi,
                            category: this.transmittedCall.category,
                            channel: this.transmittedCall.channel,
                            channelType: this.channels.find((el) => el.number == this.transmittedCall.channel).description,
                        });
                        break;
                    }
                    case 'DS100 All Ships Call': {
                        this.goToHome();
                        this.isHome = false;
                        this.isReceiveCallMenu = true;
                        this.receivedCallMode = 'All Ships Overview';
                        this.$store.dispatch('m423/addReceivedCallToLog', {
                            mode: 'other',
                            type: 'All Ships Call',
                            from: this.transmittedCall.senderMmsi,
                            category: this.transmittedCall.category,
                            channel: this.transmittedCall.channel,
                            channelType: this.channels.find((el) => el.number == this.transmittedCall.channel).description,
                        });
                        break;
                    }
                    case 'Distress Alert': {
                        this.goToHome();
                        this.isHome = false;
                        this.isReceiveCallMenu = true;
                        this.receivedCallMode = 'Distress Overview';
                        this.$store.dispatch('m423/addReceivedCallToLog', {
                            mode: 'distress',
                            type: 'Distress',
                            from: this.transmittedCall.senderMmsi,
                            category: this.transmittedCall.category,
                            channel: this.transmittedCall.channel,
                            lat: this.transmittedCall.lat,
                            lon: this.transmittedCall.lon,
                            utc: this.transmittedCall.utc,
                            channelType: this.channels.find((el) => el.number == this.transmittedCall.channel).description,
                        });
                        break;
                    }
                    case 'Distress Cancel': {
                        this.goToHome();
                        this.isHome = false;
                        this.isReceiveCallMenu = true;
                        this.receivedCallMode = 'Distress Cancel Overview';
                        this.$store.dispatch('m423/addReceivedCallToLog', {
                            mode: 'distress',
                            type: 'Distress Cancel',
                            from: this.transmittedCall.senderMmsi,
                            category: this.transmittedCall.category,
                            channel: this.transmittedCall.channel,
                            channelType: this.channels.find((el) => el.number == this.transmittedCall.channel).description,
                        });
                        break;
                    }
                }
                this.$store.dispatch('setTransmittedCall', {
                    mode: this.transmittedCall.mode,
                    isTransmitting: false,
                    senderMmsi: this.transmittedCall.senderMmsi,
                    receiverMmsi: this.transmittedCall.receiverMmsi,
                    category: this.transmittedCall.category,
                    channel: this.transmittedCall.channel,
                    lat: this.transmittedCall.lat,
                    lon: this.transmittedCall.lon,
                    utc: this.transmittedCall.utc
                });
            }
        },
        distressPressed(newVal){
            if(!this.isAtisMode && this.powerIsOn){
                if(newVal && !this.isTransmitting && !this.isResendDistressDialog){
                    this.goToHome();
                    this.isHome = false;
                    this.isCallOverviewMenu = true;
                    this.startDistressCountdown();
                    this.isDistressCountdownActive = true;
                }
                else if(newVal && this.isResendDistressDialog){
                    this.resendInterval = setInterval(() => {
                        if(this.resendDistressCountdown > 0){
                            this.resendDistressCountdown--;
                        }
                        else{
                            this.isResendDistressDialog = false;
                            this.isRetransmittingDistressDialog = true;
                            setTimeout(() => {
                                this.isWaitingForAck = true;
                                this.isWaitingForAckTimerPaused = false;
                                this.isRetransmittingDistressDialog = false;
                                this.resendDistressCountdown = 3;
                            }, 2000);
                            clearInterval(this.resendInterval);
                        }
                    }, 1000);
                }
                else if(!newVal && this.isResendDistressDialog){
                    this.resendDistressCountdown = 3;
                    clearInterval(this.resendInterval);
                }
                else if(!newVal && !this.isTransmitting && !this.isWaitingForAck && !this.isRetransmittingDistressDialog && !this.isResendDistressDialog){
                    this.goToHome();
                    if(this.distressIntervalFinished){
                        clearInterval(this.distressCountdownInterval);
                    }
                    else{
                        clearInterval(this.distressCountdownInterval)
                        this.dimm(this.backlightValue);
                    }
                    this.isDistressCountdownActive = false;
                    this.distressCountdownSeconds = 3;
                }
                else {
                    if(this.distressIntervalFinished){
                        clearInterval(this.distressCountdownInterval);
                    }
                    else{
                        clearInterval(this.distressCountdownInterval)
                        this.dimm(this.backlightValue);
                    }
                    this.isDistressCountdownActive = false;
                    this.distressCountdownSeconds = 3;
                }
            }
        },
        powerIsOn(newVal){
            if(newVal){
                this.dimm(this.backlightValue);
            }
            else{
                this.dimm(0);
                this.goToHome();
                this.isHome = false;
                this.isSplashScreen = false;
            }
        },
        receivingSignal(newVal){
            if(this.powerIsOn){
                setTimeout(() => {
                    if(newVal && (this.receivedSignalSenderDetails.channelNumber == this.channels[this.currentChannelIndex].number) || (this.receivedSignalSenderDetails.channelNumber == 16 && this.isDualMode)){
                        this.isBusy = true;
                        this.playMicrophone();
                    }
                    else{
                        this.isBusy = false;
                        this.disconnectMicrophone();
                    }
                }, 1000);
            }
        },
        sendBtnPressed(newVal){
            if(this.powerIsOn && newVal){
                if(!this.m503IsReceivingSignal)
                    this.$store.dispatch('m503/receiveSignal', {
                        isSending: true,
                        mmsi: this.mmsi,
                        channelNumber: this.channels[this.currentChannelIndex].number,
                    });
                this.isSending = true;
            }
            else {
                if(this.m503IsReceivingSignal)
                    this.$store.dispatch('m503/receiveSignal', {
                        isSending: false,
                        mmsi: '',
                        channelNumber: 0,
                    });
                this.isSending = false;
            }
        },
        isBusy(newVal){
            if(this.powerIsOn && newVal){
                this.$refs.rauschen.play();
                this.$refs.rauschen.loop = true;
                this.$refs.rauschen.volume = this.$refs.rauschen.volume = this.mapValue(this.volumeValue, 0, 20, 0, 1) >= 0 ? this.mapValue(this.volumeValue, 0, 20, 0, 1) : 0;
            }
            else{
                this.$refs.rauschen.loop = false;
                this.$refs.rauschen.pause();
                this.$refs.rauschen.currentTime = 0;
            }
        },
        quick1Pressed(newVal){
            if(this.powerIsOn && newVal && this.isAqua){
                this.$refs.aquaQuake.play();
                this.$refs.aquaQuake.loop = true;
                this.$refs.aquaQuake.volume = 0.05;
            }
            else{
                this.$refs.aquaQuake.loop = false;
                this.$refs.aquaQuake.pause();
                this.$refs.aquaQuake.currentTime = 0;
            }
        },
        quick2Pressed(newVal){
            if(this.powerIsOn && newVal && this.isAqua){
                this.$refs.aquaQuake.play();
                this.$refs.aquaQuake.loop = true;
                this.$refs.aquaQuake.volume = 0.05;
            }
            else{
                this.$refs.aquaQuake.loop = false;
                this.$refs.aquaQuake.pause();
                this.$refs.aquaQuake.currentTime = 0;
            }
        },
        quick3Pressed(newVal){
            if(this.powerIsOn && newVal && this.isAqua){
                this.$refs.aquaQuake.play();
                this.$refs.aquaQuake.loop = true;
                this.$refs.aquaQuake.volume = 0.05;
            }
            else{
                this.$refs.aquaQuake.loop = false;
                this.$refs.aquaQuake.pause();
                this.$refs.aquaQuake.currentTime = 0;
            }
        },
        quick4Pressed(newVal){
            if(this.powerIsOn && newVal && this.isAqua){
                this.$refs.aquaQuake.play();
                this.$refs.aquaQuake.loop = true;
                this.$refs.aquaQuake.volume = 0.05;
            }
            else{
                this.$refs.aquaQuake.loop = false;
                this.$refs.aquaQuake.pause();
                this.$refs.aquaQuake.currentTime = 0;
            }
        },
        isSending(newVal){
            if(this.powerIsOn && newVal && this.isHornMenu){
               //
            }
        },
        isAtisMode(newVal){
            if(newVal){
                this.$store.dispatch('m423/setChanGroup', {mode: 'ATIS'});
            }
        },
        isDscMode(newVal){
            if(newVal){
                this.$store.dispatch('m423/setChanGroup', {mode: 'DSC'});
            }
        },
        isIntMode(newVal){
            if(newVal){
                this.$store.dispatch('m423/setChanGroup', {mode: 'INT'});
            }
        }
    },
    components: {
        m423Spashscreen,
        m423HomeScreen,
        m423Menu,
        m423InputMenu,
        m423ChannelCallSelection,
        m423CallOverview,
        m423PosTimeOverview,
        m423DistressCallOverview,
        m423Horn,
        m423ReceiveAck,
        m423ReceiveCall
    },
    mounted(){
        this.togglePowerOff();
        const turnBtn = document.querySelector('.turn-btn');
        turnBtn.addEventListener("wheel", (event) => {
            this.$refs.beepOk.play();
            event.preventDefault();
            if(!this.turnBtnPressed){
                if(this.currentTurnBtnScrollCount >= 2){
                    this.currentTurnBtnScrollCount = 0;
                }
                if(this.isQuickMenu){
                    this.InitCloseQuickMenuTimer();
                }
                else{
                    this.openQuickMenu();
                }
                if(this.isInputVolume){
                    if(this.volumeValue < 20 && event.deltaY < 0){
                        this.volumeValue += 1;
                    }
                    if(this.volumeValue > 0 && event.deltaY > 0){
                        this.volumeValue -= 1;
                    }
                }
                if(this.isInputRX){
                    if(this.rxValue < 20 && event.deltaY < 0){
                        this.rxValue += 1;
                    }
                    if(this.rxValue > 0 && event.deltaY > 0){
                        this.rxValue -= 1;
                    }
                }
                if(this.isInputSql){
                    if(this.currentMenu == 'CH 70 SQL Level'){
                        if(this.sliderValue < 10 && event.deltaY < 0){
                            this.sliderValue++;
                        }
                        if(this.sliderValue > 0 && event.deltaY > 0){
                            this.sliderValue--;
                        }
                        if(this.sliderValue <= 1){
                            this.isBusy = true;
                        }
                        else{
                            this.isBusy = false;
                        }
                    }
                    else {
                        if(this.sqlValue < 10 && event.deltaY < 0){
                            this.sqlValue += 1;
                        }
                        if(this.sqlValue > 0 && event.deltaY > 0){
                            this.sqlValue -= 1;
                        }
                        if(this.sqlValue <= 1){
                            this.isBusy = true;
                        }
                        else{
                            this.isBusy = false;
                        }
                    }
                }
                if(this.isInputChannel){
                    if(event.deltaY < 0){
                        this.switchChannel('up', 'normal');
                    }
                    else{
                        this.switchChannel('down', 'normal');
                    }
                }
                if(this.isInputBacklight){
                    if(this.currentMenu == 'Backlight'){
                        if(this.sliderValue < 7 && event.deltaY < 0){
                            this.sliderValue++;
                            this.dimm(this.sliderValue);
                        }
                        if(this.sliderValue > 0 && event.deltaY > 0){
                            this.sliderValue--;
                            this.dimm(this.sliderValue);
                        }
                    }
                    else{
                        if(this.backlightValue < 7 && event.deltaY < 0){
                            this.backlightValue++;
                            this.dimm(this.backlightValue);
                        }
                        if(this.backlightValue > 0 && event.deltaY > 0){
                            this.backlightValue--;
                            this.dimm(this.backlightValue);
                        }
                    }
                }
                if(this.isInputContrast){
                    if(this.currentMenu == 'Display Contrast'){
                        if(this.sliderValue < 8 && event.deltaY < 0){
                            this.sliderValue++;
                            this.setContrast(this.sliderValue);
                        }
                        if(this.sliderValue > 0 && event.deltaY > 0){
                            this.sliderValue--;
                            this.setContrast(this.sliderValue);
                        }
                    }
                    else{
                        if(this.contrastValue < 8 && event.deltaY < 0){
                            this.contrastValue += 1;
                            this.setContrast(this.contrastValue);
                        }
                        if(this.contrastValue > 1 && event.deltaY > 0){
                            this.contrastValue -= 1;
                            this.setContrast(this.contrastValue);
                        }
                    }
                }
                else if(this.isInputMenu && event.deltaY < 0 && this.inputMenuProps.selectedDigitIndex + 1 < this.inputMenuProps.selectableDigits.length + 5){
                    let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(1, 'right');
                    if(nearestIndexWithValue != -1){
                        this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                    }
                    else if(this.inputMenuProps.selectedDigitIndex < 26) {
                        this.inputMenuProps.selectedDigitIndex = 26;
                    }
                    else {
                        this.inputMenuProps.selectedDigitIndex++;
                    }
                    this.$refs.beepOk.play();
                } 
                else if(this.isInputMenu && event.deltaY > 0 && this.inputMenuProps.selectedDigitIndex > 0){
                    let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(1, 'left');
                    if(this.inputMenuProps.selectedDigitIndex <= 26 && nearestIndexWithValue != -1){
                        this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                    }
                    else if(this.inputMenuProps.selectedDigitIndex > 26){
                        this.inputMenuProps.selectedDigitIndex--;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.isMenu && event.deltaY < 0 && this.menuSelectionIndex < this.selectedMenuItems.length - 1){
                    this.menuSelectionIndex++;
                }
                else if(this.isMenu && event.deltaY > 0 && this.menuSelectionIndex > 0){
                    this.menuSelectionIndex--;
                }
                this.turnBtnScrolled = true;
                this.currentTurnBtnScrollCount++;
            }
        });
    },
    data(){
        return {
            activeDisplayClass: 'display-container-m423',
            notActiveDisplayClass: 'display-container-off-m423',
            turnBtnPressed: false,
            splashScreenStep: 0,
            isSplashScreen: false,
            isHome: false,
            currentCarouselMenuIndex:  13,
            navLeftPressed: false,
            navRightPressed: false,
            navUpPressed: false,
            navDownPressed: false,
            currentChannelIndex: 15,
            turnBtnScrolled: false,
            currentTurnBtnScrollCount: 0,
            isInputVolume: false,
            isInputSql: false,
            isInputChannel: false,
            isInputBacklight: false,
            isQuickMenu: false,
            volumeValue: 10,
            sqlValue: 5,
            backlightValue: 3,
            contrastValue: 8,
            isInputContrast: false,
            dimmSteps: ["#5b5d3a","#9b651d","#a6671a","#b16a18","#bc6c15","#c76f12","#d1710f","#e98018"],
            quickMenuTimer: null,
            menuBtnPressed: false,
            clearBtnPressed: false,
            isMenu: false,
            menuSelectionIndex: 0,
            menuStep: 0,
            menuIcon: '',
            selectedMenuItems: [],
            enterBtnPressed: false,
            currentMenu: '',
            isInputMenu: false,
            isSelectingCategory: false,
            isSelectingCallChannel: false,
            currentCallChannelIndex: 7,
            isTransmitting: false,
            isWaitingForAck: false,
            isCallOverviewMenu: false,
            isPosTimeOverviewMenu: false,
            isDistressCallOverviewMenu: false,
            distressPressed: false,
            quick1Pressed: false,
            quick2Pressed: false,
            quick3Pressed: false,
            quick4Pressed: false,
            sliderValue: 0,
            mode: '',
            loopIsOk: false,
            scanIsRunning: false,
            scanInterval: undefined,
            isDualMode: false,
            isHigh: true,
            isHornMenu: false,
            hornTitle: '',
            isSending: false,
            sendBtnPressed: false,
            isInputRX: false,
            isRX: false,
            isAqua: false,
            rxValue: 10,
            isBusy: false,
            channel16Pressed: false,
            speakerUpPressed: false,
            speakerDownPressed: false,
            hiloPressed: false,
            isNoDataAvailable: false,
            isDistressCountdownActive: false, 
            resendInterval: undefined,
            distressSendenSound: require("../../assets/M423/sounds/distressSenden.mp3"),
            distressSendenLangSound: require("../../assets/M423/sounds/distressSendenLang.mp3"),
            distressSendenKurzSound: require("../../assets/M423/sounds/distressSendenKurz.mp3"),
            beepFalschSound: require("../../assets/M423/sounds/beepFalsch.mp3"),
            beepOkSound: require("../../assets/M423/sounds/beepOk.mp3"),
            rauschenSound: require("../../assets/M423/sounds/rauschen.mp3"),
            aquaQuakeSound: require("../../assets/M423/sounds/aquaQuake.mp3"),
            hornSound: require("../../assets/M423/sounds/aquaQuake.mp3"),
            startRauschenSound: require("../../assets/M423/sounds/startRauschen.mp3"),
            alarmSound: require("../../assets/M423/sounds/beepTripple.mp3"),
            isAllShipsCalling: false,
            isCancelingDistress: false,
            isWaitingForAckTimerPaused: false,
            isReportSituationDistressCancelDialog: false,
            isTransmittingDistressCancel: false,
            isTransmittedDistressCancel: false,
            isResendDistressDialog: false,
            isResendCountdownActive: false,
            resendDistressCountdown: 3,
            isRetransmittingDistressDialog: false,
            isDistressPausedDialog: false,
            isDistressInfoDialog: false,
            isEditMode: false,
            individualIdToEditIndex: 0,
            distressIntervalFinished: false,
            individualIdToEdit: {
                id: '', 
                title: '',
            },
            groupIdToEditIndex: 0,
            groupIdToEdit: {
                id: '',
                title: '',
            },
            inputMenuProps: {
                selectedDigitIndex: 0,
                isInputName: false,
                isInputId: false,
                isInputGroupId: false,
                activeInputTypes: [],
                currentInputIndex: 0,
                input: ['-','-','-','-','-','-','-','-','-'],
                selectableDigits: ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''],
                inputTitle: '',
            },
            individualId: {
                id: '',
                title: '',
            },
            groupId: {
                id: '',
                title: '',
            },
            audioContext: null,
            microphoneStream: null,
            audioInput: null,
            gainNode: null,
            distressCountdownInterval: null,
            distressCountdownSeconds: 3,
            receivedACK: false,
            receivedACKAlarmOff: false,
            lastWorkingChannelIndex: 15, 
            distressCallCarouselItems: [
                'CANCEL',
                'RESEND',
                'PAUSE',
                'INFO',
            ],
            isGroupCallSent: false,
            lastChannelIndex: 15,
            isReceiveCallMenu: false,
            receivedCallMode: '',
            callAlarmOff: false,
            isIndividualAckSent: false,
            tempChannelString: '',
            backlightColor: '#ed841c',
            selectedCallLog: {},
            isDeleteLogView: false,
            lastReceivedCallMode: '',
            contrastSteps: [0.35,0.3,0.25,0.2,0.15,0.1,0.05,0.02,0],
            contrastLevel: 0,
            alarmInterval: undefined
        }
    },
    computed: {
        utcOffset(){
            return this.$store.getters['m423/utcOffset'];
        },
        isSelectableMenu(){
            return this.currentMenu === 'Individual ACK' || this.currentMenu === 'Position ACK' || this.currentMenu === 'Test ACK' || this.currentMenu === 'CH 16 Switch' || this.currentMenu === 'DSC Data Output' || this.currentMenu == 'CH 70 SQL Level' || this.currentMenu == 'DSC Loop Test';
        },
        isConfigurationMenu(){
            return this.currentMenu === 'Backlight' || this.currentMenu === 'Display Contrast' || this.currentMenu === 'Key Beep' || this.currentMenu === 'UTC Offset' || this.currentMenu === 'RX' || this.currentMenu == 'TX' || this.currentMenu == 'Not DSC Related' || this.currentMenu == 'DSC Related' | this.currentMenu == 'Remote Id' || this.currentMenu == 'Noise Cancel' || this.currentMenu == 'Inactivity Timer';
        },
        menuItems(){
            return this.$store.getters['m423/menuItems'];
        },
        powerIsOn(){
            return this.$store.getters['m423/powerIsOn'];
        },
        channels(){
            return this.$store.getters['m423/channels'];
        },
        currentSendBtnState(){
            if(this.sendBtnPressed)
                return require('../../assets/M423/bPttDown.png');
            return require('../../assets/M423/bPttUp.png');
        },
        currentTurnBtnState(){
            if(this.turnBtnPressed){
                switch (this.currentTurnBtnScrollCount){
                    case 0: {
                        returnValue = require('../../assets/M423/bDreher1Down.png');
                        break;
                    }
                    case 1: {
                        returnValue = require('../../assets/M423/bDreher2Down.png');
                        break;
                    }
                    case 2: {
                        returnValue = require('../../assets/M423/bDreher3Down.png');
                        break;
                    }
                }
                return returnValue;
            }
            else if(!this.turnBtnPressed && this.turnBtnScrolled){
                var returnValue = null;
                switch (this.currentTurnBtnScrollCount){
                    case 0: {
                        returnValue = require('../../assets/M423/bDreher1.png');
                        break;
                    }
                    case 1: {
                        returnValue = require('../../assets/M423/bDreher2.png');
                        break;
                    }
                    case 2: {
                        returnValue = require('../../assets/M423/bDreher3.png');
                        break;
                    }
                }
                return returnValue;
            }
            else {
                return require('../../assets/M423/bDreher1.png')
            }
        },
        individualCallCategorys(){
            return this.$store.getters['m423/individualCallCategorys'];
        },
        currentCarouselMenuItems(){
            return this.$store.getters['m423/currentCarouselMenuItems'];
        },
        m503IsReceivingSignal(){
            return this.$store.getters['m503/isReceivingSignal'];
        },
        m503ReceivedSignalSenderDetails(){
            return this.$store.getters['m503/receivedSignalSenderDetails'];
        },
        mmsi(){
            return this.$store.getters['m423/mmsi'];
        },
        receivingSignal(){
            return this.$store.getters['m423/isReceivingSignal'];
        },
        receivedSignalSenderDetails(){
            return this.$store.getters['m423/receivedSignalSenderDetails'];
        },
        individualIds(){
            return this.$store.getters['m423/individualIds'];
        },
        groupIds(){
            return this.$store.getters['m423/groupIds'];
        },
        isAtisMode(){
            return this.$store.getters['m423/chanGroupItems'][0].selected;
        },
        isDscMode(){
            return this.$store.getters['m423/chanGroupItems'][1].selected;
        },
        isIntMode(){
            return this.$store.getters['m423/chanGroupItems'][2].selected;
        },
        individualCallDetails(){
            return this.$store.getters['m423/individualCallDetails'];
        },
        groupCallDetails(){
            return this.$store.getters['m423/groupCallDetails'];
        },
        transmittedCall(){
            return this.$store.getters['transmittedCall'];
        },
        isTransmittingCall(){
            return this.$store.getters['transmittedCall'].isTransmitting;
        },
        acknowledgementDetails(){
            return this.$store.getters['ds100/acknowledgementDetails'];
        },
        allShipsCallDetails(){
            return this.$store.getters['m423/allShipsCallDetails'];
        },
        distressCallDetails(){
            return this.$store.getters['m423/distressCallDetails'];
        },
        individualAckDetails(){
            return this.$store.getters['m423/individualAckDetails'];
        },
        testCallDetails(){
            return this.$store.getters['m423/testCallDetails'];
        }
    },
    methods: {
        startDistressCountdown(){
            this.distressIntervalFinished = false;
            this.distressCountdownInterval = setInterval(() => {
                if(this.distressCountdownSeconds < 0){
                    this.distressCountdownSeconds = 3;
                    clearInterval(this.distressCountdownInterval);
                }
                else{
                    switch(this.distressCountdownSeconds){
                        case 3: {
                            this.dimm(0);
                            break;
                        }
                        case 2: {
                            this.dimm(this.backlightValue);
                            break;
                        }
                        case 1: {
                            this.dimm(0);
                            break;
                        }
                        case 0: {
                            this.dimm(this.backlightValue);
                            this.distressIntervalFinished = true;
                            break;
                        }
                    }
                }
                
                this.distressCountdownSeconds--;
            }, 1000);
        },
        switchToWorkingChannel(){
            this.currentChannelIndex = this.lastWorkingChannelIndex;
        },
        async playMicrophone(){
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            this.microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.audioInput = this.audioContext.createMediaStreamSource(this.microphoneStream);
            this.gainNode = this.audioContext.createGain();
            this.audioInput.connect(this.gainNode);
            this.gainNode.connect(this.audioContext.destination);
            
            this.updateVolume();
        },
        disconnectMicrophone(){
            if (this.gainNode) {
                this.gainNode.disconnect();
            }
            if (this.audioInput) {
                this.audioInput.disconnect();
            }
            if (this.microphoneStream) {
                this.microphoneStream.getTracks().forEach(track => track.stop());
            }
            if (this.audioContext) {
                this.audioContext.close();
            }
        },
        updateVolume() {
            if (this.gainNode) {
                let mappedValue = this.mapValue(this.volumeValue, 0, 20, 0, 1);
                this.gainNode.gain.value = mappedValue;
            }
            requestAnimationFrame(this.updateVolume);
        },
        mapValue(value, minValue1, maxValue1, minValue2, maxValue2) {
            return (value - minValue1) * (maxValue2 - minValue2) / (maxValue1 - minValue1) + minValue2;
        },
        goToMenu(){
            this.goToHome();
            this.isHome = false;
            this.isMenu = true;
        },
        async pressQuickBtn(index){
            if(this.powerIsOn){
                if(this.isHome){
                    this.selectCarouselMenuItem(this.currentCarouselMenuItems[index]);
                    this.$refs.beepOk.play();
                }
                else if(this.receivedACK && !this.receivedACKAlarmOff){
                    this.lastWorkingChannelIndex = this.currentChannelIndex;
                    this.currentChannelIndex = this.channels.findIndex(el => el.number == this.acknowledgementDetails.channel.join(''))
                    this.receivedACKAlarmOff = true;
                }
                else if(this.isReceiveCallMenu && !this.callAlarmOff){
                    this.callAlarmOff = true;
                }
                else{
                    switch(index){
                        case 0: {
                            if(this.currentMenu == 'Individual Id' && this.isMenu){
                                this.isMenu = false;
                                this.isInputMenu = true;
                                this.inputMenuProps.inputTitle = 'IND ID';
                                this.inputMenuProps.input = ['-','-','-','-','-','-','-','-','-'];
                                this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                                this.mode = 'ADD';
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Group Id' && this.isMenu){
                                this.isMenu = false;
                                this.isInputMenu = true;
                                this.inputMenuProps.isInputName = false;
                                this.inputMenuProps.isInputId = false;
                                this.inputMenuProps.isInputGroupId = true;
                                this.inputMenuProps.inputTitle = 'GRP ID';
                                this.inputMenuProps.currentInputIndex = 1;
                                this.inputMenuProps.input = ['0','-','-','-','-','-','-','-','-'];
                                this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                                this.mode = 'ADD';
                                this.$refs.beepOk.play();
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK SENT'){
                                this.goToHome();
                                this.currentChannelIndex = this.lastWorkingChannelIndex;
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'GRP Overview' && this.callAlarmOff){
                                this.goToHome();
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'RCVD Call Log Temp'){
                                this.goToHome();
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'All Ships Overview' && this.callAlarmOff){
                                this.goToHome();
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'GRP ACCEPTED'){
                                this.currentChannelIndex = this.lastWorkingChannelIndex;
                                this.goToHome();
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'All Ships ACCEPTED'){
                                this.currentChannelIndex = this.lastWorkingChannelIndex;
                                this.goToHome();
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'Distress Cancel ACCEPTED'){
                                this.currentChannelIndex = this.lastWorkingChannelIndex;
                                this.goToHome();
                            }
                            else if(this.receivedACKAlarmOff){
                                this.goToHome();
                                this.currentChannelIndex = this.lastWorkingChannelIndex;
                                this.$refs.beepOk.play();
                            }
                            else if (this.isReceiveCallMenu && this.callAlarmOff) {
                                this.goToHome();
                            }
                            else if(this.isGroupCallSent){
                                this.goToHome();
                                this.currentChannelIndex = this.lastChannelIndex;
                                this.$refs.beepOk.play();
                            }
                            else if(this.isAllShipsCalling){
                                this.goToHome();
                                this.currentChannelIndex = this.lastChannelIndex;
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Individual Call'){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Group Call'){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'All Ships Call'){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Distress Call'){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu == 'Distress Alert' && this.isWaitingForAck) {
                                this.checkSelectedDistressCarouselItem(index);
                            }
                            else if (this.currentMenu == 'Distress Alert' && this.isCancelingDistress){
                                this.isCancelingDistress = false;
                                this.isWaitingForAck = true;

                                setTimeout(() => {
                                    this.isWaitingForAckTimerPaused = false;
                                }, 1000);
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isReportSituationDistressCancelDialog){
                                this.isCancelingDistress = false;
                                this.isReportSituationDistressCancelDialog = false;
                                this.isWaitingForAck = true;

                                setTimeout(() => {
                                    this.isWaitingForAckTimerPaused = false;
                                }, 1000);
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isDistressPausedDialog){
                                this.isWaitingForAck = true;
                                this.isDistressPausedDialog = false;
                                this.isWaitingForAckTimerPaused = false;
                            }
                            else if (this.currentMenu == 'Distress Alert') {
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu === 'RCVD Call Log' && !this.isDeleteLogView) {
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'RCVD Call Log' && this.menuStep == 3 && this.isDeleteLogView){
                                this.isDeleteLogView = false;
                                this.currentMenu = 'RCVD Call Log';
                                this.menuStep = 2;
                                this.menuSelectionIndex = 0;
                                if(this.$store.getters['m423/receivedCalls'].other.find((el) => el == this.selectedCallLog)){
                                    this.$store.dispatch('m423/deleteReceivedCallLog', this.selectedCallLog);
                                    this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].other;
                                }
                                else{
                                    this.$store.dispatch('m423/deleteReceivedCallLog', this.selectedCallLog);
                                    this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].distress;
                                }
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu === 'TX Call Log') {
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'TX Call Log' && this.menuStep == 3 && this.isDeleteLogView){
                                this.isDeleteLogView = false;
                                this.currentMenu = 'TX Call Log';
                                this.menuStep = 2;
                                this.menuSelectionIndex = 0;
                                if(this.$store.getters['m423/transmittedCalls'].other.find((el) => el == this.selectedCallLog)){
                                    this.$store.dispatch('m423/deleteTransmittedCallLog', this.selectedCallLog);
                                    this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].other;
                                }
                                else{
                                    this.$store.dispatch('m423/deleteTransmittedCallLog', this.selectedCallLog);
                                    this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].distress;
                                }
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu === 'Test Call') {
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu === 'Position Input') {
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if (this.isMenu && this.menuStep == 1){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Individual Id'){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else if(this.isHornMenu){
                                this.goToHome();
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                            break;
                        }
                        case 1: {
                            if(this.currentMenu == 'Individual Call'){
                                if(this.isInputMenu){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                }
                                else if(this.isSelectingCategory){
                                    this.isSelectingCategory = false;
                                    this.menuSelectionIndex = 0;
                                    this.isInputMenu = true;
                                    this.isMenu = false;
                                    this.inputMenuProps.selectedDigitIndex = 0;
                                    this.inputMenuProps.currentInputIndex = 0;
                                }
                                else if(this.isSelectingCallChannel){
                                    this.isSelectingCallChannel = false;
                                    this.isSelectingCategory = true;
                                    this.isMenu = true;
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/individualCallCategorys'];
                                    this.menuSelectionIndex = 0;
                                }
                                else if(this.isCallOverviewMenu){
                                    this.isCallOverviewMenu = false;
                                    this.isSelectingCallChannel = true;
                                }
                                this.$refs.beepOk.play();
                            }
                            else if (this.isReceiveCallMenu && this.receivedCallMode.includes('Overview')){
                                this.lastReceivedCallMode = this.receivedCallMode;
                                this.receivedCallMode = 'RCVD Call Log Temp';
                            }
                            else if (this.isReceiveCallMenu && this.receivedCallMode == 'RCVD Call Log Temp'){
                                this.receivedCallMode = this.lastReceivedCallMode;
                            }
                            else if (this.currentMenu == 'Distress Alert' && this.isWaitingForAck) {
                                this.checkSelectedDistressCarouselItem(index);
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isDistressPausedDialog){
                                this.isWaitingForAck = true;
                                this.isDistressPausedDialog = false;
                                this.isWaitingForAckTimerPaused = false;
                            }
                            else if(this.currentMenu == 'Group Call'){
                                if(this.isInputMenu){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                }
                                else if(this.isSelectingCallChannel){
                                    this.isSelectingCallChannel = false;
                                    this.isInputMenu = true;
                                    this.inputMenuProps.currentInputIndex = 1;
                                    this.inputMenuProps.selectedDigitIndex = 0;
                                }
                                else if(this.isCallOverviewMenu){
                                    this.isCallOverviewMenu = false;
                                    this.isSelectingCallChannel = true;
                                }
                                else {
                                    this.goToMenu();
                                }
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'All Ships Call'){
                                if(this.isSelectingCategory){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                }
                                else if(this.isSelectingCallChannel){
                                    this.isSelectingCallChannel = false;
                                    this.isSelectingCategory = true;
                                    this.isMenu = true;
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/allShipsCallCategorys'];
                                    this.menuSelectionIndex = 0;
                                }
                                else if(this.isCallOverviewMenu){
                                    this.isCallOverviewMenu = false;
                                    this.isSelectingCallChannel = true;
                                }
                                else {
                                    this.goToMenu();
                                }
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'Distress Call'){
                                if(this.isSelectingCategory){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                }
                                else if(this.isPosTimeOverviewMenu){
                                    this.isPosTimeOverviewMenu = false;
                                    this.isSelectingCategory = true;
                                    this.isMenu = true;
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/distressCallMenuItems'];
                                    this.menuSelectionIndex = 0;
                                }
                                else if(this.isDistressCallOverviewMenu){
                                    this.isDistressCallOverviewMenu = false;
                                    this.isPosTimeOverviewMenu = true;
                                }
                                else {
                                    this.goToMenu();
                                }
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu == 'RCVD Call Log'){
                                if(this.menuStep == 1){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.menuSelectionIndex = 0;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                    this.$refs.beepOk.play();
                                }
                                else if(this.menuStep == 2){
                                    this.currentMenu = 'RCVD Call Log';
                                    this.menuStep = 1;
                                    this.menuSelectionIndex = 0;
                                    this.selectedMenuItems = this.$store.getters['m423/receivedCallLogMenuItems'];
                                    this.$refs.beepOk.play();
                                }
                                else if(this.menuStep == 3 && !this.isDeleteLogView){
                                    this.currentMenu = 'RCVD Call Log';
                                    this.menuStep = 2;
                                    this.menuSelectionIndex = 0;
                                    if(this.$store.getters['m423/receivedCalls'].other.find((el) => el == this.selectedCallLog)){
                                        this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].other;
                                    }
                                    else{
                                        this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].distress;
                                    }
                                    this.$refs.beepOk.play();
                                }
                                else if(this.menuStep == 3 && this.isDeleteLogView){
                                    this.isDeleteLogView = false;
                                    this.currentMenu = 'RCVD Call Log';
                                    this.menuStep = 2;
                                    this.menuSelectionIndex = 0;
                                    if(this.$store.getters['m423/receivedCalls'].other.find((el) => el == this.selectedCallLog)){
                                        this.$store.dispatch('m423/deleteReceivedCallLog', this.selectedCallLog);
                                        this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].other;
                                    }
                                    else{
                                        this.$store.dispatch('m423/deleteReceivedCallLog', this.selectedCallLog);
                                        this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].distress;
                                    }
                                    this.$refs.beepOk.play();
                                }
                            }
                            else if(this.currentMenu == 'TX Call Log'){
                                if(this.menuStep == 1){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                    this.$refs.beepOk.play();
                                }
                                else if(this.menuStep == 2){
                                    this.currentMenu = 'TX Call Log';
                                    this.menuStep = 1;
                                    this.menuSelectionIndex = 0;
                                    this.selectedMenuItems = this.$store.getters['m423/transmittedCallLogMenuItems'];
                                    this.$refs.beepOk.play();
                                }
                                else if(this.menuStep == 3 && !this.isDeleteLogView){
                                    this.currentMenu = 'TX Call Log';
                                    this.menuStep = 2;
                                    this.menuSelectionIndex = 0;
                                    if(this.$store.getters['m423/transmittedCalls'].other.find((el) => el == this.selectedCallLog)){
                                        this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].other;
                                    }
                                    else{
                                        this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].distress;
                                    }
                                    this.$refs.beepOk.play();
                                }
                                else if(this.menuStep == 3 && this.isDeleteLogView){
                                    this.isDeleteLogView = false;
                                    this.currentMenu = 'TX Call Log';
                                    this.menuStep = 2;
                                    this.menuSelectionIndex = 0;
                                    if(this.$store.getters['m423/transmittedCalls'].other.find((el) => el == this.selectedCallLog)){
                                        this.$store.dispatch('m423/deleteTransmittedCallLog', this.selectedCallLog);
                                        this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].other;
                                    }
                                    else{
                                        this.$store.dispatch('m423/deleteTransmittedCallLog', this.selectedCallLog);
                                        this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].distress;
                                    }
                                    this.$refs.beepOk.play();
                                }
                            }
                            else if(this.currentMenu == 'Test Call'){
                                if(this.isInputMenu){
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Calls';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                                }
                                else if(this.isCallOverviewMenu){
                                    this.isCallOverviewMenu = false;
                                    this.isInputMenu = true;
                                    this.inputMenuProps.currentInputIndex = 0;
                                    this.inputMenuProps.selectedDigitIndex = 0;
                                }
                                else {
                                    this.currentMenu = 'DSC Calls';
                                    this.goToMenu();
                                }
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu === 'Position Input') {
                                if(this.inputMenuProps.inputTitle == 'LON'){
                                    this.inputMenuProps.inputTitle = 'LAT';
                                    this.inputMenuProps.input = ['-','-','°','-','-','.','-','-','-','-','N'];
                                    this.inputMenuProps.currentInputIndex = 0;
                                    this.inputMenuProps.selectedDigitIndex = 0;
                                    this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','N','S','','','','','','','','','','',''];
                                }
                                else if(this.inputMenuProps.inputTitle == 'UTC'){
                                    this.inputMenuProps.inputTitle = 'LON';
                                    this.inputMenuProps.input = ['-','-','-','°','-','-','.','-','-','-','-','W'];
                                    this.inputMenuProps.currentInputIndex = 0;
                                    this.inputMenuProps.selectedDigitIndex = 0;
                                    this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','W','E','','','','','','','','','','',''];
                                }
                                else {
                                    this.goToMenu();
                                    this.currentMenu = 'DSC Settings';
                                    this.menuStep = 1;
                                    this.selectedMenuItems = this.$store.getters['m423/dscSettingsMenuItems'];
                                }
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu == 'Scan Type' || this.currentMenu == 'Scan Timer' || this.currentMenu == 'Dual/Tri-Watch' || this.currentMenu == 'CHAN Group') {
                                this.goToMenu();
                                this.currentMenu = 'Radio Settings';
                                this.menuStep = 1;
                                this.selectedMenuItems = this.$store.getters['m423/radioSettingsMenuItems'];
                            }
                            else if(this.currentMenu === 'Individual Id'){
                                if(this.isInputMenu){
                                    this.resetInputMenuProps();
                                    this.isInputMenu = false;
                                    this.isMenu = true;
                                }
                                else{
                                    this.isEditMode = true;
                                    this.individualIdToEdit = this.individualIds[this.menuSelectionIndex];
                                    this.individualIdToEditIndex = this.menuSelectionIndex;
                                    for(let i = 0; i < this.individualIds[this.menuSelectionIndex].id.length; i++){
                                        this.inputMenuProps.input[i] = this.individualIds[this.menuSelectionIndex].id[i];
                                    }
                                    this.isMenu = false;
                                    this.isInputMenu = true;
                                    this.inputMenuProps.inputTitle = 'IND ID';
                                    this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                                }
                                this.$refs.beepOk.play();
                            }
                            else if(this.currentMenu === 'Group Id'){
                                if(this.isInputMenu){
                                    this.resetInputMenuProps();
                                    this.isInputMenu = false;
                                    this.isMenu = true;
                                }
                                else {
                                    this.isEditMode = true;
                                    this.groupIdToEdit = this.groupIds[this.menuSelectionIndex];
                                    this.groupIdToEditIndex = this.menuSelectionIndex;
                                    for(let i = 0; i < this.groupIds[this.menuSelectionIndex].id.length; i++){
                                        this.inputMenuProps.input[i] = this.groupIds[this.menuSelectionIndex].id[i];
                                    }
                                    this.isMenu = false;
                                    this.isInputMenu = true;
                                    this.inputMenuProps.inputTitle = 'GRP ID';
                                    this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                                    this.$refs.beepOk.play();
                                }
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK'){
                                this.receivedCallMode = 'IND Overview';
                                this.menuSelectionIndex = 0;
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK DETAILS'){
                                this.receivedCallMode = 'IND CALL ACK';
                                this.menuSelectionIndex = 0;
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'GRP ACCEPTED'){
                                this.receivedCallMode = 'GRP Overview';
                                this.menuSelectionIndex = 0;
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'All Ships ACCEPTED'){
                                this.receivedCallMode = 'All Ships Overview';
                                this.menuSelectionIndex = 0;
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'Distress ACCEPTED'){
                                this.receivedCallMode = 'Distress Overview';
                                this.menuSelectionIndex = 0;
                            }
                            else if(this.isReceiveCallMenu && this.receivedCallMode == 'Distress Cancel ACCEPTED'){
                                this.receivedCallMode = 'Distress Cancel Overview';
                                this.menuSelectionIndex = 0;
                            }
                            else if (this.isSelectableMenu) {
                                this.goToMenu();
                                this.currentMenu = 'DSC Settings';
                                this.menuStep = 1;
                                this.selectedMenuItems = this.$store.getters['m423/dscSettingsMenuItems'];
                            }
                            else if (this.isConfigurationMenu) {
                                this.goToMenu();
                                this.currentMenu = 'Configuration';
                                this.menuStep = 1;
                                this.selectedMenuItems = this.$store.getters['m423/configurationMenuItems'];
                            }
                            else if (this.isMenu && this.menuStep == 1){
                                this.goToMenu();
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                            break;
                        }
                        case 2: {
                            if(this.currentMenu == 'Distress Call' && this.isPosTimeOverviewMenu){
                                this.resetInputMenuProps();
                                this.isPosTimeOverviewMenu = false;
                                this.isHome = false;
                                this.isMenu = false;
                                this.currentMenu = 'Distress Call';
                                this.isInputMenu = true;
                                this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','N','S','','','','','','','','','','',''],
                                this.inputMenuProps.input = ['-','-','°','-','-','.','-','-','-','-','N'];
                                this.inputMenuProps.inputTitle = 'LAT';
                                this.menuSelectionIndex = 0;
                                this.isNoDataAvailable = true;
                            }
                            else if (this.currentMenu == 'Distress Alert' && this.isWaitingForAck) {
                                this.checkSelectedDistressCarouselItem(index);
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isCancelingDistress){
                                this.isCancelingDistress = false;
                                this.isReportSituationDistressCancelDialog = true;
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isReportSituationDistressCancelDialog){
                                await this.transmitDistressCancel();
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isDistressPausedDialog){
                                this.isWaitingForAck = true;
                                this.isDistressPausedDialog = false;
                                this.isWaitingForAckTimerPaused = false;
                            }
                            else if(this.currentMenu == 'Individual Id'){
                                if(this.isMenu){
                                    this.$store.dispatch('m423/deleteIndividualId', this.menuSelectionIndex);
                                }
                            }
                            else if(this.currentMenu == 'Group Id'){
                                if(this.isMenu){
                                    this.$store.dispatch('m423/deleteGroupId', this.menuSelectionIndex);
                                }
                            }
                            else if(this.currentMenu == 'RCVD Call Log' || this.currentMenu == 'TX Call Log' && this.menuStep == 3 && !this.isDeleteLogView){
                                this.isDeleteLogView = true;
                            }
                            else if(this.currentMenu == 'RCVD Call Log' || this.currentMenu == 'TX Call Log' && this.menuStep == 3 && this.isDeleteLogView){
                                this.isDeleteLogView = false;
                            }
                            break;
                        }
                        case 3: {
                            if(this.inputMenuProps.isInputName){
                                if(this.inputMenuProps.selectableDigits[0] == 'A'){
                                    this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                                }
                                else if(this.inputMenuProps.selectableDigits[0] == '!'){
                                    this.inputMenuProps.selectableDigits = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
                                }
                                else if(this.inputMenuProps.selectableDigits[0] == '0'){
                                    this.inputMenuProps.selectableDigits = ['!','"','#','$','%','&',"'",'(',')','*','+',',','-','.','/','[','\\',']','^','_',':',';','<','=','>','?'];
                                }
                                this.$refs.beepOk.play();
                            }
                            else if (this.currentMenu == 'Distress Alert' && this.isWaitingForAck) {
                                this.checkSelectedDistressCarouselItem(index);
                            }
                            else if(this.currentMenu == 'Distress Alert' && this.isDistressPausedDialog){
                                this.isWaitingForAck = true;
                                this.isDistressPausedDialog = false;
                                this.isWaitingForAckTimerPaused = false;
                            }
                            else if(this.isSelectingCallChannel){
                                this.pressEnter();
                            }
                            else if(this.currentMenu === 'Distress Call'){
                                this.pressEnter();
                            }
                            else if(this.currentMenu == 'RCVD Call Log' || this.currentMenu == 'TX Call Log' && !this.menuStep == 3){
                                this.pressEnter();  
                            }
                            else if(this.currentMenu == 'RCVD Call Log' || this.currentMenu == 'TX Call Log' && this.menuStep == 3 && this.isDeleteLogView){
                                this.isDeleteLogView = false;
                            }
                            else if ((this.isReceiveCallMenu && this.receivedCallMode == 'IND Overview' && this.callAlarmOff) || (this.receivedCallMode == 'RCVD Call Log Temp' && this.lastReceivedCallMode == 'IND Overview')) {
                                this.receivedCallMode = 'IND CALL ACK';
                                this.$store.dispatch('setTransmittedCall',{
                                    mode: 'Individual Ack',
                                    isTransmitting: false,
                                    senderMmsi: this.transmittedCall.senderMmsi,
                                    receiverMmsi: this.transmittedCall.receiverMmsi,
                                    category: this.transmittedCall.category,
                                    channel: this.transmittedCall.channel,
                                })
                            }
                            else if((this.isReceiveCallMenu && this.receivedCallMode == 'GRP Overview' && this.callAlarmOff) || (this.receivedCallMode == 'RCVD Call Log Temp' && this.lastReceivedCallMode == 'GRP Overview')){
                                this.tempChannelString = this.transmittedCall.channel;
                                this.receivedCallMode = 'GRP ACCEPTED';
                                this.lastWorkingChannelIndex = this.currentChannelIndex;
                                this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            }
                            else if((this.isReceiveCallMenu && this.receivedCallMode == 'All Ships Overview' && this.callAlarmOff) || (this.receivedCallMode == 'RCVD Call Log Temp' && this.lastReceivedCallMode == 'All Ships Overview')){
                                this.tempChannelString = this.transmittedCall.channel;
                                this.receivedCallMode = 'All Ships ACCEPTED';
                                this.lastWorkingChannelIndex = this.currentChannelIndex;
                                this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            }
                            else if((this.isReceiveCallMenu && this.receivedCallMode == 'Distress Overview' && this.callAlarmOff) || (this.receivedCallMode == 'RCVD Call Log Temp' && this.lastReceivedCallMode == 'Distress Overview')){
                                this.tempChannelString = this.transmittedCall.channel;
                                this.isReceiveCallMenu = true;
                                this.receivedCallMode = 'Distress ACCEPTED';
                                this.lastWorkingChannelIndex = this.currentChannelIndex;
                                this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            }
                            else if((this.isReceiveCallMenu && this.receivedCallMode == 'Distress Cancel Overview' && this.callAlarmOff) || (this.receivedCallMode == 'RCVD Call Log Temp' && this.lastReceivedCallMode == 'Distress Cancel Overview')){
                                this.tempChannelString = this.transmittedCall.channel;
                                this.isReceiveCallMenu = true;
                                this.receivedCallMode = 'Distress Cancel ACCEPTED';
                                this.lastWorkingChannelIndex = this.currentChannelIndex;
                                this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            }
                            else if (this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK') { 
                                switch(this.menuSelectionIndex){
                                    case 0: {
                                        this.receivedCallMode = 'IND CALL ACK DETAILS';
                                        this.$store.dispatch('m423/setIndividualAckDetails', {
                                            receiverId: this.transmittedCall.receiverMmsi,
                                            category: this.transmittedCall.category,
                                            callChannel: this.channels.find((el) => el.number == parseInt(this.transmittedCall.channel)),
                                            isAbleToComply: true,
                                            reason: ''
                                        });
                                        break;
                                    }
                                    case 1: {
                                        this.receivedCallMode = 'IND CALL ACK DETAILS';
                                        this.$store.dispatch('m423/setIndividualAckDetails', {
                                            receiverId: this.transmittedCall.receiverMmsi,
                                            category: this.transmittedCall.category,
                                            callChannel: this.channels.find((el) => el.number == parseInt(this.transmittedCall.channel)),
                                            isAbleToComply: false,
                                            reason: 'No reason given'
                                        });
                                        break;
                                    }
                                }
                            }
                            else if (this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK DETAILS'){
                                this.currentMenu = 'Individual Ack';
                                this.transmitCall();
                            }
                            else if (this.isMenu && this.menuStep == 1) {
                                this.pressEnter();
                            }
                            else if(this.isCallOverviewMenu){
                                this.pressEnter();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                    }
                }
            }
        },
        editItem(item, index, mode){
            switch(mode){
                case 'Individual Id': {
                    //
                    break;
                }
            }
        },
        checkSelectedDistressCarouselItem(index){
            let clickedItemIndex = index;
            if(clickedItemIndex >= 2){
                clickedItemIndex = 1;
            }
            else{
                clickedItemIndex = 0;
            }
            let currentItems = this.distressCallCarouselItems.slice(this.currentCarouselMenuIndex, this.currentCarouselMenuIndex + 2);
            switch(currentItems[clickedItemIndex]){
                case 'CANCEL': {
                    this.isWaitingForAck = false;
                    this.isCancelingDistress = true;
                    this.isWaitingForAckTimerPaused = true;
                    break;
                }
                case 'RESEND': {
                    this.isWaitingForAck = false;
                    this.isWaitingForAckTimerPaused = true;
                    this.isResendDistressDialog = true;
                    break;
                }
                case 'PAUSE': {
                    this.isWaitingForAck = false;
                    this.isWaitingForAckTimerPaused = true;
                    this.isDistressPausedDialog = true;
                    break;
                }
                case 'INFO': {
                    this.isWaitingForAck = false;
                    this.isDistressInfoDialog = true;
                    this.isMenu = true;
                    this.isCallOverviewMenu = false;
                    this.menuStep = 1;
                    break;
                }
            }
        },
        async transmitDistressCancel(){
            this.isReportSituationDistressCancelDialog = false;
            this.isTransmittingDistressCancel = true;
            this.$store.dispatch('setTransmittedCall', {
                mode: 'Distress Cancel',
                isTransmitting: true,
                senderMmsi: this.mmsi,
                category: '',
                channel: '16',
            });
            await this.runDistressCancel();
        },
        async runDistressCancel(){
            setTimeout(() => {
                this.isTransmittingDistressCancel = false;
                this.isTransmittedDistressCancel = true;
            },3000);
        },
        stopScan(){
            if(this.scanIsRunning){
                clearInterval(this.scanInterval);
                this.scanIsRunning = false;
            }
            if(!this.scanIsRunning)
                return true;
            return false;
        },
        selectCarouselMenuItem(item){
            switch(item){
                case 'SCAN': {
                    this.isDualMode = false;
                    this.runScan();
                    break;
                }
                case 'DW': {
                    this.stopScan();         
                    this.isDualMode = !this.isDualMode;
                    break;
                }
                case 'HI/LO': {
                    this.stopScan();
                    this.isHigh = !this.isHigh;
                    break;
                }
                case 'CHAN': {
                    this.switchToWorkingChannel();
                    break;
                }
                case 'PA': {
                    this.isHome = false;
                    this.hornTitle = 'PA';
                    this.isHornMenu = true;
                    break;
                }
                case 'RX': {
                    if(this.isRX) {
                        this.isRX = false;
                    }
                    else{
                        this.InitCloseQuickMenuTimer();
                        this.isQuickMenu = true;
                        this.isInputRX = true;
                        this.isRX = true;
                    }
                    break;
                }
                case 'HORN': {
                    this.isHome = false;
                    this.hornTitle = 'HORN';
                    this.isHornMenu = true;
                    break;
                }
                case 'INCM': {
                    break;
                }
                case 'AQUA': {
                    this.isQuickMenu = true;
                    this.isAqua = true;
                    this.InitCloseQuickMenuTimer();
                    break;
                }
                case '*': {
                    this.$store.dispatch('m423/toggleChannelTag', this.currentChannelIndex);
                    break;
                }
                case 'NAME': {
                    this.isHome = false;
                    this.isInputMenu = true;
                    this.currentMenu = 'CHAN Name';
                    this.inputMenuProps.inputTitle = 'NAME';
                    this.inputMenuProps.isInputName = true;
                    for(let i = 0; i < this.channels[this.currentChannelIndex].description.length; i++){ 
                        this.inputMenuProps.input[i] = this.channels[this.currentCallChannelIndex].description[i];
                    }
                    this.inputMenuProps.selectableDigits = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
                    break;
                }
                case 'BKLT': {
                    this.isQuickMenu = true;
                    this.isInputBacklight = true;
                    break;
                }
                case 'LOG': {
                    this.menuSelectionIndex = 0;
                    this.isHome = false;
                    this.isMenu = true;
                    this.menuStep = 1;
                    this.currentMenu = 'RCVD Call Log';
                    this.selectedMenuItems = this.$store.getters['m423/receivedCallLogMenuItems'];
                    break;
                }
            }
        },
        runScan(){
            if(this.scanInterval){
                clearInterval(this.scanInterval);
                this.scanInterval = undefined;
                this.scanIsRunning = false;
            }
            else{
                this.scanInterval = setInterval(() => {
                    this.scanIsRunning = true;
                    this.switchChannel('down', 'scan');
                }, 130);
            }
        },
        pressChannel16Btn(){
            if(this.powerIsOn){
                if(!this.isHome){
                    this.goToHome();
                }
                if(this.isAtisMode){
                    this.lastWorkingChannelIndex = this.currentChannelIndex;
                    this.currentChannelIndex = 9;
                }
                else{
                    this.lastWorkingChannelIndex = this.currentChannelIndex;
                    this.currentChannelIndex = 15;
                }
                this.$refs.beepOk.play();
            }
        },
        pressClear(){
            if(this.powerIsOn){
                if(this.menuStep > 0){
                    this.pressQuickBtn(1);
                }
                else if(this.isHome){
                    this.$refs.beepFalsch.play();
                }
                else{
                    this.goToHome();
                    this.$refs.beepOk.play();
                }
                this.dimm(this.backlightValue);
            }
        },
        goToHome(){
            this.isMenu = false;
            this.isSelectingCallChannel = false;
            this.isSelectingCategory = false;
            this.isTransmitting = false;
            this.isWaitingForAck = false;
            this.isCallOverviewMenu = false;
            this.menuStep = 0;
            this.menuSelectionIndex = 0;
            this.selectedMenuItems = this.menuItems;
            this.isInputMenu = false;
            this.inputMenuProps.input = ['-','-','-','-','-','-','-','-','-'];
            this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''],
            this.inputMenuProps.selectedDigitIndex = 0;
            this.inputMenuProps.currentInputIndex = 0;
            this.inputMenuProps.isInputGroupId = false;
            this.inputMenuProps.isInputId = false;
            this.inputMenuProps.isInputName = false;
            this.isTransmitting = false;
            this.isWaitingForAck = false;
            this.isPosTimeOverviewMenu = false;
            this.isDistressCallOverviewMenu = false;
            this.isHornMenu = false;
            this.isRX = false;
            this.isInputBacklight = false;
            this.isInputRX = false;
            this.isAqua = false;
            this.isNoDataAvailable = false;
            this.isCancelingDistress = false;
            this.isReportSituationDistressCancelDialog = false;
            this.isTransmittingDistressCancel = false;
            this.isTransmittedDistressCancel = false;
            this.isResendDistressDialog = false;
            this.resendDistressCountdown = 3;
            this.isAllShipsCalling = false;
            this.isCallOverviewMenu = false;
            this.receivedACK = false;
            this.receivedACKAlarmOff = false;
            this.isGroupCallSent = false;
            this.isReceiveCallMenu = false;
            this.receivedCallMode = '';
            this.currentCarouselMenuIndex = 13;
            this.isDistressCallOverviewMenu = false;
            this.isDistressInfoDialog = false;
            this.isHome = true;
        },
        resetInputMenuProps(mode = undefined){
            switch(mode){
                case 'group':{
                    this.inputMenuProps.selectedDigitIndex = 0;
                    this.inputMenuProps.currentInputIndex = 1;
                    this.inputMenuProps.input = ['','','','','','','','','',''];
                    this.isEditMode = false;
                    break;
                }
                default:{
                    this.inputMenuProps.selectedDigitIndex = 0;
                    this.inputMenuProps.currentInputIndex = 0;
                    this.inputMenuProps.input = ['','','','','','','','','',''];
                    this.isEditMode = false;
                    break;
                }
            }
        },
        pressEnter(){
            if(this.powerIsOn && this.isHome){
                this.$refs.beepFalsch.play();
            }
            if(this.powerIsOn && this.isMenu){
                this.dimm(this.backlightValue);
                var cantGoFurther = false;
                if(this.menuStep === 0){
                    switch(this.selectedMenuItems[this.menuSelectionIndex].title){
                        case 'DSC Calls':{
                            this.selectedMenuItems = this.$store.getters['m423/dscCallsMenuItems'];
                            this.currentMenu = 'DSC Calls';
                            break;
                        }
                        case 'DSC Settings':{
                            this.selectedMenuItems = this.$store.getters['m423/dscSettingsMenuItems'];
                            this.currentMenu = 'DSC Settings';
                            break;
                        }
                        case 'Radio Settings': {
                            this.selectedMenuItems = this.$store.getters['m423/radioSettingsMenuItems'];
                            this.currentMenu = 'Radio Settings';
                            break;
                        }
                        case 'Configuration': {
                            this.selectedMenuItems = this.$store.getters['m423/configurationMenuItems'];
                            this.currentMenu = 'Configuration';
                            break;
                        }
                        case 'MMSI/GPS Info': {
                            this.currentMenu = 'MMSI/GPS Info';
                        }
                    }
                }
                else if (this.menuStep === 1 && this.selectedMenuItems[this.menuSelectionIndex]){
                    switch(this.selectedMenuItems[this.menuSelectionIndex].title){
                        case 'Individual Call': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = false;
                            this.currentMenu = 'Individual Call';
                            this.isInputMenu = true;
                            this.inputMenuProps.input = ['-','-','-','-','-','-','-','-','-'];
                            this.inputMenuProps.inputTitle = 'IND ID';
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Group Call': {
                            this.resetInputMenuProps('group');
                            this.isHome = false;
                            this.isMenu = false;
                            this.currentMenu = 'Group Call';
                            this.isInputMenu = true;
                            this.inputMenuProps.input = ['0','-','-','-','-','-','-','-','-'];
                            this.inputMenuProps.isInputGroupId = true;
                            this.inputMenuProps.currentInputIndex = 1;
                            this.inputMenuProps.inputTitle = 'GRP ID';
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'All Ships Call': {
                            this.resetInputMenuProps();
                            this.menuSelectionIndex = 0;
                            this.isHome = false;
                            this.isMenu = true;
                            this.menuStep = 1;
                            this.currentMenu = 'All Ships Call';
                            this.isSelectingCategory = true;
                            this.selectedMenuItems = this.$store.getters['m423/allShipsCallCategorys'];
                            cantGoFurther = true;
                            break;
                        }
                        case 'Distress Call': {
                            this.resetInputMenuProps();
                            this.menuSelectionIndex = 0;
                            this.isHome = false;
                            this.isMenu = true;
                            this.menuStep = 1;
                            this.currentMenu = 'Distress Call';
                            this.isSelectingCategory = true;
                            this.selectedMenuItems = this.$store.getters['m423/distressCallMenuItems'];
                            cantGoFurther = true;
                            break;
                        }
                        case 'Received Call Log': {
                            this.resetInputMenuProps();
                            this.menuSelectionIndex = 0;
                            this.isHome = false;
                            this.isMenu = true;
                            this.menuStep = 1;
                            this.currentMenu = 'RCVD Call Log';
                            this.selectedMenuItems = this.$store.getters['m423/receivedCallLogMenuItems'];
                            cantGoFurther = true;
                            break;
                        }
                        case 'Transmitted Call Log': {
                            this.resetInputMenuProps();
                            this.menuSelectionIndex = 0;
                            this.isHome = false;
                            this.isMenu = true;
                            this.menuStep = 1;
                            this.currentMenu = 'TX Call Log';
                            this.selectedMenuItems = this.$store.getters['m423/transmittedCallLogMenuItems'];
                            cantGoFurther = true;
                            break;
                        }
                        case 'Test Call': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = false;
                            this.currentMenu = 'Test Call';
                            this.isInputMenu = true;
                            this.inputMenuProps.input = ['-','-','-','-','-','-','-','-','-'];
                            this.inputMenuProps.inputTitle = 'IND ID';
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Position Input': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = false;
                            this.currentMenu = 'Position Input';
                            this.isInputMenu = true;
                            this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','N','S','','','','','','','','','','',''],
                            this.inputMenuProps.input = ['-','-','°','-','-','.','-','-','-','-','N'];
                            this.inputMenuProps.inputTitle = 'LAT';
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Individual ID': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Individual Id';
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/individualIds'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Group ID': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Group Id';
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/groupIds'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Individual ACK:': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Individual ACK';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/individualAckItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Position ACK:': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Position ACK';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/positionAckItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Test ACK:': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Test ACK';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/testAckItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'CH 16 Switch:': {
                            this.resetInputMenuProps();
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'CH 16 Switch';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/ch16SwitchItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'DSC Data Output:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'DSC Data Output';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/dscDataOutputItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Alarm': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Alarm';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/alarmMenuItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Safety:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Safety';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/alarmSafetyItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Routine:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Routine';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/alarmRoutineItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Warning:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Warning';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/alarmWarningItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Self-Terminate:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Self Terminate';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/alarmSelfTerminateItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Discrete:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Discrete';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/alarmDiscreteItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'CH 70 SQL Level': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'CH 70 SQL Level';
                            this.menuStep = 1;
                            this.selectedMenuItems = [];
                            this.isInputSql = true;
                            this.sliderValue = this.sqlValue;
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'DSC Loop Test': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'DSC Loop Test';
                            this.menuStep = 1;
                            this.selectedMenuItems = [];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            this.loopIsOk = false;
                            break;
                        }
                        case 'Scan Type': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Scan Type';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/scanTypeItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Scan Timer': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Scan Timer';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/scanTimerItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Dual/Tri-Watch': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Dual/Tri-Watch';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/dualTriWatchItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'CHAN Group': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'CHAN Group';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/chanGroupItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Backlight': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Backlight';
                            this.menuStep = 1;
                            this.sliderValue = this.selectedMenuItems[0].value;
                            this.backlightColor = this.dimmSteps[this.sliderValue];
                            this.selectedMenuItems = [];
                            this.isInputBacklight = true;
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Display Contrast': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Display Contrast';
                            this.menuStep = 1;
                            this.sliderValue = this.selectedMenuItems[1].value;
                            this.contrastLevel = this.contrastSteps[this.sliderValue];
                            this.selectedMenuItems = [];
                            this.isInputContrast = true;
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Key Beep': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Key Beep';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/keyBeepItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'UTC Offset': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'UTC Offset';
                            this.selectedMenuItems = [];
                            this.menuStep = 1;
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Noise Cancel': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Noise Cancel';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/noiseCancelMenuItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'RX:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'RX';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/rxItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'TX:': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'TX';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/txItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Inactivity Timer': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Inactivity Timer';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/inactivityTimerMenuItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Not DSC Related': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Not DSC Related';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/notDscRelatedItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'DSC Related': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'DSC Related';
                            this.menuStep = 1;
                            this.selectedMenuItems = this.$store.getters['m423/dscRelatedItems'];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                        case 'Remote ID': {
                            this.isHome = false;
                            this.isMenu = true;
                            this.currentMenu = 'Remote ID';
                            this.menuStep = 1;
                            this.selectedMenuItems = [];
                            cantGoFurther = true;
                            this.menuSelectionIndex = 0;
                            break;
                        }
                    }
                }
                if(this.currentMenu == 'Individual Call' && this.isSelectingCategory && !cantGoFurther){
                    this.$store.dispatch('m423/setIndividualCallDetails', {mode: 'CATEGORY', category: this.selectedMenuItems[this.menuSelectionIndex].title});
                    this.isMenu = false;
                    this.isSelectingCategory = false;
                    this.isSelectingCallChannel = true;
                }
                else if(this.currentMenu == 'Group Call' && this.isSelectingCategory && !cantGoFurther){
                    this.$store.dispatch('m423/setGroupCallDetails', {mode: 'CATEGORY', category: this.selectedMenuItems[this.menuSelectionIndex].title});
                    this.isMenu = false;
                    this.isSelectingCategory = false;
                    this.isSelectingCallChannel = true;
                }
                else if(this.currentMenu == 'All Ships Call' && this.isSelectingCategory && !cantGoFurther){
                    this.$store.dispatch('m423/setAllShipsCallDetails', {mode: 'CATEGORY', category: this.selectedMenuItems[this.menuSelectionIndex].title});
                    this.isMenu = false;
                    this.isSelectingCategory = false;
                    this.isSelectingCallChannel = true;
                }
                else if(this.currentMenu == 'Distress Call' && this.isSelectingCategory && !cantGoFurther){
                    this.$store.dispatch('m423/setDistressCallDetails', {mode: 'CATEGORY', category: this.selectedMenuItems[this.menuSelectionIndex].title});
                    this.isSelectingCategory = false;
                    this.isMenu = false;
                    this.isPosTimeOverviewMenu = true;
                }
                else if(this.currentMenu == 'RCVD Call Log' && this.menuStep == 1 && !cantGoFurther){
                    this.isMenu = true;
                    this.menuStep = 2; 
                    if(this.selectedMenuItems[this.menuSelectionIndex].title == 'Distress'){
                        this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].distress; 
                    }
                    else if(this.selectedMenuItems[this.menuSelectionIndex].title == 'Others'){
                        this.selectedMenuItems = this.$store.getters['m423/receivedCalls'].other;
                    }
                    this.menuSelectionIndex = 0;
                }
                else if (this.currentMenu == 'RCVD Call Log' && this.menuStep == 2 && !cantGoFurther) {
                    this.isMenu = true;
                    this.menuStep = 3;
                    
                    this.selectedCallLog = this.selectedMenuItems[this.menuSelectionIndex];
                    this.menuSelectionIndex = 0;
                }
                else if(this.currentMenu == 'TX Call Log' && this.menuStep == 1 && !cantGoFurther){
                    this.isMenu = true;
                    this.menuStep = 2;
                    if(this.selectedMenuItems[this.menuSelectionIndex].title == 'Distress'){
                        this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].distress; 
                    }
                    else if(this.selectedMenuItems[this.menuSelectionIndex].title == 'Others'){
                        this.selectedMenuItems = this.$store.getters['m423/transmittedCalls'].other; 
                    }         
                    this.menuSelectionIndex = 0;
                }
                else if (this.currentMenu == 'TX Call Log' && this.menuStep == 2 && !cantGoFurther) {
                    this.isMenu = true;
                    this.menuStep = 3;
                    this.selectedCallLog = this.selectedMenuItems[this.menuSelectionIndex];
                    this.menuSelectionIndex = 0;
                }
                else if(this.currentMenu == 'Individual ACK' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setDscSettingsMenuItemsValue', {index: 3, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Position ACK' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setDscSettingsMenuItemsValue', {index: 4, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Test ACK' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setDscSettingsMenuItemsValue', {index: 5, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'CH 16 Switch' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setDscSettingsMenuItemsValue', {index: 6, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'DSC Data Output' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setDscSettingsMenuItemsValue', {index: 7, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Safety' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setAlarmMenuItemsValue', {index: 0, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Routine' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setAlarmMenuItemsValue', {index: 1, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Warning' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setAlarmMenuItemsValue', {index: 2, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Self Terminate' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setAlarmMenuItemsValue', {index: 3, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Discrete' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setAlarmMenuItemsValue', {index: 4, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'CH 70 SQL Level' && !cantGoFurther){
                    this.sqlValue = this.sliderValue;
                    this.$store.dispatch('m423/setDscSettingsMenuItemsValue', {index: 9, value: this.sqlValue});
                    this.selectedMenuItems = this.$store.getters['m423/dscSettingsMenuItems'];
                    this.currentMenu = 'DSC Settings';
                }
                else if(this.currentMenu == 'DSC Loop Test' && !cantGoFurther){
                    this.loopIsOk = true;
                }
                else if(this.currentMenu == 'Scan Type' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setRadioSettingsMenuItemsValue', {index: 0, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Scan Timer' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setRadioSettingsMenuItemsValue', {index: 1, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Dual/Tri-Watch' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setRadioSettingsMenuItemsValue', {index: 2, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'CHAN Group' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setRadioSettingsMenuItemsValue', {index: 3, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Backlight' && !cantGoFurther){
                    this.backlightValue = this.sliderValue;
                    this.backlightColor = this.dimmSteps[this.backlightValue];
                    this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 0, value: this.backlightValue});
                    this.isInputBacklight = false;
                    this.selectedMenuItems = this.$store.getters['m423/configurationMenuItems'];
                    this.currentMenu = 'Configuration';
                }
                else if(this.currentMenu == 'Display Contrast' && !cantGoFurther){
                    this.contrastValue = this.sliderValue;
                    this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 1, value: this.contrastValue});
                    this.isInputContrast = false;
                    this.selectedMenuItems = this.$store.getters['m423/configurationMenuItems'];
                    this.currentMenu = 'Configuration';
                }
                else if(this.currentMenu == 'UTC Offset' && !cantGoFurther){
                    this.menuSelectionIndex = 0;
                    this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 4, value: this.utcOffset.join('')});
                    this.selectedMenuItems = this.$store.getters['m423/configurationMenuItems'];
                    this.currentMenu = 'Configuration';
                }
                else if(this.currentMenu == 'Key Beep' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 2, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'RX' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setNoiseCancelMenuItemsValue', {index: 0, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'TX' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setNoiseCancelMenuItemsValue', {index: 1, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'DSC Related' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setInactivityTimerMenuItemsValue', {index: 1, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                else if(this.currentMenu == 'Not DSC Related' && !cantGoFurther){
                    if(!this.selectedMenuItems[this.menuSelectionIndex].selected){
                        for(let i = 0; i < this.selectedMenuItems.length; i++){
                            this.selectedMenuItems[i].selected = false;
                        }
                        this.selectedMenuItems[this.menuSelectionIndex].selected = true;
                        this.$store.dispatch('m423/setInactivityTimerMenuItemsValue', {index: 0, value: this.selectedMenuItems[this.menuSelectionIndex].value});
                    }
                }
                if(this.menuStep == 0){
                    this.menuSelectionIndex = 0;
                    this.menuStep++;
                }
            }
            else if (this.isInputMenu){
                if(this.inputMenuProps.selectedDigitIndex <= 24 && this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex]){
                    if(this.inputMenuProps.inputTitle == 'LAT'){
                        if(this.inputMenuProps.currentInputIndex == 10 && (this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex] == 'N' || this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex] == 'S')){
                            this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                        }
                        else if(this.inputMenuProps.currentInputIndex < 10){
                            this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                        }
                        if(this.inputMenuProps.currentInputIndex == 1 || this.inputMenuProps.currentInputIndex == 4){
                            this.inputMenuProps.currentInputIndex += 1;
                        }
                        if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length - 1){
                            this.inputMenuProps.currentInputIndex++;
                        }
                    }
                    else if(this.inputMenuProps.inputTitle == 'LON'){
                        if(this.inputMenuProps.currentInputIndex == 11 && (this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex] == 'W' || this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex] == 'E')){
                            this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                        }
                        else if(this.inputMenuProps.currentInputIndex < 11){
                            this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                        }
                        if(this.inputMenuProps.currentInputIndex == 2 || this.inputMenuProps.currentInputIndex == 5){
                            this.inputMenuProps.currentInputIndex += 1;
                        }
                        if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length - 1){
                            this.inputMenuProps.currentInputIndex++;
                        }
                    }
                    else if(this.inputMenuProps.inputTitle == 'UTC'){
                        if(this.inputMenuProps.currentInputIndex == 1){
                            this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                            this.inputMenuProps.currentInputIndex += 1;
                        }
                        else if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length){
                            this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                        }
                        if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length - 1){
                            this.inputMenuProps.currentInputIndex++;
                        }
                    }
                    else{
                        this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex];
                        if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length - 1){
                            this.inputMenuProps.currentInputIndex++;
                        }
                        if(!this.inputMenuProps.input.includes('-') && !this.inputMenuProps.input.includes('')){
                            this.inputMenuProps.selectedDigitIndex = 30;
                        }
                    }
                }
                else if(this.inputMenuProps.selectedDigitIndex == 26){
                    if(this.inputMenuProps.isInputGroupId){
                        if(this.inputMenuProps.currentInputIndex > 1){
                            this.inputMenuProps.currentInputIndex--;
                        }
                    }
                    else{
                        if(this.inputMenuProps.currentInputIndex > 0){
                            this.inputMenuProps.currentInputIndex--;
                        }
                    }
                }
                else if(this.inputMenuProps.selectedDigitIndex == 27){
                    if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length - 1){
                        this.inputMenuProps.currentInputIndex++;
                    }
                }
                else if(this.inputMenuProps.selectedDigitIndex == 28){
                    if(this.inputMenuProps.isInputName){
                        this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = ' ';
                        if(this.inputMenuProps.currentInputIndex < this.inputMenuProps.input.length - 1){
                            this.inputMenuProps.currentInputIndex++;
                        }
                    }
                }
                else if (this.inputMenuProps.selectedDigitIndex == 29) {
                    if(this.inputMenuProps.inputTitle == 'LAT'){
                        this.inputMenuProps.input = ['-','-','°','-','-','.','-','-','-','-','N'];
                        this.inputMenuProps.currentInputIndex = 0;
                    }
                    else if(this.inputMenuProps.inputTitle == 'LON'){
                        this.inputMenuProps.input = ['-','-','-','°','-','-','.','-','-','-','-','W'];
                        this.inputMenuProps.currentInputIndex = 0;
                    }
                    else if(this.inputMenuProps.inputTitle == 'UTC'){
                        this.inputMenuProps.input = ['-','-',':','-','-'];
                        this.inputMenuProps.currentInputIndex = 0;
                    }
                    else if(this.inputMenuProps.isInputName){
                        this.inputMenuProps.input[this.inputMenuProps.currentInputIndex] = '';
                    }
                }
                else if(this.inputMenuProps.selectedDigitIndex == 30){
                    var idString = '';
                    this.inputMenuProps.input.forEach((el) => {
                        idString += el;
                    })
                    if(this.currentMenu === 'Individual Call'){
                        this.$store.dispatch('m423/setIndividualCallDetails', {mode: 'ID', id: idString});
                        this.isMenu = true;
                        this.isSelectingCategory = true;
                        this.menuStep = 1;
                        this.selectedMenuItems = this.$store.getters['m423/individualCallCategorys'];
                        this.isInputMenu = false;
                    }
                    else if(this.currentMenu === 'Group Call'){
                        this.$store.dispatch('m423/setGroupCallDetails', {mode: 'ID', id: idString});
                        this.$store.dispatch('m423/setGroupCallDetails', {mode: 'CATEGORY', category: 'Routine'});
                        this.isMenu = true;
                        this.isSelectingCategory = true;
                        this.selectedMenuItems = this.$store.getters['m423/groupCallCategorys'];
                        this.isInputMenu = false;
                    }
                    else if(this.currentMenu === 'Test Call'){
                        this.$store.dispatch('m423/setTestCallDetails', {mode: 'ID', id: idString});
                        this.$store.dispatch('m423/setTestCallDetails', {mode: 'CATEGORY', category: 'Safety'});
                        this.isMenu = false;
                        this.isCallOverviewMenu = true;
                        this.isInputMenu = false;
                    }
                    else if(this.currentMenu === 'Position Input'){
                        if(this.inputMenuProps.inputTitle == 'LAT'){
                            this.$store.dispatch('m423/setPositionInputDetails', {mode: 'LAT', lat: idString});
                            this.inputMenuProps.inputTitle = 'LON'
                            this.inputMenuProps.input = ['-','-','-','°','-','-','.','-','-','-','-','W'];
                            this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','W','E','','','','','','','','','','',''];
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                        }
                        else if(this.inputMenuProps.inputTitle == 'LON'){
                            this.$store.dispatch('m423/setPositionInputDetails', {mode: 'LON', long: idString});
                            this.inputMenuProps.inputTitle = 'UTC'
                            this.inputMenuProps.input = ['-','-',':','-','-'];
                            this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                        }
                        else if(this.inputMenuProps.inputTitle == 'UTC'){
                            this.$store.dispatch('m423/setPositionInputDetails', {mode: 'UTC', utc: idString});
                            this.isInputMenu = false;
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                            this.isMenu = true;
                        }
                    }
                    else if (this.currentMenu === 'Distress Call') {
                        if(this.inputMenuProps.inputTitle == 'LAT'){
                            this.$store.dispatch('m423/setDistressPositionInputDetails', {mode: 'LAT', lat: idString});
                            this.inputMenuProps.inputTitle = 'LON'
                            this.inputMenuProps.input = ['-','-','-','°','-','-','.','-','-','-','-','W'];
                            this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','W','E','','','','','','','','','','',''];
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                        }
                        else if(this.inputMenuProps.inputTitle == 'LON'){
                            this.$store.dispatch('m423/setDistressPositionInputDetails', {mode: 'LON', long: idString});
                            this.inputMenuProps.inputTitle = 'UTC'
                            this.inputMenuProps.input = ['-','-',':','-','-'];
                            this.inputMenuProps.selectableDigits = ['0','1','2','3','4','5','6','7','8','9','','','','','','','','','','','','','','','',''];
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                        }
                        else if(this.inputMenuProps.inputTitle == 'UTC'){
                            this.$store.dispatch('m423/setDistressPositionInputDetails', {mode: 'UTC', utc: idString});
                            this.isInputMenu = false;
                            this.inputMenuProps.currentInputIndex = 0;
                            this.inputMenuProps.selectedDigitIndex = 0;
                            this.isMenu = false;
                            this.menuStep = 1;
                            this.currentMenu = 'Distress Call';
                            this.isSelectingCategory = false;
                            this.isPosTimeOverviewMenu = true;
                        }
                    }
                    else if(this.currentMenu === 'Individual Id'){
                        if(this.inputMenuProps.inputTitle == 'IND ID'){
                            if(this.isEditMode){
                                this.individualIdToEdit.id = idString;
                                this.inputMenuProps.inputTitle = 'NAME';
                                this.inputMenuProps.input = ['','','','','','','','','',''];
                                for(let i = 0; i < this.individualIds[this.menuSelectionIndex].title.length; i++){
                                    this.inputMenuProps.input[i] = this.individualIds[this.menuSelectionIndex].title[i];
                                }
                                this.inputMenuProps.selectableDigits = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
                                this.inputMenuProps.currentInputIndex = 0;
                                this.inputMenuProps.selectedDigitIndex = 0;
                                this.inputMenuProps.isInputName = true;
                            }
                            else{
                                this.individualId.id = idString;
                                this.inputMenuProps.inputTitle = 'NAME';
                                this.inputMenuProps.input = ['','','','','','','','','',''];
                                this.inputMenuProps.selectableDigits = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
                                this.inputMenuProps.currentInputIndex = 0;
                                this.inputMenuProps.selectedDigitIndex = 0;
                                this.inputMenuProps.isInputName = true;
                            }
                        }
                        else if(this.inputMenuProps.inputTitle == 'NAME'){
                            if(this.isEditMode){
                                this.individualIdToEdit.title = idString;
                                this.$store.dispatch('m423/editIndividualId', {index: this.individualIdToEditIndex, individualId: this.individualIdToEdit});
                                this.isInputMenu = false;
                                this.resetInputMenuProps();
                                this.isMenu = true;
                                this.menuStep = 1;
                            }
                            else{
                                this.individualId.title = idString;
                                this.$store.dispatch('m423/setIndividualIds', {mode: 'ADD', address: this.individualId});
                                this.isInputMenu = false;
                                this.resetInputMenuProps();
                                this.isMenu = true;
                                this.menuStep = 1;
                            }
                        }
                    }
                    else if(this.currentMenu === 'Group Id'){
                        if(this.inputMenuProps.inputTitle == 'GRP ID'){
                            if(this.isEditMode){
                                this.groupIdToEdit.id = idString;
                                this.inputMenuProps.inputTitle = 'NAME';
                                this.inputMenuProps.input = ['','','','','','','','','',''];
                                for(let i = 0; i < this.groupIds[this.menuSelectionIndex].title.length; i++){
                                    this.inputMenuProps.input[i] = this.groupIds[this.menuSelectionIndex].title[i];
                                }
                                this.inputMenuProps.selectableDigits = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
                                this.inputMenuProps.currentInputIndex = 0;
                                this.inputMenuProps.selectedDigitIndex = 0;
                                this.inputMenuProps.isInputName = true;
                            }
                            else{
                                this.groupId.id = idString;
                                this.inputMenuProps.inputTitle = 'NAME';
                                this.inputMenuProps.input = ['','','','','','','','','',''];
                                this.inputMenuProps.selectableDigits = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
                                this.inputMenuProps.currentInputIndex = 0;
                                this.inputMenuProps.selectedDigitIndex = 0;
                                this.inputMenuProps.isInputName = true;
                            }
                        }
                        else if(this.inputMenuProps.inputTitle == 'NAME'){
                            if(this.isEditMode){
                                this.groupIdToEdit.title = idString;
                                this.$store.dispatch('m423/editGroupId', {index: this.groupIdToEditIndex, groupId: this.groupIdToEdit});
                                this.isInputMenu = false;
                                this.resetInputMenuProps();
                                this.isMenu = true;
                                this.menuStep = 1;
                            }
                            else{
                                this.groupId.title = idString;
                                this.$store.dispatch('m423/setGroupIds', {mode: this.mode, address: this.groupId});
                                this.isInputMenu = false;
                                this.resetInputMenuProps();
                                this.isMenu = true;
                                this.menuStep = 1;
                            }
                        }
                    }
                    else if(this.currentMenu === 'CHAN Name'){
                        this.$store.dispatch('m423/setChannelDescription', {index: this.currentChannelIndex, description: idString});
                        this.goToHome();
                    }
                }
            }
            else if (this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK') { 
                switch(this.menuSelectionIndex){
                    case 0: {
                        this.receivedCallMode = 'IND CALL ACK DETAILS';
                        this.$store.dispatch('m423/setIndividualAckDetails', {
                            receiverId: this.transmittedCall.receiverMmsi,
                            category: this.transmittedCall.category,
                            callChannel: this.channels.find((el) => el.number == parseInt(this.transmittedCall.channel)),
                            isAbleToComply: true,
                            reason: ''
                        });
                        break;
                    }
                    case 1: {
                        this.receivedCallMode = 'IND CALL ACK DETAILS';
                        this.$store.dispatch('m423/setIndividualAckDetails', {
                            receiverId: this.transmittedCall.receiverMmsi,
                            category: this.transmittedCall.category,
                            callChannel: this.channels.find((el) => el.number == parseInt(this.transmittedCall.channel)),
                            isAbleToComply: false,
                            reason: 'No reason given'
                        });
                        break;
                    }
                }
            }
            else if (this.isReceiveCallMenu && this.receivedCallMode == 'IND CALL ACK DETAILS'){
                this.currentMenu = 'Individual Ack';
                this.transmitCall();
            }
            else if(this.isSelectingCallChannel){
                if(this.currentMenu === 'Individual Call'){
                    this.$store.dispatch('m423/setIndividualCallDetails', {mode: 'CHANNEL', channel: this.channels[this.currentCallChannelIndex]});
                }
                else if(this.currentMenu === 'Group Call'){
                    this.$store.dispatch('m423/setGroupCallDetails', {mode: 'CHANNEL', channel: this.channels[this.currentCallChannelIndex]});
                }
                else if(this.currentMenu === 'All Ships Call'){
                    this.$store.dispatch('m423/setAllShipsCallDetails', {mode: 'CHANNEL', channel: this.channels[this.currentCallChannelIndex]});
                }
                this.isSelectingCallChannel = false;
                this.isCallOverviewMenu = true;
            }
            else if(this.isCallOverviewMenu){
                this.transmitCall();
            }
            else if(this.isPosTimeOverviewMenu){
                this.isPosTimeOverviewMenu = false;
                this.isDistressCallOverviewMenu = true;
            }
        },
        execDistressCall(){
            if(this.powerIsOn && !this.isAtisMode && !this.isResendDistressDialog){
                this.goToHome();
                this.isHome = false;
                this.currentMenu = 'Distress Alert';
                this.isCallOverviewMenu = true;
                this.currentCarouselMenuIndex = 0;
                this.transmitCall();
            }
        },
        transmitCall(){
            this.isTransmitting = true;
            if(this.isReceiveCallMenu){
                this.isReceiveCallMenu = false;
                this.isCallOverviewMenu = true;
            }
            const transmitTimeout = setTimeout(() => {
                this.isTransmitting = false;
                switch(this.currentMenu){
                    case 'Individual Call': {
                        this.isWaitingForAck = true;
                        break;
                    }
                    case 'Group Call': {
                        this.isGroupCallSent = true;
                        break;
                    }
                    case 'All Ships Call': {
                        this.isAllShipsCalling = true;
                        break;
                    }
                    case 'Test Call': {
                        this.isWaitingForAck = true;
                        break;
                    }
                    case 'Distress Alert': {
                        this.isWaitingForAck = true;
                    }
                }
                let receiverId = '';
                let callCategory = '';
                switch(this.currentMenu){
                    case 'Individual Call':{
                        receiverId = this.individualCallDetails.id;
                        callCategory = this.individualCallDetails.category;
                        this.$store.dispatch('m423/addTransmittedCallToLog', {
                            mode: 'other',
                            type: 'Individual Call',
                            from: this.mmsi,
                            category: callCategory,
                            channel: this.individualCallDetails.callChannel.number,
                            channelType: this.channels.find((el) => el.number == this.individualCallDetails.callChannel.number).description,
                        });
                        break;
                    }
                    case 'Group Call':{
                        receiverId = this.groupCallDetails.id;
                        callCategory = this.groupCallDetails.category;
                        this.lastChannelIndex = this.currentChannelIndex;
                        this.currentChannelIndex = this.currentCallChannelIndex;
                        this.$store.dispatch('m423/addTransmittedCallToLog', {
                            mode: 'other',
                            type: 'Group Call',
                            from: this.mmsi,
                            category: callCategory,
                            channel: this.groupCallDetails.callChannel.number,
                            channelType: this.channels.find((el) => el.number == this.groupCallDetails.callChannel.number).description,
                        });
                        break;
                    }
                    case 'All Ships Call':{
                        this.$store.dispatch('setTransmittedCall', {
                            mode: this.currentMenu,
                            isTransmitting: true,
                            senderMmsi: this.mmsi,
                            category: this.allShipsCallDetails.category,
                            channel: this.channels[this.currentCallChannelIndex].number,
                        });
                        this.$store.dispatch('m423/addTransmittedCallToLog', {
                            mode: 'other',
                            type: 'All Ships Call',
                            from: this.mmsi,
                            category: this.allShipsCallDetails.category,
                            channel: this.allShipsCallDetails.callChannel.number,
                            channelType: this.channels.find((el) => el.number == this.allShipsCallDetails.callChannel.number).description,
                        });
                        this.lastChannelIndex = this.currentChannelIndex;
                        this.currentChannelIndex = this.currentCallChannelIndex;
                        break;
                    }
                    case 'Test Call':{
                        receiverId = this.testCallDetails.id;
                        callCategory = this.testCallDetails.category;
                        this.$store.dispatch('m423/addTransmittedCallToLog', {
                            mode: 'other',
                            type: 'Test Call',
                            from: this.mmsi,
                            category: callCategory,
                        });
                        break;
                    }
                    case 'Distress Alert':{
                        this.$store.dispatch('setTransmittedCall', {
                            mode: this.currentMenu,
                            isTransmitting: true,
                            senderMmsi: this.mmsi,
                            category: this.distressCallDetails.category,
                            channel: '16',
                        });
                        this.$store.dispatch('m423/addTransmittedCallToLog', {
                            mode: 'distress',
                            type: 'Distress',
                            from: this.mmsi,
                            category: this.distressCallDetails.category,
                            channel: '16',
                            channelType: this.channels.find((el) => el.number == '16').description,
                        });
                        this.currentChannelIndex = 15;
                        break;
                    }
                    case 'Individual Ack': {
                        let newChannel = this.transmittedCall.channel;
                        this.$store.dispatch('setTransmittedCall', {
                            mode: this.currentMenu,
                            isTransmitting: true,
                            senderMmsi: this.mmsi,
                            receiverMmsi: this.transmittedCall.senderMmsi,
                            category: this.transmittedCall.category,
                            channel: this.transmittedCall.channel,
                        });
                        this.$store.dispatch('m423/addTransmittedCallToLog', {
                            mode: 'other',
                            type: 'Individual Ack',
                            from: this.mmsi,
                            category: this.individualAckDetails.category,
                            channel: this.individualAckDetails.callChannel.number,
                            channelType: this.channels.find((el) => el.number == this.individualAckDetails.callChannel.number).description,
                        });
                        if(this.individualAckDetails.isAbleToComply){
                            this.lastChannelIndex = this.currentChannelIndex;
                            this.currentChannelIndex = this.channels.findIndex((el) => el.number == newChannel);
                            this.isCallOverviewMenu = false;
                            this.isReceiveCallMenu = true;
                            this.receivedCallMode = 'IND CALL ACK SENT';
                            this.tempChannelString = this.transmittedCall.channel;
                        }
                        else{
                            this.goToHome();
                        }
                    }
                }
                if(this.currentMenu == 'Individual Call' || this.currentMenu == 'Group Call'){
                        this.$store.dispatch('setTransmittedCall', {
                        mode: this.currentMenu,
                        isTransmitting: true,
                        senderMmsi: this.mmsi,
                        receiverMmsi: receiverId,
                        category: callCategory,
                        channel: this.channels[this.currentCallChannelIndex].number,
                    });
                }
                
            },3000);
            console.log(transmitTimeout);
        },
        pressMenu(){
            if(this.powerIsOn){
                if(this.isHome){
                    this.isHome = false;
                    this.isMenu = true;
                    this.selectedMenuItems = this.menuItems;
                }
                else{
                    this.goToHome();
                }
                this.$refs.beepOk.play();
                this.dimm(this.backlightValue);
            }
        },
        InitCloseQuickMenuTimer(){
            clearTimeout(this.quickMenuTimer);
            this.quickMenuTimer = setTimeout(this.closeQuickMenu, 3000);
        },
        closeQuickMenu(){
            this.isQuickMenu = false;
            this.isInputSql = false;
            this.isInputVolume = false;
            this.isInputChannel = false;
            this.isInputBacklight = false;
        },
        dimm(index){
            if(this.powerIsOn){
                this.backlightColor = this.dimmSteps[index];
            }
        },
        setContrast(index){
            if(this.powerIsOn){
                this.contrastLevel = this.contrastSteps[index];
            }
        },
        async pressTurnButton(){
            if(this.powerIsOn){
                if(this.isHome && !this.isQuickMenu){
                    await this.openQuickMenu();
                    this.dimm(this.backlightValue);
                    this.InitCloseQuickMenuTimer();
                }
                else if(this.isInputVolume){
                    this.isInputVolume = false;
                    this.isInputSql = true;
                    this.InitCloseQuickMenuTimer();
                    this.$refs.beepOk.play();
                }
                else if(this.isInputSql){
                    this.isInputSql = false;
                    this.isInputChannel = true;
                    this.InitCloseQuickMenuTimer();
                    this.$refs.beepOk.play();
                }
                else if(this.isInputChannel){
                    this.isInputChannel = false;
                    this.isInputBacklight = true;
                    this.InitCloseQuickMenuTimer();
                    this.$refs.beepOk.play();
                }
                else{
                    this.closeQuickMenu();
                    this.$refs.beepOk.play();
                }
            }
        },
        async openQuickMenu(){
            this.isQuickMenu = true;
            this.isInputVolume = true;
        },
        pressNavigation(direction){
            if(this.powerIsOn){
                switch(direction){
                    case 'left': {
                        if(this.isInputBacklight){
                            if(this.currentMenu == 'Backlight'){
                                if(this.sliderValue > 0){
                                    this.sliderValue--;
                                    this.dimm(this.sliderValue);
                                    this.$refs.beepOk.play();
                                }
                            }
                            else{
                                if(this.backlightValue > 0){
                                    this.backlightValue--;
                                    this.dimm(this.backlightValue);
                                    this.$refs.beepOk.play();
                                }
                                this.InitCloseQuickMenuTimer();
                            }
                        }
                        else if(this.isInputVolume){
                            if(this.volumeValue > 0){
                                this.volumeValue--;
                                this.$refs.beepOk.play();
                            }
                            this.InitCloseQuickMenuTimer();
                        }
                        else if(this.isInputSql){
                            if(this.currentMenu == 'CH 70 SQL Level'){
                                if(this.sliderValue > 0){
                                    this.sliderValue--;
                                }
                                if(this.sliderValue <= 1){
                                    this.isBusy = true;
                                }
                                else{
                                    this.isBusy = false;
                                }
                            }
                            else {
                                if(this.sqlValue > 0){
                                    this.sqlValue--;
                                    this.$refs.beepOk.play();
                                }
                                if(this.sqlValue <= 1){
                                    this.isBusy = true;
                                }
                                else{
                                    this.isBusy = false;
                                }
                                this.InitCloseQuickMenuTimer();
                            }
                        }
                        else if(this.isHome){ 
                            if(this.currentCarouselMenuIndex == 9){
                                this.currentCarouselMenuIndex = 22;
                            }
                            else{
                                this.currentCarouselMenuIndex--;
                            }
                            this.$refs.beepOk.play();
                        } 
                        else if(this.currentMenu === 'UTC Offset'){
                            if(this.inputMenuProps.currentInputIndex == 4){
                                this.inputMenuProps.currentInputIndex -= 2;
                            }
                            else if(this.inputMenuProps.currentInputIndex > 0){
                                this.inputMenuProps.currentInputIndex--;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex > 0){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(1, 'left');
                            if(this.inputMenuProps.selectedDigitIndex <= 26 && nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else if(this.inputMenuProps.selectedDigitIndex > 26){
                                this.inputMenuProps.selectedDigitIndex--;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.currentMenu == 'CH 70 SQL Level'){
                            if(this.sliderValue > 0){
                                this.sliderValue--;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.currentMenu == 'Display Contrast'){
                            if(this.sliderValue > 0){
                                this.sliderValue--;
                                this.setContrast(this.sliderValue + 1);
                                this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 1, value: this.sliderValue});
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.currentMenu == 'Distress Alert' && this.isWaitingForAck){
                            if(this.currentCarouselMenuIndex == 0){
                                this.currentCarouselMenuIndex = this.distressCallCarouselItems.length - 2;
                            }
                            else{
                                this.currentCarouselMenuIndex--;
                            }
                        }
                        else{
                            this.$refs.beepFalsch.play();
                        }
                        break;
                    }
                    case 'right': {
                        if(this.isInputBacklight){
                            if(this.currentMenu == 'Backlight'){
                                if(this.sliderValue < 7){
                                    this.sliderValue++;
                                    this.dimm(this.sliderValue);
                                    this.$refs.beepOk.play();
                                }
                            }
                            else{
                                if(this.backlightValue < 7){
                                    this.backlightValue++;
                                    this.dimm(this.backlightValue);
                                    this.$refs.beepOk.play();
                                }
                                this.InitCloseQuickMenuTimer();
                            }
                        }
                        else if(this.isInputVolume){
                            if(this.volumeValue < 20){
                                this.volumeValue++;
                                this.$refs.beepOk.play();
                            }
                            this.InitCloseQuickMenuTimer();
                        }
                        else if(this.isInputSql){
                            if(this.currentMenu == 'CH 70 SQL Level'){
                                if(this.sliderValue < 10){
                                    this.sliderValue++;
                                }
                                if(this.sliderValue <= 1){
                                    this.isBusy = true;
                                }
                                else{
                                    this.isBusy = false;
                                }
                            }
                            else {
                                if(this.sqlValue < 10){
                                    this.sqlValue++;
                                    this.$refs.beepOk.play();
                                }
                                if(this.sqlValue <= 1){
                                    this.isBusy = true;
                                }
                                else{
                                    this.isBusy = false;
                                }
                                this.InitCloseQuickMenuTimer();
                            }
                        }
                        else if(this.isHome){
                            if(this.currentCarouselMenuIndex == 25){
                                this.currentCarouselMenuIndex = 13;
                            }
                            else{
                                this.currentCarouselMenuIndex++;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.currentMenu === 'UTC Offset'){
                            if(this.inputMenuProps.currentInputIndex == 2){
                                this.inputMenuProps.currentInputIndex += 2;
                                this.$refs.beepOk.play();
                            }
                            else if(this.inputMenuProps.currentInputIndex < 5){
                                this.inputMenuProps.currentInputIndex++;
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex + 1 < this.inputMenuProps.selectableDigits.length + 5){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(1, 'right');
                            if(nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else if(this.inputMenuProps.selectedDigitIndex < 26) {
                                this.inputMenuProps.selectedDigitIndex = 26;
                            }
                            else {
                                this.inputMenuProps.selectedDigitIndex++;
                            }
                            this.$refs.beepOk.play();
                        } 
                        else if(this.currentMenu == 'CH 70 SQL Level'){
                            if(this.sliderValue < 10){
                                this.sliderValue++;
                                this.$refs.beepOk.play();
                            }
                            else {
                                this.$refs.beepFalsch.play();
                            }
                        }
                        else if(this.currentMenu == 'Backlight'){
                            if(this.sliderValue < 7){
                                this.sliderValue++;
                                this.dimm(this.sliderValue);
                                this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 0, value: this.sliderValue});
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                        else if(this.currentMenu == 'Display Contrast'){
                            if(this.sliderValue < 8){
                                this.sliderValue++;
                                this.setContrast(this.sliderValue);
                                this.$store.dispatch('m423/setConfigurationMenuItemsValue', {index: 1, value: this.sliderValue});
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                        else if(this.currentMenu == 'Distress Alert' && this.isWaitingForAck){
                            if(this.currentCarouselMenuIndex == this.distressCallCarouselItems.length - 2){
                                this.currentCarouselMenuIndex = 0;
                            }
                            else{
                                this.currentCarouselMenuIndex++;
                            }
                        }
                        else{
                            this.$refs.beepFalsch.play();
                        }
                        break;
                    }
                    case 'up': {
                        if(this.isHome){
                            this.switchChannel('up', 'normal');
                            this.$refs.beepOk.play();
                        }
                        else if (this.receivedCallMode == 'IND CALL ACK') {
                            if(this.menuSelectionIndex > 0){
                                this.menuSelectionIndex--;
                            }
                        }
                        else if (this.receivedCallMode == 'IND CALL ACK DETAILS') {
                            if(this.menuSelectionIndex > 0){
                                this.menuSelectionIndex = 0;
                            }
                        }
                        else if(this.currentMenu == 'RCVD Call Log' || this.currentMenu == 'TX Call Log' && this.menuStep == 3){
                            this.menuSelectionIndex = 0;
                        }
                        else if(this.currentMenu === 'UTC Offset'){
                            let utcValue = null;
                            switch(this.inputMenuProps.currentInputIndex){
                                case 0: {
                                    if(this.$store.getters['m423/utcOffset'][0] != '+' && this.$store.getters['m423/utcOffset'][0] != '-'){
                                        utcValue = '+';
                                    }
                                    else if(this.$store.getters['m423/utcOffset'][0] == '+'){
                                        utcValue = '-';
                                    }
                                    else if(this.$store.getters['m423/utcOffset'][0] == '-'){
                                        utcValue = '+';
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 1: {
                                    if(this.$store.getters['m423/utcOffset'][1] < '1'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][1]) + 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 2: {
                                    if(this.$store.getters['m423/utcOffset'][2] < '9'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][2]) + 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 4: {
                                    if(this.$store.getters['m423/utcOffset'][4] < '5'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][4]) + 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 5: {
                                    if(this.$store.getters['m423/utcOffset'][5] < '9'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][5]) + 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                            }
                            if(utcValue != null){
                                this.$store.dispatch('m423/setUtcOffset', {index: this.inputMenuProps.currentInputIndex, value: utcValue});
                            }
                        }
                        else if(this.currentMenu == 'Remote ID'){
                            this.$store.dispatch('m423/incrementRemoteId', {mode: 'add'});
                            this.$refs.beepOk.play();
                        }
                        else if(this.isMenu || this.isDistressCallOverviewMenu){
                            if(this.menuSelectionIndex > 0){
                                this.menuSelectionIndex--;
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                        if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex >= 13 && this.inputMenuProps.selectedDigitIndex <= 25){
                            if(this.inputMenuProps.selectedDigitIndex - 13 > 0 && this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex - 13] != '')
                            {
                                this.inputMenuProps.selectedDigitIndex -= 13;
                            }
                            else{
                                let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'up');
                                if(nearestIndexWithValue != -1){
                                    this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                                }
                                else {
                                    this.inputMenuProps.selectedDigitIndex = 0;
                                }
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex == 26){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'up');
                            if(nearestIndexWithValue != 13 && nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else{
                                this.inputMenuProps.selectedDigitIndex = 13;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex == 27){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'up');
                            if(nearestIndexWithValue != 15 && nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else{
                                this.inputMenuProps.selectedDigitIndex = 15;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex == 28){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'up');
                            if(nearestIndexWithValue != 17 && nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else{
                                this.inputMenuProps.selectedDigitIndex = 17;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex == 29){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'up');
                            if(nearestIndexWithValue != 20 && nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else{
                                this.inputMenuProps.selectedDigitIndex = 20;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex == 30){
                            let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'up');
                            if(nearestIndexWithValue != 23 && nearestIndexWithValue != -1){
                                this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                            }
                            else{
                                this.inputMenuProps.selectedDigitIndex = 23;
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isSelectingCallChannel){
                            this.switchChannel('up', 'call');
                            this.$refs.beepOk.play();
                        }
                        else{
                            this.$refs.beepFalsch.play();
                        }
                        break;
                    }
                    case 'down': {
                        if(this.isHome){
                            this.switchChannel('down', 'normal');
                            this.$refs.beepOk.play();
                        }
                        else if (this.receivedCallMode == 'IND CALL ACK') {
                            if(this.menuSelectionIndex < 3){
                                this.menuSelectionIndex++;
                            }
                        }
                        else if (this.receivedCallMode == 'IND CALL ACK DETAILS'){
                            if(this.menuSelectionIndex < 4){
                                this.menuSelectionIndex = 4;
                            }
                        }
                        else if(this.currentMenu == 'RCVD Call Log' || this.currentMenu == 'TX Call Log' && this.menuStep == 3){
                            this.menuSelectionIndex = 1;
                        }
                        else if(this.currentMenu === 'UTC Offset'){
                            let utcValue = null;
                            switch(this.inputMenuProps.currentInputIndex){
                                case 0: {
                                    if(this.$store.getters['m423/utcOffset'][0] != '+' && this.$store.getters['m423/utcOffset'][0] != '-'){
                                        utcValue = '+';
                                    }
                                    else if(this.$store.getters['m423/utcOffset'][0] == '+'){
                                        utcValue = '-';
                                    }
                                    else if(this.$store.getters['m423/utcOffset'][0] == '-'){
                                        utcValue = '+';
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 1: {
                                    if(this.$store.getters['m423/utcOffset'][1] > '0'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][1]) - 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 2: {
                                    if(this.$store.getters['m423/utcOffset'][2] > '0'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][2]) - 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 4: {
                                    if(this.$store.getters['m423/utcOffset'][4] > '0'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][4]) - 1;
                                    }
                                    this.$refs.beepOk.play();
                                    break;
                                }
                                case 5: {
                                    if(this.$store.getters['m423/utcOffset'][5] > '0'){
                                        utcValue = parseInt(this.$store.getters['m423/utcOffset'][5]) - 1;
                                    }
                                    this.$refs.beepOk.play();
                                }
                            }

                            if(utcValue != null){
                                this.$store.dispatch('m423/setUtcOffset', {index: this.inputMenuProps.currentInputIndex, value: utcValue});
                            }
                        }
                        else if(this.currentMenu == 'Remote ID'){
                            this.$store.dispatch('m423/incrementRemoteId', {mode: 'remove'});
                            this.$refs.beepOk.play();
                        }
                        else if(this.isMenu){
                            if(this.menuSelectionIndex < this.selectedMenuItems.length - 1){
                                this.menuSelectionIndex++;
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                        else if(this.isDistressCallOverviewMenu){
                            if(this.menuSelectionIndex < 2){
                                this.menuSelectionIndex++;
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.$refs.beepFalsch.play();
                            }
                        }
                        if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex <= 12){
                            if(this.inputMenuProps.selectedDigitIndex + 13 <= this.inputMenuProps.selectableDigits.length && this.inputMenuProps.selectableDigits[this.inputMenuProps.selectedDigitIndex + 13] != '')
                            {
                                this.inputMenuProps.selectedDigitIndex += 13;
                            }
                            else{
                                let nearestIndexWithValue = this.findNearestElementWithValueFromSelectableDigits(13, 'down');
                                if(nearestIndexWithValue != -1){
                                    this.inputMenuProps.selectedDigitIndex = nearestIndexWithValue;
                                }
                                else {
                                    this.inputMenuProps.selectedDigitIndex = 26;
                                }
                            }
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex >= 13 && this.inputMenuProps.selectedDigitIndex <= 14){
                            this.inputMenuProps.selectedDigitIndex = 26;
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex >= 15 && this.inputMenuProps.selectedDigitIndex <= 16){
                            this.inputMenuProps.selectedDigitIndex = 27;
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex >= 17 && this.inputMenuProps.selectedDigitIndex <= 19){
                            this.inputMenuProps.selectedDigitIndex = 28;
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex >= 20 && this.inputMenuProps.selectedDigitIndex <= 22){
                            this.inputMenuProps.selectedDigitIndex = 29;
                            this.$refs.beepOk.play();
                        }
                        else if(this.isInputMenu && this.inputMenuProps.selectedDigitIndex >= 23 && this.inputMenuProps.selectedDigitIndex <= 25){
                            this.inputMenuProps.selectedDigitIndex = 30;
                            this.$refs.beepOk.play();
                        }
                        else if(this.isSelectingCallChannel){
                            this.switchChannel('down', 'call');
                            this.$refs.beepOk.play();
                        }
                        else{
                            this.$refs.beepFalsch.play();
                        }
                        break;
                    }
                }
            }
        },
        switchChannel(direction, mode){
            if(mode === 'normal'){
                switch(direction){
                    case 'up': {
                        if(this.currentChannelIndex == (this.channels.length - 1)){
                            this.currentChannelIndex = 0;
                        }
                        else{
                            this.currentChannelIndex += 1;
                        }
                        break;
                    }
                    case 'down': {
                        if(this.currentChannelIndex == 0){
                            this.currentChannelIndex = this.channels.length - 1;
                        }
                        else{
                            this.currentChannelIndex -= 1;
                        }
                    }
                }
            }
            else if(mode === 'call'){
                switch(direction){
                    case 'up': {
                        if(this.currentCallChannelIndex == (this.channels.length - 1)){
                            this.currentCallChannelIndex = 0;
                        }
                        else{
                            this.currentCallChannelIndex += 1;
                        }
                        break;
                    }
                    case 'down': {
                        if(this.currentCallChannelIndex == 0){
                            this.currentCallChannelIndex = this.channels.length - 1;
                        }
                        else{
                            this.currentCallChannelIndex -= 1;
                        }
                    }
                }
            }
            else if(mode === 'scan'){
                switch(direction){
                    case 'up': {
                        if(this.currentChannelIndex == (this.channels.length - 1)){
                            this.currentChannelIndex = 0;
                        }
                        else{
                            this.currentChannelIndex += 1;
                        }
                        break;
                    }
                    case 'down': {
                        if(this.currentChannelIndex == 0){
                            this.currentChannelIndex = this.channels.length - 1;
                        }
                        else{
                            this.currentChannelIndex -= 1;
                        }
                    }
                }
            }
        },
        findNearestElementWithValueFromSelectableDigits(steps, direction){
            var nearestIndexWithValue;
            switch(direction){
                case 'down': {
                    nearestIndexWithValue = this.inputMenuProps.selectableDigits.findIndex(
                        (el, index) => (index > this.inputMenuProps.selectedDigitIndex + steps) && el != ''
                    );
                    break;
                }
                case 'up': {
                    nearestIndexWithValue = this.inputMenuProps.selectableDigits.findLastIndex(
                        (el, index) => (index < this.inputMenuProps.selectedDigitIndex - steps) && el != ''
                    );
                    break;
                }
                case 'right': {
                    nearestIndexWithValue = this.inputMenuProps.selectableDigits.findIndex(
                        (el, index) => (index > this.inputMenuProps.selectedDigitIndex) && el != ''
                    );
                    break;
                }
                case 'left': {
                    nearestIndexWithValue = this.inputMenuProps.selectableDigits.findLastIndex(
                        (el, index) => (index < this.inputMenuProps.selectedDigitIndex) && el != ''
                    );
                    break;
                }
            }
            return nearestIndexWithValue;
        },
        async initSplashScreen(){
            if(this.powerIsOn){
                this.$refs.startRauschen.play();
                await this.wait(500);
                this.isSplashScreen = true;
                await this.goToSplashScreenStep(1);
                await this.goToSplashScreenStep(2);
                this.isSplashScreen = false;
                this.isHome = true;

            }
            else{
                this.isSplashScreen = false;
                this.isHome = false;
            }
        },
        async togglePowerOff(){
            this.$store.dispatch('m423/togglePower', false);
            this.dimm(0);
        },
        async togglePower(){
            if(!this.powerIsOn){
                this.$store.dispatch('m423/togglePower', true);
                await this.initSplashScreen();
            }
            else{
                this.$store.dispatch('m423/togglePower', false);
                this.dimm(0);
            }
        },
        async goToSplashScreenStep(step){
            this.splashScreenStep = step;
            await this.wait(1000);

        },
        async wait(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
    },
})
</script>

<style scoped>
    .speaker-hilo{
        position: absolute;
        left: 35px;
        bottom: 70px;
        height: 18px;
        border-radius: 25%;
        width: 24px;
        z-index: 15;
    }
    .speaker-down{
        position: absolute;
        left: -4px;
        bottom: 62px;
        height: 20px;
        border-radius: 25%;
        width: 20px;
        z-index: 15;
    }
    .speaker-up{
        position: absolute;
        left: -12px;
        bottom: 84px;
        height: 22px;
        border-radius: 25%;
        width: 22px;
        z-index: 15;
    }
    .ch-16-btn{
        position: absolute;
        right: 108px;
        bottom: 39px;
        height: 30px;
        border-radius: 100%;
        width: 30px;
        z-index: 15;
    }
    .send-pressed{
        z-index: 12 !important;
    }
    .send-btn{
        position: absolute;
        left: -58px;
        top: 44px;
        z-index: 12;
        height: 55px;
        width: 55px;
    }
    .quick-1-btn{
        position: absolute;
        height: 33px;
        width: 48px;
        top: 212px;
        left: 202px;
        z-index: 10;
        border-radius: 30%;
    }
    .quick-2-btn{
        position: absolute;
        height: 36px;
        width: 49px;
        top: 211px;
        left: 260px;
        z-index: 10;
        border-radius: 30%;
    }
    .quick-3-btn{
        position: absolute;
        height: 36px;
        width: 49px;
        top: 211px;
        left: 318px;
        z-index: 10;
        border-radius: 30%;
    }
    .quick-4-btn{
        position: absolute;
        height: 34px;
        width: 49px;
        top: 211px;
        left: 376px;
        z-index: 10;
        border-radius: 30%;
    }
    .distress-btn{
        position: absolute;
        content: url('../../assets/M423/bDistress.png');
        top: 125px;
        left: 111.5px;
        width: 50px;
        height: 135px;
        z-index: 20;
        cursor: pointer;
    }
    .distress-btn-pressed{
        position: absolute;
        content: url('../../assets/M423/bDistressDown.png') !important;
        top: 125px;
        left: 111.5px;
        width: 50px;
        height: 135px;
        z-index: 20;
    }
    .distress-btn:hover{
        animation-name: distress;
        animation-duration: 0.3s;
        animation-iteration-count: initial;         
        animation-fill-mode: forwards;   
        z-index: 20;
    }
    @keyframes distress{
        0% {
            content: url('../../assets/M423/bDistress.png')
        }
        33% {
            content: url('../../assets/M423/bDistress1.png')
        }
        66% {
            content: url('../../assets/M423/bDistress2.png')
        }
        100% {
            content: url('../../assets/M423/bDistress3.png')
        }
    }
    .enter-btn{
        position: absolute;
        height: 37px;
        width: 37px;
        z-index: 5;
        top: 61px;
        right: 58px;
        cursor: pointer;
        border-radius: 30%;
    }
    .clear-btn{
        position: absolute;
        height: 27px;
        width: 47px;
        z-index: 5;
        top: 139px;
        right: 25px;
        cursor: pointer;
        border-radius: 30%;
    }
    .menu-btn{
        position: absolute;
        height: 27px;
        width: 47px;
        z-index: 5;
        top: 139px;
        right: 80px;
        cursor: pointer;
        border-radius: 30%;
    }
    .nav-down{
        position: absolute;
        height: 27px;
        width: 55px;
        z-index: 5;
        top: 103px;
        right: 49px;
        cursor: pointer;
        border-radius: 30%;
    }
    .nav-up{
        position: absolute;
        height: 27px;
        width: 55px;
        z-index: 5;
        top: 29px;
        right: 50px;
        cursor: pointer;
        border-radius: 30%;
    }
    .nav-right{
        position: absolute;
        height: 43px;
        width: 26px;
        z-index: 5;
        top: 58px;
        right: 26px;
        cursor: pointer;
        border-radius: 30%;
    }
    .pressed{
        box-shadow: inset 0 0 5px black;
    }
    .nav-left{
        position: absolute;
        height: 43px;
        width: 26px;
        z-index: 5;
        top: 58px;
        right: 102px;
        cursor: pointer;
        border-radius: 30%;
        
    }
    .home-screen{
        height: 100%;
        width: 100%;
    }
    .splash-screen{
        position: absolute;
        top: 50px;
        left: 50px;
    }
    .turn-btn{
        position: absolute;
        z-index: 5;
        height: 80px;
        width: 80px;
        top:177px;
        left: 495px;
        cursor: pointer;
    }
    .speaker{
        z-index: 5;
        position: absolute;
        height: 400px;
        width:400px;
        left: -180px;
    }
    .device-holder{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        max-width: 593px;
    }
    .m423-container{
        display: flex;
    }
    .m423{
        z-index: 2;
        width: 100%;
        right: 0;
    }
    .display-contrast-container-m423{
        z-index: 5;
        position:absolute;
        width: 280px;
        height: 90px;
        top: 62px;
        left: 192px;
        background-color: white;
        max-width: 237px;
        min-height: 119px;
        border-radius: 3%;
        opacity: v-bind('contrastLevel');;
    }
    .display-container-m423{
        z-index: 1;
        position:absolute;
        width: 280px;
        height: 90px;
        top: 62px;
        left: 192px;
        background-color: v-bind('dimmSteps[3]');
        max-width: 238px;
        min-height: 120px;
    }
    .display-container-off-m423{
        z-index: 1;
        position:absolute;
        width: 280px;
        height: 90px;
        top: 62px;
        left: 192px;
        background-color: v-bind('dimmSteps[0]') !important;
        max-width: 238px;
        min-height: 120px;
    }
</style>