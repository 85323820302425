import { createStore } from 'vuex'
import m503Module from './m503.js'
import ds100Module from './ds100.js'
import m423Module from './m423.js'

export default createStore({
  state: {
    selectedDevices: [],
    submitted: false,
    transmittedCall: {
      mode: '',
      isTransmitting: false,
      senderMmsi: '',
      receiverMmsi: '',
      category: '',
      channel: '',
      lat: '',
      lon: '',
      utc: '',
    }
  },
  getters: {
    selectedDevices(state){
      return state.selectedDevices;
    },
    submitted(state){
      return state.submitted;
    },
    transmittedCall(state){
      return state.transmittedCall;
    }
  },
  mutations: {
    SET_SELECTED_DEVICES(state, selectedDevices){
      state.selectedDevices = selectedDevices;
    },
    TOGGLE_SUBMIT(state){
      state.submitted = !state.submitted;
    },
    SET_TRANSMITTED_CALL(state, payload){
      state.transmittedCall = payload;
    }
  },
  actions: {
    setSelectedDevices({commit}, selectedDevices){
      commit('SET_SELECTED_DEVICES', selectedDevices);
    },
    toggleSubmit({commit}){
      commit('TOGGLE_SUBMIT');
    },
    setTransmittedCall({commit}, payload){
      commit('SET_TRANSMITTED_CALL', payload);
    }
  },
  modules: {
    m503: m503Module,
    ds100: ds100Module,
    m423: m423Module,
  }
})
