const state = {
    powerIsOn: false,
    mmsi: '211001780',
    atis: '9211001780',
    isReceivingSignal: false,
    receivedSignalSenderDetails: {
        mmsi: '',
        channelNumber: '',
    },
    sog: '',
    cog: '',
    swver: '',
    utcOffset: ['_','0','0',':','0','0'],
    remoteId: 15,
    currentCarouselMenuItems: [],
    channels: [
        { number: '01', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '02', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '03', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '04', description: 'INTL', isTag: true, isDUP: true },
        { number: '05', description: 'INTL', isTag: true, isDUP: true },
        { number: '06', description: 'SAFETY', isTag: true, isDUP: false },
        { number: '07', description: 'INTL', isTag: true, isDUP: true },
        { number: '08', description: 'COMMERCIAL', isTag: true, isDUP: false },
        { number: '09', description: 'CALLING', isTag: true, isDUP: false },
        { number: '10', description: 'COMMERCIAL', isTag: true, isDUP: false },
        { number: '11', description: 'VTS', isTag: true, isDUP: false },
        { number: '12', description: 'VTS', isTag: true, isDUP: false },
        { number: '13', description: 'BRG/BRG', isTag: true, isDUP: false },
        { number: '14', description: 'VTS', isTag: true, isDUP: false },
        { number: '15', description: 'COMMERCIAL', isTag: true, isDUP: false },
        { number: '16', description: 'CALLING', isTag: true, isDUP: false },
        { number: '17', description: 'SAR', isTag: true, isDUP: false },
        { number: '18', description: 'INTL', isTag: true, isDUP: true },
        { number: '19', description: 'INTL', isTag: true, isDUP: true },
        { number: '20', description: 'PORT OPR', isTag: true, isDUP: true },
        { number: '21', description: 'INTL', isTag: true, isDUP: true },
        { number: '22', description: 'INTL', isTag: true, isDUP: true },
        { number: '23', description: 'INTL', isTag: true, isDUP: true },
        { number: '24', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '25', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '26', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '27', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '28', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '60', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '61', description: 'INTL', isTag: true, isDUP: true },
        { number: '62', description: 'INTL', isTag: true, isDUP: true },
        { number: '63', description: 'INTL', isTag: true, isDUP: true },
        { number: '64', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '65', description: 'INTL', isTag: true, isDUP: true },
        { number: '66', description: 'INTL', isTag: true, isDUP: true },
        { number: '67', description: 'BRG/BRG', isTag: true, isDUP: false },
        { number: '68', description: 'SHIP-SHIP', isTag: true, isDUP: false },
        { number: '69', description: 'PLEASURE', isTag: true, isDUP: false },
        { number: '70', description: 'DSC', isTag: true, isDUP: false },
        { number: '71', description: 'PLEASURE', isTag: true, isDUP: false },
        { number: '72', description: 'SHIP-SHIP', isTag: true, isDUP: false },
        { number: '73', description: 'PORT-OPR', isTag: true, isDUP: false },
        { number: '74', description: 'PORT-OPR', isTag: true, isDUP: false },
        { number: '75', description: '', isTag: true, isDUP: false },
        { number: '76', description: '', isTag: true, isDUP: false },
        { number: '77', description: 'PORT-OPR', isTag: true, isDUP: false },
        { number: '78', description: 'INTL', isTag: true, isDUP: true },
        { number: '79', description: 'INTL', isTag: true, isDUP: true },
        { number: '80', description: 'INTL', isTag: true, isDUP: true },
        { number: '81', description: 'INTL', isTag: true, isDUP: true },
        { number: '82', description: 'INTL', isTag: true, isDUP: true },
        { number: '83', description: 'CCG', isTag: true, isDUP: true },
        { number: '84', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '85', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '86', description: 'TELEPHONE', isTag: true, isDUP: true },
        { number: '87', description: '', isTag: true, isDUP: false },
        { number: '88', description: '', isTag: true, isDUP: false },
    ],
    menuItems: [
        {title: 'DSC Calls', icon: 'mdi-ferry', subItems: []},
        {title: 'DSC Settings', icon: 'mdi-wrench', subItems: []},
        {title: 'Radio Settings', icon: 'mdi-radio-handheld', subItems: []},
        {title: 'Configuration', icon: 'mdi-cog', subItems: []},
        {title: 'MMSI/GPS Info', icon: 'mdi-information', subItems: []},
    ],
    dscCallsMenuItems: [
        {title: 'Individual Call', subItems: []},
        {title: 'Group Call', subItems: []},
        {title: 'All Ships Call'},
        {title: 'Distress Call', subItems: []},
        {title: 'Received Call Log', subItems: []},
        {title: 'Transmitted Call Log', subItems: []},
        {title: 'Test Call', subItems: []},
    ],
    allShipsCallMenuItem: [
        {title: 'Safety'},
        {title: 'Urgency'},
    ],
    distressCallMenuItems: [
        {title: 'Undesignated', subItems: []},
        {title: 'Fire,Explosion', subItems: []},
        {title: 'Flooding', subItems: []},
        {title: 'Collision', subItems: []},
        {title: 'Grounding', subItems: []},
        {title: 'Capsizing', subItems: []},
        {title: 'Sinking', subItems: []},
        {title: 'Adrift', subItems: []},
        {title: 'Abandoning Ship', subItems: []},
        {title: 'Piracy', subItems: []},
        {title: 'Man Overboard', subItems: []},
    ],
    receivedCallLogMenuItems: [
        {title: 'Distress', icon: 'mdi-alert-outline', subItems: []},
        {title: 'Others', icon: 'mdi-email-outline', subItems: []},
    ],
    transmittedCallLogMenuItems: [
        {title: 'Distress', icon: 'mdi-alert-outline', subItems: []},
        {title: 'Others', icon: 'mdi-email-outline', subItems: []},
    ],
    dscSettingsMenuItems: [
        {title: 'Position Input', subItems: []},
        {title: 'Individual ID', subItems: []},
        {title: 'Group ID', subItems: []},
        {title: 'Individual ACK:', value: 'Auto', subItems: []},
        {title: 'Position ACK:', value: 'Auto', subItems: []},
        {title: 'Test ACK:', value: 'Auto', subItems: []},
        {title: 'CH 16 Switch:', value: '10 sec', subItems: []},
        {title: 'DSC Data Output:', value: 'All', subItems: []},
        {title: 'Alarm', subItems: []},
        {title: 'CH 70 SQL Level', value: '3', subItems: []},
        {title: 'DSC Loop Test', subItems: []},
    ],
    radioSettingsMenuItems: [
        {title: 'Scan Type', value: 'Normal', subItems: []},
        {title: 'Scan Timer', value: 'OFF', subItems: []},
        {title: 'Dual/Tri-Watch', value: 'Dual', subItems: []},
        {title: 'CHAN Group', value: 'DSC', subItems: []},
    ],
    alarmMenuItems: [
        {title: 'Safety:', value: 'ON', subItems: []},
        {title: 'Routine:', value: 'ON', subItems: []},
        {title: 'Warning:', value: 'ON', subItems: []},
        {title: 'Self-Terminate:', value: 'ON', subItems: []},
        {title: 'Discrete:', value: 'ON', subItems: []},
    ],
    configurationMenuItems: [
        {title: 'Backlight', value: '3', subItems: []},
        {title: 'Display Contrast', value: '5', subItems: []},
        {title: 'Key Beep', value: 'ON', subItems: []},
        {title: 'Key Assignment', value: '', subItems: []},
        {title: 'UTC Offset', value: '00:00', subItems: []},
        {title: 'Noise Cancel', value: '', subItems: []},
        {title: 'Inactivity Timer', value: '', subItems: []},
        {title: 'Remote ID', value: '15', subItems: []},
    ],
    noiseCancelMenuItems: [
        {title: 'RX:', value: 'OFF', subItems: []},
        {title: 'TX:', value: 'OFF', subItems: []},
    ],
    inactivityTimerMenuItems: [
        {title: 'Not DSC Related', value: '10 min', subItems: []},
        {title: 'DSC Related', value: '15 min', subItems: []},
    ],
    individualCallDetails: {
        id: '',
        category: '',
        callChannel: {},
    },
    individualAckDetails: {
        receiverId: '',
        category: '',
        callChannel: {},
        isAbleToComply: false,
        reason: '',
    },
    groupCallDetails: {
        id: '',
        category: '',
        callChannel: {},
    },
    individualCallCategorys: [
        {title: 'Routine', icon: ''},
        {title: 'Safety', icon: ''},
        {title: 'Urgency', icon: ''},
    ],
    groupCallCategorys: [
        {title: 'Routine', icon: ''},
        {title: 'Safety', icon: ''},
        {title: 'Urgency', icon: ''},
    ],
    allShipsCallCategorys: [
        {title: 'Routine', icon: ''},
        {title: 'Safety', icon: ''},
        {title: 'Urgency', icon: ''},
    ],
    allShipsCallDetails: {
        id: 'All Ships',
        category: '',
        callChannel: {},
    },
    distressCallDetails: {
        id: '211890800',
        category: '',
        lat: '',
        lon: '',
        time: '',
    },
    receivedCalls: {
        distress: [
            {icon: 'mdi-email-outline', type: 'Distress Cancel', from: '211001893', category: 'Collision', channel: '16', channelType: 'CALLING'},
            {icon: 'mdi-email-outline', type: 'Distress', from: '211001893', category: 'Collision', channel: '16', channelType: 'CALLING', lat: '23°43.1264N', lon: '269°30.2384W', utc: '12:35'},
        ],
        other: [
            {icon: 'mdi-email-outline', type: 'Individual Call', from: '211001893', category: 'Routine', channel: '15', channelType: 'COMMERCIAL'},
            {icon: 'mdi-email-outline', type: 'Group Call', from: '211001312', category: 'Safety', channel: '05', channelType: 'INTL'},
        ],
    },
    transmittedCalls: {
        distress: [
            {icon: 'mdi-email-outline', type: 'Distress Cancel', from: '211001780', category: 'Collision', channel: '16', channelType: 'CALLING'},
            {icon: 'mdi-email-outline', type: 'Distress', from: '211001780', category: 'Collision', channel: '16', channelType: 'CALLING', lat: '23°43.1264N', lon: '269°30.2384W', utc: '12:35'},
        ],
        other: [
            {icon: 'mdi-email-outline', type: 'Individual Call', from: '211001780', category: 'Routine', channel: '15', channelType: 'COMMERCIAL'},
            {icon: 'mdi-email-outline', type: 'Group Call', from: '211001780', category: 'Safety', channel: '05', channelType: 'INTL'},
        ],
    },
    testCallDetails: {
        id: '',
        category: '',
    },
    positionInputDetails: {
        position: {
            lat: '',
            long: '',
        },
        utc: '',
    },
    distressPositionInputDetails: {
        position: {
            lat: '',
            long: '',
        },
        utc: '',
    },
    individualIds: [
        { id: '879866987', title: 'Tim'}
    ],
    groupIds: [
        { id: '079866987', title: 'Tims Gruppe'}
    ],
    individualAckItems: [
        { title: 'Auto TX (Unable)', value: 'Auto', selected: true},
        { title: 'Manual TX', value: 'Manual', selected: false},
    ],
    positionAckItems: [
        { title: 'Auto TX', value: 'Auto', selected: true},
        { title: 'Manual TX', value: 'Manual', selected: false},
    ],
    testAckItems: [
        { title: 'Auto TX', value: 'Auto', selected: true},
        { title: 'Manual TX', value: 'Manual', selected: false},
    ],
    ch16SwitchItems: [
        { title: 'Auto (No Delay)', value: 'Auto', selected: false},
        { title: '10 Seconds Delay', value: '10 sec', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    dscDataOutputItems: [
        { title: 'All Station', value: 'All', selected: false},
        { title: 'List Station', value: 'List', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    alarmSafetyItems: [
        { title: 'ON', value: 'ON', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    alarmRoutineItems: [
        { title: 'ON', value: 'ON', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    alarmWarningItems: [
        { title: 'ON', value: 'ON', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    alarmSelfTerminateItems: [
        { title: 'ON', value: 'ON', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    alarmDiscreteItems: [
        { title: 'ON', value: 'ON', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    scanTypeItems: [
        { title: 'Normal Scan', value: 'Normal', selected: true},
        { title: 'Priority Scan', value: 'Priority', selected: false},
    ],
    scanTimerItems: [
        { title: 'ON', value: 'ON', selected: false},
        { title: 'OFF', value: 'OFF', selected: true},
    ],
    dualTriWatchItems: [
        { title: 'Dualwatch', value: 'Dual', selected: true},
        { title: 'Tri-watch', value: 'Tri', selected: false},
    ],
    chanGroupItems: [
        { title: 'ATIS', value: 'ATIS', selected: false},
        { title: 'DSC', value: 'DSC', selected: true},
        { title: 'INT', value: 'INT', selected: false},
    ],
    keyBeepItems: [
        { title: 'ON', value: 'ON', selected: true},
        { title: 'OFF', value: 'OFF', selected: false},
    ],
    rxItems: [
        { title: 'OFF', value: 'OFF', selected: true},
        { title: '1', value: '1', selected: false},
        { title: '2', value: '2', selected: false},
        { title: '3', value: '3', selected: false},
    ],
    txItems: [
        { title: 'OFF', value: 'OFF', selected: true},
        { title: 'ON', value: 'ON', selected: false},
    ],
    notDscRelatedItems: [
        { title: 'OFF', value: 'OFF', selected: false},
        { title: '1 min', value: '1 min', selected: false},
        { title: '2 min', value: '2 min', selected: false},
        { title: '3 min', value: '3 min', selected: false},
        { title: '4 min', value: '4 min', selected: false},
        { title: '5 min', value: '5 min', selected: false},
        { title: '6 min', value: '6 min', selected: false},
        { title: '7 min', value: '7 min', selected: false},
        { title: '8 min', value: '8 min', selected: false},
        { title: '9 min', value: '9 min', selected: false},
        { title: '10 min', value: '10 min', selected: true},
    ],
    dscRelatedItems: [
        { title: 'OFF', value: 'OFF', selected: false},
        { title: '1 min', value: '1 min', selected: false},
        { title: '2 min', value: '2 min', selected: false},
        { title: '3 min', value: '3 min', selected: false},
        { title: '4 min', value: '4 min', selected: false},
        { title: '5 min', value: '5 min', selected: false},
        { title: '6 min', value: '6 min', selected: false},
        { title: '7 min', value: '7 min', selected: false},
        { title: '8 min', value: '8 min', selected: false},
        { title: '9 min', value: '9 min', selected: false},
        { title: '10 min', value: '10 min', selected: false},
        { title: '11 min', value: '11 min', selected: false},
        { title: '12 min', value: '12 min', selected: false},
        { title: '13 min', value: '13 min', selected: false},
        { title: '14 min', value: '14 min', selected: false},
        { title: '15 min', value: '15 min', selected: true},
    ]
};
const getters = {
    atis(state){
        return state.atis;
    },
    sog(state){
        return state.sog;
    },
    cog(state){
        return state.cog;
    },
    swver(state){
        return state.swver;
    },
    mmsi(state){
        return state.mmsi;
    },
    powerIsOn(state){
        return state.powerIsOn;
    },
    channels(state){
        return state.channels;
    },
    menuItems(state){
        return state.menuItems;
    },
    dscCallsMenuItems(state){
        return state.dscCallsMenuItems;
    },
    dscSettingsMenuItems(state){
        return state.dscSettingsMenuItems;
    },
    individualCallDetails(state){
        return state.individualCallDetails;
    },
    individualCallCategorys(state){
        return state.individualCallCategorys;
    },
    groupCallDetails(state){
        return state.groupCallDetails;
    },
    allShipsCallCategorys(state){
        return state.allShipsCallCategorys;
    },
    allShipsCallDetails(state){
        return state.allShipsCallDetails;
    },
    distressCallMenuItems(state){
        return state.distressCallMenuItems;
    },
    distressCallDetails(state){
        return state.distressCallDetails;
    },
    receivedCallLogMenuItems(state){
        return state.receivedCallLogMenuItems;
    },
    receivedCalls(state){
        return state.receivedCalls;
    },
    transmittedCalls(state){
        return state.transmittedCalls;
    },
    testCallDetails(state){
        return state.testCallDetails;
    },
    positionInputDetails(state){
        return state.positionInputDetails;
    },
    distressPositionInputDetails(state){
        return state.positionInputDetails;
    },
    individualIds(state){
        return state.individualIds;
    },
    groupIds(state){
        return state.groupIds;
    },
    individualAckItems(state){
        return state.individualAckItems;
    },
    positionAckItems(state){
        return state.positionAckItems;
    },
    testAckItems(state){
        return state.testAckItems;
    },
    ch16SwitchItems(state){
        return state.ch16SwitchItems;
    },
    dscDataOutputItems(state){
        return state.dscDataOutputItems;
    },
    alarmMenuItems(state){
        return state.alarmMenuItems;
    },
    alarmSafetyItems(state){
        return state.alarmSafetyItems;
    },
    alarmRoutineItems(state){
        return state.alarmRoutineItems;
    },
    alarmWarningItems(state){
        return state.alarmWarningItems;
    },
    alarmSelfTerminateItems(state){
        return state.alarmSelfTerminateItems;
    },
    alarmDiscreteItems(state){
        return state.alarmDiscreteItems;
    },
    radioSettingsMenuItems(state){
        return state.radioSettingsMenuItems;
    },
    scanTypeItems(state){
        return state.scanTypeItems;
    },
    scanTimerItems(state){
        return state.scanTimerItems;
    },
    dualTriWatchItems(state){
        return state.dualTriWatchItems;
    },
    chanGroupItems(state){
        return state.chanGroupItems;
    },
    configurationMenuItems(state){
        return state.configurationMenuItems;
    },
    keyBeepItems(state){
        return state.keyBeepItems;
    },
    utcOffset(state){
        return state.utcOffset;
    },
    noiseCancelMenuItems(state){
        return state.noiseCancelMenuItems;
    },
    rxItems(state){
        return state.rxItems;
    },
    txItems(state){
        return state.txItems;
    },
    inactivityTimerMenuItems(state){
        return state.inactivityTimerMenuItems;
    },
    notDscRelatedItems(state){
        return state.notDscRelatedItems;
    },
    dscRelatedItems(state){
        return state.dscRelatedItems;
    },
    remoteId(state){
        return state.remoteId;
    },
    currentCarouselMenuItems(state){
        return state.currentCarouselMenuItems;
    },
    isReceivingSignal(state){
        return state.isReceivingSignal;
    },
    receivedSignalSenderDetails(state){
        return state.receivedSignalSenderDetails;
    },
    transmittedCallLogMenuItems(state){
        return state.transmittedCallLogMenuItems;
    },
    individualAckDetails(state){
        return state.individualAckDetails;
    },
    groupCallCategorys(state){
        return state.groupCallCategorys;
    }
};
const mutations = {
    TOGGLE_POWER(state, payload){
        state.powerIsOn = payload;
    },
    SET_INDIVIDUAL_CALL_DETAILS(state, payload){
        switch(payload.mode){
            case 'ID':{
                state.individualCallDetails.id = payload.id;
                break;
            }
            case 'CATEGORY': {
                state.individualCallDetails.category = payload.category;
                break;
            }
            case 'CHANNEL': {
                state.individualCallDetails.callChannel = payload.channel;
            }
        }
    },
    SET_GROUP_CALL_DETAILS(state, payload){
        switch(payload.mode){
            case 'ID':{
                state.groupCallDetails.id = payload.id;
                break;
            }
            case 'CATEGORY': {
                state.groupCallDetails.category = payload.category;
                break;
            }
            case 'CHANNEL': {
                state.groupCallDetails.callChannel = payload.channel;
            }
        }
    },
    SET_ALL_SHIPS_CALL_DETAILS(state, payload){
        switch(payload.mode){
            case 'ID':{
                state.allShipsCallDetails.id = payload.id;
                break;
            }
            case 'CATEGORY': {
                state.allShipsCallDetails.category = payload.category;
                break;
            }
            case 'CHANNEL': {
                state.allShipsCallDetails.callChannel = payload.channel;
            }
        }
    },
    SET_DISTRESS_CALL_DETAILS(state, payload){
        switch(payload.mode){
            case 'ID':{
                state.distressCallDetails.id = payload.id;
                break;
            }
            case 'CATEGORY': {
                state.distressCallDetails.category = payload.category;
                break;
            }
        }
    },
    SET_TEST_CALL_DETAILS(state, payload){
        switch(payload.mode){
            case 'ID':{
                state.testCallDetails.id = payload.id;
                break;
            }
            case 'CATEGORY': {
                state.testCallDetails.category = payload.category;
                break;
            }
        }
    },
    SET_POSITION_INPUT_DETAILS(state, payload){
        switch(payload.mode){
            case 'LAT': {
                state.positionInputDetails.position.lat = payload.lat;
                break;
            }
            case 'LON': {
                state.positionInputDetails.position.long = payload.long;
                break;
            }
            case 'UTC': {
                state.positionInputDetails.utc = payload.utc;
                break;
            }
        }
    },
    SET_DISTRESS_POSITION_INPUT_DETAILS(state, payload){
        switch(payload.mode){
            case 'LAT': {
                state.distressPositionInputDetails.position.lat = payload.lat;
                break;
            }
            case 'LON': {
                state.distressPositionInputDetails.position.long = payload.long;
                break;
            }
            case 'UTC': {
                state.distressPositionInputDetails.utc = payload.utc;
                break;
            }
        }
    },
    SET_INDIVIDUAL_IDS(state, payload){
        switch(payload.mode){
            case 'ADD': {
                state.individualIds.push({...payload.address});
                break;
            }
        }
    },
    SET_GROUP_IDS(state, payload){
        switch(payload.mode){
            case 'ADD': {
                state.groupIds.push({...payload.address});
                break;
            }
        }
    },
    SET_DSC_SETTINGS_MENU_ITEMS_VALUE(state, payload){
        state.dscSettingsMenuItems[payload.index].value = payload.value;
    },
    SET_ALARM_MENU_ITEMS_VALUE(state, payload){
        state.alarmMenuItems[payload.index].value = payload.value;
    },
    SET_RADIO_SETTINGS_MENU_ITEMS_VALUE(state, payload){
        state.radioSettingsMenuItems[payload.index].value = payload.value;
    },
    SET_CONFIGURATION_MENU_ITEMS_VALUE(state, payload){
        state.configurationMenuItems[payload.index].value = payload.value;
    },
    SET_UTC_OFFSET(state, payload){
        state.utcOffset[payload.index] = payload.value;
    },
    SET_NOISE_CANCEL_MENU_ITEMS_VALUE(state, payload){
        state.noiseCancelMenuItems[payload.index].value = payload.value;
    },
    SET_INACTIVITY_TIMER_MENU_ITEMS_VALUE(state, payload){
        state.inactivityTimerMenuItems[payload.index].value = payload.value;
    },
    INCREMENT_REMOTE_ID(state, payload){
        switch(payload.mode){
            case 'add': {
                if(state.remoteId < 69){
                    state.remoteId++;
                }
                break;
            }
            case 'remove': {
                if(state.remoteId > 1){
                    state.remoteId--;
                }
                break;
            }
        }
    },
    SET_CURRENT_CAROUSEL_MENU_ITEMS(state, payload){
        state.currentCarouselMenuItems = payload;
    },
    TOGGLE_CHANNEL_TAG(state, payload){
        state.channels[payload].isTag = !state.channels[payload].isTag;
    },
    SET_CHANNEL_DESCRIPTION(state, payload){
        state.channels[payload.index].description = payload.description;
    },
    RECEIVE_SIGNAL(state, payload){
        state.isReceivingSignal = payload.isSending;
        state.receivedSignalSenderDetails.mmsi = payload.mmsi;
        state.receivedSignalSenderDetails.channelNumber = payload.channelNumber;
    },
    EDIT_INDIVIDUAL_ID(state, payload){
        state.individualIds[payload.index] = payload.individualId;
    },
    DELETE_INDIVIDUAL_ID(state, payload){
        state.individualIds.splice(payload, 1);
    },
    EDIT_GROUP_ID(state, payload){
        state.groupIds[payload.index] = payload.groupId;
    },
    DELETE_GROUP_ID(state, payload){
        state.groupIds.splice(payload, 1);
    },
    SET_CHAN_GROUP(state, payload){
        switch(payload.mode){
            case 'ATIS': {
                state.menuItems = [
                    {title: 'Radio Settings', icon: 'mdi-radio-handheld', subItems: []},
                    {title: 'Configuration', icon: 'mdi-cog', subItems: []},
                    {title: 'MMSI/GPS Info', icon: 'mdi-information', subItems: []},
                ];
                break;
            }
            case 'DSC': {
                state.menuItems = [
                    {title: 'DSC Calls', icon: 'mdi-ferry', subItems: []},
                    {title: 'DSC Settings', icon: 'mdi-wrench', subItems: []},
                    {title: 'Radio Settings', icon: 'mdi-radio-handheld', subItems: []},
                    {title: 'Configuration', icon: 'mdi-cog', subItems: []},
                    {title: 'MMSI/GPS Info', icon: 'mdi-information', subItems: []},
                ];
                break;
            }
            case 'INT': {
                state.menuItems = [
                    {title: 'Radio Settings', icon: 'mdi-radio-handheld', subItems: []},
                    {title: 'Configuration', icon: 'mdi-cog', subItems: []},
                    {title: 'MMSI/GPS Info', icon: 'mdi-information', subItems: []},
                ];
                break;
            }
        }
    },
    SET_INDIVIDUAL_ACK_DETAILS(state, payload){
        state.individualAckDetails = payload;
    },
    ADD_RECEIVED_CALL_TO_LOG(state, payload){
        switch(payload.mode){
            case 'distress': {
                state.receivedCalls.distress.unshift({
                    icon: 'mdi-email-outline', 
                    type: payload.type,
                    from: payload.from,
                    category: 'Undesignated',
                    lat: payload.lat,
                    lon: payload.lon,
                    utc: payload.utc,
                    channelType: payload.channelType,
                });
                break;
            }
            case 'other': {
                state.receivedCalls.other.unshift({
                    icon: 'mdi-email-outline', 
                    type: payload.type,
                    from: payload.from,
                    category: payload.category,
                    channel: payload.channel,
                    channelType: payload.channelType
                })
            }
        }
    },
    ADD_TRANSMITTED_CALL_TO_LOG(state, payload){
        switch(payload.mode){
            case 'distress': {
                state.transmittedCalls.distress.unshift({
                    icon: 'mdi-email-outline', 
                    type: payload.type,
                    from: payload.from,
                    category: 'Undesignated',
                    lat: payload.lat,
                    lon: payload.lon,
                    utc: payload.utc,
                    channelType: payload.channelType,
                });
                break;
            }
            case 'other': {
                state.transmittedCalls.other.unshift({
                    icon: 'mdi-email-outline', 
                    type: payload.type,
                    from: payload.from,
                    category: payload.category,
                    channel: payload.channel,
                    channelType: payload.channelType
                })
            }
        }
    },
    DELETE_RECEIVED_CALL_LOG(state, payload){
        if(state.receivedCalls.other.find((el) => el == payload)){
            let index = state.receivedCalls.other.findIndex((el) => el == payload);
            state.receivedCalls.other.splice(index, 1);
        }
        else{
            let index = state.receivedCalls.distress.findIndex((el) => el == payload);
            state.receivedCalls.distress.splice(index, 1);
        }
    },
    DELETE_TRANSMITTED_CALL_LOG(state, payload){
        if(state.transmittedCalls.other.find((el) => el == payload)){
            let index = state.transmittedCalls.other.findIndex((el) => el == payload);
            state.transmittedCalls.other.splice(index, 1);
        }
        else{
            let index = state.transmittedCalls.distress.findIndex((el) => el == payload);
            state.transmittedCalls.distress.splice(index, 1);
        }
    }
};
const actions = {
    togglePower({commit}, payload){
        commit("TOGGLE_POWER", payload);
    },
    setIndividualCallDetails({commit}, payload){
        commit("SET_INDIVIDUAL_CALL_DETAILS", payload);
    },
    setGroupCallDetails({commit}, payload){
        commit("SET_GROUP_CALL_DETAILS", payload);
    },
    setAllShipsCallDetails({commit}, payload){
        commit("SET_ALL_SHIPS_CALL_DETAILS", payload);
    },
    setDistressCallDetails({commit}, payload){
        commit("SET_DISTRESS_CALL_DETAILS", payload);
    },
    setTestCallDetails({commit}, payload){
        commit("SET_TEST_CALL_DETAILS", payload);
    },
    setPositionInputDetails({commit}, payload){
        commit("SET_POSITION_INPUT_DETAILS", payload);
    },
    setDistressPositionInputDetails({commit}, payload){
        commit("SET_POSITION_INPUT_DETAILS", payload);
    },
    setIndividualIds({commit}, payload){
        commit("SET_INDIVIDUAL_IDS", payload);
    },
    setGroupIds({commit}, payload){
        commit("SET_GROUP_IDS", payload);
    },
    setDscSettingsMenuItemsValue({commit}, payload){
        commit("SET_DSC_SETTINGS_MENU_ITEMS_VALUE", payload);
    },
    setAlarmMenuItemsValue({commit}, payload){
        commit("SET_ALARM_MENU_ITEMS_VALUE", payload);
    },
    setRadioSettingsMenuItemsValue({commit}, payload){
        commit("SET_RADIO_SETTINGS_MENU_ITEMS_VALUE", payload);
    },
    setConfigurationMenuItemsValue({commit}, payload){
        commit("SET_CONFIGURATION_MENU_ITEMS_VALUE", payload);
    },
    setUtcOffset({commit}, payload){
        commit("SET_UTC_OFFSET", payload);
    },
    setNoiseCancelMenuItemsValue({commit}, payload){
        commit("SET_NOISE_CANCEL_MENU_ITEMS_VALUE", payload);
    },
    setInactivityTimerMenuItemsValue({commit}, payload){
        commit("SET_INACTIVITY_TIMER_MENU_ITEMS_VALUE", payload);
    },
    incrementRemoteId({commit}, payload){
        commit("INCREMENT_REMOTE_ID", payload);
    },
    setCurrentCarouselMenuItems({commit}, payload){
        commit("SET_CURRENT_CAROUSEL_MENU_ITEMS", payload);
    },
    toggleChannelTag({commit}, payload){
        commit("TOGGLE_CHANNEL_TAG", payload);
    },
    setChannelDescription({commit}, payload){
        commit("SET_CHANNEL_DESCRIPTION", payload);
    },
    receiveSignal({commit}, payload){
        commit("RECEIVE_SIGNAL", payload);
    },
    editIndividualId({commit}, payload){
        commit("EDIT_INDIVIDUAL_ID", payload);
    },
    deleteIndividualId({commit}, payload){
        commit("DELETE_INDIVIDUAL_ID", payload);
    },
    editGroupId({commit}, payload){
        commit("EDIT_GROUP_ID", payload);
    },
    deleteGroupId({commit}, payload){
        commit("DELETE_GROUP_ID", payload);
    },
    setChanGroup({commit}, payload){
        commit("SET_CHAN_GROUP", payload);
    },
    setIndividualAckDetails({commit}, payload){
        commit("SET_INDIVIDUAL_ACK_DETAILS", payload);
    },
    addReceivedCallToLog({commit}, payload){
        commit("ADD_RECEIVED_CALL_TO_LOG", payload);
    },
    addTransmittedCallToLog({commit}, payload){
        commit("ADD_TRANSMITTED_CALL_TO_LOG", payload);
    },
    deleteReceivedCallLog({commit}, payload){
        commit("DELETE_RECEIVED_CALL_LOG", payload);
    },
    deleteTransmittedCallLog({commit}, payload){
        commit("DELETE_TRANSMITTED_CALL_LOG", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}