<template>
    <div class="container" v-if="step == 1">
        <p class="company">Bootsschule1</p>
        <p class="domain">bootsschule1.de</p>
        <p class="title">IC-M423</p>
        <p class="version">Ver1.001</p>
    </div>
    <div class="container" v-if="step == 2">
        <p class="mmsi-title">{{ '<MMSI>' }}</p>
        <p class="dsc-title">DSC: 239488023</p>
        <p class="atis-title">ATIS: 2394880233</p>
        <p class="version">Ver1.001</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        step: Number,
    }
})
</script>

<style scoped>
    .container{
        position: absolute;
        top: 45px;
        left: 50px;
    }
    .company{
        position: absolute;
        font-family: m423-font;
        font-size: 20pt;
        white-space: nowrap;
        left: -18px;
        top: -50px;
    }
    .domain{
        position: absolute;
        font-family: m423-font;
        font-size: 18pt;
        white-space: nowrap;
        left: -26px;
        top: -30px;
    }
    .title{
        position:absolute;
        font-family: m423-font;
        font-size: 25pt;
        white-space: nowrap;
        left: 0px;
    }
    .version{
        position:absolute;
        font-family: m423-font;
        font-size: 18pt;
        white-space: nowrap;
        top: 30px;
        left: 17px;
    }
    .mmsi-title{
        position:absolute;
        font-family: m423-font;
        font-size: 20pt;
        white-space: nowrap;
        top: -35px;
        left: 17px;
    }
    .dsc-title{
        position:absolute;
        font-family: m423-font;
        font-size: 18pt;
        white-space: nowrap;
        top: -10px;
        left: -23px;
    }
    .atis-title{
        position:absolute;
        font-family: m423-font;
        font-size: 18pt;
        white-space: nowrap;
        top:8px;
        left: -35.5px;
    }
</style>