<template>
    <div class="device-container">
        <v-btn @click="goBack" icon class="back-btn"><v-icon size="40" color="white">mdi-arrow-left</v-icon></v-btn>
        <div class="first-device mx-10 my-5">
            <m503ds100 v-if="selectedDevices[0].id == 0"></m503ds100>
            <m423Device v-if="selectedDevices[0].id == 1"></m423Device>
        </div>
        <div v-if="selectedDevices.length > 1" class="second-device mx-10 my-5">
            <m503ds100 v-if="selectedDevices[1].id == 0"></m503ds100>
            <m423Device v-if="selectedDevices[1].id == 1"></m423Device>
        </div>
    </div>
</template>

<script>
    import m503ds100 from "@/components/M503_DS100/M503-DS100.vue"
    import m423Device from "@/components/M423/M423_Device.vue"
    import { defineComponent } from "vue";

    export default defineComponent({
        computed: {
            selectedDevices(){
                return this.$store.getters['selectedDevices'];
            }
        },
        methods:{
            goBack(){
                this.$store.dispatch('toggleSubmit');
            }
        },
        components: {
            m503ds100,
            m423Device,
        },
    })
</script>

<style>
    .back-btn{
        position: absolute;
        top: 5%;
        left: 5%;
        z-index: 30;
        background-color: #021df0;
    }
    .device-container{
        margin-bottom: 5%;
        margin-top: 5%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .first-device {
        width: 600px;
        min-width: 600px;
        max-width: 600px;
        border-radius: 1%;
        border: 1px solid rgb(197, 197, 197);
    }
    .second-device {
        width: 600px;
        min-width: 600px;
        max-width: 600px;
        border-radius: 1%;
        border: 1px solid rgb(179, 179, 179);
    }
</style>