<template>
    <div v-if="mode == 'IND Overview'">
        <p class="title-text">-RCV Individual call-</p>
        <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
        <p class="category-text">Category: {{ transmittedCall.category }}</p>
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'IND ACK'">
        <p class="title-text">-RCV Individual ACK-</p>
        <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
        <p class="category-text">{{individualAckDetails.isAbleToComply ? 'Able to comply' : 'Unable to comply'}}</p>
        <p v-show="!individualAckDetails.isAbleToComply" class="reason-text">{{individualAckDetails.reason ? individualAckDetails.reason : 'No reason given'}}</p>
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'GRP Overview'">
        <p class="title-text">-RCV Group call-</p>
        <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
        <p class="category-text">Category: {{ transmittedCall.category }}</p>
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'All Ships Overview'">
        <p class="title-text">-RCV All ships call-</p>
        <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
        <p class="category-text">Category: {{ transmittedCall.category }}</p>
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'Distress Call Overview'">
        <p class="title-text">-RCV Distress-</p>
        <p class="from-text">Distress ID</p>
        <p class="distress-id">{{ transmittedCall.senderMmsi }}</p>
        <p class="distress-time-and-pos-text">Distress time and pos</p>
        <p v-show="m423DistressPositionInputDetails.utc" class="distress-time-text">{{ `Time: UTC ${m423DistressPositionInputDetails.utc}` }}</p>
        <p v-show="!m423Lat.includes('undefined')" class="distress-lat-text">{{ `Pos: Lat ${m423Lat}` }}</p>
        <p v-show="!m423Lon.includes('undefined') && !clearPressed" class="distress-lon-text">{{ `Lon ${m423Lon}` }}</p>
        <p v-show="m423DistressPositionInputDetails.utc == ''" class="distress-time-text">No time data</p>
        <p v-show="m423Lat.includes('undefined')" class="distress-lat-text">No position data</p>
        <p v-show="clearPressed" class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'Distress Cancel Overview'">
        <p class="title-text">-RCV Distress ACKN-</p>
        <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
        <p class="distress-id-text">Distress ID:</p>
        <p class="distress-cancel-id">{{ transmittedCall.senderMmsi }}</p>
        <p v-show="!clearPressed" class="options-text">{{ '<CLR->Alarm stop>' }}</p>
        <p v-show="clearPressed" class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'IND Address'">
        <p class="title-text">{{'<Select address ID>'}}</p>
        <p class="selection-cursor">></p>
        <DS100AddressIdSelection
            :mode="'Individual Call'"
        />
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'IND Comply'">
        <p class="title-text">{{ '<Select a comply>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection
            class="selection-list"
            :mode="'IND Comply'"
            :items="complyOptions"
        />
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'IND Cant Comply'">
        <p class="title-text">{{ '<Select a reason>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection
            class="selection-list"
            :mode="'IND Cant Comply'"
            :items="cantComplyOptions"
        />
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'IND Channel'">
        <p class="title-text">{{ '<Input a traffic CH>' }}</p>
        <p class="channel-text">CH:</p>
        <DS100Input
            class="channel-input"
            :items="acknowledgementDetails.channel"
            :isActive="true"
            :selectedIndex="selectedInputIndex"
        />
        <p class="options-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-else-if="mode == 'IND Exec'">
        <p class="multi-line-title">{{ '<Push [CALL] & [ENT] simultaneosly>' }}</p>
        <p class="multi-line-exit">To stop the call,<br/> push [CLR]</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import DS100AddressIdSelection from '../IndividualCallMenu/DS100AddressIdSelection.vue'
import DS100Selection from '../base/DS100Selection.vue'
import DS100Input from '../base/DS100Input.vue'
export default defineComponent({
    data(){
        return {
            complyOptions: ['Able to comply','Unable to comply'],
        }
    },
    components: {
        DS100AddressIdSelection,
        DS100Selection,
        DS100Input
    },
    computed: {
        transmittedCall(){
            return this.$store.getters['transmittedCall'];
        },
        acknowledgementDetails(){
            return this.$store.getters['ds100/acknowledgementDetails'];
        },
        m423DistressPositionInputDetails(){
            return this.$store.getters['m423/distressPositionInputDetails'];
        },
        m423Lat(){
            var splittedString = this.m423DistressPositionInputDetails.position.lat.split('.');
            return `${splittedString[0]}'${this.m423DistressPositionInputDetails.position.lat[this.m423DistressPositionInputDetails.position.lat.length - 1]}`;
        },
        m423Lon(){
            var splittedString = this.m423DistressPositionInputDetails.position.long.split('.');
            return `${splittedString[0]}'${this.m423DistressPositionInputDetails.position.long[this.m423DistressPositionInputDetails.position.long.length - 1]}`;
        },
        individualAckDetails(){
            return this.$store.getters['m423/individualAckDetails'];
        }
    },
    props: {
        mode: String,
        trafficChannel: Array,
        selectedInputIndex: Number,
        cantComplyOptions: Array,
        clearPressed: Boolean,
    }
})
</script>

<style>
.reason-text{
    position:absolute;
    font-family: ds-digi;
    top: 70px;
    left: 10px;
    font-size: 14pt;
}
.distress-lon-text{
    position:absolute;
    font-family: ds-digi;
    top: 105px;
    left: 60px;
    font-size: 14pt;
}
.distress-lat-text{
    position:absolute;
    font-family: ds-digi;
    top: 90px;
    left: 24.5px;
    font-size: 14pt;
}
.distress-time-text{
    position:absolute;
    font-family: ds-digi;
    top: 75px;
    left: 20px;
    font-size: 14pt;
}
.distress-time-and-pos-text{
    position:absolute;
    font-family: ds-digi;
    top: 60px;
    left: 10px;
    font-size: 14pt;
}
.distress-id{
    position:absolute;
    font-family: ds-digi;
    top:45px;
    left:60px;
    font-size: 14pt;
}
.multi-line-exit{
    position: absolute;
    font-family: ds-digi;
    font-size: 13pt;
    left: 20px;
    top: 90px;
    line-height: 20px;
}
.multi-line-title{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 20px;
    line-height: 20px;
    top: 5px;
}
.channel-input{
    position: absolute;
    display: flex;
    left: 35px;
    top: 32.5px;

}
.channel-text{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 15px;
}
.selection-list{
    position: absolute;
    top: 27px;
    height: 70px;
}
.selection-cursor{
    position: absolute;
    font-family: ds-digi;
    font-size: 20pt;
    left: 7px;
    top: 19px;
    height: 30px;
    width: 30px;
    z-index: 10;
}
.title-text{
    margin-left: 15px;
    margin-top: 5px;
    font-family: ds-digi;
    font-size: 14pt;
}
.distress-cancel-id{
    position:absolute;
    font-family: ds-digi;
    top:65px;
    left:55px;
    font-size: 14pt;
}
.distress-id-text{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 10px;
    top:50px;
}
.from-text{
    position: absolute;
    font-family: ds-digi;
    font-size: 14pt;
    left: 10px;
    top:30px;
}
.category-text{
    position: absolute;
    top: 50px;
    left: 10px;
    font-family: ds-digi;
    font-size: 14pt;
}
.options-text{
    position: absolute;
    left: 37px;
    bottom: 5px;
    font-family: ds-digi;
    font-size: 14pt;
}
</style>