<template>
    <div class="horn-container">
        <div class="title-bar">
            <p class="horn-title">{{hornTitle}}</p>
        </div>
        <v-icon size="70" class="horn">mdi-bullhorn</v-icon>
        <v-icon v-show="isSending" size="50" class="signal">mdi-wifi</v-icon>
    </div>
    <div class="menu-actions">
        <div class="action exit">
            <p class="action-text">EXIT</p>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        isSending: Boolean,
        hornTitle: String,
        backlightColor: String,
    }
})
</script>

<style scoped>
    .horn-title{
        font-family: m423-text-font;
        font-size: 13pt;
    }
    .title-bar{
        width: 100%;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        text-align: center;
    }
    .signal{
        transform: rotate(0.25turn);
        position: absolute;
        top: 19px;
        left: 113px;
    }
    .horn{
        position: absolute;
        top: 10px;
        left: 72px;
    }
    .horn-container{
        height: 67%;
        width: 90%;
        position: absolute;
        left: 10px;
        top: 10px;
        border: 3px solid black;
    }
    .menu-actions{
        position: absolute;
        display: flex;

        align-items: center;
        bottom: 5px;
        left: 5px;
        width: 227px;
        height: 20px;
    }
    .exit{
        position:absolute;
        left: 5px;
    }
    .action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
</style>