<template>
    <div v-if="isSelecting">
        <p class="title">{{ '<Select address ID>' }}</p>
        <p class="selection-cursor">></p>
        <DS100AddressIdSelection class="selection-list" mode="individual"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isManualEntry">
        <p class="title">{{ '<Input address ID>' }}</p>
        <p class="id-header">{{ 'ID: (9digit)' }}</p>
        <DS100Input class="input-container" :selectedIndex="inputStep" :items="positionRequest.tempAddressId" :isActive="true"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isCalling">
        <p class="multi-line-title">{{ '<Push [CALL] & [ENT] simultaneosly>' }}</p>
        <p class="multi-line-exit">To stop the call,<br/> push [CLR]</p>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import DS100AddressIdSelection from '../IndividualCallMenu/DS100AddressIdSelection.vue';
    import DS100Input from '../base/DS100Input.vue';
    export default defineComponent ({
        computed:{
            positionRequest(){
                return this.$store.getters['ds100/positionRequest'];
            },
            categories(){
                return this.$store.getters['ds100/categories'];
            }
        },
        components: {
            DS100AddressIdSelection,
            DS100Input,
        },
        props: {
            isSelecting: Boolean,
            isManualEntry: Boolean,
            isCalling: Boolean,
            inputStep: Number,
        },
    })
</script>

<style scoped>
    .channel-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 45px;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .id-header{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .multi-line-exit{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 90px;
        line-height: 20px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        line-height: 20px;
        top: 5px;
    }
    .title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
    .selection-list{
        max-height: 140px;
        height: 90px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 22px;
        font-family: ds-digi;
    }
    .selection-cursor{
        position: absolute;
        font-family: ds-digi;
        font-size: 20pt;
        left: 7px;
        top: 14px;
    }
</style>