<template>
    <div v-if="isSelectingCategory">
        <p class="title">{{ '<Select a category>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" :items="categories"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isInputChannel">
        <p class="title">{{ '<Input a traffic CH>' }}</p>
        <p class="channel-title">CH</p>
        <DS100Input class="input-container" :selectedIndex="inputStep" :items="allShipsCall.channel" :isActive="true"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="isCalling">
        <p class="multi-line-title">{{ '<Push [CALL] & [ENT] simultaneosly>' }}</p>
        <p class="multi-line-exit">To stop the call,<br/> push [CLR]</p>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import DS100Selection from '../base/DS100Selection.vue'
import DS100Input from '../base/DS100Input.vue'
export default defineComponent({
    components: {
        DS100Selection,
        DS100Input,
    },
    props: {
        inputStep: Number,
        isSelectingCategory: Boolean,
        isInputChannel: Boolean,
        isCalling: Boolean
    },
    computed: {
        allShipsCall(){
            return this.$store.getters['ds100/allShipsCall'];
        },
        categories(){
            return this.$store.getters['ds100/categories'];
        }
    }
})
</script>

<style scoped>
    .channel-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 45px;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .id-header{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .multi-line-exit{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 90px;
        line-height: 20px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        line-height: 20px;
        top: 5px;
    }
    .title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
    .selection-list{
        max-height: 90px;
        height: 90px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 22px;
        font-family: ds-digi;
    }
    .selection-cursor{
        position: absolute;
        font-family: ds-digi;
        font-size: 20pt;
        left: 7px;
        top: 14px;
    }
</style>