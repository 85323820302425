const state = {
    powerIsOn: false,
    isReceivingSignal: false,
    receivedSignalSenderDetails: {
        mmsi: '',
        channelNumber: '',
    },
};
const getters = {
    powerIsOn(state){
        return state.powerIsOn;
    },
    isReceivingSignal(state){
        return state.isReceivingSignal;
    },
    receivedSignalSenderDetails(state){
        return state.receivedSignalSenderDetails;
    }
};
const mutations = {
    TOGGLE_POWER(state){
        state.powerIsOn = !state.powerIsOn;
    },
    RECEIVE_SIGNAL(state, payload){
        state.isReceivingSignal = payload.isSending;
        state.receivedSignalSenderDetails.mmsi = payload.mmsi;
        state.receivedSignalSenderDetails.channelNumber = payload.channelNumber;
    }
};
const actions = {
    togglePower({commit}){
        commit("TOGGLE_POWER");
    },
    receiveSignal({commit}, payload){
        commit("RECEIVE_SIGNAL", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}