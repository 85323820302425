<template>
    <div v-if="IsSelectingMessageCategory">
        <p class="title">{{ '<Select a message>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" :items="messageCategories"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="IsSelectingMessage">
        <p class="title">{{ '<Select a message>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" mode="messages" :items="receivedMessages"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="IsSelectingDistressMessage">
        <p class="title">{{ '<Select a message>' }}</p>
        <p class="selection-cursor">></p>
        <DS100Selection class="selection-list" mode="messages" :items="receivedDistressMessages"/>
        <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
    </div>
    <div v-if="IsShowMessageContent">
        <p class="title">{{ '<Message content>' }}</p>
        <p class="from">From: 211930310</p>
        <p class="id-title">Distress ID:</p>
        <p class="id">211930310</p>
        <p class="type">Undesignated</p>
        <p class="exit-text">{{ '<A/a>Data clear>' }}</p>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import DS100Selection from '../base/DS100Selection.vue'
export default defineComponent({
    computed: {
        messageCategories(){
            return this.$store.getters['ds100/messageCategories'];
        },
        receivedMessages(){
            return this.$store.getters['ds100/receivedMessages'];
        },
        receivedDistressMessages(){
            return this.$store.getters['ds100/receivedDistressMessages'];
        },
    },
    components: {
        DS100Selection
    },
    props: {
        IsSelectingMessageCategory: Boolean,
        IsSelectingMessage: Boolean,
        IsSelectingDistressMessage: Boolean,
        IsShowMessageContent: Boolean,
    }
    
})
</script>

<style scoped>
.channel-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 45px;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .input-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 45px;
    }
    .id-header{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 30px;
    }
    .multi-line-exit{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 90px;
        line-height: 20px;
    }
    .multi-line-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        line-height: 20px;
        top: 5px;
    }
    .title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
    .selection-list{
        max-height: 140px;
        height: 90px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 22px;
        font-family: ds-digi;
    }
    .selection-cursor{
        position: absolute;
        font-family: ds-digi;
        font-size: 20pt;
        left: 7px;
        top: 14px;
    }
.from {
    position: absolute;
    font-family: ds-digi;
    font-size: 13pt;
    left: 20px;
    top: 35px;
}
.id-title {
    position: absolute;
    font-family: ds-digi;
    font-size: 13pt;
    left: 20px;
    top: 50px;
}
.id {
    position: absolute;
    font-family: ds-digi;
    font-size: 13pt;
    left: 61px;
    top: 65px;
}
.type {
    position: absolute;
    font-family: ds-digi;
    font-size: 13pt;
    left: 20px;
    top: 80px;
}
</style>