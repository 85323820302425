const state = {
    position: {
        latitude: ['_','_','_','_','N'],
        longitude: ['_','_','_','_','_','E'],
    },
    time: ['_','_',':','_','_'],
    addressIds: [],
    groupAddressIds: [],
    manualIndividualCall: {
        tempAddressId: ['_','_','_','_','_','_','_','_','_'],
        category: '',
        channel: ['_','_'],
    },
    manualGroupCall: {
        tempAddressId: ['0','_','_','_','_','_','_','_','_'],
        category: '',
        channel: ['_','_'],
    },
    positionRequest: {
        tempAddressId: ['_','_','_','_','_','_','_','_','_'],
    },
    categories: [
        'Routine',
        'Safety',
        'Urgency',
        'Distress'
    ],
    messageCategories: [
        'Distress message',
        'Other message'
    ],
    receivedDistressMessages: [
        {type: 'Distress', time: '00:00'}
    ],
    receivedMessages: [
        {type: 'Individual', time: 'acknow'}
    ],
    allShipsCall: {
        category: '',
        channel: ['_','_'],
    },
    distressSettings: {
        type: '',
        pos: {
            latitude: ['_','_','_','_','N'],
            longitude: ['_','_','_','_','_','E'],
        },
        time: ['_','_',':','_','_'],
    },
    distressTypes: [
        'Undesignated',
        'Fire, Explosion',
        'Sinking',
        'Flooding',
        'Collision',
        'Grounding',
        'Capsizing',
        'Disable adrift',
        'Abandoning ship',
        'Piracy Attack',
        'Man overboard',
        'EPIRB Emmision'
    ],
    addressToAdd: {
        id: ['_','_','_','_','_','_','_','_','_'],
        name: ['_','_','_','_','_','_','_','_','_','_','_','_','_','_','_'],
    },
    groupAddressToAdd: {
        id: [0,'_','_','_','_','_','_','_','_'],
        name: ['_','_','_','_','_','_','_','_','_','_','_','_','_','_','_'],
    },
    offsetTime: ['_','0','0',':','0','0'],
    brightnessLevel: ['_'],
    contrastLevel: ['_'],
    mmsi: '',
    acknowledgementDetails: {
        selectedAddress: '',
        complyMode: '',
        channel: ['_','_']
    }
};
const getters = {
    position(state){
        return state.position;
    },
    time(state){
        return state.time;
    },
    addressIds(state){
        return state.addressIds;
    },
    manualIndividualCall(state){
        return state.manualIndividualCall;
    },
    categories(state){
        return state.categories;
    },
    groupAddressIds(state){
        return state.groupAddressIds;
    },
    manualGroupCall(state){
        return state.manualGroupCall;
    },
    allShipsCall(state){
        return state.allShipsCall;
    },
    positionRequest(state){
        return state.positionRequest;
    },
    messageCategories(state){
        return state.messageCategories;
    },
    receivedMessages(state){
        return state.receivedMessages;
    },
    receivedDistressMessages(state){
        return state.receivedDistressMessages;
    },
    distressTypes(state){
        return state.distressTypes;
    },
    addressToAdd(state){
        return state.addressToAdd;
    },
    groupAddressToAdd(state){
        return state.groupAddressToAdd;
    },
    offsetTime(state){
        return state.offsetTime;
    },
    brightnessLevel(state){
        return state.brightnessLevel;
    },
    contrastLevel(state){
        return state.contrastLevel;
    },
    mmsi(state){
        return state.mmsi;
    },
    acknowledgementDetails(state){
        return state.acknowledgementDetails;
    }
};
const mutations = {
    SET_POSITION(state, payload){
        state.position = payload;
    },
    SET_TIME(state, payload){
        state.time = payload;
    },
    ADD_TIME_NUMBER(state, payload){
        state.offsetTime[payload.index] = payload.number;
    },
    ADD_ADDRESS_ID(state, payload){
        state.addressIds.push(payload);
    },
    ADD_MANUAL_ENTRY(state, payload){
        switch(payload){
            case "address": {
                state.addressIds.unshift({id: 0, name: 'Manual Entry'});
                break;
            }
            case "group": {
                state.groupAddressIds.unshift({id: 0, name: 'Manual Entry'});
                break;
            }
        }
    },
    ADD_TEMP_ADDRESS_NUM(state, payload){
        switch(payload.mode){
            case "individual": {
                state.manualIndividualCall.tempAddressId[payload.index] = payload.number;
                break;
            }
            case "group": {
                state.manualGroupCall.tempAddressId[payload.index] = payload.number;
                break;
            }
            case "posRequest": {
                state.positionRequest.tempAddressId[payload.index] = payload.number;
                break;
            }
            case "addAddress": {
                state.addressToAdd.id[payload.index] = payload.number;
                break;
            }
            case "addName": {
                state.addressToAdd.name[payload.index] = payload.number.replaceAll('"', '').replaceAll("'", '');
                break;
            }
            case "addGroupAddress": {
                state.groupAddressToAdd.id[payload.index] = payload.number;
                break;
            }
            case "addGroupName": {
                state.groupAddressToAdd.name[payload.index] = payload.number;
                break;
            }
        }
    },
    SET_TEMP_TO_DEFAULT(state, payload){
        switch(payload.mode){
            case "individual": {
                state.manualIndividualCall.tempAddressId = ['_','_','_','_','_','_','_','_','_'];
                break;
            }
            case "group": {
                state.manualGroupCall.tempAddressId = ['0','_','_','_','_','_','_','_','_'];
                break;
            }
            case "posRequest": {
                state.positionRequest.tempAddressId = ['_','_','_','_','_','_','_','_','_'];
                break;
            }
            case "addGroup": {
                state.groupAddressToAdd = {
                    id: ['0','_','_','_','_','_','_','_','_'],
                    name: ['_','_','_','_','_','_','_','_','_','_','_','_','_','_','_'],
                };
                break;
            }
            case "addAddress": {
                state.addressToAdd = {
                    id: ['_','_','_','_','_','_','_','_','_'],
                    name: ['_','_','_','_','_','_','_','_','_','_','_','_','_','_','_'],
                };
                break;
            }
        }
    },
    SET_TEMP_CATEGORY(state, payload){
        switch(payload.mode){
            case "individual": {
                state.manualIndividualCall.category = state.categories[payload.index];
                break;
            }
            case "group": {
                state.manualGroupCall.category = state.categories[payload.index];
                break;
            }
            case "allShips": {
                state.allShipsCall.category = state.categories[payload.index];
                break;
            }
            case "distress": {
                state.distressSettings.type = state.distressTypes[payload.index];
            }
        }
    },
    SET_TEMP_CHANNEL_NUMBER(state, payload){
        switch(payload.mode){
            case "individual": {
                state.manualIndividualCall.channel[payload.index] = payload.number;
                break;
            }
            case "group": {
                state.manualGroupCall.channel[payload.index] = payload.number;
                break;
            }
            case "allShips": {
                state.allShipsCall.channel[payload.index] = payload.number;
                break;
            }
        }
    },
    DELETE_ADDRESS_ID(state, payload){
        state.addressIds.splice(payload,1);
    },
    DELETE_GROUP_ID(state, payload){
        state.groupAddressIds.splice(payload,1);
    },
    ADD_GROUP_ID(state, payload){
        state.groupAddressIds.push(payload);
    },
    CLEAR_OFFSET_TIME(state){
        state.offsetTime = ['_','0','0',':','0','0'];
    },
    SET_BRIGHTNESS_LEVEL(state, payload){
        state.brightnessLevel[0] = payload;
    },
    SET_CONTRAST_LEVEL(state, payload){
        state.contrastLevel[0] = payload;
    },
    SET_MMSI(state){
        state.mmsi = '211001781';
    },
    SET_ACKNOWLEDGEMENT_CHANNEL(state, payload){
        state.acknowledgementDetails.channel[payload.index] = payload.number;
    },
    SET_ACKNOWLEDGEMENT_COMPLY_MODE(state, payload){
        state.acknowledgementDetails.complyMode = payload;
    }
};
const actions = {
    clearOffsetTime({commit}, payload){
        commit("CLEAR_OFFSET_TIME", payload);
    },
    setPosition({commit}, payload){
        commit("SET_POSITION", payload);
    },
    setTime({commit}, payload){
        commit("SET_TIME", payload);
    },
    addAddressId({commit}, payload){
        commit("ADD_ADDRESS_ID", payload);
    },
    addManualEntry({commit}, payload){
        commit("ADD_MANUAL_ENTRY", payload);
    },
    addTempAddressNumber({commit}, payload){
        commit("ADD_TEMP_ADDRESS_NUM", payload);
    },
    setTempAddressNumberToDefault({commit}, payload){
        commit("SET_TEMP_TO_DEFAULT", payload);
    },
    setTempCategory({commit}, payload){
        commit("SET_TEMP_CATEGORY", payload);
    },
    addTempChannelNumber({commit}, payload){
        commit("SET_TEMP_CHANNEL_NUMBER", payload);
    },
    deleteAddressId({commit}, payload){
        commit("DELETE_ADDRESS_ID", payload);
    },
    addGroupId({commit}, payload){
        commit("ADD_GROUP_ID", payload);
    },
    deleteGroupAddressId({commit}, payload){
        commit("DELETE_GROUP_ID", payload);
    },
    addTimeNumber({commit}, payload){
        commit("ADD_TIME_NUMBER", payload);
    },
    setBrightnessLevel({commit}, payload){
        commit("SET_BRIGHTNESS_LEVEL", payload);
    },
    setContrastLevel({commit}, payload){
        commit("SET_CONTRAST_LEVEL", payload);
    },
    generateMMSI({commit}){
        commit("SET_MMSI");
    },
    setAcknowledgementChannel({commit}, payload){
        commit("SET_ACKNOWLEDGEMENT_CHANNEL", payload);
    },
    setAcknowledgementComplyMode({commit}, payload){
        commit("SET_ACKNOWLEDGEMENT_COMPLY_MODE", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}