<template>
    <div draggable="true" class="device-holder">
        <div class="main-device-container">
            <v-img class="speaker" :src="require('../../assets/M503_DS100/Muschel.png')" alt=""></v-img>
            <v-img class="main-device" :src="require('../../assets/M503_DS100/M503.png')" alt=""></v-img>
            <img draggable="false" class="channel-turn-button" :src="require('../../assets/M503_DS100/bDreherBig.png')" alt="">
            <img draggable="false" class="volume-turn-button" :src="require('../../assets/M503_DS100/bDreherSmall.png')" alt="">
            <img draggable="false" class="sql-turn-button" :src="require('../../assets/M503_DS100/bDreherSmall.png')" alt="">
            <v-img draggable="0" @mousedown="sendBtnPressed = true" @mouseup="sendBtnPressed = false" @mouseleave="sendBtnPressed = false" class="send-btn" :class="{'send-pressed': sendBtnPressed}" :src="currentSendBtnState"></v-img>
            <div :class="[powerIsOn ? buttonBackgroundClass : buttonBackgroundOffClass]"></div>
        </div>
        <v-img class="ds100" :src="require('../../assets/M503_DS100/DS100.png')" alt=""></v-img>

        <div class="m503-buttons">
            <button class="power-button" :class="{'power-button-pressed': powerIsPressed}" @mousedown="powerIsPressed = true" @mouseup="powerIsPressed = false" v-click="togglePower"></button>
            <button class="hilo-button" :class="{'hilo-button-pressed': hiloIsPressed}" @contextmenu.prevent="hiloIsHold = !hiloIsHold, hiloIsPressed = true" @mousedown="hiloIsPressed = true" @mouseup="hiloIsPressed = false" v-click="toggleWatt">
                <v-icon v-show="hiloIsHold" size="38" color="black">mdi-gesture-tap</v-icon>
            </button>
            <button class="dual-button" :class="{'dual-button-pressed': dualIsPressed}" @mousedown="dualIsPressed = true" @mouseup="dualIsPressed = false" v-click="toggleDual"></button>
            <button class="scan-button" :class="{'scan-button-pressed': scanIsPressed}" @mousedown="scanIsPressed = true" @mouseup="scanIsPressed = false" v-click="runScanTag" v-click.hold.1s="toggleTag"></button>
            <button class="dial-button" :class="{'dial-button-pressed': dialIsPressed}" @mousedown="dialIsPressed = true" @mouseup="dialIsPressed = false" v-click="switchToWorkingChannel"></button>
            <button class="call-button" :class="{'call-button-pressed': callIsPressed}" @mousedown="callIsPressed = true" @mouseup="callIsPressed = false" v-click="toggleCall"></button>
            <button class="backspace-button" :class="{'backspace-button-pressed': backspaceIsPressed}" @mousedown="backspaceIsPressed = true" @mouseup="backspaceIsPressed = false" v-click="pressBackspace"></button>
            <button class="dimmer-button" :class="{'dimmer-button-pressed': dimmerIsPressed}" @mousedown="dimmerIsPressed = true" @mouseup="dimmerIsPressed = false" v-click="dimm"></button>
            <button class="sixteen-button" :class="{'sixteen-button-pressed': sixteenIsPressed}" @mousedown="sixteenIsPressed = true" @mouseup="sixteenIsPressed = false" v-click="sixteen"></button>
            <button class="speaker-c-up-button" :class="{'speaker-c-up-button-pressed': speakerChannelUpIsPressed}" @mousedown="speakerChannelUpIsPressed = true" @mouseup="speakerChannelUpIsPressed = false" v-click="switchChannelUp"></button>
            <button class="speaker-c-down-button" :class="{'speaker-c-down-button-pressed': speakerChannelDownIsPressed}" @mousedown="speakerChannelDownIsPressed = true" @mouseup="speakerChannelDownIsPressed = false" v-click="switchChannelDown"></button>
            <button class="speaker-hilo-button" :class="{'speaker-hilo-button-pressed': speakerHiloIsPressed}" @mousedown="speakerHiloIsPressed = true" @mouseup="speakerHiloIsPressed = false" v-click="toggleWatt"></button>

            <div :class="[powerIsOn ? activeDisplayClass : notActiveDisplayClass]">
                <p v-show="powerIsOn" class="display-header-text mt-1">{{headerText}}</p>
                <p v-show="powerIsOn && isBusy" class="display-busy-text ml-2">BUSY</p>
                <p v-show="powerIsOn && isSending" class="display-busy-text ml-2">TX</p>
                <p v-show="powerIsOn" class="display-watt-text ml-2">{{wattText}}</p>
                <p v-show="powerIsOn" class="display-tag-text ml-2">{{channels[currentChannelIndex].isTag ? 'TAG' : ''}}</p>
                <p v-show="powerIsOn && !scanIsRunning" class="display-channel-desc-text ml-2">{{isDualMode ? 'DUAL' : channels[currentChannelIndex].description}}</p>
                <p v-show="powerIsOn && scanIsRunning" class="display-channel-desc-text ml-2">Pri scan</p>
                <p v-show="powerIsOn" class="display-channel-number-text ml-2">{{channels[currentChannelIndex].number}}</p>
                <p v-show="powerIsOn && isDualMode" class="display-dual-channel-number-text ml-2">16</p>
                <p v-show="powerIsOn && channels[currentChannelIndex].isDUP" class="display-dup-text ml-2">DUP</p>
                <p v-show="powerIsOn && isCalling" class="display-call-text ml-2">CALL</p>
            </div>
        </div>

        <div class="ds100-buttons">
            <div :class="[powerIsOn ? ds100ActiveDisplayClass : ds100NotActiveDisplayClass]">
                <div v-if="powerIsOn && d100IsHome" class="ds100-homescreen">
                    <p class="ds100-home-title">--VHF DSC CONTROLLER--</p>
                    <p class="ds100-home-watch-title">CH70 WATCHING</p>
                    <p v-if="latitudeIsNull" class="ds100-home-mnl-time">MNL: No time data</p>
                    <p v-if="latitudeIsNull" class="ds100-home-mnl-position">No position data</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-time">MNL:</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-utc">UTC</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-time-format">{{ formattedDate }}</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-lat">Lat</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-lat-format">{{'' + latitude[0] + latitude[1] + ' ° ' + latitude[2] + latitude[3] + ` ' ` + latitude[4]}}</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-lon">Lon</p>
                    <p v-if="!latitudeIsNull" class="ds100-home-mnl-lon-format">{{'' + longitude[0] + longitude[1] + longitude[2] + ' ° ' + longitude[3] + longitude[4] + ` ' ` + longitude[5]}}</p>
                </div>
                <div v-if="powerIsOn && ds100IsMenu" class="ds100-menu">
                    <p class="ds100-menu-title">{{ '<Select a subject>' }}</p>
                    <p class="ds100-menu-list-cursor">></p>
                    <ul class="ds100-menu-list">
                        <li class="ds100-menu-item" v-for="item in ds100MenuItems" :key="item.id">{{ item.title }}</li>
                    </ul>
                </div>

                <DS100IndividualCallMenuVue 
                    :isSelectingCategory="ds100IsSelectingCategory" 
                    :inputStep="ds100CurrentSelectStep" 
                    :isSelecting="ds100IsSelectingAddress" 
                    :isManualEntry="ds100IsManualEntry"
                    :isInputChannel="ds100IsInputChannel"
                    :isCalling="ds100IsCalling"
                    v-if="powerIsOn && ds100IsIndividualCallMenu"
                />

                <DS100GroupCallMenu 
                    :isSelectingAddress="ds100IsSelectingAddress" 
                    :inputStep="ds100CurrentSelectStep" 
                    :isManualEntry="ds100IsManualEntry" 
                    :isSelectingCategory="ds100IsSelectingCategory"
                    :isInputChannel="ds100IsInputChannel"
                    :isCalling="ds100IsCalling"
                    v-if="powerIsOn && ds100IsGroupCallMenu"
                />

                <DS100AllShipsCallMenu
                    :inputStep="ds100CurrentSelectStep"
                    :isSelectingCategory="ds100IsSelectingCategory"
                    :isInputChannel="ds100IsInputChannel"
                    :isCalling="ds100IsCalling"
                    v-if="powerIsOn && ds100IsAllShipsMenu"
                />

                <DS100PositionRequest
                    :isSelecting="ds100IsSelectingAddress"
                    :inputStep="ds100CurrentSelectStep"
                    :isCalling="ds100IsCalling"
                    :isManualEntry="ds100IsManualEntry"
                    v-if="powerIsOn && ds100IsPositionRequestMenu"
                />

                <DS100ReceivedCallsMenu
                    :IsSelectingMessageCategory="ds100IsSelectingCategory"
                    :IsSelectingDistressMessage="ds100IsSelectingDistressMessage"
                    :IsSelectingMessage="ds100IsSelectingMessage"
                    :IsShowMessageContent="ds100IsShowMessageContent"
                    v-if="powerIsOn && ds100IsReceivedCallsMenu"
                />

                <DS100DistressSettingsMenu
                    :isSelectingType="ds100IsSelectingCategory"
                    :submitDistress="ds100SubmitDistress"
                    :distressSubmitted="ds100DistressSubmitted"
                    v-if="powerIsOn && ds100IsDistressSettingsMenu"
                />

                <DS100SetupMenu
                    :isSelectingCategory="ds100IsSelectingCategory"
                    :isSelectingAddressIdCategory="ds100IsSelectingAddressIdCategory"
                    :addressMode="ds100AddressMode"
                    :inputStep="ds100CurrentSelectStep"
                    :entryLatitude="ds100EnterLatitude"
                    :entryLongitude="ds100EnterLongitude"
                    :addressToShowIndex="ds100CurrentSelectStep"
                    :isOffsetTime="ds100IsOffsetTime"
                    :isBrightnessMenu="ds100IsBrightnessMenu"
                    :isContrastMenu="ds100IsContrastMenu"
                    :isMMSIMenu="ds100IsMMSIMenu"
                    v-if="powerIsOn && ds100IsSetupMenu"  
                />

                <ReceiveCallMenu
                    v-if="powerIsOn && ds100IsReceiveCallMenu"
                    :mode="ds100ReceivedCallMode"
                    :selectedInputIndex="ds100CurrentSelectStep"
                    :cantComplyOptions="cantComplyOptions"
                    :clearPressed="distressClearPressed"
                />

                <div v-if="powerIsOn && ds100EntryPosScreen" class="ds100-entry-pos-time">
                    <p class="input-pos-title">{{ '<Input a position>' }}</p>
                    <p class="latitude-text">Latitude</p>
                    <div class="latitude-input">
                        <input ref="lat0" v-focus class="latitude-0-input">
                            <p class="latitude-0-number">{{ latitude[0] }}</p>
                        <input ref="lat1" class="latitude-1-input">
                            <p class="latitude-1-number">{{ latitude[1] }}</p>
                        <p class="latitude-degree">°</p>
                        <input ref="lat2" class="latitude-2-input">
                            <p class="latitude-2-number">{{ latitude[2] }}</p>
                        <input ref="lat3" class="latitude-3-input">
                            <p class="latitude-3-number">{{ latitude[3] }}</p>
                        <p class="latitude-dir">'</p>
                        <input ref="lat4" class="latitude-4-input">
                            <p class="latitude-4-number">{{ latitude[4] }}</p>
                        <p v-show="latitudeIsNull" class="lat-null">Null</p>
                    </div>
                    <p class="longitude-text">Longitude</p>
                    <div class="longitude-input">
                        <input ref="long0" class="longitude-0-input">
                            <p class="longitude-0-number">{{ longitude[0] }}</p>
                        <input ref="long1" class="longitude-1-input">
                            <p class="longitude-1-number">{{ longitude[1] }}</p>
                        <input ref="long2" class="longitude-2-input">
                            <p class="longitude-2-number">{{ longitude[2] }}</p>
                        <p class="longitude-degree">°</p>
                        <input ref="long3" class="longitude-3-input">
                            <p class="longitude-3-number">{{ longitude[3] }}</p>
                        <input ref="long4" class="longitude-4-input">
                            <p class="longitude-4-number">{{ longitude[4] }}</p>
                        <p class="longitude-dir">'</p>
                        <input ref="long5" class="longitude-5-input">
                            <p class="longitude-5-number">{{ longitude[5] }}</p>
                        <p v-show="longitudeIsNull" class="long-null">Null</p>
                    </div>
                    <p class="null-data-text">{{ '<A/a>Null data>' }}</p>
                    <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
                </div>
                <div v-if="powerIsOn && ds100EntryTimeScreen">
                    <p class="input-time-title">{{ '<Input a time>' }}</p>
                    <p class="utc-text">UTC</p>
                    <DS100Input class="time-container" :items="time" :isActive="true" :selectedIndex="ds100CurrentTimeIndex"></DS100Input>
                    <p v-show="timeIsNull" class="time-null">Null</p>
                    <p class="null-data-text">{{ '<A/a>Null data>' }}</p>
                    <p class="exit-text">{{ '<CLR->Exit / ENT->OK>' }}</p>
                </div>
            </div>
            <div :class="[powerIsOn ? ds100ButtonOnClass : ds100ButtonOffClass]">

            </div>
            <div :class="[powerIsOn ? ds100ButtonOtherOnClass : ds100ButtonOtherOffClass]">
            </div>
            <div class="cursor-container" v-if="mirrorCursor">
                <!-- Originaler Cursor -->
                <div
                class="cursor"
                :style="{ top: `${cursorPosition.y}px`, left: `${cursorPosition.x}px` }"
                ></div>

                <!-- Gespiegelter Cursor -->
                <div
                class="mirror-cursor"
                :style="{ top: `${mirrorPosition.y}px`, left: `${mirrorPosition.x}px` }"
                ><v-icon color="white" size="28">mdi-cursor-pointer</v-icon></div>
            </div>
            <div class="cursor-container" v-if="mirrorCursorReversed">
                <!-- Originaler Cursor -->
                <div
                class="cursor"
                :style="{ top: `${cursorPosition.y}px`, left: `${cursorPosition.x}px` }"
                ></div>

                <!-- Gespiegelter Cursor -->
                <div
                class="mirror-cursor"
                :style="{ top: `${reversedMirrorPosition.y}px`, left: `${reversedMirrorPosition.x}px` }"
                ><v-icon color="white" size="28">mdi-cursor-pointer</v-icon></div>
            </div>
            <button @click="ds100ToggleMenu" @mouseover="checkReversedMirrorAvailibility" @mouseleave="mirrorCursorReversed = false" @mousedown="ds100CallIsPressed = true" @mouseup="ds100CallIsPressed = false" class="ds100-call-btn" :class="{'ds100-call-btn-pressed': ds100CallIsPressed}"></button>
            <button @click="ds100Enter" @mouseover="checkMirrorAvailibility" @mouseleave="mirrorCursor = false" @mousedown="ds100EnterIsPressed = true" @mouseup="ds100EnterIsPressed = false" class="ds100-enter-btn" :class="{'ds100-enter-btn-pressed': ds100EnterIsPressed}"></button>
            <button @click="ds100Clear" @mousedown="ds100ClearIsPressed = true" @mouseup="ds100ClearIsPressed = false" class="ds100-clear-btn" :class="{'ds100-clear-btn-pressed': ds100ClearIsPressed}"></button>
            <button @click="ds100ArrowDown" @mousedown="ds100ArrowDownIsPressed = true" @mouseup="ds100ArrowDownIsPressed = false" class="ds100-arrow-down-btn" :class="{'ds100-arrow-down-btn-pressed': ds100ArrowDownIsPressed}"></button>
            <button @click="ds100ArrowUp" @mousedown="ds100ArrowUpIsPressed = true" @mouseup="ds100ArrowUpIsPressed = false" class="ds100-arrow-up-btn" :class="{'ds100-arrow-up-btn-pressed': ds100ArrowUpIsPressed}"></button>
            <button @click="ds100ArrowLeft" @mousedown="ds100ArrowLeftIsPressed = true" @mouseup="ds100ArrowLeftIsPressed = false" class="ds100-arrow-left-btn" :class="{'ds100-arrow-left-btn-pressed': ds100ArrowLeftIsPressed}"></button>
            <button @click="ds100ArrowRight" @mousedown="ds100ArrowRightIsPressed = true" @mouseup="ds100ArrowRightIsPressed = false" class="ds100-arrow-right-btn" :class="{'ds100-arrow-right-btn-pressed': ds100ArrowRightIsPressed}"></button>

            <!-- Zifferblock -->
            <button @click="ds100PressNumber(0)" @mousedown="ds100Numbers[0].pressed = true" @mouseup="ds100Numbers[0].pressed = false" class="ds100-0-btn" :class="{'ds100-0-btn-pressed': ds100Numbers[0].pressed}"></button>
            <button @click="ds100PressNumber(1)" @mousedown="ds100Numbers[1].pressed = true" @mouseup="ds100Numbers[1].pressed = false" class="ds100-1-btn" :class="{'ds100-1-btn-pressed': ds100Numbers[1].pressed}"></button>
            <button @click="ds100PressNumber(2)" @mousedown="ds100Numbers[2].pressed = true" @mouseup="ds100Numbers[2].pressed = false" class="ds100-2-btn" :class="{'ds100-2-btn-pressed': ds100Numbers[2].pressed}"></button>
            <button @click="ds100PressNumber(3)" @mousedown="ds100Numbers[3].pressed = true" @mouseup="ds100Numbers[3].pressed = false" class="ds100-3-btn" :class="{'ds100-3-btn-pressed': ds100Numbers[3].pressed}"></button>
            <button @click="ds100PressNumber(4)" @mousedown="ds100Numbers[4].pressed = true" @mouseup="ds100Numbers[4].pressed = false" class="ds100-4-btn" :class="{'ds100-4-btn-pressed': ds100Numbers[4].pressed}"></button>
            <button @click="ds100PressNumber(5)" @mousedown="ds100Numbers[5].pressed = true" @mouseup="ds100Numbers[5].pressed = false" class="ds100-5-btn" :class="{'ds100-5-btn-pressed': ds100Numbers[5].pressed}"></button>
            <button @click="ds100PressNumber(6)" @mousedown="ds100Numbers[6].pressed = true" @mouseup="ds100Numbers[6].pressed = false" class="ds100-6-btn" :class="{'ds100-6-btn-pressed': ds100Numbers[6].pressed}"></button>
            <button @click="ds100PressNumber(7)" @mousedown="ds100Numbers[7].pressed = true" @mouseup="ds100Numbers[7].pressed = false" class="ds100-7-btn" :class="{'ds100-7-btn-pressed': ds100Numbers[7].pressed}"></button>
            <button @click="ds100PressNumber(8)" @mousedown="ds100Numbers[8].pressed = true" @mouseup="ds100Numbers[8].pressed = false" class="ds100-8-btn" :class="{'ds100-8-btn-pressed': ds100Numbers[8].pressed}"></button>
            <button @click="ds100PressNumber(9)" @mousedown="ds100Numbers[9].pressed = true" @mouseup="ds100Numbers[9].pressed = false" class="ds100-9-btn" :class="{'ds100-9-btn-pressed': ds100Numbers[9].pressed}"></button>

            <div class="distress-btn-background"></div>
            <div class="distress-btn" v-click.hold.5s="execDistress" @mousedown="ds100DistressPressed = true" @mouseup="ds100DistressPressed = false" :class="{'distress-btn-pressed': ds100DistressPressed}"></div>
            <button @click="ds100PressUpperLowerCase" @mousedown="ds100UpperLowerCasePressed = true" @mouseup="ds100UpperLowerCasePressed = false" class="ds100-upper-lower-btn" :class="{'ds100-upper-lower-btn-pressed': ds100UpperLowerCasePressed}"></button>
        </div>
        <audio ref="beep" preload="auto" :src="beepSound"></audio>
        <audio ref="beepFalsch" preload="auto" :src="beepFalschSound"></audio>
        <audio ref="beepOk" preload="auto" :src="beepOkSound"></audio>
        <audio ref="rauschen" preload="auto" :src="rauschenSound"></audio>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import DS100Input from './base/DS100Input.vue';
    import DS100IndividualCallMenuVue from './IndividualCallMenu/DS100IndividualCallMenu.vue';
    import DS100GroupCallMenu from './GroupCallMenu/DS100GroupCallMenu.vue'
    import DS100AllShipsCallMenu from './AllShipsCallMenu/AllShipsCallMenu.vue'
    import DS100PositionRequest from './PositionRequestMenu/PositionRequestMenu.vue'
    import DS100ReceivedCallsMenu from './ReceivedCallsMenu/ReceivedCallsMenu.vue'
    import DS100DistressSettingsMenu from './DistressSettings/DistressSettingsMenu.vue'
    import DS100SetupMenu from './SetUp/DS100SetupMenu.vue'
    import ReceiveCallMenu from './base/ReceiveCallMenu.vue'

    const focusDirective = {
        mounted(el) {
            el.focus();
        }
    };

    export default defineComponent ({
        computed: {
            powerIsOn(){
                return this.$store.getters['m503/powerIsOn'];
            },
            latitude(){
                return this.$store.getters['ds100/position'].latitude;
            },
            longitude(){
                return this.$store.getters['ds100/position'].longitude;
            },
            time(){
                return this.$store.getters['ds100/time'];
            },
            formattedDate() {
                const timeArray = this.time // Hier das Array mit der Zeit eintragen
                const offsetTimeArray = this.offsetTime // Hier das Array mit der Offset-Zeit eintragen

                // Extrahiere Stunden und Minuten aus dem timeArray
                const hours = parseInt(JSON.stringify(timeArray[0]) + JSON.stringify(timeArray[1]));
                const minutes = parseInt(JSON.stringify(timeArray[3]) + JSON.stringify(timeArray[4]));

                // Erstelle ein Date-Objekt mit dem heutigen Datum
                const currentDate = new Date();

                // Setze die Stunden und Minuten des Date-Objekts basierend auf dem timeArray
                currentDate.setHours(hours);
                currentDate.setMinutes(minutes);

                // Extrahiere Stunden und Minuten aus dem offsetTimeArray
                const offsetHours = parseInt(offsetTimeArray[1] + offsetTimeArray[2]);
                const offsetMinutes = parseInt(offsetTimeArray[4] + offsetTimeArray[5]);

                // Berechne die Offset-Zeit in Millisekunden
                const offsetTime = (offsetHours * 60 + offsetMinutes) * 60000;

                var finalDate = new Date();
                // Füge die Offset-Zeit hinzu oder subtrahiere sie
                if (offsetTimeArray[0] === '-') {
                    finalDate = new Date(currentDate.getTime() - offsetTime);
                } else {
                    finalDate = new Date(currentDate.getTime() + offsetTime);
                }

                const formattedHours = finalDate.getHours().toString().padStart(2, '0');
                const formattedMinutes = finalDate.getMinutes().toString().padStart(2, '0');

                // Formatieren des finalen Date-Objekts als einzelnen String
                const formattedString = `${formattedHours}:${formattedMinutes}`;

                return formattedString;
            },
            latitudeIsNull(){
                return JSON.stringify(this.latitude) === JSON.stringify(['_','_','_','_','N']); 
            },
            longitudeIsNull(){
                return JSON.stringify(this.longitude) === JSON.stringify(['_','_','_','_','_','E']); 
            },
            timeIsNull(){
                return JSON.stringify(this.time) === JSON.stringify(['_','_',':','_','_']);
            },
            addressIds(){
                return this.$store.getters['ds100/addressIds'];
            },
            categories(){
                return this.$store.getters['ds100/categories'];
            },
            manualIndividualCall(){
                return this.$store.getters['ds100/manualIndividualCall'];
            },
            groupAddressIds(){
                return this.$store.getters['ds100/groupAddressIds'];
            },
            manualGroupCall(){
                return this.$store.getters['ds100/manualGroupCall'];
            },
            allShipCall(){
                return this.$store.getters['ds100/allShipsCall'];
            },
            positionRequest(){
                return this.$store.getters['ds100/positionRequest'];
            },
            receivedMessages(){
                return this.$store.getters['ds100/receivedMessages'];
            },
            receivedDistressMessages(){
                return this.$store.getters['ds100/receivedDistressMessages'];
            },
            distressTypes(){
                return this.$store.getters['ds100/distressTypes'];
            },
            addressToAdd(){
                return this.$store.getters['ds100/addressToAdd'];
            },
            groupAddressToAdd(){
                return this.$store.getters['ds100/groupAddressToAdd'];
            },
            offsetTime(){
                return this.$store.getters['ds100/offsetTime'];
            },
            receivingSignal(){
                return this.$store.getters['m503/isReceivingSignal'];
            },
            currentSendBtnState(){
                if(this.sendBtnPressed)
                    return require('../../assets/M503_DS100/bPttDown.png');
                return require('../../assets/M503_DS100/bPttUp.png');
            },
            m423IsReceivingSignal(){
                return this.$store.getters['m423/isReceivingSignal'];
            },
            receivedSignalSenderDetails(){
                return this.$store.getters['m503/receivedSignalSenderDetails'];
            },
            isTransmittingCall(){
                return this.$store.getters['transmittedCall'].isTransmitting;
            },
            transmittedCall(){
                return this.$store.getters['transmittedCall'];
            },
            mmsi(){
                return this.$store.getters['ds100/mmsi'];
            },
            acknowledgementDetails(){
                return this.$store.getters['ds100/acknowledgementDetails'];
            }
        },
        directives: {
            focus: focusDirective
        },
        components: {
            DS100Input,
            DS100IndividualCallMenuVue,
            DS100GroupCallMenu,
            DS100AllShipsCallMenu,
            DS100PositionRequest,
            DS100ReceivedCallsMenu,
            DS100DistressSettingsMenu,
            DS100SetupMenu,
            ReceiveCallMenu,
        },
        beforeUnmount() {
            window.removeEventListener("mousemove", this.updateCursorPosition);
        },
        mounted(){
            this.$store.dispatch('ds100/generateMMSI');
            window.addEventListener("mousemove", this.updateCursorPosition);
                const channelButton = document.querySelector('.channel-turn-button');
                const volumeButton = document.querySelector('.volume-turn-button');
                const sqlButton = document.querySelector('.sql-turn-button');
                sqlButton.style.transform = `rotate(${180}deg)`;
                volumeButton.style.transform = `rotate(${300}deg)`;

                this.$refs.rauschen.volume = 1;

                const rotateVolumeButton = (e) => {
                    e.preventDefault();
                    if(this.powerIsOn){
                        const deltaY = e.deltaY;
                        const currentRotation = this.getRotationDegrees(volumeButton.style.transform);

                        if(deltaY < 0 && currentRotation < 300){
                            volumeButton.style.transform = `rotate(${currentRotation + 30 }deg)`;
                            this.$refs.rauschen.volume += 0.1;
                            this.volumeValue++;
                        }
                        else if(deltaY > 0 && currentRotation > 0){
                            volumeButton.style.transform = `rotate(${currentRotation - 30 }deg)`;
                            this.$refs.rauschen.volume -= 0.1;
                            this.volumeValue--;
                        }
                    }
                }

                const rotateSqlButton = (e) => {
                    e.preventDefault();
                    if(this.powerIsOn){
                        const deltaY = e.deltaY;
                        const currentRotation = this.getRotationDegrees(sqlButton.style.transform);
                        
                        if(deltaY < 0 && currentRotation < 300){
                            sqlButton.style.transform = `rotate(${currentRotation + 30 }deg)`;

                            if(currentRotation + 30 <= 90){                              
                                this.$refs.rauschen.play();
                                this.$refs.rauschen.loop = true;
                                this.isBusy = true;
                            }
                            else{
                                if(this.$refs.rauschen){
                                    this.$refs.rauschen.loop = false;
                                    this.$refs.rauschen.pause();
                                    this.$refs.rauschen.currentTime = 0;
                                    this.isBusy = false;
                                }
                            }
                        }
                        else if(deltaY > 0 && currentRotation > 0){
                            sqlButton.style.transform = `rotate(${currentRotation - 30 }deg)`;

                            if(currentRotation - 30 <= 90){
                                this.$refs.rauschen.play();
                                this.$refs.rauschen.loop = true;
                                this.isBusy = true;
                            }
                            else{
                                if(this.$refs.rauschen){
                                    this.$refs.rauschen.loop = false;
                                    this.$refs.rauschen.pause();
                                    this.$refs.rauschen.currentTime = 0;
                                    this.isBusy = false;
                                }
                            }
                        }
                    }
                }

                const rotateChannelButton = (e) => {
                    e.preventDefault();
                    if(this.powerIsOn){
                        const deltaY = e.deltaY;
                        const currentRotation = this.getRotationDegrees(channelButton.style.transform);

                        var currentIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                        channelButton.style.transform = `rotate(${deltaY > 0 ? currentRotation - 30 : currentRotation + 30}deg)`;

                        if(this.channelNumber == '88' && deltaY < 0){
                            this.channelNumber = '01';
                        }
                        else if (this.channelNumber < '88' && deltaY < 0){
                            this.channelNumber = this.channels[currentIndex + 1].number;
                        }
                        
                        if(this.channelNumber == '88' && deltaY > 0){
                            this.channelNumber -= 1;
                        }
                        else if (this.channelNumber < '88' && deltaY > 0){
                            this.channelNumber = this.channels[currentIndex - 1].number;
                        }

                        if(this.channelNumber == '01' && deltaY > 0){
                            this.channelNumber = '88';
                        }

                        this.currentChannelIndex = currentIndex;
                        this.playTurnSound();
                    }
                }

                channelButton.addEventListener("wheel", (event) => {
                    event.preventDefault();
                    rotateChannelButton(event);
                });

                volumeButton.addEventListener("wheel", (event) => {
                    event.preventDefault();
                    rotateVolumeButton(event);
                });

                sqlButton.addEventListener("wheel", (event) => {
                    event.preventDefault();
                    rotateSqlButton(event);
                });
        },
        data() {
            return{
                powerIsPressed: false,
                activeDisplayClass: 'display-container-m503',
                notActiveDisplayClass: 'display-container-off-m503',
                buttonBackgroundClass: 'button-background',
                buttonBackgroundOffClass: 'button-background-off',
                ds100ActiveDisplayClass: 'ds100-display',
                ds100NotActiveDisplayClass: 'ds100-display-off',
                ds100ButtonOnClass: 'ds100-button-background-1',
                ds100ButtonOffClass: 'ds100-button-background-1-off',
                ds100ButtonOtherOnClass: 'ds100-button-background-2',
                ds100ButtonOtherOffClass: 'ds100-button-background-2-off',
                headerText: 'INT/DSC',
                wattText: '25W',
                tagText: 'TAG',
                channelDescText: 'SELEKTIV',
                channelNumber: '16',
                currentChannelIndex: 15,
                lastDeg: 0,
                hiloIsPressed: false,
                dualIsPressed: false,
                isDualMode: false,
                scanIsPressed: false,
                scanIsRunning: false,
                dialIsPressed: false,
                lastWorkingChannelIndex: 12,
                callIsPressed: false,
                dimmerIsPressed: false,
                dimmIndex: 3,
                sixteenIsPressed: false,
                speakerChannelUpIsPressed: false,
                speakerChannelDownIsPressed: false,
                speakerHiloIsPressed: false,
                isBusy: false,
                hiloIsHold: false,
                highestStayOnChannel: {index: 15, time: 0},
                lastChannelBeforeSwitchTo16: null,
                isCalling: false,
                sendBtnPressed: false,
                volumeValue: 10,
                beepSound: require("../../assets/M503_DS100/sounds/beepOk2.mp3"),
                beepFalschSound: require("../../assets/M503_DS100/sounds/beepFalsch.mp3"),
                beepOkSound: require("../../assets/M503_DS100/sounds/beepOk.mp3"),
                rauschenSound: require("../../assets/M503_DS100/sounds/rauschen.mp3"),
                d100IsHome: true,
                ds100IsMenu: false,
                ds100CallIsPressed: false,
                ds100ArrowDownIsPressed: false,
                ds100CurrentMenuStep: 1,
                ds100ArrowUpIsPressed: false,
                ds100ArrowLeftIsPressed: false,
                ds100ArrowRightIsPressed: false,
                ds100EnterIsPressed: false,
                ds100ClearIsPressed: false,
                ds100EntryPosScreen: false,
                ds100CurrentLatIndex: 0,
                ds100CurrentLongIndex: 0,
                ds100lastFocusedLat: null,
                ds100EnterLatitude: false,
                ds100EnterLongitude: false,
                ds100UpperLowerCasePressed: false,
                ds100EntryTimeScreen: false,
                ds100CurrentTimeIndex: 0,
                ds100IsIndividualCallMenu: false,
                ds100CurrentSelectStep: 0,
                ds100IsSelectingAddress: true,
                ds100IsManualEntry: false,
                ds100IsSelectingCategory: false,
                ds100CategoryStep: 0,
                ds100IsInputChannel: false,
                ds100IsCalling: false,
                ds100IsGroupCallMenu: false,
                ds100IsReceiveCallMenu: false,
                mirrorCursor: false,
                cursorPosition: { x: 0, y: 0 },
                mirrorPosition: { x: 0, y: 0 },
                reversedMirrorPosition: { x: 0, y: 0 },
                isSending: false,
                ds100IsAllShipsMenu: false,
                ds100IsPositionRequestMenu: false,
                oldChannel: 0,
                ds100IsReceivedCallsMenu: false,
                ds100IsSelectingMessage: false,
                ds100IsSelectingDistressMessage: false,
                ds100IsShowMessageContent: false,
                ds100IsDistressSettingsMenu: false,
                ds100SubmitDistress: false,
                ds100DistressPressed: false,
                ds100DistressSubmitted: false,
                ds100IsSetupMenu: false,
                ds100IsSelectingAddressIdCategory: false,
                ds100AddressMode: null,
                ds100IsOffsetTime: false,
                ds100IsBrightnessMenu: false,
                ds100IsContrastMenu: false,
                ds100IsMMSIMenu: false,
                ds100ReceivedCallMode: '',
                audioContext: null,
                microphoneStream: null,
                audioInput: null,
                gainNode: null,
                currentLetterIndex: 0,
                lastNumber: 0,
                isLowerCase: false,
                backspaceIsPressed: false,
                mirrorCursorReversed: false,
                distressCounter: 0,
                distressInterval: null,
                distressClearPressed: false,

                dimmSteps: ["#5f613e","#9f6921","#aa6b1e","#b56e1c","#cb7316","#d67513","#ed841c"],
                ds100DimmSteps: ['#9f6921','#aa6b1e','#b56e1c','#c07019','#cb7316','#d67513','#ed841c','#ed841c'],
                ds100ContrastSteps: ['#fb922a','#f68d25','#f28921','#ed841c','#e98018','#e47b13','#e0770f','#db720a'],
                ds100ReceivedCallTrafficChannel: ['_','_','_'],
                cantComplyOptions: ['No reason given', 'Congestion', 'Busy', 'Queue Indication', 'Station barred', 'No operator', 'Operator Unavailable', 'Equipment disable', 'Channel unable', 'Mode unable'],

                ds100Numbers: [
                    {number: '0', pressed: false, letters: ['-','/','.']},
                    {number: '1', pressed: false, letters: ['Q','Z']},
                    {number: '2', pressed: false, letters: ['A','B','C']},
                    {number: '3', pressed: false, letters: ['D','E','F']},
                    {number: '4', pressed: false, letters: ['G','H','I']},
                    {number: '5', pressed: false, letters: ['J','K','L']},
                    {number: '6', pressed: false, letters: ['M','N','O']},
                    {number: '7', pressed: false, letters: ['P','R','S']},
                    {number: '8', pressed: false, letters: ['T','U','V']},
                    {number: '9', pressed: false, letters: ['W','X','Y']},                 
                ],

                channels: [
                    { number: '01', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '02', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '03', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '04', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '05', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '06', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '07', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '08', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '09', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '10', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '11', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '12', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '13', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '14', description: 'SCH-HAFEN', isTag: true, isDUP: false },
                    { number: '15', description: 'AN-BORD', isTag: true, isDUP: false },
                    { number: '16', description: 'SELEKTIV', isTag: true, isDUP: false },
                    { number: '17', description: 'AN-BORD', isTag: true, isDUP: false },
                    { number: '18', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '19', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '20', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '21', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '22', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '23', description: 'INTL', isTag: true, isDUP: true },
                    { number: '24', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '25', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '26', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '27', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '28', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '60', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '61', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '62', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '63', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '64', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '65', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '66', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '67', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '68', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '69', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '71', description: 'SCH-HAFEN', isTag: true, isDUP: false },
                    { number: '72', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '73', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '74', description: 'SCH-HAFEN', isTag: true, isDUP: false },
                    { number: '75', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '76', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '77', description: 'SCH-SCHIFF', isTag: true, isDUP: false },
                    { number: '78', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '79', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '80', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '81', description: 'NAUTIK', isTag: true, isDUP: true },
                    { number: '82', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '83', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '84', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '85', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '86', description: 'TELEPHONE', isTag: true, isDUP: true },
                    { number: '87', description: 'NAUTIK', isTag: true, isDUP: false },
                    { number: '88', description: 'NAUTIK', isTag: true, isDUP: false },
                ],

                ds100MenuItems: [
                    {id:0, title: 'Entry position/time'},
                    {id:1, title: 'Individual call'},
                    {id:2, title: 'Group call'},
                    {id:3, title: 'All ships call'},
                    {id:4, title: 'Position request'},
                    {id:5, title: 'Received calls'},
                    {id:6, title: 'Distress settings'},
                    {id:7, title: 'Set-up'},
                ]
            }
        },
        watch: {
            isTransmittingCall(newVal){
                if(newVal && this.transmittedCall.senderMmsi != this.mmsi){
                    if(this.transmittedCall.receiverMmsi == this.mmsi && this.transmittedCall.mode == 'Individual Call'){
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = false;
                        this.ds100IsReceiveCallMenu = true;
                        this.ds100ReceivedCallMode = 'IND Overview';
                    }
                    else if(this.transmittedCall.mode == 'Group Call'){
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = false;
                        this.ds100IsReceiveCallMenu = true;
                        this.ds100ReceivedCallMode = 'GRP Overview';
                    }
                    else if(this.transmittedCall.mode == 'All Ships Call'){
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = false;
                        this.ds100IsReceiveCallMenu = true;
                        this.ds100ReceivedCallMode = 'All Ships Overview';
                    }
                    else if(this.transmittedCall.mode == 'Distress Alert'){
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = false;
                        this.ds100IsReceiveCallMenu = true;
                        this.ds100ReceivedCallMode = 'Distress Call Overview';
                    }
                    else if(this.transmittedCall.mode == 'Distress Cancel'){
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = false;
                        this.ds100IsReceiveCallMenu = true;
                        this.ds100ReceivedCallMode = 'Distress Cancel Overview';
                    }
                    else if(this.transmittedCall.receiverMmsi == this.mmsi && this.transmittedCall.mode == 'Individual Ack'){
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = false;
                        this.ds100IsReceiveCallMenu = true;
                        this.ds100ReceivedCallMode = 'IND ACK';
                    }
                    this.$store.dispatch('setTransmittedCall', {
                        mode: this.transmittedCall.mode,
                        isTransmitting: false,
                        senderMmsi: this.transmittedCall.senderMmsi,
                        receiverMmsi: this.transmittedCall.receiverMmsi,
                        category: this.transmittedCall.category,
                        channel: this.transmittedCall.channel,
                    });
                }
            },
            ds100DistressPressed(newValue){
                if(newValue){
                    if(!this.distressInterval && this.distressCounter < 5){
                        this.distressInterval = setInterval(() => {
                            if(this.distressCounter < 5){
                                this.ds100GoToMenu();
                                this.ds100IsMenu = false;
                                this.d100IsHome = false;
                                this.ds100IsDistressSettingsMenu = true;
                                this.ds100SubmitDistress = true;
                                this.distressCounter++;
                                this.$refs.beepOk.play();
                            }
                            else{
                                this.ds100SubmitDistress = false;
                                this.ds100DistressSubmitted = true;
                                this.oldChannel = this.channelNumber;
                                this.channels.push(
                                    { number: '70', description: 'Under DSC', isTag: true, isDUP: false },
                                )
                                this.channelNumber = 70;
                                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);

                                setTimeout(() => {
                                    this.currentChannelIndex = 15;
                                    this.$store.dispatch('setTransmittedCall', {
                                        mode: 'Distress Alert',
                                        isTransmitting: true,
                                        senderMmsi: this.mmsi,
                                        lat: this.latitude.join(''),
                                        lon: this.longitude.join(''),
                                        utc: this.formattedDate,
                                        channel: '16',
                                    });
                                }, 2000);
                                clearInterval(this.distressInterval);
                            }
                        }, 1000);
                    }
                    else{
                        this.distressCounter = 0;
                        clearInterval(this.distressInterval);
                        this.distressInterval = null;
                    }
                }
                else{
                    if(this.ds100SubmitDistress){
                        this.d100IsHome = true;
                        this.ds100IsDistressSettingsMenu = false;
                        this.ds100SubmitDistress = false;
                        this.ds100DistressSubmitted = false;
                    }
                    this.distressCounter = 0;
                    clearInterval(this.distressInterval);
                }
            },
            ds100EnterIsPressed(newValue) {
                if (newValue && (this.mirrorCursor || this.mirrorCursorReversed)) {
                    this.ds100CallIsPressed = true;
                }
                else{
                    this.ds100CallIsPressed = false;
                }
            },
            ds100CallIsPressed(newValue) {
                if (newValue && (this.mirrorCursor || this.mirrorCursorReversed)) {
                    this.ds100EnterIsPressed = true;
                    this.ds100Enter();
                }
                else{
                    this.ds100EnterIsPressed = false;
                }
            },
            receivingSignal(newVal){
                setTimeout(() => {
                    if(newVal && this.receivedSignalSenderDetails.channelNumber == this.channels[this.currentChannelIndex].number){
                        this.isBusy = true;
                        this.playMicrophone();
                    }
                    else{
                        this.isBusy = false;
                        this.disconnectMicrophone();
                    }
                }, 1000);
            },
            sendBtnPressed(newVal){
                if(newVal){
                    if(!this.m423IsReceivingSignal)
                        this.$store.dispatch('m423/receiveSignal', {
                            isSending: true,
                            mmsi: this.mmsi,
                            channelNumber: this.channels[this.currentChannelIndex].number,
                        });
                    this.isSending = true;
                }
                else {
                    if(this.m423IsReceivingSignal)
                        this.$store.dispatch('m423/receiveSignal', {
                            isSending: false,
                            mmsi: '',
                            channelNumber: 0,
                        });
                    this.isSending = false;
                }
            },
            isBusy(newVal){
                if(this.powerIsOn && newVal){
                    this.$refs.rauschen.play();
                    this.$refs.rauschen.loop = true;
                    this.$refs.rauschen.volume = this.mapValue(this.volumeValue, 0, 10, 0, 1) >= 0 ? this.mapValue(this.volumeValue, 0, 10, 0, 1) : 0;
                }
                else{
                    this.$refs.rauschen.loop = false;
                    this.$refs.rauschen.pause();
                    this.$refs.rauschen.currentTime = 0;
                }
            }
        },
        methods: {
            async playMicrophone(){
                this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
                this.microphoneStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.audioInput = this.audioContext.createMediaStreamSource(this.microphoneStream);
                this.gainNode = this.audioContext.createGain();
                this.audioInput.connect(this.gainNode);
                this.gainNode.connect(this.audioContext.destination);
                
                this.updateVolume();
            },
            disconnectMicrophone(){
                if (this.gainNode) {
                    this.gainNode.disconnect();
                }
                if (this.audioInput) {
                    this.audioInput.disconnect();
                }
                if (this.microphoneStream) {
                    this.microphoneStream.getTracks().forEach(track => track.stop());
                }
                if (this.audioContext) {
                    this.audioContext.close();
                }
            },
            updateVolume() {
                if (this.gainNode) {
                    let mappedValue = this.mapValue(this.volumeValue, 0, 10, 0, 1);
                    this.gainNode.gain.value = mappedValue;
                }
                requestAnimationFrame(this.updateVolume);
            },
            mapValue(value, minValue1, maxValue1, minValue2, maxValue2) {
                return (value - minValue1) * (maxValue2 - minValue2) / (maxValue1 - minValue1) + minValue2;
            },
            updateCursorPosition(event) {
                if(this.mirrorCursor){
                    // Aktualisiere die Position des Cursors
                    this.cursorPosition = { x: event.clientX, y: event.clientY };

                    // Berechne die Position des gespiegelten Cursors
                    this.mirrorPosition = {
                        x: this.cursorPosition.x - 70, // 50px links vom originalen Cursor
                        y: this.cursorPosition.y,
                    };
                }
                else if(this.mirrorCursorReversed){
                    this.cursorPosition = { x: event.clientX, y: event.clientY };

                    // Berechne die Position des gespiegelten Cursors
                    this.reversedMirrorPosition = {
                        x: this.cursorPosition.x + 50, // 50px links vom originalen Cursor
                        y: this.cursorPosition.y,
                    };
                }
            },
            checkMirrorAvailibility(){
                if(this.ds100IsCalling || this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == "IND Exec"){
                    this.mirrorCursor = true;
                }
            },
            checkReversedMirrorAvailibility(){
                if(this.ds100IsCalling || this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == "IND Exec"){
                    this.mirrorCursorReversed = true;
                }
            },
            toggleCall(){
                switch(this.headerText){
                    case "INT/DSC":{
                        this.currentChannelIndex = 5;
                        this.channelNumber = this.channels[this.currentChannelIndex].number;
                        this.isCalling = true;
                        break;
                    }
                    case "ATIS":{
                        this.currentChannelIndex = 9;
                        this.channelNumber = this.channels[this.currentChannelIndex].number;
                        this.isCalling = true;
                        break;
                    }
                }
                this.$refs.beep.play();
            },
            getRotationDegrees(transform) {
                if (!transform) {
                    return 0;
                }
                const match = transform.match(/rotate\(([-]?\d+)deg\)/);
                return match ? Number(match[1]) : 0;
            },
            switchChannelUp(){
                if(this.powerIsOn){
                    this.isCalling = false;
                    this.$refs.beep.play();
                    if(this.currentChannelIndex == (this.channels.length - 1)){
                        this.currentChannelIndex = 0;
                        this.channelNumber = this.channels[this.currentChannelIndex].number;
                    }
                    else{
                        this.currentChannelIndex += 1;
                        this.channelNumber = this.channels[this.currentChannelIndex].number;
                    }
                }
            },
            switchChannelDown(){
                if(this.powerIsOn){
                    this.isCalling = false;
                    this.$refs.beep.play();
                    if(this.currentChannelIndex == 0){
                        this.currentChannelIndex = this.channels.length - 1;
                        this.channelNumber = this.channels[this.currentChannelIndex].number;
                    }
                    else{
                        this.currentChannelIndex -= 1;
                        this.channelNumber = this.channels[this.currentChannelIndex].number;
                    }       
                }
            },
            sixteen(){
                this.isCalling = false;
                this.lastChannelBeforeSwitchTo16 = this.currentChannelIndex;
                this.scanIsRunning = false;
                this.currentChannelIndex = 15;
                this.channelNumber = '16';
                this.$refs.beep.play();
            },
            dimm(){
                if(this.powerIsOn){
                    const display = document.querySelector('.display-container-m503');
                    const buttonBackground = document.querySelector('.button-background');
                    if(this.dimmSteps[this.dimmIndex + 1]){
                        display.style.backgroundColor = this.dimmSteps[this.dimmIndex + 1];
                        buttonBackground.style.backgroundColor = this.dimmSteps[this.dimmIndex + 1];
                        this.dimmIndex += 1;
                    }
                    else{
                        display.style.backgroundColor = this.dimmSteps[0];
                        buttonBackground.style.backgroundColor = 'transparent';
                        this.dimmIndex = 0;
                    }
                    this.$refs.beep.play();
                }
            },
            dimmDS100(level){
                if(this.powerIsOn){
                    const display = document.querySelector('.ds100-display');
                    const buttonBackground = document.querySelector('.ds100-button-background-1');
                    const buttonBackground2 = document.querySelector('.ds100-button-background-2');
                    if(this.dimmSteps[level - 1]){
                        display.style.backgroundColor = this.dimmSteps[level - 1];
                        buttonBackground.style.backgroundColor = this.dimmSteps[level - 1];
                        buttonBackground2.style.backgroundColor = this.dimmSteps[level - 1];
                    }
                    this.$refs.beep.play();
                }
            },
            setContrastDS100(level){
                if(this.powerIsOn){
                    const display = document.querySelector('.ds100-display');
                    if(this.ds100ContrastSteps[level - 1]){
                        display.style.backgroundColor = this.ds100ContrastSteps[level - 1];
                    }
                    this.$refs.beep.play();
                }
            },
            switchToWorkingChannel(){
                if(this.powerIsOn)
                {
                    this.isCalling = false;
                    if(this.hiloIsHold){
                        switch(this.headerText)
                        {
                            case "ATIS":{
                                this.headerText = "INT/DSC";
                                break;
                            }
                            case "INT/DSC":{
                                this.headerText = "ATIS";
                                break;
                            }
                        }
                        this.hiloIsHold = false;
                        this.hiloIsPressed = false;
                    }
                    else{
                        this.$refs.beep.play();

                        if(this.lastChannelBeforeSwitchTo16){
                            this.currentChannelIndex = this.lastChannelBeforeSwitchTo16;
                            this.channelNumber = this.channels[this.currentChannelIndex].number;
                        }
                        else{
                            this.currentChannelIndex = this.highestStayOnChannel.index;
                            this.channelNumber = this.channels[this.currentChannelIndex].number;
                        }
                    }
                }
            },
            async playTurnSound(){
                if(this.powerIsOn){
                    this.isCalling = false;
                    this.$refs.beep.play();
                    
                    var currentChannelTime = 0;
                    var channel = this.currentChannelIndex;
                    while(this.powerIsOn && this.currentChannelIndex == channel){
                        currentChannelTime += 1;
                        if(this.highestStayOnChannel.time <= currentChannelTime){
                            this.highestStayOnChannel.time = currentChannelTime;
                            this.highestStayOnChannel.index = channel;
                        }
                        await this.wait(1000);
                    }
                }
            },
            async wait(milliseconds) {
                return new Promise(resolve => setTimeout(resolve, milliseconds));
            },
            togglePower(){
                this.isCalling = false;
                this.$refs.beep.play();
                this.$store.dispatch('m503/togglePower');
                if(!this.powerIsOn){
                    const display = document.querySelector('.display-container-m503');
                    const buttonBackground = document.querySelector('.button-background');
                    const ds100Display = document.querySelector('.ds100-display');
                    ds100Display.style.backgroundColor = this.dimmSteps[0];
                    display.style.backgroundColor = this.dimmSteps[0];
                    buttonBackground.style.backgroundColor = 'transparent';
                    this.$refs.rauschen.pause();
                }
                else{
                    const display = document.querySelector('.display-container-off-m503');
                    const buttonBackground = document.querySelector('.button-background-off');
                    const sqlButton = document.querySelector('.sql-turn-button');
                    const ds100Display = document.querySelector('.ds100-display-off');
                    const currentSqlRotation = this.getRotationDegrees(sqlButton.style.transform);

                    if(currentSqlRotation <= 90){
                        this.$refs.rauschen.play();
                    }
                    display.style.backgroundColor = this.dimmSteps[this.dimmIndex];
                    buttonBackground.style.backgroundColor = this.dimmSteps[this.dimmIndex];
                    ds100Display.style.backgroundColor = this.dimmSteps[this.dimmIndex];
                }
                this.scanIsRunning = false;
                

            },
            toggleWatt(){
                if(this.powerIsOn && !this.hiloIsHold){
                    this.$refs.beep.play();
                    if(this.wattText === "25W") this.wattText = "1W";
                    else this.wattText = "25W";
                }
            },
            toggleDual(){
                if(this.powerIsOn){
                    this.isCalling = false;
                    this.$refs.beep.play();
                    this.scanIsRunning = false;
                    this.isDualMode = !this.isDualMode;
                }
            },
            toggleTag(){
                if(this.powerIsOn){
                    this.$refs.beep.play();
                    this.channels[this.currentChannelIndex].isTag = !this.channels[this.currentChannelIndex].isTag;
                }
            },
            async runScanTag(){
                if(this.powerIsOn){
                    this.isCalling = false;
                    this.$refs.beep.play();
                    this.scanIsRunning = !this.scanIsRunning;

                    var i = this.currentChannelIndex;
                    while(this.scanIsRunning){
                        if(this.channels[i].isTag) {
                            if(i == this.channels.length - 1){
                                this.currentChannelIndex = 0;
                                i = 0;
                                continue;
                            }
                            this.currentChannelIndex = i;
                            await new Promise(resolve => setTimeout(resolve, 100));
                        }
                        i++;
                    }
                }
            },
            ds100ToggleMenu(){
                if(this.powerIsOn){
                    if(this.d100IsHome){
                        this.d100IsHome = false;
                        this.ds100IsMenu = true;
                    }
                    else if(this.ds100IsMenu){
                        this.ds100IsMenu = false;
                        this.d100IsHome = true;
                    }
                    else if(this.powerIsOn && this.ds100IsIndividualCallMenu){       
                        if(this.ds100IsCalling){
                            this.ds100ExecIndividualCallInit();
                        }
                        this.ds100CurrentSelectStep = 0;
                        this.ds100CategoryStep = 0;
                    }
                    else if(this.powerIsOn && this.ds100IsGroupCallMenu){
                        if(this.ds100IsCalling){
                            this.ds100ExecGroupCallInit();
                        }
                        this.ds100CurrentSelectStep = 0;
                        this.ds100CategoryStep = 0;
                    }
                    else if(this.powerIsOn && this.ds100IsAllShipsMenu){
                        if(this.ds100IsCalling){
                            this.ds100ExecAllShipCallInit();
                        }
                        this.ds100CurrentSelectStep = 0;
                        this.ds100CategoryStep = 0;
                    }
                    else if(this.powerIsOn && this.ds100IsPositionRequestMenu){
                        if(this.ds100IsCalling){
                            this.ds100ExecPositionRequestInit();
                        }
                        this.ds100CurrentSelectStep = 0;
                        this.ds100CategoryStep = 0;
                    }
                    this.$refs.beepOk.play();
                }
            },
            ds100ArrowDown(){
                if(this.powerIsOn && this.ds100IsMenu){
                    if(this.ds100CurrentMenuStep < 6){
                        this.ds100MoveCursorDown('.ds100-menu-list-cursor');
                        this.ds100CurrentMenuStep += 1;
                    }
                    else if(this.ds100CurrentMenuStep >= 6 && this.ds100CurrentMenuStep < this.ds100MenuItems.length + 1){
                        this.ds100MenuItems.shift();
                        this.ds100CurrentMenuStep += 1;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100ReceivedCallMode == 'IND Cant Comply'){
                    if(this.ds100CurrentSelectStep < 3){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep += 1;
                    }
                    else if(this.ds100CurrentSelectStep >= 3 && this.ds100CurrentSelectStep < 10){
                        this.cantComplyOptions.shift();
                        this.ds100CurrentSelectStep += 1;
                    }
                }
                else if(this.powerIsOn && this.ds100IsIndividualCallMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CategoryStep < this.categories.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CategoryStep++;
                        }
                        this.$refs.beepOk.play();
                        return;
                    }
                    if(this.ds100CurrentSelectStep < this.addressIds.length - 1){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep += 1;
                    }
                    else if(this.ds100CurrentSelectStep >= 6 && this.ds100CurrentSelectStep < this.addressIds.length + 1){
                        this.ds100CurrentSelectStep += 1;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsGroupCallMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CategoryStep < this.categories.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CategoryStep++;
                        }
                        this.$refs.beepOk.play();
                        return;
                    }
                    if(this.ds100IsSelectingAddress){
                        if(this.ds100CurrentSelectStep < this.groupAddressIds.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CurrentSelectStep++;
                        }
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsAllShipsMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CategoryStep < this.categories.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CategoryStep++;
                        }
                        this.$refs.beepOk.play();
                        return;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsPositionRequestMenu){
                    if(this.ds100IsSelectingAddress){
                        if(this.ds100CurrentSelectStep < this.addressIds.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CurrentSelectStep++;
                        }
                        this.$refs.beepOk.play();
                    }
                }
                else if(this.powerIsOn && this.ds100IsReceivedCallsMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CurrentSelectStep < 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CurrentSelectStep++;
                        }
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100IsSelectingMessage){
                        if(this.ds100CurrentSelectStep < this.receivedMessages.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CurrentSelectStep++;
                        }
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100IsSelectingDistressMessage){
                        if(this.ds100CurrentSelectStep < this.receivedDistressMessages.length - 1){
                            this.ds100MoveCursorDown('.selection-cursor');
                            this.ds100CurrentSelectStep++;
                        }
                        this.$refs.beepOk.play();
                    }
                }
                else if(this.powerIsOn && this.ds100IsDistressSettingsMenu){
                    if(this.ds100IsSelectingCategory && this.ds100CurrentSelectStep < 4){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep++;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsSetupMenu){
                    if(this.ds100IsSelectingCategory && this.ds100CurrentSelectStep < 4){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep++;
                    }
                    else if(this.ds100IsSelectingAddressIdCategory && this.ds100CurrentSelectStep < 3){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep++;
                    }
                    else if(this.ds100AddressMode === 'delete' && this.ds100CurrentSelectStep < this.addressIds.length - 1){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep++;
                    }
                    else if(this.ds100AddressMode === 'deleteGroup' && this.ds100CurrentSelectStep < this.groupAddressIds.length - 1){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep++;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == "IND Address" || this.ds100ReceivedCallMode == "IND Comply"){
                    if(this.ds100CurrentSelectStep < 1){
                        this.ds100MoveCursorDown('.selection-cursor');
                        this.ds100CurrentSelectStep++;
                        this.$refs.beepOk.play();
                    }
                    else{
                        this.$refs.beepFalsch.play();
                    }
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.ds100EntryPosScreen){
                    if(this.ds100EnterLatitude){
                        switch(this.ds100CurrentLatIndex){
                            case 4: {
                                if(this.latitude[4] === 'N'){
                                    this.latitude[4] = 'S'
                                }
                                else{
                                    this.latitude[4] = 'N'
                                }
                                this.$refs.lat4.focus();
                                break;
                            }
                        }
                    }
                    else if(this.ds100EnterLongitude){
                        switch(this.ds100CurrentLongIndex){
                            case 5: {
                                if(this.longitude[5] === 'E'){
                                    this.longitude[5] = 'W'
                                }
                                else{
                                    this.longitude[5] = 'E'
                                }
                                this.$refs.long5.focus();
                                break;
                            }
                        }
                    }
                }
            },
            ds100MoveCursorDown(selector){
                const cursor = document.querySelector(selector);
                const style = getComputedStyle(cursor);
                let newPos = parseInt(style.top.match(/\d+/)[0]) + 17;
                cursor.style.top = newPos + 'px';
            },
            ds100MoveCursorUp(selector){
                const cursor = document.querySelector(selector);
                const style = getComputedStyle(cursor);
                let newPos = parseInt(style.top.match(/\d+/)[0]) - 17;
                cursor.style.top = newPos + 'px';
            },
            ds100ArrowUp(){
                if(this.powerIsOn && this.ds100IsMenu){
                    if(this.ds100CurrentMenuStep <= 6 && this.ds100CurrentMenuStep > 1){
                        this.ds100MoveCursorUp('.ds100-menu-list-cursor');
                        this.ds100CurrentMenuStep -= 1;
                    }
                    else if(this.ds100CurrentMenuStep == 8){
                        this.ds100MenuItems.unshift({id:1, title: 'Individual call'})
                        this.ds100CurrentMenuStep -= 1;
                    }
                    else if(this.ds100CurrentMenuStep == 7){
                        this.ds100MenuItems.unshift({id:0, title: 'Entry position/time'});
                        this.ds100CurrentMenuStep -= 1;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100ReceivedCallMode == 'IND Cant Comply'){
                    if(this.ds100CurrentSelectStep <= 3 && this.ds100CurrentSelectStep > 1){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep -= 1;
                    }
                    else if(this.ds100CurrentSelectStep > 3){
                        let options = ['No reason given', 'Congestion', 'Busy', 'Queue Indication', 'Station barred', 'No operator', 'Operator Unavailable', 'Equipment disable', 'Channel unable', 'Mode unable'];
                        this.cantComplyOptions.unshift(options[this.ds100CurrentSelectStep - 4]);
                        this.ds100CurrentSelectStep -= 1;
                    }
                }
                else if(this.powerIsOn && this.ds100IsIndividualCallMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CategoryStep > 0){
                            this.ds100MoveCursorUp('.selection-cursor');
                            this.ds100CategoryStep--;
                        }
                        this.$refs.beepOk.play();
                        return;
                    }
                    else if(this.ds100CurrentSelectStep <= 6 && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep -= 1;
                    }
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.ds100IsGroupCallMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CategoryStep > 0){
                            this.ds100MoveCursorUp('.selection-cursor');
                            this.ds100CategoryStep--;
                        }
                        this.$refs.beepOk.play();
                        return;
                    }
                    if(this.ds100IsSelectingAddress && this.ds100CurrentSelectStep != 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsAllShipsMenu){
                    if(this.ds100IsSelectingCategory){
                        if(this.ds100CategoryStep > 0){
                            this.ds100MoveCursorUp('.selection-cursor');
                            this.ds100CategoryStep--;
                        }
                        this.$refs.beepOk.play();
                        return;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsPositionRequestMenu){
                    if(this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsReceivedCallsMenu){
                    if(this.ds100IsSelectingCategory && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    else if(this.ds100IsSelectingMessage && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    else if(this.ds100IsSelectingDistressMessage && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsDistressSettingsMenu){
                    if(this.ds100IsSelectingCategory && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsSetupMenu){
                    if(this.ds100IsSelectingCategory && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    else if(this.ds100IsSelectingAddressIdCategory && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    else if(this.ds100AddressMode === 'delete' && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    else if(this.ds100AddressMode === 'deleteGroup' && this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                    }
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == "IND Address" || this.ds100ReceivedCallMode == "IND Comply"){
                    if(this.ds100CurrentSelectStep > 0){
                        this.ds100MoveCursorUp('.selection-cursor');
                        this.ds100CurrentSelectStep--;
                        this.$refs.beepOk.play();
                    }
                    else{
                        this.$refs.beepFalsch.play();
                    }
                }
            },
            ds100ArrowLeft(){
                if(this.powerIsOn && this.ds100IsMenu){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else if(this.ds100EntryPosScreen){
                    if(this.ds100EnterLatitude){
                        if(this.ds100CurrentLatIndex > 0){
                        this.ds100CurrentLatIndex--;
                        }
                        switch(this.ds100CurrentLatIndex){
                            case 0: {
                                this.$refs.lat0.focus();
                                break;
                            }
                            case 1: {
                                this.$refs.lat1.focus();
                                break;
                            }
                            case 2: {
                                this.$refs.lat2.focus();
                                break;
                            }
                            case 3: {
                                this.$refs.lat3.focus();
                                break;
                            }
                        }
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100EnterLongitude){
                        if(this.ds100CurrentLongIndex > 0){
                            this.ds100CurrentLongIndex--;
                        }
                        switch(this.ds100CurrentLongIndex){
                            case 0: {
                                this.$refs.long0.focus();
                                break;
                            }
                            case 1: {
                                this.$refs.long1.focus();
                                break;
                            }
                            case 2: {
                                this.$refs.long2.focus();
                                break;
                            }
                            case 3: {
                                this.$refs.long3.focus();
                                break;
                            }
                            case 4: {
                                this.$refs.long4.focus();
                                break;
                            }
                        }
                        this.$refs.beepOk.play();
                    }
                }
                else{
                    this.ds100CurrentSelectStep--;
                }
            },
            ds100ArrowRight(){
                if(this.powerIsOn && this.ds100IsMenu){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else{
                    this.ds100CurrentSelectStep++;
                }
            },
            ds100ExecAcknowledgementFinal(){
                if(!this.acknowledgementDetails.complyMode.includes('cant')){
                    let channelString = JSON.stringify(this.acknowledgementDetails.channel[0]) + JSON.stringify(this.acknowledgementDetails.channel[1]);
                    this.channelNumber = parseInt(channelString);
                    this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                    this.isSending = false;
                    this.ds100IsReceiveCallMenu = false;
                    this.d100IsHome = true;
                    this.$store.dispatch('setTransmittedCall', {
                        mode: 'IND ACK',
                        isTransmitting: true,
                        senderMmsi: this.mmsi,
                        receiverMmsi: this.transmittedCall.senderMmsi,
                        category: this.transmittedCall.category,
                        channel: this.channelNumber
                    });
                }
                else {
                    this.isSending = false;
                    this.ds100IsReceiveCallMenu = false;
                    this.d100IsHome = true;
                    this.$store.dispatch('setTransmittedCall', {
                        mode: 'IND ACK',
                        isTransmitting: true,
                        senderMmsi: this.mmsi,
                        receiverMmsi: this.transmittedCall.senderMmsi,
                        category: this.transmittedCall.category,
                    });
                }
            },
            async ds100ExecAcknowledgementTimer(){
                this.channels.push(
                    { number: '70', description: 'Under DSC', isTag: true, isDUP: false },
                )
                this.channelNumber = 70;
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = true;
            },
            async ds100ExecAcknowledgementInit(){
                const result = await this.ds100ExecAcknowledgementTimer();
                console.log(result);
                setTimeout(() => {
                    this.ds100ExecAcknowledgementFinal();
                }, 1000);
            },
            ds100ExecAcknowledgement(mode){
                switch(mode){
                    case 'IND': {
                        this.ds100ExecAcknowledgementInit();
                    }
                }
            },
            ds100Enter(){
                if(this.powerIsOn && this.ds100IsMenu){
                    switch(this.ds100CurrentMenuStep){
                        case 1: {
                            this.ds100IsMenu = false;
                            this.ds100EntryPosScreen = true;
                            this.ds100EnterLatitude = true;
                            break;
                        }
                        case 2: {
                            this.ds100IsMenu = false;
                            this.ds100IsIndividualCallMenu = true;
                            this.ds100CurrentSelectStep = 0;
                            break;
                        }
                        case 3: {
                            this.ds100IsMenu = false;
                            this.ds100IsGroupCallMenu = true;
                            this.ds100CurrentSelectStep = 0;
                            break;
                        }
                        case 4: {
                            this.ds100IsMenu = false;
                            this.ds100IsAllShipsMenu = true;
                            this.ds100IsSelectingCategory = true;
                            this.ds100CurrentSelectStep = 0;
                            break;
                        }
                        case 5: {
                            this.ds100IsMenu = false;
                            this.ds100IsSelectingCategory = false;
                            this.ds100IsSelectingAddress = true;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsPositionRequestMenu = true;
                            break;
                        }
                        case 6: {
                            this.ds100IsMenu = false;
                            this.ds100IsSelectingCategory = true;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsReceivedCallsMenu = true;
                            break;
                        }
                        case 7: {
                            this.ds100IsMenu = false;
                            this.ds100IsSelectingCategory = true;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsDistressSettingsMenu = true;
                            break;
                        }
                        case 8: {
                            this.ds100IsMenu = false;
                            this.ds100IsSelectingCategory = true;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsSetupMenu = true;
                        }
                    }
                    this.ds100CurrentMenuStep = 1;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsReceiveCallMenu){
                    switch(this.ds100ReceivedCallMode){
                        case 'IND Overview': {
                            this.ds100ReceivedCallMode = 'IND Address';
                            break;
                        }
                        case 'IND ACK': {
                            this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            this.ds100GoToMenu();
                            this.ds100IsMenu = false;
                            this.d100IsHome = true;
                            break;
                        }
                        case 'GRP Overview': {
                            this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            this.ds100GoToMenu();
                            this.ds100IsMenu = false;
                            this.d100IsHome = true;
                            break;
                        }
                        case 'All Ships Overview': {
                            this.currentChannelIndex = this.channels.findIndex((el) => el.number == this.transmittedCall.channel);
                            this.ds100GoToMenu();
                            this.ds100IsMenu = false;
                            this.d100IsHome = true;
                            break;
                        }
                        case 'IND Address': {
                            this.ds100ReceivedCallMode = 'IND Comply';
                            break;
                        }
                        case 'IND Comply': {
                            if(this.ds100CurrentSelectStep == 0){
                                this.ds100ReceivedCallMode = 'IND Channel';
                                this.$store.dispatch('ds100/setAcknowledgementComplyMode', 'comply');
                            }
                            else{
                                this.ds100ReceivedCallMode = 'IND Cant Comply'
                            }
                            break;
                        }
                        case 'IND Cant Comply': {
                            this.ds100ReceivedCallMode = 'IND Exec';
                            this.$store.dispatch('ds100/setAcknowledgementComplyMode', 'cant comply');
                            break;
                        }
                        case 'IND Channel': {
                            this.ds100ReceivedCallMode = 'IND Exec';
                            break;
                        }
                        case 'IND Exec': {
                            this.ds100ExecAcknowledgement('IND');
                            break;
                        }
                        case 'Distress Call Overview': {
                            if(this.distressClearPressed){
                                this.ds100GoToMenu();
                                this.ds100IsMenu = false;
                                this.d100IsHome = true;
                            }
                            break;
                        }
                        case 'Distress Cancel Overview': {
                            if(this.distressClearPressed){
                                this.ds100GoToMenu();
                                this.ds100IsMenu = false;
                                this.d100IsHome = true;
                            }
                        }
                    }
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.ds100EntryPosScreen && this.ds100EnterLatitude){
                    this.ds100EnterLongitude = true;
                    this.ds100EnterLatitude = false;
                    this.$refs.long0.focus();
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100EntryPosScreen && this.ds100EnterLongitude){
                    this.ds100EntryPosScreen = false;
                    this.ds100EntryTimeScreen = true;
                    this.$store.dispatch('ds100/setPosition', {
                        latitude: this.latitude,
                        longitude: this.longitude,
                    });
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100EntryTimeScreen){
                    this.$store.dispatch('ds100/setTime', this.time);
                    this.ds100EntryTimeScreen = false;
                    this.ds100CurrentTimeIndex = 0;
                    this.ds100CurrentLatIndex = 0;
                    this.ds100CurrentLongIndex = 0;

                    if(this.ds100IsDistressSettingsMenu){
                        this.ds100SubmitDistress = true;
                        return;
                    }

                    this.ds100IsMenu = true;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsIndividualCallMenu){
                    if(this.ds100IsSelectingAddress){
                        if(this.ds100CurrentSelectStep > 0){
                            this.ds100IsSelectingAddress = false;
                            this.ds100IsManualEntry = false;
                            this.ds100IsSelectingCategory = true;
                            return;
                        }
                        this.ds100IsSelectingAddress = false;
                        this.ds100IsManualEntry = true;
                        this.$store.dispatch('ds100/setTempAddressNumberToDefault', {mode: 'individual'});
                    }
                    else if(this.ds100IsManualEntry){
                        this.ds100IsManualEntry = false;
                        this.ds100IsSelectingCategory = true;
                    }
                    else if(this.ds100IsSelectingCategory){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsInputChannel = true;
                        this.$store.dispatch('ds100/setTempCategory', {index: this.ds100CategoryStep, mode: 'individual'});
                    }
                    else if(this.ds100IsInputChannel){
                        this.ds100IsInputChannel = false;
                        this.ds100IsCalling = true;
                    }
                    else if(this.ds100IsCalling){
                        this.ds100ExecIndividualCallInit();
                    }
                    this.ds100CurrentSelectStep = 0;
                    this.ds100CategoryStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsGroupCallMenu){
                    this.ds100CurrentSelectStep = 0;
                    if(this.ds100IsSelectingAddress){
                        if(this.ds100CurrentSelectStep > 0){
                            this.ds100IsSelectingAddress = false;
                            this.ds100IsManualEntry = false;
                            this.ds100IsSelectingCategory = true;
                            return;
                        }
                        this.ds100IsSelectingAddress = false;
                        this.ds100IsManualEntry = true;
                        this.ds100CurrentSelectStep = 1;
                        this.$store.dispatch('ds100/setTempAddressNumberToDefault', {mode: 'group'});
                    }
                    else if(this.ds100IsManualEntry){
                        this.ds100IsManualEntry = false;
                        this.ds100IsSelectingCategory = true;
                        this.ds100CurrentSelectStep = 1;
                    }
                    else if(this.ds100IsSelectingCategory){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsInputChannel = true;
                        this.$store.dispatch('ds100/setTempCategory', {index: this.ds100CategoryStep, mode: 'group'});
                    }
                    else if(this.ds100IsInputChannel){
                        this.ds100IsInputChannel = false;
                        this.ds100IsCalling = true;
                    }
                    else if(this.ds100IsCalling){
                        this.ds100ExecGroupCallInit();
                    }
                    this.ds100CategoryStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsAllShipsMenu){
                    if(this.ds100IsSelectingCategory){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsInputChannel = true;
                        this.$store.dispatch('ds100/setTempCategory', {index: this.ds100CategoryStep, mode: 'allShips'});
                    }
                    else if(this.ds100IsInputChannel){
                        this.ds100IsInputChannel = false;
                        this.ds100IsCalling = true;
                    }
                    else if(this.ds100IsCalling){
                        this.ds100ExecAllShipCallInit();
                    }
                    this.ds100CurrentSelectStep = 0;
                    this.ds100CategoryStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsPositionRequestMenu){
                    this.$store.dispatch('ds100/setTempAddressNumberToDefault', {mode: 'posRequest'});
                    if(this.ds100IsSelectingAddress){
                        if(this.ds100CurrentSelectStep > 0){
                            this.ds100IsSelectingAddress = false;
                            this.ds100IsCalling = true;
                            return;
                        }
                        this.ds100IsSelectingAddress = false;
                        this.ds100IsManualEntry = true;
                        this.ds100CurrentSelectStep = 1;
                        this.$store.dispatch('ds100/setTempAddressNumberToDefault', {mode: 'posRequest'});
                    }
                    else if(this.ds100IsManualEntry){
                        this.ds100IsManualEntry = false;
                        this.ds100IsCalling = true;
                    }
                    else if(this.ds100IsCalling){
                        this.ds100ExecPositionRequestInit();
                    }
                    this.ds100CurrentSelectStep = 0;
                    this.ds100CategoryStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsReceivedCallsMenu){
                    if(this.ds100IsSelectingCategory && this.ds100CurrentSelectStep > 0){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsSelectingMessage = true;
                    }
                    else if (this.ds100IsSelectingCategory && this.ds100CurrentSelectStep == 0){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsSelectingDistressMessage = true;
                    }
                    else if(this.ds100IsSelectingDistressMessage){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsSelectingDistressMessage = false;
                        this.ds100IsShowMessageContent = true;
                    }
                    else if(this.ds100IsSelectingMessage){
                        this.ds100IsSelectingCategory = false;
                        this.ds100IsSelectingMessage = false;
                        this.ds100IsShowMessageContent = true;
                    }
                    this.ds100CurrentSelectStep = 0;
                    this.ds100CategoryStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.powerIsOn && this.ds100IsDistressSettingsMenu){
                    if(this.ds100IsSelectingCategory){
                        this.ds100IsSelectingCategory = false;
                        this.ds100EntryPosScreen = true;
                        this.ds100EnterLatitude = true;
                        this.$store.dispatch('ds100/setTempCategory', {
                            mode: 'distress',
                            index: this.ds100CurrentSelectStep,
                        });
                    this.$refs.beepOk.play();
                    }
                }
                else if(this.powerIsOn && this.ds100IsSetupMenu){
                    if(this.ds100IsSelectingCategory){
                        switch(this.ds100CurrentSelectStep){
                            case 0: {
                                this.ds100IsSelectingCategory = false;
                                this.ds100IsSelectingAddressIdCategory = true;
                                break;
                            }
                            case 1: {
                                this.ds100IsSelectingCategory = false;
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100IsOffsetTime = true;
                                this.ds100CurrentSelectStep = 0;
                                break;
                            }
                            case 2: {
                                this.ds100IsSelectingCategory = false;
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100IsBrightnessMenu = true;
                                this.ds100CurrentSelectStep = 0;
                                break;
                            }
                            case 3: {
                                this.ds100IsSelectingCategory = false;
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100IsContrastMenu = true;
                                this.ds100CurrentSelectStep = 0;
                                break;
                            }
                            case 4: {
                                this.ds100IsSelectingCategory = false;
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100IsMMSIMenu = true;
                                this.ds100CurrentSelectStep = 0;
                                break;
                            }
                        }
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100IsSelectingAddressIdCategory){
                        switch(this.ds100CurrentSelectStep){
                            case 0: {
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100EnterLatitude = true;
                                this.ds100EnterLongitude = false;
                                this.ds100AddressMode = 'add';
                                break;
                            }
                            case 1: {
                                if(this.addressIds.length > 0){
                                    if(this.addressIds[0].id === 0){
                                        this.addressIds.shift();
                                    }
                                }
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100EnterLatitude = false;
                                this.ds100EnterLongitude = false;
                                this.ds100AddressMode = 'delete';
                                this.ds100CurrentSelectStep = 0;
                                break;
                            }
                            case 2: {
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100EnterLatitude = true;
                                this.ds100EnterLongitude = false;
                                this.ds100CurrentSelectStep = 1;
                                this.ds100AddressMode = 'addGroup';
                                break;
                            }
                            case 3: {
                                if(this.groupAddressIds.length > 0){
                                    if(this.groupAddressIds[0].id === 0){
                                        this.groupAddressIds.shift();
                                    }
                                }
                                this.ds100IsSelectingAddressIdCategory = false;
                                this.ds100EnterLatitude = false;
                                this.ds100EnterLongitude = false;
                                this.ds100AddressMode = 'deleteGroup';
                                this.ds100CurrentSelectStep = 0;
                                break;
                            }
                        }
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100AddressMode === 'add' && this.ds100EnterLatitude){
                        this.ds100EnterLatitude = false;
                        this.ds100EnterLongitude = true;
                        this.ds100CurrentSelectStep = 0;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100AddressMode === 'add' && this.ds100EnterLongitude){
                        let id = '';
                        let name = '';
                        this.addressToAdd.id.forEach(idEl => {
                            if(idEl != '_'){
                                id += JSON.stringify(idEl);
                            }
                        });
                        this.addressToAdd.name.forEach(nameEl => {
                            if(nameEl != '_'){
                                name += JSON.stringify(nameEl);
                            }
                        });
                        this.$store.dispatch('ds100/addAddressId', {id: id, name: name});
                        this.$store.dispatch('ds100/setTempAddressNumberToDefault', {mode: 'addAddress'});
                        this.ds100GoToMenu();
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100AddressMode === 'delete'){
                        this.ds100AddressMode = 'show';
                    }
                    else if(this.ds100AddressMode === 'deleteGroup'){
                        this.ds100AddressMode = 'showGroup';
                    }
                    else if(this.ds100AddressMode === 'show'){
                        if(this.addressIds[this.ds100CurrentSelectStep]){
                            this.$store.dispatch('ds100/deleteAddressId', this.ds100CurrentSelectStep);
                            this.ds100GoToMenu();
                        }
                        else{
                            this.$refs.beepFalsch.play();
                        }
                    }
                    else if(this.ds100AddressMode === 'showGroup'){
                        if(this.groupAddressIds[this.ds100CurrentSelectStep]){
                            this.$store.dispatch('ds100/deleteGroupAddressId', this.ds100CurrentSelectStep);
                            this.ds100GoToMenu();
                        }
                        else{
                            this.$refs.beepFalsch.play();
                        }
                    }
                    else if(this.ds100AddressMode === 'addGroup'){
                        if(this.ds100EnterLatitude){
                            this.ds100EnterLatitude = false;
                            this.ds100EnterLongitude = true;
                            this.ds100CurrentSelectStep = 0;
                        }
                        else if(this.ds100EnterLongitude){
                            this.ds100EnterLongitude = false;
                            this.ds100CurrentSelectStep = 0;
                            var id = '';
                            var name = '';
                            this.groupAddressToAdd.id.forEach((idEl) => {
                                if(idEl != '_'){
                                    id += JSON.stringify(idEl);
                                }
                            });
                            this.groupAddressToAdd.name.forEach((nameEl) => {
                                if(nameEl != '_'){
                                    name += JSON.stringify(nameEl);
                                }
                            });
                            this.$store.dispatch('ds100/addGroupId', {id: id, name: name});
                            this.$store.dispatch('ds100/setTempAddressNumberToDefault', {mode: 'addGroup'});
                            this.ds100GoToMenu();
                        }
                    }
                    else if (this.ds100IsOffsetTime){
                        this.ds100GoToMenu();
                    }
                    else if(this.ds100IsBrightnessMenu){
                        this.ds100GoToMenu();
                    }
                    else if(this.powerIsOn && this.ds100IsContrastMenu){
                        this.ds100IsContrastMenu = false;
                        this.ds100GoToMenu();
                    }
                }
            },
            ds100ExecPositionRequestFinal(){
                this.channelNumber = this.oldChannel;
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = false;
                this.ds100IsPositionRequestMenu = false;
                this.ds100IsCalling = false;
                this.ds100IsSelectingAddress = true;
                this.d100IsHome = true;
            },
            async ds100ExecPositionRequestCall(){
                this.oldChannel = this.channelNumber;
                this.channels.push(
                    { number: '70', description: 'Under DSC', isTag: true, isDUP: false },
                )
                this.channelNumber = 70;
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = true;
            },
            async ds100ExecPositionRequestInit(){
                const result = await this.ds100ExecPositionRequestCall();
                console.log(result);
                setTimeout(() => {
                    this.ds100ExecPositionRequestFinal();
                }, 1000);
            },
            ds100ExecAllShipCallFinal(){
                let channelString = JSON.stringify(this.allShipCall.channel[0]) + JSON.stringify(this.allShipCall.channel[1]);
                this.channelNumber = parseInt(channelString);
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = false;
                this.ds100IsAllShipsMenu = false;
                this.ds100IsCalling = false;
                this.ds100IsSelectingAddress = true;

                this.$store.dispatch('setTransmittedCall', {
                    mode: 'DS100 All Ships Call',
                    isTransmitting: true,
                    senderMmsi: this.mmsi,
                    category: this.allShipCall.category,
                    channel: channelString,
                });
                this.d100IsHome = true;
            },
            async ds100ExecAllShipCall(){
                this.channels.push(
                    { number: '70', description: 'Under DSC', isTag: true, isDUP: false },
                )
                this.channelNumber = 70;
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = true;
            },
            async ds100ExecAllShipCallInit(){
                const result = await this.ds100ExecAllShipCall();
                console.log(result);
                setTimeout(() => {
                    this.ds100ExecAllShipCallFinal();
                }, 1000);
            },
            ds100ExecIndividualCallFinal(){
                let channelString = JSON.stringify(this.manualIndividualCall.channel[0]) + JSON.stringify(this.manualIndividualCall.channel[1]);
                this.channelNumber = parseInt(channelString);
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = false;
                this.ds100IsIndividualCallMenu = false;
                this.ds100IsCalling = false;
                this.ds100IsSelectingAddress = true;
                let idString = '';
                this.manualIndividualCall.tempAddressId.forEach((el) => {
                    if(el != '_'){
                        idString += JSON.stringify(el);
                    }
                });
                this.$store.dispatch('setTransmittedCall', {
                    mode: 'Individual Call',
                    isTransmitting: true,
                    senderMmsi: this.mmsi,
                    receiverMmsi: idString,
                    category: this.manualIndividualCall.category,
                    channel: channelString,
                });
                this.d100IsHome = true;
            },
            async ds100ExecIndividualCall(){
                this.channels.push(
                    { number: '70', description: 'Under DSC', isTag: true, isDUP: false },
                )
                this.channelNumber = 70;
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = true;
            },
            async ds100ExecIndividualCallInit(){
                const result = await this.ds100ExecIndividualCall();
                console.log(result);
                setTimeout(() => {
                    this.ds100ExecIndividualCallFinal();
                }, 1000);
            },
            ds100ExecGroupCallFinal(){
                let channelString = JSON.stringify(this.manualGroupCall.channel[0]) + JSON.stringify(this.manualGroupCall.channel[1]);
                this.channelNumber = parseInt(channelString);
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = false;
                this.ds100IsGroupCallMenu = false;
                this.ds100IsCalling = false;
                this.ds100IsSelectingAddress = true;
                let idString = '';
                this.manualGroupCall.tempAddressId.forEach((el) => {
                    if(el != '_'){
                        idString += JSON.stringify(el);
                    }
                })
                this.$store.dispatch('setTransmittedCall', {
                    mode: 'DS100 Group Call',
                    isTransmitting: true,
                    senderMmsi: this.mmsi,
                    receiverMmsi: idString,
                    category: this.manualGroupCall.category,
                    channel: channelString,
                });
                this.d100IsHome = true;
            },
            async ds100ExecGroupCall(){
                this.channels.push(
                    { number: '70', description: 'Under DSC', isTag: true, isDUP: false },
                )
                this.channelNumber = 70;
                this.currentChannelIndex = this.channels.findIndex(el => el.number == this.channelNumber);
                this.isSending = true;
            },
            async ds100ExecGroupCallInit(){
                const result = await this.ds100ExecGroupCall();
                console.log(result);
                setTimeout(() => {
                    this.ds100ExecGroupCallFinal();
                }, 1000);
            },
            ds100Clear(){
                if(this.powerIsOn){
                    if(this.ds100IsMenu){
                        this.$refs.beepOk.play();
                        this.ds100IsMenu = false;
                        this.d100IsHome = true;
                    }
                    else if(this.d100IsHome){
                        this.$refs.beepFalsch.play();
                    }
                    else if(this.ds100EntryPosScreen){
                        this.ds100GoToMenu();
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100EntryTimeScreen){
                        this.ds100EntryTimeScreen = false;
                        this.ds100EnterLatitude = true;
                        this.ds100CurrentLatIndex = 0;
                        this.ds100CurrentLongIndex = 0;
                        this.ds100EntryPosScreen = true;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100IsIndividualCallMenu){
                        this.ds100HandleIndividualCallClearButton();
                    }
                    else if(this.ds100IsGroupCallMenu){
                        this.ds100HandleGroupCallClearButton();
                    }
                    else if(this.ds100IsAllShipsMenu){
                        this.ds100HandleAllShipsCallClearButton();
                    }
                    else if(this.ds100IsPositionRequestMenu){
                        this.ds100HandlePositionRequestClearButton();
                    }
                    else if(this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == 'IND Exec'){
                        this.ds100ExecAcknowledgement('IND Exec');
                    }
                    else if(this.ds100DistressSubmitted){
                        this.$store.dispatch('setTransmittedCall', {
                            mode: 'Distress Cancel',
                            isTransmitting: true,
                            senderMmsi: this.mmsi,
                            channel: '16',
                        });
                        this.ds100GoToMenu();
                        this.ds100IsMenu = false;
                        this.d100IsHome = true;
                    }
                    else if(this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == 'Distress Call Overview' && !this.distressClearPressed){
                        this.distressClearPressed = true;
                    }
                    else if(this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == 'Distress Call Overview' && this.distressClearPressed){
                        this.ds100GoToMenu();
                        this.$store.dispatch('setTransmittedCall', {
                            mode: "",
                            isTransmitting: false,
                            senderMmsi: '',
                            category: '',
                            channel: '',
                        });
                        this.ds100IsMenu = false;
                        this.d100IsHome = true;
                    }
                    else if(this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == 'Distress Cancel Overview' && !this.distressClearPressed){
                        this.distressClearPressed = true;
                    }
                    else if(this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == 'Distress Cancel Overview' && this.distressClearPressed){
                        this.ds100GoToMenu();
                        this.$store.dispatch('setTransmittedCall', {
                            mode: "",
                            isTransmitting: false,
                            senderMmsi: '',
                            category: '',
                            channel: '',
                        });
                        this.ds100IsMenu = false;
                        this.d100IsHome = true;
                    }
                    else if(this.ds100IsReceivedCallsMenu){
                        if(this.ds100IsSelectingCategory){
                            this.ds100GoToMenu();
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsSelectingDistressMessage || this.ds100IsSelectingMessage){
                            this.ds100IsSelectingDistressMessage = false;
                            this.ds100IsSelectingMessage = false;
                            this.ds100IsSelectingCategory = true;
                            this.ds100CurrentSelectStep = 0;
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsShowMessageContent){
                            this.ds100IsShowMessageContent = false;
                            this.ds100IsSelectingCategory = true;
                            this.$refs.beepOk.play();
                        }
                    }
                    else if(this.ds100IsDistressSettingsMenu){
                        if(this.ds100IsSelectingCategory){
                            this.ds100GoToMenu();
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100EntryPosScreen){
                            this.ds100EntryPosScreen = false;
                            this.ds100EnterLatitude = false;
                            this.ds100CurrentLatIndex = 0;
                            this.ds100CurrentLongIndex = 0;
                            this.ds100IsDistressSettingsMenu = true;
                            this.ds100IsSelectingCategory = true;
                            this.$refs.beepOk.play();
                        }
                        else{
                            this.ds100GoToMenu();
                            this.$refs.beepOk.play();
                        }
                    }
                    else if(this.ds100IsSetupMenu){
                        if(this.ds100IsSelectingCategory){
                            this.ds100GoToMenu();
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsSelectingAddressIdCategory){
                            this.ds100IsSelectingAddressIdCategory = false;
                            this.ds100IsSelectingCategory = true;
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsOffsetTime){
                            this.$store.dispatch('ds100/clearOffsetTime');
                            this.ds100IsOffsetTime = false;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsSelectingCategory = true;
                            this.ds100IsSetupMenu = true;
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsBrightnessMenu){
                            this.ds100IsBrightnessMenu = false;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsSelectingCategory = true;
                            this.ds100IsSetupMenu = true;
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsContrastMenu){
                            this.ds100IsContrastMenu = false;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsSelectingCategory = true;
                            this.ds100IsSetupMenu = true;
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100IsMMSIMenu){
                            this.ds100IsMMSIMenu = false;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsSelectingCategory = true;
                            this.ds100IsSetupMenu = true;
                            this.$refs.beepOk.play();
                        }
                        else {
                            this.ds100EnterLatitude = false;
                            this.ds100EnterLongitude = false;
                            this.ds100CurrentSelectStep = 0;
                            this.ds100IsSelectingAddressIdCategory = true;
                            this.ds100AddressMode = '';
                            this.$refs.beepOk.play();
                        }
                    }
                    else {
                        this.ds100GoToMenu();
                    }
                }
            },
            ds100HandlePositionRequestClearButton(){
                if(this.ds100IsSelectingAddress){
                    this.ds100GoToMenu();
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsManualEntry){
                    this.ds100IsManualEntry = false;
                    this.ds100IsSelectingAddress = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsCalling){
                    this.ds100IsCalling = false;
                    this.ds100IsSelectingCategory = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
            },
            ds100HandleAllShipsCallClearButton(){
                if(this.ds100IsSelectingCategory){
                    this.ds100IsSelectingCategory = false;
                    this.ds100IsManualEntry = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsInputChannel){
                    this.ds100IsInputChannel = false;
                    this.ds100IsSelectingCategory = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsCalling){
                    this.ds100IsCalling = false;
                    this.ds100IsInputChannel = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
            },
            ds100HandleGroupCallClearButton(){
                if(this.ds100IsSelectingAddress){
                    this.ds100GoToMenu();
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsManualEntry){
                    this.ds100IsManualEntry = false;
                    this.ds100IsSelectingAddress = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsSelectingCategory){
                    this.ds100IsSelectingCategory = false;
                    this.ds100IsManualEntry = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsInputChannel){
                    this.ds100IsInputChannel = false;
                    this.ds100IsSelectingCategory = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsCalling){
                    this.ds100IsCalling = false;
                    this.ds100IsInputChannel = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
            },
            ds100HandleIndividualCallClearButton(){
                if(this.ds100IsSelectingAddress){
                    this.ds100GoToMenu();
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsManualEntry){
                    this.ds100IsManualEntry = false;
                    this.ds100IsSelectingAddress = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsSelectingCategory){
                    this.ds100IsSelectingCategory = false;
                    this.ds100IsManualEntry = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsInputChannel){
                    this.ds100IsInputChannel = false;
                    this.ds100IsSelectingCategory = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
                else if(this.ds100IsCalling){
                    this.ds100IsCalling = false;
                    this.ds100IsInputChannel = true;
                    this.ds100CurrentSelectStep = 0;
                    this.$refs.beepOk.play();
                }
            },
            ds100GoToMenu(){
                this.ds100IsOffsetTime = false;
                this.ds100AddressMode = '';
                this.ds100IsSelectingAddressIdCategory = false;
                this.ds100EntryPosScreen = false;
                this.ds100EntryTimeScreen = false;
                this.ds100EnterLatitude = false;
                this.ds100CurrentSelectStep = 0;
                this.ds100CurrentLatIndex = 0;
                this.ds100CurrentLongIndex = 0;
                this.ds100CurrentTimeIndex = 0;
                this.ds100CurrentMenuStep = 1;
                this.ds100IsIndividualCallMenu = false;
                this.ds100IsGroupCallMenu = false;
                this.ds100IsAllShipsMenu = false;
                this.ds100IsPositionRequestMenu = false;
                this.ds100IsReceivedCallsMenu = false;
                this.ds100IsDistressSettingsMenu = false;
                this.ds100IsSelectingAddress = true;
                this.ds100IsManualEntry = false;
                this.ds100IsSelectingCategory = false;
                this.ds100IsCalling = false;
                this.ds100IsInputChannel = false;
                this.ds100IsSelectingMessage = false;
                this.ds100IsSelectingDistressMessage = false;
                this.ds100IsShowMessageContent = false;
                this.ds100DistressSubmitted = false;
                this.ds100SubmitDistress = false;
                this.ds100IsSetupMenu = false;
                this.ds100IsBrightnessMenu = false;
                this.ds100IsContrastMenu = false;
                this.ds100IsMMSIMenu = false;
                this.ds100IsReceiveCallMenu = false;
                this.ds100ReceivedCallMode = '';
                this.distressClearPressed = false;
                this.ds100MenuItems = [
                    {id:0, title: 'Entry position/time'},
                    {id:1, title: 'Individual call'},
                    {id:2, title: 'Group call'},
                    {id:3, title: 'All ships call'},
                    {id:4, title: 'Position request'},
                    {id:5, title: 'Received calls'},
                    {id:6, title: 'Distress settings'},
                    {id:7, title: 'Set-up'},
                ];
                this.ds100IsMenu = true;
            },
            ds100PressNumber(number){
                if(this.powerIsOn && this.ds100IsMenu){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.ds100EntryPosScreen){

                    if(this.ds100EnterLatitude){
                        if(this.ds100CurrentLatIndex != 4){
                            this.latitude[this.ds100CurrentLatIndex] = number;
                        }
                        else{
                            this.$refs.lat4.focus();
                            this.$refs.beepFalsch.play();
                        }
                    
                        switch(this.ds100CurrentLatIndex){
                            case 0: {
                                this.$refs.lat1.focus();
                                this.ds100CurrentLatIndex++;
                                break;
                            }
                            case 1: {
                                this.$refs.lat2.focus();
                                this.ds100CurrentLatIndex++;
                                break;
                            }
                            case 2: {
                                this.$refs.lat3.focus();
                                this.ds100CurrentLatIndex++;
                                break;
                            }
                            case 3: {
                                this.$refs.lat4.focus();
                                this.ds100CurrentLatIndex++;
                                break;
                            }
                            case 4: {
                                if(number == 6){
                                    this.latitude[4] = 'N';
                                }
                                else if(number == 7){
                                    this.latitude[4] = 'S';
                                }
                            }
                        }
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100EnterLongitude){
                        if(this.ds100CurrentLongIndex != 5){
                            this.longitude[this.ds100CurrentLongIndex] = number;
                        }
                        else{
                            this.$refs.long5.focus();
                            this.$refs.beepFalsch.play();
                        }
                    
                        switch(this.ds100CurrentLongIndex){
                            case 0: {
                                this.$refs.long1.focus();
                                this.ds100CurrentLongIndex++;
                                break;
                            }
                            case 1: {
                                this.$refs.long2.focus();
                                this.ds100CurrentLongIndex++;
                                break;
                            }
                            case 2: {
                                this.$refs.long3.focus();
                                this.ds100CurrentLongIndex++;
                                break;
                            }
                            case 3: {
                                this.$refs.long4.focus();
                                this.ds100CurrentLongIndex++;
                                break;
                            }
                            case 4: {
                                this.$refs.long5.focus();
                                this.ds100CurrentLongIndex++;
                                break;
                            }
                            case 5: {
                                if(number == 3){
                                    this.longitude[5] = 'E';
                                }
                                else if(number == 9){
                                    this.longitude[5] = 'W';
                                }
                            }
                        }
                        this.$refs.beepOk.play();
                    }
                }
                else if(this.powerIsOn && this.ds100EntryTimeScreen){
                    if(this.ds100CurrentTimeIndex == 0 && number <= 2){
                        this.time[this.ds100CurrentTimeIndex] = number;
                        this.ds100CurrentTimeIndex++;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100CurrentTimeIndex == 1 && number <= 9){
                        this.time[this.ds100CurrentTimeIndex] = number;
                        this.ds100CurrentTimeIndex++;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100CurrentTimeIndex == 3 && number <= 5){
                        this.time[this.ds100CurrentTimeIndex] = number;
                        this.ds100CurrentTimeIndex++;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100CurrentTimeIndex == 4 && number <= 9){
                        this.time[this.ds100CurrentTimeIndex] = number;
                        this.$refs.beepOk.play();
                    }
                    else {
                        this.$refs.beepFalsch.play();
                    }
                    if(this.ds100CurrentTimeIndex == 2){
                        this.ds100CurrentTimeIndex = 3;
                        return;
                    }
                    else if(this.ds100CurrentTimeIndex == 4){
                        return;
                    }
                }
                else if(this.powerIsOn && this.ds100IsReceiveCallMenu && this.ds100ReceivedCallMode == 'IND Channel'){
                    this.$store.dispatch('ds100/setAcknowledgementChannel',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                        }
                    );
                    if(this.ds100CurrentSelectStep < 1){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsIndividualCallMenu && this.ds100IsManualEntry){
                    this.$store.dispatch('ds100/addTempAddressNumber', 
                        {   
                            number: number, 
                            index: this.ds100CurrentSelectStep,
                            mode: 'individual'
                        }
                    );
                    if(this.ds100CurrentSelectStep < 8){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsInputChannel && this.ds100IsIndividualCallMenu){
                    this.$store.dispatch('ds100/addTempChannelNumber', 
                        {   
                            number: number, 
                            index: this.ds100CurrentSelectStep,
                            mode: 'individual'
                        }
                    );
                    if(this.ds100CurrentSelectStep < 1){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsGroupCallMenu && this.ds100IsManualEntry){
                    this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                            mode: 'group'
                        }
                    );
                    if(this.ds100CurrentSelectStep < 8){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsInputChannel && this.ds100IsGroupCallMenu){
                    this.$store.dispatch('ds100/addTempChannelNumber',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                            mode: 'group'
                        }
                    );
                    if(this.ds100CurrentSelectStep < 1){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsInputChannel && this.ds100IsAllShipsMenu){
                    this.$store.dispatch('ds100/addTempChannelNumber',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                            mode: 'allShips'
                        }
                    );
                    if(this.ds100CurrentSelectStep < 1){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsPositionRequestMenu && this.ds100IsManualEntry){
                    this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                            mode: 'posRequest'
                        }
                    );
                    if(this.ds100CurrentSelectStep < 8){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep++;
                    }
                }
                else if(this.powerIsOn && this.ds100IsSetupMenu){
                    if(this.ds100AddressMode === 'add' && this.ds100EnterLatitude){
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                            mode: 'addAddress'
                        }
                        );
                        if(this.ds100CurrentSelectStep < 8){
                            this.$refs.beepOk.play();
                            this.ds100CurrentSelectStep++;
                        }
                    }
                    else if(this.ds100AddressMode === 'add' && this.ds100EnterLongitude){
                        let letter;
                        if(number != this.lastNumber){
                            this.currentLetterIndex = 0;
                        }
                        if(this.currentLetterIndex == 0){
                            letter = this.ds100Numbers[number].number;
                            this.currentLetterIndex++;
                        }
                        else if(this.currentLetterIndex < this.ds100Numbers[number].letters.length + 1){
                            letter = this.ds100Numbers[number].letters[this.currentLetterIndex - 1];
                            this.currentLetterIndex++;
                        }
                        else
                        {
                            letter = this.ds100Numbers[number].number;
                            this.currentLetterIndex = 0;
                        }

                        if(this.isLowerCase){
                            letter = letter.toLowerCase();
                        }
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: letter,
                            index: this.ds100CurrentSelectStep,
                            mode: 'addName'
                        }
                        );
                        this.lastNumber = number;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100AddressMode === 'addGroup' && this.ds100EnterLatitude){
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: number,
                            index: this.ds100CurrentSelectStep,
                            mode: 'addGroupAddress'
                        }
                        );
                        if(this.ds100CurrentSelectStep < 8){
                            this.$refs.beepOk.play();
                            this.ds100CurrentSelectStep++;
                        }
                    }
                    else if(this.ds100AddressMode === 'addGroup' && this.ds100EnterLongitude){
                        let letter;
                        if(number != this.lastNumber){
                            this.currentLetterIndex = 0;
                        }
                        if(this.currentLetterIndex == 0){
                            letter = this.ds100Numbers[number].number;
                            this.currentLetterIndex++;
                        }
                        else if(this.currentLetterIndex < this.ds100Numbers[number].letters.length + 1){
                            letter = this.ds100Numbers[number].letters[this.currentLetterIndex - 1];
                            this.currentLetterIndex++;
                        }
                        else
                        {
                            letter = this.ds100Numbers[number].number;
                            this.currentLetterIndex = 0;
                        }

                        if(this.isLowerCase){
                            letter = letter.toLowerCase();
                        }
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: letter,
                            index: this.ds100CurrentSelectStep,
                            mode: 'addGroupName'
                        }
                        );
                        this.lastNumber = number;
                        this.$refs.beepOk.play();
                    }
                    else if(this.ds100IsOffsetTime){
                        if(this.ds100CurrentSelectStep == 0 && number != 0){
                            return;
                        }
                        if(this.ds100CurrentSelectStep == 2){
                            this.$store.dispatch('ds100/addTimeNumber',
                                {
                                    number: number,
                                    index: 2,
                                }
                            );
                            this.ds100CurrentSelectStep = 4;
                            this.$refs.beepOk.play();
                        }
                        else if(this.ds100CurrentSelectStep == 0 && number == 0){
                            this.$store.dispatch('ds100/addTimeNumber',
                                {
                                    number: '-',
                                    index: 0,
                                }
                            );
                            this.ds100CurrentSelectStep++;
                            this.$refs.beepOk.play();
                            return;
                        }
                        else if(this.ds100CurrentSelectStep < 5){
                            this.$store.dispatch('ds100/addTimeNumber',
                                {
                                    number: number,
                                    index: this.ds100CurrentSelectStep,
                                }
                            );
                            this.ds100CurrentSelectStep++;
                            this.$refs.beepOk.play();
                        }
                    }
                    else if(this.ds100IsBrightnessMenu){
                        if(number <= 8 && number > 0){
                            this.$store.dispatch('ds100/setBrightnessLevel', number);
                            this.dimmDS100(number);
                            this.$refs.beepOk.play();
                        }
                    }
                    else if(this.ds100IsContrastMenu){
                        if(number <= 8 && number > 0){
                            this.$store.dispatch('ds100/setContrastLevel', number);
                            this.setContrastDS100(number);
                            this.$refs.beepOk.play();
                        }
                    }
                }
            },
            ds100PressUpperLowerCase(){
                if(this.powerIsOn && this.ds100EntryPosScreen){
                    let position = {
                        latitude: ['_','_','_','_','N'],
                        longitude: ['_','_','_','_','_','E'],
                    };
                    this.$store.dispatch('ds100/setPosition', position);
                    this.latitudeIsNull = true;
                    this.longitudeIsNull = true;
                    this.$refs.beepOk.play();
                    this.$refs.lat0.focus();
                    this.ds100CurrentLatIndex = 0;
                    this.ds100CurrentLongIndex = 0;
                    this.ds100EnterLatitude = true;
                    this.ds100EnterLongitude = false;
                }
                else if(this.powerIsOn && this.ds100EntryTimeScreen){
                    this.$store.dispatch('ds100/setTime', ['_','_',':','_','_']);
                    this.timeIsNull = true;
                    this.ds100CurrentTimeIndex = 0;
                    this.$refs.beepOk.play();
                }
                else{
                    this.isLowerCase = !this.isLowerCase;
                    this.$refs.beepFalsch.play();
                }
            },
            pressBackspace(){
                if(this.powerIsOn && this.ds100IsMenu){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.d100IsHome){
                    this.$refs.beepFalsch.play();
                }
                else if(this.powerIsOn && this.ds100EntryPosScreen){

                    if(this.ds100EnterLatitude){
                        if(this.ds100CurrentLatIndex != 4){
                            this.latitude[this.ds100CurrentLatIndex] = '_';
                        }
                        else{
                            this.$refs.lat4.focus();
                            this.$refs.beepFalsch.play();
                        }
                        this.$refs.beepOk.play();
                        if(this.ds100CurrentLatIndex > 0){
                            this.$refs.beepOk.play();
                            this.ds100CurrentLatIndex--;
                        }
                        switch(this.ds100CurrentLatIndex){
                            case 0: {
                                this.$refs.lat0.focus();
                                break;
                            }
                            case 1: {
                                this.$refs.lat1.focus();
                                break;
                            }
                            case 2: {
                                this.$refs.lat2.focus();
                                break;
                            }
                            case 3: {
                                this.$refs.lat3.focus();
                                break;
                            }
                        }
                    }
                    else if(this.ds100EnterLongitude){
                        if(this.ds100CurrentLongIndex != 5){
                            this.longitude[this.ds100CurrentLongIndex] = '_';
                        }
                        else{
                            this.$refs.long5.focus();
                            this.$refs.beepFalsch.play();
                        }
                        this.$refs.beepOk.play();
                        if(this.ds100CurrentLongIndex > 0){
                            this.$refs.beepOk.play();
                            this.ds100CurrentLongIndex--;
                        }
                        switch(this.ds100CurrentLongIndex){
                            case 0: {
                                this.$refs.long0.focus();
                                break;
                            }
                            case 1: {
                                this.$refs.long1.focus();
                                break;
                            }
                            case 2: {
                                this.$refs.long2.focus();
                                break;
                            }
                            case 3: {
                                this.$refs.long3.focus();
                                break;
                            }
                            case 4: {
                                this.$refs.long4.focus();
                                break;
                            }
                        }
                    }
                }
                else if(this.ds100EntryTimeScreen){
                    let newTime = this.time;
                    newTime[this.ds100CurrentTimeIndex] = '_';
                    this.$store.dispatch('ds100/setTime', newTime);
                    if(this.ds100CurrentTimeIndex > 0){
                        this.ds100CurrentTimeIndex--;
                        this.$refs.beepOk.play();
                    }
                    if(this.ds100CurrentTimeIndex == 2){
                        this.ds100CurrentTimeIndex--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsIndividualCallMenu && this.ds100IsManualEntry){
                    this.$store.dispatch('ds100/addTempAddressNumber', 
                        {   
                            number: '_', 
                            index: this.ds100CurrentSelectStep,
                            mode: 'individual'
                        }
                    );
                    if(this.ds100CurrentSelectStep > 0){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsInputChannel && this.ds100IsIndividualCallMenu){
                    this.$store.dispatch('ds100/addTempChannelNumber', 
                        {   
                            number: '_', 
                            index: this.ds100CurrentSelectStep,
                            mode: 'individual'
                        }
                    );
                    if(this.ds100CurrentSelectStep > 0){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsGroupCallMenu && this.ds100IsManualEntry){
                    this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'group'
                        }
                    );
                    if(this.ds100CurrentSelectStep > 1){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsInputChannel && this.ds100IsGroupCallMenu){
                    this.$store.dispatch('ds100/addTempChannelNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'group'
                        }
                    );
                    if(this.ds100CurrentSelectStep > 0){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsInputChannel && this.ds100IsAllShipsMenu){
                    this.$store.dispatch('ds100/addTempChannelNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'allShips'
                        }
                    );
                    if(this.ds100CurrentSelectStep > 0){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsPositionRequestMenu && this.ds100IsManualEntry){
                    this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'posRequest'
                        }
                    );
                    if(this.ds100CurrentSelectStep > 0){
                        this.$refs.beepOk.play();
                        this.ds100CurrentSelectStep--;
                    }
                }
                else if(this.powerIsOn && this.ds100IsSetupMenu){
                    if(this.ds100AddressMode === 'add' && this.ds100EnterLatitude){
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'addAddress'
                        }
                        );
                        if(this.ds100CurrentSelectStep > 0){
                            this.$refs.beepOk.play();
                            this.ds100CurrentSelectStep--;
                        }
                    }
                    else if(this.ds100AddressMode === 'add' && this.ds100EnterLongitude){
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'addName'
                        }
                        );
                        if(this.ds100CurrentSelectStep > 0){
                            this.$refs.beepOk.play();
                            this.ds100CurrentSelectStep--;
                        }
                    }
                    else if(this.ds100AddressMode === 'addGroup' && this.ds100EnterLatitude){
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'addGroupAddress'
                        }
                        );
                        if(this.ds100CurrentSelectStep > 1){
                            this.$refs.beepOk.play();
                            this.ds100CurrentSelectStep--;
                        }
                    }
                    else if(this.ds100AddressMode === 'addGroup' && this.ds100EnterLongitude){
                        this.$store.dispatch('ds100/addTempAddressNumber',
                        {
                            number: '_',
                            index: this.ds100CurrentSelectStep,
                            mode: 'addGroupName'
                        }
                        );
                        if(this.ds100CurrentSelectStep > 0){
                            this.$refs.beepOk.play();
                            this.ds100CurrentSelectStep--;
                        }
                    }
                }
            }
        }
    })
</script>

<style scoped>
    .backspace-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .backspace-button{
        z-index: 3;
        width: 36px;
        height: 31px;
        position: absolute;
        top: 603px;
        left: 486px;
    }
    .send-pressed{
        z-index: 12 !important;
    }
    .send-btn{
        position: absolute;
        left: -135px;
        top: 60px;
        z-index: 12;
        height: 80px;
        width: 80px;
    }
    .distress-btn{
        position: absolute;
        content: url('../../assets/M503_DS100/bDistress.png');
        top: 608px;
        left: 79px;
        width: 80px;
        height: 150px;
        z-index: 20;
        cursor: pointer;
    }
    .distress-btn-pressed{
        position: absolute;
        content: url('../../assets/M503_DS100/bDistressDown.png') !important;
        top: 608px;
        left: 79px;
        width: 80px;
        height: 150px;
        z-index: 20;
    }
    .distress-btn:hover{
        animation-name: distress;
        animation-duration: 0.3s;
        animation-iteration-count: initial;         
        animation-fill-mode: forwards;
        z-index: 20;
    }
    @keyframes distress{
        0% {
            content: url('../../assets/M503_DS100/bDistress.png')
        }
        20% {
            content: url('../../assets/M503_DS100/bDistress1.png')
        }
        40% {
            content: url('../../assets/M503_DS100/bDistress2.png')
        }
        60% {
            content: url('../../assets/M503_DS100/bDistress3.png')
        }
        100% {
            content: url('../../assets/M503_DS100/bDistress4.png')
        }
    }
    .cursor-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        z-index: 5;
    }

    .mirror-cursor {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        pointer-events: none;
        z-index: 9999;
    }
    .ds100-home-mnl-lon-format{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 132px;
        top: 105px;
    }
    .ds100-home-mnl-lon{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 60px;
        top: 105px;
    }
    .ds100-home-mnl-lat-format{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 132px;
        top: 87px;
    }
    .ds100-home-mnl-lat{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 60px;
        top: 87px;
    }
    .ds100-home-mnl-time-format{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 132px;
        top: 70px;
    }
    .ds100-home-mnl-utc{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 60px;
        top: 70px;
    }
    .time-null{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        top: 40px;
        left: 120px;
    }
    .time-container{
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50px;
        top: 40px;
    }
    .utc-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 40px;
    }
    .input-time-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
    .ds100-upper-lower-btn{
        z-index: 3;
        width: 36px;
        height: 31px;
        position: absolute;
        top: 603px;
        left: 390px;
    }
    .ds100-upper-lower-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .exit-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 109px;
    }
    .null-data-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 13pt;
        left: 20px;
        top: 95px;
    }
    .lat-null{
        position: absolute;
        top: 0px;
        left: 76px;
    }
    .latitude-degree{
        position: absolute;
        top: 0px;
        left: 16px;
    }
    .latitude-dir{
        position: absolute;
        top: 0px;
        left: 46px;
    }
    .latitude-4-number{
        position: absolute;
        left: 56px;
    }
    .latitude-4-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 56px;
        top: 8px;
    }
    .latitude-4-input{
        position: absolute;
        top: 0px;
        left: 56px;
    }
    .latitude-3-number{
        position: absolute;
        left: 36px;
    }
    .latitude-3-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 36px;
        top: 8px;
    }
    .latitude-3-input{
        position: absolute;
        top: 0px;
        left: 36px;
    }
    .latitude-2-number{
        position: absolute;
        left: 26px;
    }
    .latitude-2-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 26px;
        top: 8px;
    }
    .latitude-2-input{
        position: absolute;
        top: 0px;
        left: 26px;
    }
    .latitude-1-number{
        position: absolute;
        left: 6px;
    }
    .latitude-1-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 6px;
        top: 8px;
    }
    .latitude-1-input{
        position: absolute;
        top: 0px;
        outline: none;
        left: 8px;
    }
    .latitude-0-number{
        position: absolute;
        left: -4px;
    }
    .latitude-0-input{
        position: absolute;
        outline: none;
    }
    .latitude-0-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: -3px;
        top: 8px;
    }
    @keyframes blink {
        0% {
            opacity: 0;
            background-color: #000000;
        }
        50% {
            opacity: 0.8;
            background-color: #000000;
        }
        100% {
            opacity: 0;
            background-color: #000000;
        }
    }
    .long-null{
        position: absolute;
        top: 0px;
        left: 83px;
    }
    .longitude-degree{
        position: absolute;
        top: 0px;
        left: 24px;
    }
    .longitude-dir{
        position: absolute;
        top: 0px;
        left: 54px;
    }
    .longitude-5-number{
        position: absolute;
        left: 64px;
    }
    .longitude-5-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 64px;
        top: 8px;
    }
    .longitude-5-input{
        position: absolute;
        top: 0px;
        left: 64px;
    }
    .longitude-4-number{
        position: absolute;
        left: 44px;
    }
    .longitude-4-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 44px;
        top: 8px;
    }
    .longitude-4-input{
        position: absolute;
        top: 0px;
        left: 44px;
    }
    .longitude-3-number{
        position: absolute;
        left: 34px;
    }
    .longitude-3-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 34px;
        top: 8px;
        }
    .longitude-3-input{
        position: absolute;
        top: 0px;
        left: 34px;
    }
    .longitude-2-number{
        position: absolute;
        left: 14px;
    }
    .longitude-2-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 14px;
        top: 8px;
    }
    .longitude-2-input{
        position: absolute;
        top: 0px;
        left: 14px;
    }
    .longitude-1-number{
        position: absolute;
        left: 4px;
    }
    .longitude-1-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: 4px;
        top: 8px;
    }
    .longitude-1-input{
        position: absolute;
        left:4px;
    }
    .longitude-0-number{
        position: absolute;
        left: -6px;
    }
    .longitude-0-input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        height: 15px;
        left: -6px;
        top: 8px;
    }
    .longitude-0-input{
        position: absolute;
        left: -6;
    }
    .longitude-input{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 72px;
        top: 77px;
    }
    .longitude-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 62px;
    }
    .latitude-input{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 80px;
        top: 42px;
    }
    .latitude-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
        top: 27px;
    }
    .input-pos-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 20px;
    }
    .ds100-9-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 554px;
        left: 486px;
        
    }
    .ds100-9-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-8-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 554px;
        left: 438px;
        
    }
    .ds100-8-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-7-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 554px;
        left: 390px;
        
    }
    .ds100-7-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-6-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 507px;
        left: 486px;
        
    }
    .ds100-6-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-5-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 507px;
        left: 438px;
        
    }
    .ds100-5-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-4-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 507px;
        left: 390px;
        
    }
    .ds100-4-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-3-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 458px;
        left: 486px;
        
    }
    .ds100-3-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-2-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 458px;
        left: 438px;
        
    }
    .ds100-2-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-1-btn{
        z-index: 3;
        width: 36px;
        height: 33px;
        position: absolute;
        top: 458px;
        left: 390px;
        
    }
    .ds100-1-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-0-btn{
        z-index: 3;
        width: 36px;
        height: 31px;
        position: absolute;
        top: 603px;
        left: 438px;
    }
    .ds100-0-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-arrow-right-btn{
        z-index: 3;
        width: 43px;
        height: 68px;
        position: absolute;
        top: 670px;
        left: 485px;
        border-radius: 100% !important;
    }
    .ds100-arrow-right-btn-pressed{
        border-radius: 15%;
    }
    .ds100-arrow-left-btn{
        z-index: 3;
        width: 43px;
        height: 68px;
        position: absolute;
        top: 670px;
        left: 385px;
        border-radius: 100% !important;
    }
    .ds100-arrow-left-btn-pressed{
        border-radius: 15%;
    }
    .ds100-arrow-up-btn{
        z-index: 3;
        width: 43px;
        height: 33px;
        position: absolute;
        top: 660px;
        left: 435px;
    }
    .ds100-arrow-up-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-arrow-down-btn{
        z-index: 3;
        width: 43px;
        height: 33px;
        position: absolute;
        top: 714px;
        left: 435px;
    }
    .ds100-arrow-down-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-menu-list-cursor{
        position: absolute;
        font-family: ds-digi;
        font-size: 20pt;
        left: 7px;
        top: 19px;
    }
    .ds100-menu-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 17pt;
        left: 20px;
    }
    .ds100-menu-item{
        height: 17px;
        font-family: ds-digi;
        font-size: 12pt;
    }
    .ds100-menu-list{
        max-height: 140px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 27px;
    }
    .ds100-clear-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-clear-btn{
        position: absolute;
        z-index: 3;
        width: 41px;
        height: 52px;
        top: 677px;
        left: 172px;
    }
    .ds100-enter-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-enter-btn{
        position: absolute;
        z-index: 3;
        width: 41px;
        height: 52px;
        top: 677px;
        left: 292px;
    }
    .ds100-call-btn-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .ds100-call-btn{
        position: absolute;
        z-index: 3;
        width: 41px;
        height: 52px;
        top: 677px;
        left: 232px;
    }
    .ds100-home-mnl-position{
        position: absolute;
        font-family: ds-digi;
        font-size: 16pt;
        left: 49px;
        top: 85px;
    }
    .ds100-home-mnl-time{
        position: absolute;
        font-family: ds-digi;
        font-size: 16pt;
        left: 8px;
        top: 65px;
    }
    .ds100-home-watch-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 16pt;
        left: 55px;
        top: 35px;
    }
    .ds100-home-title{
        position: absolute;
        font-family: ds-digi;
        font-size: 16pt;
        left: 8px;
        top: 5px;
    }
    .ds100-button-background-2{
        background-color: #ed841c;
        position: absolute;
        height: 100px;
        width: 250px;
        top: 650px;
        left: 85px;
        z-index: 1;
    }
    .ds100-button-background-2-off{
        position: absolute;
        height: 300px;
        width: 180px;
        top: 450px;
        left: 370px;
        z-index: 1;
    }
    .ds100-button-background-1{
        background-color: #ed841c;
        position: absolute;
        height: 300px;
        width: 180px;
        top: 450px;
        left: 370px;
        z-index: 1;
    }
    .ds100-button-background-1-off{
        position: absolute;
        height: 300px;
        width: 180px;
        top: 450px;
        left: 370px;
        z-index: 1;
    }
    .ds100{
        z-index: 2;
    }
    .ds100-display{
        background-color: #ed841c;
        position: absolute;
        height: 140px;
        width: 245px;
        top: 478px;
        left: 90px;
        z-index: 1;
    }
    .ds100-display-off{
        background-color: #5f613e;
        position: absolute;
        height: 140px;
        width: 245px;
        top: 478px;
        left: 90px;
        z-index: 1;
    }
    .display-busy-text{
        position: absolute;
        font-family: ds-digi;
        font-weight: 100;
        font-size: 14pt;
        left: 0;
        top: 1px;
    }
    .button-background{
        z-index: 1;
        position:absolute;
        width: 400px;
        height: 300px;
        top: 50px;
        left: 130px;
        background-color: #b56e1c;
    }
    .button-background-off{
        z-index: 1;
        position:absolute;
        width: 400px;
        height: 300px;
        top: 50px;
        left: 130px;
    }
    .channel-turn-button{
        cursor: row-resize;
        position: absolute;
        z-index: 5;
        width: 140px;
        height: 140px;
        top: 230px;
        left: 300px;
        transition: transform 0.1s ease;
    }
    .volume-turn-button{
        cursor: row-resize;
        position: absolute;
        z-index: 5;
        width: 71px;
        height: 71px;
        top: 295px;
        left: 85px;
        transition: transform 0.1s ease;
    }
    .sql-turn-button{
        cursor: row-resize;
        position: absolute;
        z-index: 5;
        width: 71px;
        height: 71px;
        top: 295px;
        left: 192px;
        transition: transform 0.1s ease;
    }
    .speaker{
        z-index: 5;
        position: absolute;
        height: 400px;
        width:400px;
        left: -225px;
    }
    .display-container-m503{
        z-index: 1;
        position:absolute;
        width: 205px;
        height: 75px;
        top: 75px;
        left: 220px;
        background-color: #b56e1c;
        max-width: 205px;
        min-height: 75px;
    }
    .display-container-off-m503{
        z-index: 1;
        position:absolute;
        width: 205px;
        height: 75px;
        top: 75px;
        left: 220px;
        background-color: #5f613e;
        max-width: 205px;
        min-height: 75px;
    }
    .main-device-container{
        display: flex;
    }
    .main-device{
        z-index: 2;
        width: 90%;
        right: 0;
    }
    .device-holder{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        max-width: 593px;
    }
    .power-button{
        z-index: 6;
        position:absolute;
        height: 30px;
        width: 52px;
        top: 250px;
        left: 137px;

    }
    .power-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .hilo-button{
        z-index: 6;
        position:absolute;
        height: 36px;
        width: 60px;
        top: 240px;
        left: 217px;

    }
    .hilo-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .dual-button{
        z-index: 6;
        position:absolute;
        height: 36px;
        width: 60px;
        top: 185px;
        left: 217px;
    }
    .dual-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .scan-button{
        z-index: 6;
        position:absolute;
        height: 36px;
        width: 60px;
        top: 185px;
        left: 299px;
    }
    .scan-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .dial-button{
        z-index: 6;
        position:absolute;
        height: 36px;
        width: 60px;
        top: 185px;
        left: 380px;
    }
    .dial-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .call-button{
        z-index: 6;
        position:absolute;
        height: 36px;
        width: 60px;
        top: 185px;
        left: 460px;
    }
    .call-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .sixteen-button{
        z-index: 6;
        position:absolute;
        height: 47px;
        width: 50px;
        top: 90px;
        left: 464px;
    }
    .sixteen-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .dimmer-button{
        z-index: 6;
        position:absolute;
        height: 36px;
        width: 60px;
        top: 240px;
        left: 460px;
    }
    .dimmer-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .speaker-c-up-button{
        z-index: 6;
        position:absolute;
        height: 30px;
        width: 30px;
        top: 248px;
        left: -68px;
    }
    .speaker-c-up-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .speaker-c-down-button{
        z-index: 6;
        position:absolute;
        height: 30px;
        width: 30px;
        top: 282px;
        left: -60px;
    }
    .speaker-c-down-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .speaker-hilo-button{
        z-index: 6;
        position:absolute;
        height: 22px;
        width: 30px;
        top: 275px;
        left: -3px;
    }
    .speaker-hilo-button-pressed{
        box-shadow: inset 0 0 8px black;
        border-radius: 15%;
    }
    .display-header-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        left: 60px;
        top: -3px;
    }
    .display-watt-text{
        position: absolute;
        font-family: ds-digi;
        font-weight: 100;
        font-size: 14pt;
        top: 15px;
    }
    .display-tag-text{
        position: absolute;
        font-family: ds-digi;
        font-weight: 100;
        font-size: 14pt;
        top: 30px;
    }
    .display-channel-desc-text{
        position: absolute;
        font-family: ds-digi;
        font-weight: 100;
        font-size: 14pt;
        top: 45px;
    }
    .display-channel-number-text{
        position: absolute;
        font-family: ds-digi;
        font-size: 59pt;
        right: 10px;
        top: -20px;
    }
    .display-dual-channel-number-text{
       position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
        right: 115px;
        top: 45px;
    } 
    .display-dup-text{
       position: absolute;
        font-family: ds-digi;
        font-weight: 100;
        font-size: 14pt;
        right: 100px;
        top: 30px;
    }
    .display-call-text{
       position: absolute;
       font-family: ds-digi;
       font-size: 14pt;
       right: 100px;
       top: 15px;
    }
</style>