<template>
    <div class="sub-menu-container">
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">{{currentMenu.toUpperCase()}}</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <div v-if="currentMenu != 'Distress Alert' && !isDistressPausedDialog && !isRetransmittingDistressDialog && !isResendDistressDialog && !isReportSituationDistressCancelDialog && !isCancelingDistress && !isAllShipsCalling && !isTransmitting && !isWaitingForAck && !isDistressCountdownActive && !isTransmittingDistressCancel && !isTransmittedDistressCancel && !isGroupCallSent" class="details-container">
            <p class="detail" v-if="!isAllShips">To: {{callDetails.id}}</p>
            <p class="detail" v-else>To: [All Ships]</p>
            <p class="detail">{{callDetails.category}}</p>
            <p class="detail" v-if="callDetails.callChannel">CH {{callDetails.callChannel.number}}</p>
            <p class="detail" v-if="callDetails.callChannel">{{callDetails.callChannel.description}}</p>
        </div>
        <div v-else-if="isDistressPausedDialog" class="distress-paused-dialog-container">
            <p>Waiting for ACK</p>
            <p>Next TX is Paused</p>
        </div>
        <div v-else-if="isResendDistressDialog" class="resend-distress-dialog-container">
            <p class="resend-title">RESEND</p>
            <p class="hold-down-text">Hold Down</p>
            <div class="distress">DISTRESS</div>
            <p class="countdown-text">for {{ resendCountdownSec }} sec.</p>
        </div>
        <div v-else-if="isRetransmittingDistressDialog" class="retransmitting-distress-dialog-container">
            <p class="retransmitt-title">RETRANSMITTING</p>
            <p class="distress-text">DISTRESS</p>
            <div class="distress-retransmitt">DISTRESS</div>
        </div>
        <div v-else-if="isCancelingDistress" class="cancel-distress-dialog-container">
            <p class="sure-text">Are you sure?</p>
        </div>
        <div v-else-if="isReportSituationDistressCancelDialog" class="report-situation-container">
            <p class="report-text">Report Your Situation</p>
            <p class="report-channel-text">On CH 16</p>
        </div>
        <div v-else-if="isTransmittingDistressCancel" class="transmitting-distress-cancel-container">
            <p>TRANSMITTING</p>
            <p>DISTRESS CANCEL</p>
        </div>
        <div v-else-if="isTransmittedDistressCancel" class="distress-cancel-container">
            <p class="distress-cancel-watt">25W</p>
            <p class="distress-cancel-channel">16</p>
        </div>
        <div v-else-if="isTransmitting" class="transmitting-container">
            <p>TRANSMITTING</p>
            <p>{{ currentMenu.toUpperCase() }}</p>
        </div>
        <div v-else-if="isIndividualAckSent" class="transmitting-container">
            <p class="channel">{{ transmittedCall.channel }}</p>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt">{{ isHigh ? '25W' : '1W' }}</p>
            <div class="divider"></div>
            <p class="to-all-ships">To: {{transmittedCall.receiverId}}</p>
            <p class="elapsed-timer">Elapsed: {{ timer }}</p>
        </div>
        <div v-else-if="isAllShipsCalling" class="transmitting-container">
            <p class="channel">{{ callDetails.callChannel.number }}</p>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt">{{ isHigh ? '25W' : '1W' }}</p>
            <div class="divider"></div>
            <p class="to-all-ships">To: All Ships</p>
            <p class="elapsed-timer">Elapsed: {{ timer }}</p>
        </div>
        <div v-else-if="isGroupCallSent">
            <p class="group-watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="group-channel-text">{{ transmittedCall.channel }}</p>
            <div class="group-divider"></div>
            <p class="group-from-text">From: {{ transmittedCall.senderMmsi }}</p>
            <p class="group-elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div v-else-if="isWaitingForAck" class="transmitting-container">
            <p>Waiting for ACK</p>
            <p v-if="currentMenu === 'Distress Alert'">Next TX after</p>
            <p v-if="currentMenu != 'Distress Alert'">Elapsed: {{ timer }}</p>
            <p v-else>{{ countdownTimer }}</p>
        </div>
        <div v-else-if="isDistressCountdownActive" class="transmitting-container">
            <p class="hold-down">Hold Down</p>
            <p class="hold-down-timer">for {{ distressCountdownSeconds }} sec.</p>
        </div>
        <div class="menu-actions">
            <div class="action-biggest" v-if="isDistressPausedDialog">
                <p>RESUME COUNTDOWN</p>
            </div>
            <div class="action back" v-if="isReportSituationDistressCancelDialog">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back" v-if="currentMenu == 'All Ships Call' || currentMenu == 'Individual Call' || currentMenu == 'Group Call' || currentMenu == 'Test Call' || isAllShipsCalling || (currentMenu == 'Individual Call' && isWaitingForAck)">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back-2" v-if="(currentMenu == 'All Ships Call' || currentMenu == 'Individual Call' || currentMenu == 'Group Call' || currentMenu == 'Test Call') && !isWaitingForAck && !isTransmitting && !isAllShipsCalling && !isGroupCallSent">
                <p class="action-text">BACK</p>
            </div>
            <div class="action call" v-if="(currentMenu == 'All Ships Call' || currentMenu == 'Individual Call' || currentMenu == 'Group Call' || currentMenu == 'Test Call') && !isWaitingForAck && !isTransmitting && !isAllShipsCalling && !isGroupCallSent">
                <p class="action-text">CALL</p>
            </div>
            <div class="action back" v-if="isGroupCallSent">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action-big action-finish" v-if="isReportSituationDistressCancelDialog">
                <p class="action-text">FINISH</p>
            </div>
            <div class="action back" v-if="isCancelingDistress">
                <p class="action-text">BACK</p>
            </div>
            <div class="action-big action-continue" v-if="isCancelingDistress">
                <p class="action-text">CONTINUE</p>
            </div>
            <div class="action-big" v-if="!isDistressPausedDialog && !isRetransmittingDistressDialog && !isResendDistressDialog && !isTransmittingDistressCancel && !isTransmittedDistressCancel && !isCancelingDistress && currentMenu == 'Distress Alert' && isWaitingForAck">
                <p class="action-text">{{ selectedDistressCallCarouselItems[0] }}</p>
            </div>
            <div class="action-big" v-if="!isDistressPausedDialog && !isRetransmittingDistressDialog && !isResendDistressDialog && !isTransmittingDistressCancel && !isTransmittedDistressCancel && !isCancelingDistress && currentMenu == 'Distress Alert' && isWaitingForAck">
                <p class="action-text">{{ selectedDistressCallCarouselItems[1] }}</p>
            </div>
            <div class="action exit" v-if="isTransmittedDistressCancel || (!isDistressPausedDialog && !isRetransmittingDistressDialog && !isResendDistressDialog && !isCancelingDistress && !currentMenu == 'Distress Alert')">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back" v-if="!currentMenu == 'All Ships Call' && !isGroupCallSent && !isDistressPausedDialog && !isRetransmittingDistressDialog && !isResendDistressDialog && !isTransmittingDistressCancel && !isTransmittedDistressCancel && !isCancelingDistress && !isWaitingForAck && !isAllShipsCalling && !isReportSituationDistressCancelDialog">
                <p class="action-text">BACK</p>
            </div>
            <div class="action enter" v-if="!currentMenu == 'All Ships Call' && !isGroupCallSent && !isDistressPausedDialog && !isRetransmittingDistressDialog && !isResendDistressDialog && !isTransmittingDistressCancel && !isTransmittedDistressCancel && !isCancelingDistress && !isWaitingForAck && !isAllShipsCalling && !isReportSituationDistressCancelDialog">
                <p class="action-text">ENT</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    beforeUnmount(){
        clearInterval(this.timerInterval);
        clearInterval(this.countdownInterval);
    },
    mounted(){
        this.selectedDistressCallCarouselItems = this.$props.distressCallCarouselItems.slice(0, 2);
    },
    data(){
        return {
            timer: '00:00:00',
            countdownTimer: '',
            timerInterval: undefined,
            countdownInterval: undefined,
            seconds: 0,
            selectedDistressCallCarouselItems: [],
        }
    },
    watch: {
        isGroupCallSent(newVal){
            if(newVal){
                this.startTimer();
            }
        },
        isWaitingForAck(newVal){
            if(newVal && !this.$props.isTimerPaused && !this.timerInterval){
               this.startTimer(240);
            }
        },
        isAllShipsCalling(newVal){
            if(newVal){
               this.startTimer(240);
            }
        },
        isDistressCountdownActive(newVal){
            if(newVal){
               this.startTimer(3);
            }
        },
        currentCarouselMenuIndex(newVal){
            this.selectedDistressCallCarouselItems = this.$props.distressCallCarouselItems.slice(newVal, newVal + 2);
        }
    },
    computed: {
        callDetails(){
            if(this.$props.currentMenu === 'Group Call'){
                return this.$store.getters['m423/groupCallDetails'];
            }
            else if(this.$props.currentMenu === 'Individual Call'){
                return this.$store.getters['m423/individualCallDetails'];
            }
            else if(this.$props.currentMenu === 'All Ships Call'){
                return this.$store.getters['m423/allShipsCallDetails'];
            }
            else if(this.$props.currentMenu === 'Test Call'){
                return this.$store.getters['m423/testCallDetails'];
            }
            return []
        },
        isAllShips(){
            if(!this.$props.currentMenu == 'Distress Alert' && !this.$props.isCancelingDistress && !this.$props.isReportSituationDistressCancelDialog && !this.$props.isTransmittingDistressCancel && !this.$props.isTransmittedDistressCancel){
                let isAll = true;
                for(let i = 0; i < this.callDetails.id.length; i++){
                    if(this.callDetails.id[i] != '0'){
                        isAll = false;
                    }
                }
                return isAll;
            }
            return false;
        },
        transmittedCall(){
            return this.$store.getters['transmittedCall'];
        }
    },
    methods: {
        startTimer(seconds){
            if(this.$props.currentMenu == 'Distress Alert'){
                this.seconds = seconds;
                this.countdownInterval = setInterval(() => {
                    if(!this.$props.isTimerPaused){
                        this.countdown()
                    }
                }, 1000);
                return;
            }
            this.timerInterval = setInterval(() => {
                if(!this.$props.isTimerPaused){
                    this.interval();
                }
            },1000);
        },
        countdown(){
            if(this.seconds < 0){
                clearInterval(this.countdownInterval);
            }
            this.seconds--;
            var minute = Math.floor(this.seconds / 60);
            var updSecond = this.seconds % 60;
            this.countdownTimer = minute + ' min. ' + updSecond + ' sec.'
        },
        interval(){
            this.seconds++;
            var hour = Math.floor(this.seconds / 3600);
            var minute = Math.floor((this.seconds - hour * 3600) / 60);
            var updSecond = this.seconds - (hour * 3600 + minute * 60);
            this.timer = (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (updSecond < 10 ? '0' + updSecond : updSecond);
        }
    },
    props: {
        currentMenu: String,
        isTransmitting: Boolean,
        isWaitingForAck: Boolean,
        isAllShipsCalling: Boolean,
        isDistressCountdownActive: Boolean,
        distressCountdownSeconds: Number,
        currentCarouselMenuIndex: Number,
        distressCallCarouselItems: Array,
        isCancelingDistress: Boolean,
        isTimerPaused: Boolean,
        isReportSituationDistressCancelDialog: Boolean,
        isTransmittingDistressCancel: Boolean,
        isTransmittedDistressCancel: Boolean,
        isResendDistressDialog: Boolean,
        resendCountdownSec: Number,
        isRetransmittingDistressDialog: Boolean,
        isDistressPausedDialog: Boolean,
        isGroupCallSent: Boolean,
        isHigh: Boolean,
        isBusy: Boolean,
        isSending: Boolean,
        isIndividualAckSent: Boolean,
        backlightColor: String
    }
})
</script>

<style scoped>
.busy-text{
    position: absolute;
    font-family: m423-text-font;
    font-size: 7pt;
    top:8px;
    left: 5px;
    color: v-bind('backlightColor') !important;
    padding-left: 4px;
    padding-right: 2px;
    border-radius: 20%;
    text-align: center;
    background-color: rgb(37, 37, 37);
}
.group-elapsed-timer-text{
    position: absolute;
    top: 53px;
    left: 5px;
    font-family: m423-text-font;
    font-size: 9pt;
}
.group-from-text{
    position: absolute;
    top: 38px;
    left: 5px;
    font-family: m423-text-font;
    font-size: 9pt;
}
.group-divider{
    position: absolute;
    top: 35px;
    width: 100%;
    height: 1px;
    border: 1px solid rgb(37, 37, 37);
}
.group-watt-text{
    position: absolute;
    font-size: 8pt;
    top: 8px;
    left: 50px;
    font-family: m423-text-font;
}
.group-channel-text{
    position: absolute;
    font-size: 45pt;
    top:-20px;
    left: 160px;
    font-family: m423-font;
}
.countdown-text{
    position: absolute;
    font-size: 7pt;
    top:52px;
}
.hold-down-text{
    position: absolute;
    font-size: 7pt;
    top:35px;
    left:50px;
}
.resend-title{
    position: absolute;
    font-size: 8pt;
    top:18px;
}
.distress-retransmitt{
    font-family: m423-text-font;
    font-size: 5pt;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;
    height: 17px;
    color: v-bind('backlightColor') !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4px;
    margin-left: 5px;
    margin-right: 5px;
}
.distress{
    font-family: m423-text-font;
    font-size: 5pt;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;
    height: 17px;
    color: v-bind('backlightColor') !important;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4px;
    margin-left: 5px;
    margin-right: 5px;
    position: absolute;
    top:35px;
    right: 50px;
}
.distress-cancel-watt{
    position: absolute;
    top:5px;
    left:50px;
}
.distress-cancel-channel{
    position: absolute;
    top:-5px;
    right:5px;
    font-size: 30pt;
    font-family: m423-text-font;
}
.distress-cancel-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 10pt;
}
.action-finish{
    position: absolute;
    right: 3px;
}
.action-continue{
    position: absolute;
    right: 3px;
}
.action-biggest{
    background-color: rgb(37, 37, 37);
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    width: 100%;
    height: 18px;
    padding: 2px;
    font-size: 8pt;
    font-family: m423-text-font;
    color:v-bind('backlightColor') !important;
    text-align: center;
}
.action-big{
    background-color: rgb(37, 37, 37);
    border-top-left-radius: 20%;
    border-top-right-radius: 20%;
    width: 106px;
    height: 18px;
    padding: 2px;
    margin-left: 5px;
    font-size: 8pt;
    font-family: m423-text-font;
    color:v-bind('backlightColor') !important;
    text-align: center;
}
.elapsed-timer{
    position: absolute;
    bottom: -3px;
    left: 5px;
    font-size: 10pt;
}
.to-all-ships{
    position: absolute;
    bottom: 12px;
    left: 5px;
    font-size: 10pt;
}
.channel{
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 20pt;
}
.watt{
    position: absolute;
    top: 8px;
    left: 50px;
    font-size: 8pt;
}
.divider{
    position: absolute;
    border: 1px solid black;
    height: 1px;
    width: 100%;
    top: 35px;
    font-size: 10pt;
}

.distress-paused-dialog-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 8pt;
}

.retransmitting-distress-dialog-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 8pt;
}
.resend-distress-dialog-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 8pt;
}
.transmitting-distress-cancel-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 10pt;
}
.transmitting-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 10pt;
}
.report-situation-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 10pt;
}
.cancel-distress-dialog-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
    font-family: m423-text-font;
    font-size: 10pt;
}
.detail{
    line-height: 17px;
    font-family: m423-text-font;
    font-size: 8pt;
}
.details-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 5px;
}
    .enter{
        position:absolute;
        right: 5px;
    }
    .call{
        position:absolute;
        right: 5px;
    }
    .back{
        position:absolute;
        left: 3px;
    }
    .back-2{
        position:absolute;
        left: 60px;
    }
    .exit{
        position:absolute;
        left: 5px;
    }
.menu-actions{
        position: absolute;
        display: flex;

        align-items: center;
        bottom: -24px;
        left: -2px;
        width: 227px;
        height: 20px;
    }
.action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
.sub-menu-container{
    position: absolute;
    height: 90px;
    width: 227px;
    left: 5px;
    top: 5px;
    border: 2px solid rgb(37, 37, 37);;
    border-top: 20px solid rgb(37, 37, 37);;
}
.menu-header{
    position: absolute;
    width: 225px;
    height: 20px;
    top: -20px;
    left: -3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-left: 2%;
    padding-right: 1%;
    text-align: center;
}
.menu-header-title{
    color: v-bind('backlightColor') !important;
    font-family: m423-font;
    font-size: 16pt;
    padding-top: 5px;
}
</style>