<template>
    <div class="sub-menu-container">
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">{{currentMenu.toUpperCase()}}</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <div class="time-pos-container">
            <p v-if="!distressPositionAndTime.position.lat && !distressPositionAndTime.position.long" class="lat">No Position data</p>
            <p v-if="distressPositionAndTime.position.lat" class="lat">{{distressPositionAndTime.position.lat}}</p>
            <p v-if="distressPositionAndTime.position.long" class="long">{{distressPositionAndTime.position.long}}</p>
            <p class="time">{{distressPositionAndTime.utc ? distressPositionAndTime.utc + ' UTC' : 'No Time Data'}}</p>
        </div>
        <div class="menu-actions">
            <div class="action exit">
                <p class="action-text">EXIT</p>
            </div>
            <div class="action back" v-if="!isWaitingForAck">
                <p class="action-text">BACK</p>
            </div>
            <div class="action change">
                <p class="action-text">CHG</p>
            </div>
            <div class="action enter" v-if="!isWaitingForAck">
                <p class="action-text">ENT</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    computed:{
        distressPositionAndTime(){
            return this.$store.getters['m423/distressPositionInputDetails'];
        },
    },
    props: {
        currentMenu: String,
        backlightColor: String
    }
})
</script>

<style scoped>
    .time-pos-container{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        align-items: start;
        margin-left: 5px;
        margin-top: 2px;
        font-family: m423-text-font;
        font-size:8pt;
    }
    .change{
        position:absolute;
        left: 117px;
    }
   .enter{
        position:absolute;
        right: 5px;
    }
    .back{
        position:absolute;
        left: 60px;
    }
    .exit{
        position:absolute;
        left: 5px;
    }
.menu-actions{
        position: absolute;
        display: flex;

        align-items: center;
        bottom: -24px;
        left: -2px;
        width: 227px;
        height: 20px;
    }
.action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 18px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
.sub-menu-container{
    position: absolute;
    height: 90px;
    width: 227px;
    left: 5px;
    top: 5px;
    border: 2px solid rgb(37, 37, 37);;
    border-top: 20px solid rgb(37, 37, 37);;
}
.menu-header{
    position: absolute;
    width: 225px;
    height: 20px;
    top: -20px;
    left: -3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding-left: 2%;
    padding-right: 1%;
    text-align: center;
}
.menu-header-title{
    color: v-bind('backlightColor') !important;
    font-family: m423-font;
    font-size: 16pt;
    padding-top: 5px;
}
</style>