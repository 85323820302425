<template>
    <div class="header">
        <h1 class="title-software">Bootschule1 Funkgerätesimulator</h1>
    </div>
    <div class="device-select-container mt-10">
        <device-card @selectDevice="(i) => selectDevice(i)" :device="device" v-for="device in devices" :key="device.id"/>
    </div>
    <div class="header">
        <v-btn @click="$emit('submit', selectedDevices)" color="#021df0" class="text-white submit-btn">Bestätigen</v-btn>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import DeviceCard from './DeviceCard.vue';
    export default defineComponent ({
        name: "deviceSelect",
        components: {
            DeviceCard
        },

        methods:{
            selectDevice(index){
                const selectedCount = this.devices.filter((device) => device.selected === true).length;
                if(this.devices[index].selected === false &&  selectedCount === 2) return;
                this.devices[index].selected = !this.devices[index].selected;
                let selectedDevices = [];
                this.devices.forEach(device => {
                    if(device.selected){
                        selectedDevices.push(device);
                    }
                });
                this.$store.dispatch('setSelectedDevices', selectedDevices);
            },
        },

        computed:{
            selectedDevices(){
                return this.$store.getters['selectedDevices'];
            }
        },

        data(){
            return {
                devices: [
                    {id: 0, name: "M503 + DS100", imagePath: "/M503_DS100/M503.png", turnBtnImagePath: "/M503_DS100/bDreherBig.png", selected: false},
                    {id: 1, name: "M423", imagePath: "/M423/NewM423.png", selected: false},
                    //{id: 2, name: "M330", imagePath: "/M330/M330.png", selected: false},
                    //{id: 3, name: "M505", imagePath: "/M505/NewM505.png", selected: false}
                ]
            }
        }
    })
</script>

<style lang="scss" scoped>
    .device-select-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .header{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100px;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
    }
    .title-software{
        color: #021df0;
        font-size: 15pt;
        font-weight: 300;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .submit-btn{
        width: 200px;
        height: 50px;
        font-size: large;
    }
</style>