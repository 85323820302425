<template>
    <div class="input-menu-container">
        <div class="menu-header">
            <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
            <p class="menu-header-title">{{currentMenu.toUpperCase()}}</p>
            <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
        </div>
        <div class="final-input-container">
            <p class="input-title">{{inputTitle}}:</p>
            <div class="input-border">
                <div class="final-input" ref="m423Input" v-for="(item, index) in input" :key="index">
                    <input :value="item" class="input" type="text"/>
                </div>
            </div>
        </div>
        <div class="input-grid-container">
            <div class="digit-container" ref="digit" tabindex="-1" v-for="(digit, index) in selectableDigits" :key="index">
                <p>{{ digit }}</p>
            </div>
            <div class="input-action span-two" id="arrow-left" tabindex="-1" v-if="selectableDigits.length > 0">
                <v-icon size="13">mdi-arrow-left</v-icon>
            </div>
            <div class="input-action span-two" id="arrow-right" tabindex="-1" v-if="selectableDigits.length > 0">
                <v-icon size="13">mdi-arrow-right</v-icon>
            </div>
            <div class="input-action span-three" id="space" tabindex="-1" v-if="selectableDigits.length > 0">
                <p v-if="isInputName">SPACE</p>
            </div>
            <div class="input-action span-three" id="a" tabindex="-1" v-if="selectableDigits.length > 0">
                <p v-if="isInputName">DELETE</p>
                <p v-else-if="isNoDataAvailable || currentMenu == 'Position Input'">NO DATA</p>
            </div>
            <div class="input-action span-three" id="finish" tabindex="-1" v-if="selectableDigits.length > 0">FINISH</div>
        </div>
        <div class="menu-actions">
            <div v-if="!isInputName" class="action exit">
                <p class="action-text">EXIT</p>
            </div>
            <div v-if="!isInputName" class="action back">
                <p class="action-text">BACK</p>
            </div>
            <div v-if="isInputName" class="action switchDigits">
                <p v-if="selectableDigits[0] == 'A'" class="action-text">123</p>
                <p v-else-if="selectableDigits[0] == '0'" class="action-text">!$?</p>
                <p v-else-if="selectableDigits[0] == '!'" class="action-text">ABC</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    mounted(){
        if(this.$props.isInputGroupId){
            this.switchActiveInputIndex(1);
        }
        else{
            this.switchActiveInputIndex(0);
        }
        this.switchActiveDigit(0);
        this.pushElsToRef();
    },
    watch: {
        currentInputIndex(newVal, oldVal){
            this.switchActiveInputIndex(newVal, oldVal);
        },
        selectedDigitIndex(newVal, oldVal){
            this.switchActiveDigit(newVal, oldVal);
        }
    },
    methods: {
        pushElsToRef(){
            const arrowLeft = document.getElementById('arrow-left');
            this.$refs.digit.push(arrowLeft);
            const arrowRight = document.getElementById('arrow-right');
            this.$refs.digit.push(arrowRight);
            const space = document.getElementById('space');
            this.$refs.digit.push(space);
            const a = document.getElementById('a');
            this.$refs.digit.push(a);
            const finish = document.getElementById('finish');
            this.$refs.digit.push(finish);
        },
        switchActiveDigit(newIndex, oldIndex){
            if(oldIndex != undefined){
                var oldEl = this.$refs.digit[oldIndex];
                oldEl.style.backgroundColor = 'transparent';
                oldEl.style.color = 'rgb(37, 37, 37)';
            }
            var newEl = this.$refs.digit[newIndex];
            newEl.style.backgroundColor = 'rgb(37, 37, 37)';
            newEl.style.color = this.$props.backlightColor;
        },
        switchActiveInputIndex(newIndex, oldIndex){
            if(oldIndex != undefined){
                var oldEl = this.$refs.m423Input[oldIndex];
                oldEl.style.backgroundColor = 'transparent';
                oldEl.style.color = 'rgb(37, 37, 37)';
            }
            var newEl = this.$refs.m423Input[newIndex];
            newEl.style.backgroundColor = 'rgb(37, 37, 37)';
            newEl.style.color = this.$props.backlightColor;
        }
    },
    props: {
        selectedDigitIndex: Number,
        isInputName: Boolean,
        isInputId: Boolean,
        isInputGroupId: Boolean,
        activeInputTypes: Array,
        currentMenu: String,
        inputTitle: String,
        currentInputIndex: Number,
        selectableDigits: Array,
        input: Array,
        isNoDataAvailable: Boolean,
        backlightColor: String,
    }
})
</script>

<style scoped>
    .span-three{
        grid-column: span 3;
    }
    .span-two{
        grid-column: span 2;
    }
    .input-action{
        height: 100% !important;
        width: 100% !important;
        border: 1px solid rgb(37, 37, 37);
        font-family: m423-text-font;
        font-size: 6pt;
    }
    .input-action-arrow{
        border: 1px solid rgb(37, 37, 37);
        height: 100%;
        width: 34.7px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .input-actions{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 17.35px;
        font-size: 8pt;
        position: absolute;
        top: 58.8px;
    }
    .digit-container{
        height: 100%;
        width: 100%;
        border: 1px solid rgb(37, 37, 37);
        font-family: m423-text-font;
        font-size: 6pt;
        text-align: center;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .input-grid-container{
        margin-top: 2px;
        height: 45px;
        display: grid;
        text-align: center;
        grid-template-columns: 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px 17.35px;
        grid-template-rows: 17.35px 17.35px 17.35px;
        text-align: center;
        /* grid-template-areas: "topbar topbar topbar topbar topbar topbar topbar topbar topbar topbar topbar topbar topbar"; */
    }
    .final-input{
        height: 20px;
        width: 25px !important;
        font-family: m423-text-font;
        font-size: 9pt;
        margin-left: 6px;
    }
    .input-title{
        font-family: m423-text-font;
        font-size: 10pt;
        margin-right: auto;
        color: rgb(37, 37, 37);
    }
    .input-border{
        width: 150px;
        border: 2px solid rgb(37, 37, 37);
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 3%;
        justify-self: end;
    }
    .input{
        width: 20px;
        padding: 2px;
    }
    .final-input-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1%;
    }
    .menu-header-title{
        color: v-bind('backlightColor') !important;
        font-family: m423-font;
        font-size: 16pt;
        padding-top: 5px;

    }
    .input-menu-container{
        position: absolute;
        height: 112px;
        width: 227px;
        left: 5px;
        top: 5px;
        background-color: v-bind('backlightColor') !important;;
        border-top: 20px solid rgb(37, 37, 37);
    }
    .menu-header{
        position: absolute;
        width: 225px;
        height: 20px;
        top: -20px;
        left: -3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        padding-left: 2%;
        padding-right: 1%;
        text-align: center;
    }
    .action{
        background-color: rgb(37, 37, 37);
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        width: 50px;
        height: 16px;
        padding: 2px;
        font-size: 8pt;
        font-family: m423-text-font;
        color:v-bind('backlightColor') !important;
        text-align: center;
    }
    .menu-actions{
        position: absolute;
        display: flex;
        align-items: center;
        bottom: -3px;
        left: -2px;
        width: 227px;
        height: 20px;
        color:v-bind('backlightColor') !important;
    }
    .switchDigits{
        position:absolute;
        left: 175px;
    }
    .back{
        position:absolute;
        left: 62px;
    }
    .exit{
        position:absolute;
        left: 5px;
        color: v-bind('backlightColor') !important
    }
</style>