<template>
    <div class="main-container">
        <div v-for="(item,index) in items" :key="index" class="container">
            <input ref="freeInput" class="input">
            <p class="number">{{ item }}</p>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        watch: {
            isActive(val){
                if(val){
                        this.$refs['freeInput'][0].focus();
                }
                this.focusInterval = setInterval(() => {
                    this.$refs['freeInput'][this.$props.selectedIndex].focus();
                }, 500);
            }
        },
        data(){
            return {
                focusInterval: null,
            }
        },
        mounted(){
            if(this.$props.isActive){
                this.$refs['freeInput'][0].focus();
            }
            this.focusInterval = setInterval(() => {
                    this.$refs['freeInput'][this.$props.selectedIndex].focus();
                }, 500);
        },
        beforeUnmount(){
            clearInterval(this.focusInterval);
        },
        updated(){
            if(this.$props.isActive){
                this.$refs['freeInput'][this.$props.selectedIndex].focus();
            }
        },
        props: {
            items: Array,
            selectedIndex: Number,
            mode: String,
            isActive: Boolean,
        }
    })
</script>

<style scoped>
    .main-container{
        display: flex;
        flex-direction: row;
    }
    .container{
        font-family: m423-text-font;
        font-size: 8pt;
        margin-left: 10px;

    }
    .number{
        position: absolute;
    }
    .input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        top: 1px;
        height: 15px;
    }
    .input{
        position: absolute;
        width: 8px;
    }
    @keyframes blink {
        0% {
            opacity: 0;
            background-color: #000000;
        }
        50% {
            opacity: 0.8;
            background-color: #000000;
        }
        100% {
            opacity: 0;
            background-color: #000000;
        }
    }
</style>