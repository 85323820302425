<template>
    <div>
        <p v-if="isSending || isBusy" class="tx-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
        <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
        <p v-if="isDsc" class="dsc-text">DSC</p>
        <p v-else-if="isAtis" class="dsc-text">ATIS</p>
        <p v-else-if="isInt" class="dsc-text">INT</p>
        <p class="call-text">CALL</p>
        <p v-if="isRX" class="rx-text">RX <v-icon>mdi-volume-high</v-icon></p>
        <p v-if="isDualMode" class="dual-text">DUAL 16</p>
        <p v-if="scanIsRunning" class="dual-text">SCAN</p>
        <p v-if="!positionInputDetails.position.lat || positionInputDetails.position.lat.includes('-') || positionInputDetails.position.long.includes('-')" class="position-text">{{'NO POSITION'}}</p>
        <p v-if="positionInputDetails.position.lat && !positionInputDetails.position.lat.includes('-')" class="lat-text">{{positionInputDetails.position.lat}}</p>
        <p v-if="positionInputDetails.position.long && !positionInputDetails.position.long.includes('-')" class="long-text">{{positionInputDetails.position.long}}</p>
        <p v-if="!positionInputDetails.utc || positionInputDetails.utc.includes('--')" class="time-text">NO TIME</p>
        <p v-if="positionInputDetails.utc && !positionInputDetails.utc.includes('--')" class="utc-text">{{formattedDate + ' MNL'}}</p>
        <p class="tag">{{currentChannel.isTag ? '*' : ''}}</p>
        <div class="channel-container">
            <p class="channel">{{currentChannel.number}}</p>
        </div>
        <div class="call-container">
            <p class="calling">{{currentChannel.description}}</p>
        </div>
        <m423CarouselMenu
            :currentMenuIndex="currentMenuIndex"
            :backlightColor="backlightColor"
        />
        <m423QuickMenu
            class="quick-menu"
            v-if="isQuickMenu"
            :isInputVolume="isInputVolume"
            :isInputSql="isInputSql"
            :isInputChannel="isInputChannel"
            :isInputBacklight="isInputBacklight"
            :volumeValue="volumeValue"
            :sqlValue="sqlValue"
            :channelIndex="channelIndex"
            :backlightValue="backlightValue"
            :isInputRX="isInputRX"
            :rxValue="rxValue"
            :isAqua="isAqua"
            :backlightColor="backlightColor"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import m423CarouselMenu from './M423_CarouselMenu.vue'
import m423QuickMenu from '../Display/M423_QuickMenuContainer.vue'

    export default defineComponent({
        computed: {
            currentChannel(){
                return this.$store.getters['m423/channels'][this.$props.currentChannelIndex];
            },
            positionInputDetails(){
                return this.$store.getters['m423/positionInputDetails'];
            },
            utcOffset(){
                return this.$store.getters['m423/utcOffset']
            },
            formattedDate() 
            {
                let timeArray = []; // Hier das Array mit der Zeit eintragen
                let offsetTimeArray = this.utcOffset;
                for(let i = 0; i < this.positionInputDetails.utc.length; i++){
                    timeArray.push(this.positionInputDetails.utc[i]);
                }

                // Extrahiere Stunden und Minuten aus dem timeArray
                const hours = parseInt(timeArray[0] + timeArray[1]);
                const minutes = parseInt(timeArray[3] + timeArray[4]);

                // Erstelle ein Date-Objekt mit dem heutigen Datum
                const currentDate = new Date();

                // Setze die Stunden und Minuten des Date-Objekts basierend auf dem timeArray
                currentDate.setHours(hours);
                currentDate.setMinutes(minutes);

                // Extrahiere Stunden und Minuten aus dem offsetTimeArray
                const offsetHours = parseInt(offsetTimeArray[1] + offsetTimeArray[2]);
                const offsetMinutes = parseInt(offsetTimeArray[4] + offsetTimeArray[5]);

                // Berechne die Offset-Zeit in Millisekunden
                const offsetTime = (offsetHours * 60 + offsetMinutes) * 60000;

                var finalDate = new Date();
                // Füge die Offset-Zeit hinzu oder subtrahiere sie
                if (offsetTimeArray[0] === '-') {
                    finalDate = new Date(currentDate.getTime() - offsetTime);
                } else {
                    finalDate = new Date(currentDate.getTime() + offsetTime);
                }

                const formattedHours = finalDate.getHours().toString().padStart(2, '0');
                const formattedMinutes = finalDate.getMinutes().toString().padStart(2, '0');

                // Formatieren des finalen Date-Objekts als einzelnen String
                const formattedString = `${formattedHours}:${formattedMinutes}`;

                return formattedString;
            },
        },
        components:{
            m423CarouselMenu,
            m423QuickMenu,
        },
        props: {
            currentMenuIndex: Number,
            currentChannelIndex: Number,
            isInputVolume: Boolean,
            isInputSql: Boolean,
            isInputChannel: Boolean,
            isInputBacklight: Boolean,
            channelIndex: Number,
            isQuickMenu: Boolean,
            volumeValue: Number,
            sqlValue: Number,
            backlightValue: Number,
            isDualMode: Boolean,
            scanIsRunning: Boolean,
            isHigh: Boolean,
            isInputRX: Boolean,
            rxValue: Number,
            isRX: Boolean,
            isAqua: Boolean,
            isSending: Boolean,
            isBusy: Boolean,
            isAtis: Boolean,
            isDsc: Boolean,
            isInt: Boolean,
            backlightColor: String,
        }
    })
</script>

<style>
    .tx-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        left: 5px;
        width: 40px;
        color: #b56e1c;
        background-color: black;
        top: 3px;
        text-align: center;
    }
    .call-container{
        position: absolute;
        height: 20px;
        width: 110px;
        right: 10px;
        top: 80px;
        justify-content: center;
        align-items: center;
        text-align: center;
        justify-items: center;
    }
    .channel-container{
        position: absolute;
        height: 70px;
        width: 110px;
        right: 12px;
        top: -8px;
        justify-content: center;
        align-items: center;
        text-align: center;
        justify-items: center;
    }
    .rx-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        left: 85px;
        top: 25px;
    }
    .dual-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 10pt;
        left: 6px;
        top: 30px;
    }
    .channel{
        font-weight: 900;
        font-size: 60pt;
    }
    .calling{
        font-family: m423-text-font;
        font-size: 10pt;
    }
    .watt-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        left: 50px;
        top: 3px;
    }
    .dsc-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        left: 85px;
        top: 3px;
    }
    .call-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        left: 85px;
        top: 14px;
    }
    .lat-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 10pt;
        left: 17.5px;
        top: 50px;
    }
    .long-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 10pt;
        left: 6px;
        top: 65px;
    }
    .utc-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 9.5pt;
        font-weight: 100;
        left: 17.5px;
        top: 80px;
    }
    .position-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 10pt;
        left: 6px;
        top: 50px;
    }
    .time-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 9.5pt;
        font-weight: 100;
        left: 6px;
        top: 65px;
    }
    .tag{
        position: absolute;
        font-family: m423-text-font;
        font-size: 20pt;
        font-weight: 100;
        left: 138px;
        top: -2px;
    }
</style>