<template>
    <div @click="$emit('selectDevice', device.id)" class="device-card mx-5 my-5">
        <div class="device-header">
            <h1 class="device-title">{{ device.name }}</h1>
        </div>
        <img class="device-image" cover :src="require('../assets' + device.imagePath)"/>
        <img v-if="device.turnBtnImagePath" class="turn-btn-image" cover :src="require('../assets' + device.turnBtnImagePath)"/>
        <v-icon class="icon" color="green" size="40" v-if="device.selected">mdi-check</v-icon>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent ({
        props: {
            device: Object
        },
    })
</script>

<style lang="scss" scoped>
    .turn-btn-image{
        position: absolute;
        z-index: 15;
        height: 85px;
        width: 100px;
        right: 108px;
        bottom: 36px;
    }
    .icon{
        position: absolute;
        top: 5px;
    }
    .device-card {
        position: relative;
        height: 300px;
        width: 420px;
        text-align: center;
        border-radius: 8%;
        align-items: center;
        background-color: rgb(233, 233, 233);
        border: 1px solid black;
    }

    .device-card:hover{
        cursor: pointer;
        transform: scale(1.05);
    }
    .device-title {
        color: #021df0;
        font-weight: 300;
    }

    .device-image{
        height: 75%;
        width: 100%
    }
    .device-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
</style>