<template>
    <div class="receive-call-container">
        <div class="text-container" v-if="receivedCallMode == 'IND Overview'">
            <p class="">RCVD INDIVIDUAL CALL</p>
            <p class="">FROM: {{transmittedCall.senderMmsi}}</p>
            <p class="">CH {{transmittedCall.channel}}</p>
            <p class="">ELAPSED: {{timer}}</p>
        </div>
        <div class="text-container" v-if="receivedCallMode == 'GRP Overview'">
            <p class="">RCVD GROUP CALL</p>
            <p class="">FROM: {{transmittedCall.senderMmsi}}</p>
            <p class="">CH {{transmittedCall.channel}}</p>
            <p class="">ELAPSED: {{timer}}</p>
        </div>
        <div class="text-container" v-if="receivedCallMode == 'All Ships Overview'">
            <p class="">RCVD ALL SHIPS CALL</p>
            <p class="">FROM: {{transmittedCall.senderMmsi}}</p>
            <p class="">CH {{transmittedCall.channel}}</p>
            <p class="">ELAPSED: {{timer}}</p>
        </div>
        <div class="text-container" v-if="receivedCallMode == 'Distress Overview'">
            <p class="">RCVD DISTRESS</p>
            <p class="">FROM: {{transmittedCall.senderMmsi}}</p>
            <p class="">UNDESIGNATED</p>
            <p class="">ELAPSED: {{timer}}</p>
        </div>
        <div class="text-container" v-if="receivedCallMode == 'Distress Cancel Overview'">
            <p class="">RCVD DISTRESS ACK</p>
            <p class="">FROM: {{transmittedCall.senderMmsi}}</p>
            <p class="">CANCELLED</p>
            <p class="">ELAPSED: {{timer}}</p>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'IND CALL ACK'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">INDIVIDUAL ACK</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <v-list id="myList" class="sub-menu-list">
                <v-list-item ref="menu-item" :class="{ 'focused-item': menuSelectionIndex == 0 }" class="menu-list-item pt-0 pb-0 pl-1">Able to Comply</v-list-item>
                <v-list-item ref="menu-item" :class="{ 'focused-item': menuSelectionIndex == 1 }" class="menu-list-item pt-0 pb-0 pl-1">Unable to Comply</v-list-item>
                <v-list-item ref="menu-item" :class="{ 'focused-item': menuSelectionIndex == 2 }" density append-icon="mdi-menu-right" class="menu-list-item pt-0 pb-0 pl-1">Propose New Channel</v-list-item>
            </v-list>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'IND CALL ACK DETAILS'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">INDIVIDUAL ACK</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <v-list id="myList" class="sub-menu-list">
                <v-list-item ref="menu-item" class="menu-list-item pt-0 pb-0 pl-1">To: {{transmittedCall.senderMmsi}}</v-list-item>
                <v-list-item ref="menu-item" class="menu-list-item pt-0 pb-0 pl-1">{{transmittedCall.category}}</v-list-item>
                <v-list-item ref="menu-item" class="menu-list-item pt-0 pb-0 pl-1">CH {{ transmittedCall.channel }}</v-list-item>
                <v-list-item ref="menu-item" class="menu-list-item pt-0 pb-0 pl-1">{{individualAckDetails.callChannel.description}}</v-list-item>
                <v-list-item ref="menu-item" class="menu-list-item pt-0 pb-0 pl-1">{{individualAckDetails.isAbleToComply ? 'Able to Comply' : 'Unable to Comply'}}</v-list-item>
            </v-list>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'IND CALL ACK SENT'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">INDIVIDUAL ACK</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p class="channel-text">{{ channelString }}</p>
            <div class="divider"></div>
            <p class="from-text">To: {{ transmittedCall.receiverMmsi }}</p>
            <p class="elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'GRP ACCEPTED'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">RCVD GROUP CALL</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p class="channel-text">{{ channelString }}</p>
            <div class="divider"></div>
            <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
            <p class="elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'All Ships ACCEPTED'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">RCVD ALL SHIPS CALL</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p class="channel-text">{{ channelString }}</p>
            <div class="divider"></div>
            <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
            <p class="elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'Distress ACCEPTED'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">RCVD DISTRESS</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p class="channel-text">{{ channelString }}</p>
            <div class="divider"></div>
            <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
            <p class="elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'Distress Cancel ACCEPTED'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">RCVD DISTRESS CANCEL</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <p v-if="isSending || isBusy" class="busy-text">{{ isSending ? 'TX' : 'BUSY' }}</p>
            <p class="watt-text">{{ isHigh ? '25W' : '1W' }}</p>
            <p class="channel-text">{{ channelString }}</p>
            <div class="divider"></div>
            <p class="from-text">From: {{ transmittedCall.senderMmsi }}</p>
            <p class="elapsed-timer-text">Elapsed: {{timer}}</p>
        </div>
        <div class="ack-container" v-else-if="receivedCallMode == 'RCVD Call Log Temp'">
            <div class="menu-header">
                <v-icon size="16" :color="backlightColor" class="icon-left">mdi-menu</v-icon>
                <p class="menu-header-title">RCVD CALL LOG</p>
                <v-icon size="16" :color="backlightColor" class="icon-right">mdi-menu</v-icon>
            </div>
            <v-list class="sub-menu-list">
                <v-list-item density class="menu-list-item type pt-0 pb-0 pl-1">{{ selectedCallLog.type }}</v-list-item>
                <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ 'From: ' + selectedCallLog.from }}</v-list-item>
                <v-list-item density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.category }}</v-list-item>
                <v-list-item v-if="selectedCallLog.lat" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.lat }}</v-list-item>
                <v-list-item v-if="selectedCallLog.lon" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.lon }}</v-list-item>
                <v-list-item v-if="selectedCallLog.utc" density class="menu-list-item pt-0 pb-0 pl-1">{{ selectedCallLog.utc + ' UTC' }}</v-list-item>
                <v-list-item v-if="selectedCallLog.channel" density class="menu-list-item pt-0 pb-0 pl-1">{{ 'CH ' + selectedCallLog.channel }}</v-list-item>
                <v-list-item density class="menu-list-item channel-type pt-0 pb-0 pl-1">{{ selectedCallLog.channelType }}</v-list-item>
        </v-list>
        </div>
    </div>
    <div v-if="!alarmOff && (receivedCallMode == 'IND Overview' || receivedCallMode == 'GRP Overview' || receivedCallMode == 'All Ships Overview' || receivedCallMode == 'Distress Overview' || receivedCallMode == 'Distress Cancel Overview')" class="alarm-off-btn">ALARM OFF</div>
    <div v-if="alarmOff && (receivedCallMode == 'IND Overview' || receivedCallMode == 'GRP Overview' || receivedCallMode == 'All Ships Overview' || receivedCallMode == 'Distress Overview' || receivedCallMode == 'Distress Cancel Overview' || receivedCallMode == 'RCVD Call Log Temp')" class="ignore-btn">IGN</div>
    <div v-if="alarmOff && (receivedCallMode == 'IND Overview' || receivedCallMode == 'GRP Overview' || receivedCallMode == 'All Ships Overview' || receivedCallMode == 'Distress Overview' || receivedCallMode == 'Distress ACCEPTED' || receivedCallMode == 'Distress Cancel Overview' || receivedCallMode == 'Distress Cancel ACCEPTED')" class="info-btn">INFO</div>
    <div v-if="alarmOff && (receivedCallMode == 'GRP Overview' || receivedCallMode == 'All Ships Overview' || receivedCallMode == 'Distress Overview' || receivedCallMode == 'Distress Cancel Overview')" class="ack-btn">ACPT</div>
    <div v-if="alarmOff && (receivedCallMode == 'IND Overview' || receivedCallMode == 'RCVD Call Log Temp')" class="ack-btn">ACK</div>
    <div v-if="receivedCallMode == 'IND CALL ACK' || receivedCallMode == 'IND CALL ACK DETAILS' || receivedCallMode == 'IND CALL ACK SENT' || receivedCallMode == 'GRP ACCEPTED' || receivedCallMode == 'All Ships ACCEPTED' || receivedCallMode == 'Distress ACCEPTED' || receivedCallMode == 'Distress Cancel ACCEPTED'" class="ignore-btn">EXIT</div>
    <div v-if="receivedCallMode == 'IND CALL ACK' || receivedCallMode == 'IND CALL ACK DETAILS' || receivedCallMode == 'RCVD Call Log Temp'" class="info-btn">BACK</div>
    <div v-if="receivedCallMode == 'IND CALL ACK' || receivedCallMode == 'IND CALL ACK DETAILS'" class="ack-btn">{{this.receivedCallMode == 'IND CALL ACK DETAILS' ? 'CALL' : 'ENT'}}</div>
</template>

<script>
    import { defineComponent } from 'vue'
    export default defineComponent({
        data(){
            return{
                timer: '00:00:00',
                timerInterval: undefined,
                seconds: 0,
            }
        },
        mounted(){
            this.startTimer();
        },
        computed: {
            transmittedCall(){
                return this.$store.getters['transmittedCall'];
            },
            individualAckDetails(){
                return this.$store.getters['m423/individualAckDetails'];
            },
            selectedCallLog(){
                return this.$store.getters['m423/receivedCalls'].other[0];
            }
        },
        watch: {
            menuSelectionIndex(value){
                if(this.receivedCallMode == 'IND CALL ACK DETAILS'){
                    let element = document.querySelectorAll('.menu-list-item')[value];
                    this.scrollIntoViewIfNeeded(element);
                }
                else if(this.receivedCallMode == 'RCVD Call Log Temp'){
                    switch(this.menuSelectionIndex){
                        case 0: {
                            let element = document.querySelectorAll('.type')[value];
                            this.scrollIntoViewIfNeeded(element);
                            break;
                        }
                        case 1: {
                            let element = document.querySelectorAll('.channel-type')[value];
                            this.scrollIntoViewIfNeeded(element);
                            break;
                        }
                    }
                }
            }
        },
        props: {
            receivedCallMode: String,
            alarmOff: Boolean,
            menuSelectionIndex: Number,
            channelString: String,
            isHigh: Boolean,
            isSending: Boolean,
            isBusy: Boolean,
            backlightColor: String,
        },
        methods:{
            scrollIntoViewIfNeeded(focusedItem) {
                var listContainer = null;
                if(document.querySelector('.sub-menu-list')){
                    listContainer = document.querySelector('.sub-menu-list');
                }

                if (focusedItem) {
                    const containerRect = listContainer.getBoundingClientRect();
                    const itemRect = focusedItem.getBoundingClientRect();

                    if (itemRect.top < containerRect.top || itemRect.bottom > containerRect.bottom) {
                        focusedItem.scrollIntoView({ behavior: "smooth", block: 'end' });
                    }
                }
            },
            startTimer(){
                this.timerInterval = setInterval(() => {
                    this.interval();
                },1000);
            },
            interval(){
                this.seconds++;
                var hour = Math.floor(this.seconds / 3600);
                var minute = Math.floor((this.seconds - hour * 3600) / 60);
                var updSecond = this.seconds - (hour * 3600 + minute * 60);
                this.timer = (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (updSecond < 10 ? '0' + updSecond : updSecond);
            }
        }
        
    })
</script>

<style scoped>
    .busy-text{
        position: absolute;
        font-family: m423-text-font;
        font-size: 7pt;
        top:25px;
        left: 5px;
        color: v-bind('backlightColor') !important;
        padding-left: 4px;
        padding-right: 2px;
        border-radius: 20%;
        text-align: center;
        background-color: rgb(37, 37, 37);
    }
    .elapsed-timer-text{
        position: absolute;
        top: 68px;
        left: 5px;
        font-family: m423-text-font;
        font-size: 9pt;
    }
    .from-text{
        position: absolute;
        top: 52px;
        left: 5px;
        font-family: m423-text-font;
        font-size: 9pt;
    }
    .divider{
        position: absolute;
        top: 50px;
        width: 98%;
        height: 1px;
        border: 1px solid rgb(37, 37, 37);
    }
    .watt-text{
        position: absolute;
        font-size: 8pt;
        top: 25px;
    }
    .channel-text{
        position: absolute;
        font-size: 45pt;
        top: -5px;
        left: 150px;
        font-family: m423-font;
    }
    .sub-menu-list{
        position: absolute;
        top:15px;
        left: 2px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        right: 0;
        width: 98%;
        height: 75%;
        padding-top: 4px;
        background-color: v-bind('backlightColor') !important;
    }
    .v-list-item__content{
        width: fit-content
    }
    .v-list-item{
        min-height: 15px !important;
        
    }
    .sub-menu-container{
        position: absolute;
        height: 90px;
        width: 227px;
        left: 5px;
        top: 5px;
        border: 2px solid rgb(37, 37, 37);;
        border-top: 20px solid rgb(37, 37, 37);;
    }
    .sub-menu-list::-webkit-scrollbar{
        background-color: v-bind('backlightColor') !important;
        width: 5px;
    }

    .sub-menu-list::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgb(37, 37, 37);;
        border: 1px solid #000;
    }
    .menu-list-item{
        font-size: 8pt;
        height: 5px !important;
        white-space: nowrap;
        font-family: m423-text-font;
        color: rgb(37, 37, 37);
    }
    .focused-item {
        background-color: rgb(37, 37, 37); /* Customize the focused item's style here */
        color: v-bind('backlightColor') !important;
    }
    .ack-container{
        height: 100%;
        width: 100%;
        font-family: m423-text-font;
        font-size: 9pt;
        border: 3px solid rgb(37, 37, 37);
    }
    .menu-header{
        position: absolute;
        top: 1px;
        left: 0px;
        width: 214px;
        height: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
        padding-left: 2%;
        padding-right: 1%;
        text-align: center;
        background-color: rgb(37, 37, 37);
    }
    .menu-header-title{
        color: v-bind('backlightColor') !important;
        font-family: m423-font;
        font-size: 11pt;
        padding-top: 5px;
    }
    .ack-btn{
        position:absolute;
        bottom: 3px;
        right: 12px;
        background-color: rgb(37, 37, 37);
        height: 15px;
        width: 45px;
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: v-bind('backlightColor') !important;
        font-size: 12pt;
        font-family: ds-digi;
    }
    .info-btn{
        position:absolute;
        bottom: 3px;
        left: 68px;
        background-color: rgb(37, 37, 37);
        height: 15px;
        width: 45px;
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: v-bind('backlightColor') !important;
        font-size: 12pt;
        font-family: ds-digi;
    }
    .ignore-btn{
        position:absolute;
        bottom: 3px;
        left: 12px;
        background-color: rgb(37, 37, 37);
        height: 15px;
        width: 45px;
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: v-bind('backlightColor') !important;
        font-size: 12pt;
        font-family: ds-digi;
    }
    .alarm-off-btn{
        position:absolute;
        bottom: 3px;
        left: 10px;
        background-color: rgb(37, 37, 37);
        height: 15px;
        width: 90%;
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        color: v-bind('backlightColor') !important;
        font-size: 12pt;
        font-family: ds-digi;
    }
    .text-container{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-family: m423-text-font;
        font-size: 9pt;
        flex-direction: column;
        border: 5px solid rgb(37, 37, 37);
    }
    .receive-call-container{
        position: absolute;
        top: 7px;
        left: 10px;
        height: 75%;
        width: 90%;
    }
</style>