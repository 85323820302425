<template>
    <div>
        <div v-for="(item,index) in items" :key="index" class="container">
            <input ref="ds100Input" class="input">
            <p class="number">{{ item }}</p>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        watch: {
            isActive(val){
                if(val){
                    switch(this.$props.mode){
                        case 'individual':{
                            this.$refs['ds100Input'][0].focus();
                            break;
                        }
                        case 'group': {
                            this.$refs['ds100Input'][1].focus();
                            break;
                        }
                        default: {
                            this.$refs['ds100Input'][0].focus();
                            break;
                        }
                    }
                    this.focusInterval = setInterval(() => {
                        this.$refs['ds100Input'][this.$props.selectedIndex].focus();
                    }, 500);
                }
                else {
                    clearInterval(this.focusInterval);
                }
            }
        },
        data(){
            return {
                focusInterval: null,
            }
        },
        mounted(){
            if(this.$props.isActive){
                switch(this.$props.mode){
                case 'individual':{
                    this.$refs['ds100Input'][0].focus();
                    break;
                }
                case 'group': {
                    this.$refs['ds100Input'][1].focus();
                    break;
                }
                default: {
                    this.$refs['ds100Input'][0].focus();
                    break;
                }
            }
            }
        },
        beforeUnmount(){
            clearInterval(this.focusInterval);
        },
        updated(){
            if(this.$props.isActive){
                this.$refs['ds100Input'][this.$props.selectedIndex].focus();
            }
        },
        props: {
            items: Array,
            selectedIndex: Number,
            mode: String,
            isActive: Boolean,
        }
    })
</script>

<style>
    .container{
        font-size: 10pt;
        margin-left: 10px;
        margin-top: 5%;

    }
    .number{
        position: absolute;
        font-family: ds-digi;
        font-size: 14pt;
    }
    .input:focus{
        animation-name: blink;
        animation-duration: 900ms;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
        outline: none;
        color: transparent;
        text-shadow: 0 0 0 #000000;
        width: 8px;
        top: 8px;
        height: 15px;
    }
    .input{
        position: absolute;
    }
    @keyframes blink {
        0% {
            opacity: 0;
            background-color: #000000;
        }
        50% {
            opacity: 0.8;
            background-color: #000000;
        }
        100% {
            opacity: 0;
            background-color: #000000;
        }
    }
</style>