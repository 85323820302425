<template>
    <div class="address-container-normal" v-if="mode == 'individual' || mode == 'group'">
        <ul class="address-list-normal">
            <li class="selection-item-normal" v-for="(address, index) in addressIds" :key="index">
                {{ address.name.replaceAll('"', '').replaceAll("'", '') }}
            </li>
        </ul>
    </div>
    <div class="address-container" v-if="mode == 'Individual Call'">
        <ul class="address-list-call">
            <li class="selection-item" v-for="(address, index) in callAdresses" :key="index">
                {{ address.replaceAll('"', '').replaceAll("'", '') }}
            </li>
        </ul>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    export default defineComponent ({
        data() {
            return {
                callAdresses: []
            }
        },
        mounted(){
            if(this.$props.mode === "individual"){
                if(this.addressIds.length > 0 && this.addressIds[0].id === 0){
                    return;
                }
                this.$store.dispatch('ds100/addManualEntry', 'address');
            }
            else if(this.$props.mode === "Individual Call"){
                this.callAdresses.push(this.transmittedCall.senderMmsi.toString());
                this.callAdresses.push('Other');
            }
        },
        props: {
            mode: String,
        },
        computed: {
            addressIds(){
                return this.$store.getters['ds100/addressIds'];
            },
            transmittedCall(){
                return this.$store.getters['transmittedCall'];
            }
        }
    });
</script>

<style scoped>
    .address-list-normal{
        list-style:none;
        position: absolute;
        top: 23px;
        left: 20px;
    }
    .address-list-call{
        list-style:none;
        position: absolute;
        top: 28px;
        left: 20px;
    }
    .selection-item{
        height: 17px;
        font-family: ds-digi;
        font-size: 12pt;
    }
    .selection-item-normal{
        height: 17px;
        font-family: ds-digi;
        font-size: 12pt;
    }
</style>